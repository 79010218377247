import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { TableFunctions } from "./ui";
import { enums, strings } from "../resources";
import { dateEpochToDateTimeString } from "../../App/helpers/DateTimeFormatConversion";
import { Popup } from "semantic-ui-react";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      return {
        onClick: () => {
          if (rowInfo) {
            this.props.history.push({
              pathname: "/forms/" + rowInfo.original.form_type,
              state: {
                patientId: rowInfo.original.patient_id,
                formId: rowInfo.original.form_details,
                mode: "view",
              },
            });
          }
        },
      };
    }
    return {}; // getTdProps requires a return statement
  };

  render = () => {
    let data = this.props.formListData;

    const lookupFormName = () => {
      return (row) => {
        let value = row.value ? row.value : "";
        let ft = null;
        for (ft in enums.formType) {
          if (
            enums.formType[ft]["type"] === value ||
            enums.formType[ft]["link"] === value
          ) {
            value = <div>{enums.formType[ft]["name"]}</div>;
          }
        }
        return value;
      };
    };

    return (
      <div>
        <TableFunctions
          pageTitle={this.props.pageTitle}
          history={this.props.history}
          setFormType={this.props.setFormType}
          patientData={this.props.patientData}
          patientFillsInForm={this.props.patientFillsInForm}
          toggleFormFillerChange={this.props.toggleFormFillerChange}
        />
        <div>
          <ReactTable
            data={data.results}
            columns={[
              {
                Header: strings.table.header.formType,
                accessor: "form_type",
                headerStyle: { textAlign: "left" },
                Cell: lookupFormName(),
              },
              {
                Header: strings.table.header.createdDate,
                accessor: "created_timestamp",
                headerStyle: { textAlign: "left" },
                Cell: (row) => (
                  <span>{dateEpochToDateTimeString(row.value)}</span>
                ),
              },
              {
                Header: strings.table.header.doctorSigned,
                accessor: "doctor_signature_applied_date",
                headerStyle: { textAlign: "left" },
                Cell: (row) => {
                  const formData = row.original;
                  return (
                    <React.Fragment>
                      {formData.form_type.startsWith("consent") ? (
                        <React.Fragment>
                          {formData.doctor_signature_applied_date ? (
                            <span>{strings.table.content.hasSignature}</span>
                          ) : (
                            <Popup
                              trigger={
                                <div>
                                  <span>
                                    {strings.table.content.needsSignature}
                                  </span>
                                </div>
                              }
                            >
                              <Popup.Content>
                                {this.props.systemHeader_permissions ===
                                "doctor" ? (
                                  <span>
                                    {strings.table.content.signaturePopupDoctor}
                                  </span>
                                ) : (
                                  <span>
                                    {strings.table.content.signaturePopupOther}
                                  </span>
                                )}
                              </Popup.Content>
                            </Popup>
                          )}
                        </React.Fragment>
                      ) : (
                        <span>{strings.table.content.noSignatureRequired}</span>
                      )}
                    </React.Fragment>
                  );
                },
              },
            ]}
            defaultSorted={[
              {
                id: "created_timestamp",
                desc: true,
              },
            ]}
            className="-striped -highlight"
            defaultPageSize={10}
            loading={this.props.loadingTable}
            filterable={false}
            getTdProps={this.getTdProps}
            getTrProps={this.getTrProps}
            minRows="1"
            noDataText="No results found."
            pageSizeOptions={[10, 20, 30]}
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  formListData: PropTypes.object.isRequired,
  deleteFormModalOpen: PropTypes.bool.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setDeleteFormModalVisibility: PropTypes.func.isRequired,
  setFormType: PropTypes.func,
};

export default TableComponent;
