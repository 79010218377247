import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Menu } from "semantic-ui-react";
import { enums } from "../../../resources/Enums";
import ShowIfAuthorised from "../../ShowIfAuthorised";
import "./css/topMenuBar.css";

const TopMenuBar = (props) => (
  <div
    className="topMenuImage"
    style={{
      height: props.isMobile ? 75 : 85,
      margin: "0",
    }}
  >
    <Menu inverted pointing secondary className="topMenuBar" size="large">
      {/* Hide the hamburger button on the menu bar if the patient role is logged in, because they don't have permissions to view the menu */}
      <ShowIfAuthorised
        allowedRoles={[
          enums.role.PULSION,
          enums.role.ADMIN,
          enums.role.DOCTOR,
          enums.role.RECEPTIONIST,
        ]}
      >
        <Menu.Item onClick={props.toggleSidebar}>
          <Icon name="sidebar" />
        </Menu.Item>
      </ShowIfAuthorised>

      {props.isLoggedIn && (
        <Menu.Item position="right">
          <Button className="logOutButton" onClick={props.logout}>
            Log Out
          </Button>
        </Menu.Item>
      )}
    </Menu>
  </div>
);

TopMenuBar.propTypes = {
  isMobile: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  role: PropTypes.string,
};

export default TopMenuBar;
