export const imageActionTypes = {
  PURGE_STATE: "PURGE_STATE",

  GET_IMAGES_REQUEST: "GET_IMAGES_REQUEST",
  GET_IMAGES_SUCCESS: "GET_IMAGES_SUCCESS",
  GET_IMAGES_FAILURE: "GET_IMAGES_FAILURE",

  SET_LOADING_IMAGES_SUCCESS: "SET_LOADING_IMAGES_SUCCESS",

  UPDATE_LOCAL_IMAGES_SUCCESS: "UPDATE_LOCAL_IMAGES_SUCCESS",

  UPLOAD_IMAGE_REQUEST: "UPLOAD_IMAGE_REQUEST",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",

  DELETE_IMAGE_REQUEST: "DELETE_IMAGE_REQUEST",
  DELETE_IMAGE_SUCCESS: "DELETE_IMAGE_SUCCESS",
  DELETE_IMAGE_FAILURE: "DELETE_IMAGE_FAILURE",

  SET_FILE_TYPE_WARNING_VISIBLE_REQUEST: "SET_FILE_TYPE_WARNING_VISIBLE_REQUEST",

  UPDATE_IMAGE_REQUEST: "UPDATE_IMAGE_REQUEST",
  UPDATE_IMAGE_SUCCESS: "UPDATE_IMAGE_SUCCESS",
  UPDATE_IMAGE_FAILURE: "UPDATE_IMAGE_FAILURE",

  GET_PATIENT_SIGNATURE_REQUEST: "GET_PATIENT_SIGNATURE_REQUEST",
  GET_PATIENT_SIGNATURE_SUCCESS: "GET_PATIENT_SIGNATURE_SUCCESS",
  GET_PATIENT_SIGNATURE_FAILURE: "GET_PATIENT_SIGNATURE_FAILURE",

  GET_DOCTOR_SIGNATURE_REQUEST: "GET_DOCTOR_SIGNATURE_REQUEST",
  GET_DOCTOR_SIGNATURE_SUCCESS: "GET_DOCTOR_SIGNATURE_SUCCESS",
  GET_DOCTOR_SIGNATURE_FAILURE: "GET_DOCTOR_SIGNATURE_FAILURE",

  UPDATE_SIGNATURE_OBJECT_SUCCESS: "UPDATE_SIGNATURE_OBJECT_SUCCESS",
  
  UPDATE_ANNOTATION_OBJECT_SUCCESS: "UPDATE_ANNOTATION_OBJECT_SUCCESS",

  GET_ANNOTATION_IMAGE_REQUEST: "GET_ANNOTATION_IMAGE_REQUEST",
  GET_ANNOTATION_IMAGE_SUCCESS: "GET_ANNOTATION_IMAGE_SUCCESS",
  GET_ANNOTATION_IMAGE_FAILURE: "GET_ANNOTATION_IMAGE_FAILURE",

  UPDATE_PHOTO_FILE_NAME_SUCCESS: "UPDATE_PHOTO_FILE_NAME_SUCCESS",

  RESET_LOCAL_IMAGE_DATA_SUCCESS: "RESET_LOCAL_IMAGE_DATA_SUCCESS",

  GET_SINGLE_IMAGE_REQUEST: "GET_SINGLE_IMAGE_REQUEST",
  GET_SINGLE_IMAGE_SUCCESS: "GET_SINGLE_IMAGE_SUCCESS",
  GET_SINGLE_IMAGE_FAILURE: "GET_SINGLE_IMAGE_FAILURE",

  SET_LOADING_FULLSIZED_IMAGE_SUCCESS: "SET_LOADING_FULLSIZED_IMAGE_SUCCESS",

  CLEAR_DOCTOR_SIGNATURE_SUCCESS: "CLEAR_DOCTOR_SIGNATURE_SUCCESS",

  CLEAR_ANNOTATIONS_OBJECT_SUCCESS: "CLEAR_ANNOTATIONS_OBJECT_SUCCESS",
};
