import React from "react";
import PropTypes from "prop-types";
import { Button, Divider, Form, Message } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { EditDeleteButtons } from "../../App/components/Buttons";
import { strings } from "../resources";
import "./css/TreatmentComponents.css";
import DoctorSignatureContainer from "../../Images/containers/DoctorSignatureContainer";

class FollowUpNoteView extends React.Component {
  render = () => {

    const editDeleteButtons = (
      <EditDeleteButtons
        handleModalConfirm={this.props.handleModalConfirm}
        handleModeSwitch={this.props.handleModeSwitch}
        confirmDeleteModalOpen={this.props.confirmDeleteModalOpen}
        toggleConfirmDeleteModalVisibility={this.props.toggleConfirmDeleteModalVisibility}
      ></EditDeleteButtons>);

    const downloadButton = (props) => {
      return (
        <div className="downloadButtons" data-html2canvas-ignore="true">
          <Button.Group>
            <Button
              type="button"
              className="downloadButton"
              onClick={props.downloadFormPdf}
              loading={props.generatingPdf}
            >
              {strings.button.downloadFormLabel}
            </Button>
          </Button.Group>
        </div>
      );
    };

    const followUpNoteForm = this.props.selectedPatientData ? (
      <>  
        <div className="sectionHeader">
          {strings.form.header.followUpNoteReport}
        </div>

        <div className="additionalOutcomeDetails">
          <Form.Group>
            {this.props.selectedTreatmentData.was_expected_outcome ===
              strings.form.label.radioYes && (
              <div className="followUpNoteReport">
                {strings.form.patientMappings.expectedOutcome}
              </div>
            )}
            {this.props.selectedTreatmentData.was_expected_outcome ===
              strings.form.label.radioNo && (
              <div className="followUpNoteReport">
                {strings.form.patientMappings.notExpectedOutcome}
              </div>
            )}
          </Form.Group>

          <Form.Group>
            {this.props.selectedTreatmentData.were_there_complications ===
              strings.form.label.radioYes && (
              <div className="followUpNoteReport">
                {strings.form.patientMappings.experiencedComplications}
              </div>
            )}
            {this.props.selectedTreatmentData.were_there_complications ===
              strings.form.label.radioNo && (
              <div className="followUpNoteReport">
                {strings.form.patientMappings.experiencedNoComplications}
              </div>
            )}
          </Form.Group>
          <Form.Group>
            <div className="followUpNoteReport">
              {this.props.selectedTreatmentData.additional_outcome_details}
            </div>
          </Form.Group>
          
          {this.props.selectedTreatmentData.doctor_signature_name != null &&
            this.props.selectedTreatmentData.doctor_signature_path != null &&
            this.props.selectedTreatmentData.doctor_signature_applied_date !=
              null && (
              <DoctorSignatureContainer
                containerWidth={240}
                containerHeight={150}
                dateDoctorSigned={
                  this.props.selectedTreatmentData.doctor_signature_applied_date
                }
                doctorName={
                  this.props.selectedTreatmentData.doctor_signature_name
                }
                formId={null}
                headers={null}
                signaturePathAttachedToForm={
                  this.props.selectedTreatmentData.doctor_signature_path
                }
              />
            )}
        </div>
      </>
    ) : (
      <Redirect to="/patients" />
    );

    return (
      <div className="FollowUpNoteContainer" id="formDiv">
        {this.props.validationErrors.length > 0 && (
          <div className="errorMessageContainer">
            <Message
              compact
              error
              header={strings.form.label.errorHeader}
              list={this.props.validationErrors.map((error) => {
                return error.error;
              })}
            />
          </div>
        )}
        <div className="pageHeader">{this.props.pageTitle}</div>
        <Form
          className="details"
        >
          <fieldset disabled className="treatmentFormFieldset">
            {followUpNoteForm}
          </fieldset>
        </Form>
        <div className="centerContent">
          {editDeleteButtons}
          <Divider hidden />
          {downloadButton(this.props)}
        </div>
      </div>
    );
  };
}

FollowUpNoteView.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedTreatmentData: PropTypes.object.isRequired,
  selectedPatientData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmDeleteModalOpen: PropTypes.bool.isRequired,
  toggleConfirmDeleteModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModeSwitch: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
};

export default FollowUpNoteView;
