import { authActionTypes } from "./auth.actionTypes";
import { authService } from "../../services/auth.service";

const generateSystemHeaders = (payload) => {
  const success = (headers) => {
    return { type: authActionTypes.GENERATE_SYSTEM_HEADERS_SUCCESS, headers };
  };
  const failure = (error) => {
    return { type: authActionTypes.GENERATE_SYSTEM_HEADERS_FAILURE, error };
  };

  return (dispatch) => {
    authService.generateSystemHeaders(payload).then(
      (headers) => dispatch(success(headers)),
      (error) => dispatch(failure(error))
    );
  };
};

const setSystemHeader = (header, value) => {
  const request = (header, value) => {
    return { type: authActionTypes.SET_SYSTEM_HEADER_REQUEST, header, value };
  };
  const success = (headers) => {
    return { type: authActionTypes.SET_SYSTEM_HEADER_SUCCESS, headers };
  };
  const failure = (error) => {
    return { type: authActionTypes.SET_SYSTEM_HEADER_FAILURE, error };
  };

  return (dispatch) => {
    dispatch(request(header, value));

    authService.setSystemHeader(header, value).then(
      (headers) => dispatch(success(headers)),
      (error) => dispatch(failure(error))
    );
  };
};

const refreshTokens = (user) => {
  const request = (user) => {
    return { type: authActionTypes.REFRESH_TOKENS_REQUEST, user };
  };
  const success = (user) => {
    return { type: authActionTypes.REFRESH_TOKENS_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: authActionTypes.REFRESH_TOKENS_FAILURE, error };
  };

  return (dispatch) => {
    dispatch(request(user));

    authService.refreshTokens().then(
      (user) => dispatch(success(user)),
      (error) => dispatch(failure(error))
    );
  };
};

const signUp = (
  username,
  password,
  email,
  role,
  signature_path,
  given_name,
  family_name
) => {
  const request = () => {
    return { type: authActionTypes.SIGNUP_REQUEST };
  };
  const success = () => {
    return { type: authActionTypes.SIGNUP_SUCCESS };
  };
  const failure = (error) => {
    return { type: authActionTypes.SIGNUP_FAILURE, error };
  };

  return (dispatch) => {
    dispatch(request());

    authService
      .signUp(
        username,
        password,
        email,
        role,
        signature_path,
        given_name,
        family_name
      )
      .then((user) => {
        console.log(user);
        dispatch(success());
      })
      .catch((error) => {
        console.log(error.message);
        let message = "Signup failure";

        try {
          if (JSON.stringify(error.message).includes("policy")) {
            message = "Password does not conform with policy.";
          }
        } catch (err) {}

        dispatch(failure(message));
      });
  };
};

const signInWithLink = (usernamePasswordOpts) => {
  const request = (username) => {
    return { type: authActionTypes.SIGNIN_REQUEST, username };
  };
  const success = (signInInfo) => {
    return { type: authActionTypes.SIGNIN_SUCCESS, signInInfo };
  };
  const failure = (error) => {
    return { type: authActionTypes.SIGNIN_FAILURE, error };
  };

  return (dispatch) => {
    if (usernamePasswordOpts.username.length === 0) {
      dispatch(failure("Username must be provided"));
      return;
    }
    dispatch(request(usernamePasswordOpts.username));

    authService
      .signInWithLink(usernamePasswordOpts)
      .then(async (details) => {
        //NOTE: We need to retrieve more than just the role from the login request, to set up the headers
        // generate the microservice system headers
        await dispatch(generateSystemHeaders(details));

        dispatch(success(details));
      })
      .catch((error) => {
        let message =
          "Incorrect Username or Link Verification Details. Your account may need to be confirmed by an Administrator";

        try {
          if (error.code === "PasswordResetRequiredException") {
            message = "Password reset required for the user";
          } else if (error.code === "UserNotConfirmedException") {
            message = "This account needs confirmed by an Administrator";
          } else if (JSON.stringify(error.message).includes("Inactive")) {
            message = "This account has been set to inactive";
          } else if (JSON.stringify(error.message).includes("disabled")) {
            message = "This account has been disabled";
          }
        } catch (err) {}

        dispatch(failure(message));
      });
  };
};

const signIn = (username, password) => {
  const request = (username) => {
    return { type: authActionTypes.SIGNIN_REQUEST, username };
  };
  const success = (signInInfo) => {
    return { type: authActionTypes.SIGNIN_SUCCESS, signInInfo };
  };
  const failure = (error) => {
    return { type: authActionTypes.SIGNIN_FAILURE, error };
  };

  return (dispatch) => {
    if (username.length === 0 || password.length === 0) {
      dispatch(failure("Username and password must be provided"));
      return;
    }

    dispatch(request(username));

    authService
      .signIn(username, password)
      .then(async (details) => {
        //NOTE: We need to retrieve more than just the role from the login request, to set up the headers
        // generate the microservice system headers
        await dispatch(generateSystemHeaders(details));

        dispatch(success(details));
      })
      .catch((error) => {
        let message =
          "Incorrect Username or Password. Your account may need to be confirmed by an Administrator";

        try {
          if (error.code === "PasswordResetRequiredException") {
            message = "Password reset required for the user";
          } else if (error.code === "UserNotConfirmedException") {
            message = "This account needs confirmed by an Administrator";
          } else if (JSON.stringify(error.message).includes("Inactive")) {
            message = "This account has been set to inactive";
          } else if (JSON.stringify(error.message).includes("disabled")) {
            message = "This account has been disabled";
          }
        } catch (err) {}

        dispatch(failure(message));
      });
  };
};

const signOut = () => {
  const request = () => {
    return { type: authActionTypes.SIGNOUT_REQUEST };
  };
  const success = () => {
    return { type: authActionTypes.SIGNOUT_SUCCESS };
  };
  const failure = (error) => {
    return { type: authActionTypes.SIGNOUT_FAILURE, error };
  };

  return (dispatch) => {
    new Promise(function (resolve, reject) {
      dispatch(request());

      authService
        .signOut()
        .then(() => {
          dispatch(success());
          resolve();
        })
        .catch((error) => {
          dispatch(failure(error));
          reject(error);
        });
    });
  };
};

const refreshSignInStatus = () => {
  const request = () => {
    return { type: authActionTypes.CHECK_SIGNIN_STATUS };
  };
  const refresh = (status) => {
    return { type: authActionTypes.REFRESH_SIGNIN_STATUS, status };
  };

  return (dispatch) => {
    dispatch(request());

    authService
      .getCurrentUserSession()
      .then((session) => {
        dispatch(refresh(session));
      })
      .catch((error) => {
        //dispatch(failure(message));
        console.log(error);
      });
  };
};

const checkCurrentAuthenticatedUser = () => {
  const request = () => {
    return { type: authActionTypes.CHECK_AUTHENTICATION_STATUS_REQUEST };
  };
  const success = (user) => {
    return { type: authActionTypes.CHECK_AUTHENTICATION_STATUS_SUCCESS, user };
  };
  return (dispatch) => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      authService
        .getCurrentAuthenticatedUser()
        .then((user) => {
          dispatch(success(user));
          resolve(user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const changePassword = (oldPassword, newPassword, newPasswordConfirmation) => {
  const request = () => {
    return { type: authActionTypes.CHANGE_PASSWORD_REQUEST };
  };
  const success = () => {
    return { type: authActionTypes.CHANGE_PASSWORD_SUCCESS };
  };
  const failure = (error) => {
    return { type: authActionTypes.CHANGE_PASSWORD_FAILURE, error };
  };

  return (dispatch) => {
    dispatch(request());

    authService
      .changePassword(oldPassword, newPassword, newPasswordConfirmation)
      .then(
        () => dispatch(success()),
        (error) => dispatch(failure(error))
      );
  };
};

const purgeSystemHeaders = () => {
  const request = () => {
    return { type: authActionTypes.PURGE_SYSTEM_HEADERS_REQUEST };
  };
  const success = () => {
    return { type: authActionTypes.PURGE_SYSTEM_HEADERS_SUCCESS };
  };

  return (dispatch) => {
    dispatch(request());
    dispatch(success());
  };
};

const setSessionExpired = () => {
  const request = () => {
    return { type: authActionTypes.SET_SESSION_EXPIRED_REQUEST };
  };
  const success = (message) => {
    return { type: authActionTypes.SET_SESSION_EXPIRED_SUCCESS, message };
  };
  return (dispatch) => {
    dispatch(request());
    let message = "Your session has expired, please log in again";
    dispatch(success(message));
  };
};

export const authActions = {
  generateSystemHeaders,
  setSystemHeader,
  refreshTokens,
  signUp,
  signInWithLink,
  signIn,
  signOut,
  refreshSignInStatus,
  checkCurrentAuthenticatedUser,
  changePassword,
  purgeSystemHeaders,
  setSessionExpired,
};
