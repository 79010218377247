import { treatmentActionTypes } from "../actions/treatment.actionTypes";

const initialState = {
  loadingPage: false,
  selectedTreatmentData: {},
  treatmentListData: [{}],
  treatmentToDelete: "",
  result: [],
  error: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case treatmentActionTypes.CREATE_TREATMENT_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentActionTypes.CREATE_TREATMENT_SUCCESS:
      return { ...state, loadingPage: false, result: action.data };
    case treatmentActionTypes.CREATE_TREATMENT_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case treatmentActionTypes.UPDATE_TREATMENT_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentActionTypes.UPDATE_TREATMENT_SUCCESS:
      return { ...state, loadingPage: false, result: action.data };
    case treatmentActionTypes.UPDATE_TREATMENT_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case treatmentActionTypes.DELETE_FROM_SELECTED_TREATMENT_REQUEST:
      return { ...state };
    case treatmentActionTypes.DELETE_FROM_SELECTED_TREATMENT_SUCCESS:
      const newTreatmentData = Object.assign({}, state.selectedTreatmentData)
      delete newTreatmentData[action.key]
      return {
        ...state,
        selectedTreatmentData: newTreatmentData
      };
    case treatmentActionTypes.DELETE_FROM_SELECTED_TREATMENT_FAILURE:
      return { ...state };

    case treatmentActionTypes.DELETE_TREATMENT_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentActionTypes.DELETE_TREATMENT_SUCCESS:
      return { ...state, loadingPage: false, result: action.data };
    case treatmentActionTypes.DELETE_TREATMENT_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case treatmentActionTypes.GET_TREATMENTS_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentActionTypes.GET_TREATMENTS_SUCCESS:
      return { ...state, loadingPage: false, treatmentListData: action.data };
    case treatmentActionTypes.GET_TREATMENTS_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case treatmentActionTypes.GET_TREATMENT_BY_ID_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentActionTypes.GET_TREATMENT_BY_ID_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        selectedTreatmentData: action.data
      };
    case treatmentActionTypes.GET_TREATMENT_BY_ID_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case treatmentActionTypes.SET_TREATMENT_TO_DELETE_REQUEST:
      return { ...state };
    case treatmentActionTypes.SET_TREATMENT_TO_DELETE_SUCCESS:
      return { ...state, treatmentToDelete: action.id };

    case treatmentActionTypes.UPDATE_SELECTED_TREATMENT_REQUEST:
      return { ...state };
    case treatmentActionTypes.UPDATE_SELECTED_TREATMENT_SUCCESS:
      return {
        ...state,
        selectedTreatmentData: {
          ...state.selectedTreatmentData,
          [action.key]: action.value
        }
      };
    case treatmentActionTypes.UPDATE_SELECTED_TREATMENT_FAILURE:
      return { ...state };

    case treatmentActionTypes.CLEAR_SELECTED_TREATMENT_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentActionTypes.CLEAR_SELECTED_TREATMENT_SUCCESS:
      return { ...state, loadingPage: false, selectedTreatmentData: {} };
    case treatmentActionTypes.CLEAR_SELECTED_TREATMENT_FAILURE:
      return { ...state, loadingPage: false };

    default:
      return state;
  }
};
