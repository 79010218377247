import React from "react";
import ReactTable from "react-table";
import { TableFunctions, BasicModal } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";

class TableComponent extends React.Component {
  render = () => {
    let data = this.props.userListData;

    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
        />
        <div>
          <ReactTable
            data={data}
            columns={[
              {
                Header: strings.table.header.userName,
                accessor: "Username",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(20)
              },
              {
                Header: strings.table.header.userType,
                accessor: "Attributes[5].Value",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(20)
              },
              {
                Header: strings.table.header.firstName,
                accessor: "Attributes[3].Value",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(20)
              },
              {
                Header: strings.table.header.surname,
                accessor: "Attributes[4].Value",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(40)
              },
              {
                Header: strings.table.header.email,
                accessor: "Attributes[6].Value",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(40)
              },
              {
                Header: strings.table.header.createdDate,
                accessor: "UserCreateDate",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(20)
              }
            ]}
            className="-striped -highlight"
            defaultPageSize={10}
            minRows="1"
            noDataText="No results found."
            pageSizeOptions={[10, 20, 30]}
          />
          <BasicModal
            confirmMessage={strings.modal.deleteUserMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="deleteUserModal"
            modalOpen={this.props.deleteModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleDeleteModal}
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  deleteModalOpen: PropTypes.bool.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  userListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired
};

export default TableComponent;
