export const userActionTypes = {
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

  UPDATE_SELECTED_USER_REQUEST: "UPDATE_SELECTED_USER_REQUEST",
  UPDATE_SELECTED_USER_SUCCESS: "UPDATE_SELECTED_USER_SUCCESS",
  UPDATE_SELECTED_USER_FAILURE: "UPDATE_SELECTED_USER_FAILURE",

  CLEAR_SELECTED_USER_REQUEST: "CLEAR_SELECTED_USER_REQUEST",
  CLEAR_SELECTED_USER_SUCCESS: "CLEAR_SELECTED_USER_SUCCESS",
  CLEAR_SELECTED_USER_FAILURE: "CLEAR_SELECTED_USER_FAILURE",

  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  GET_USER_BY_ID_REQUEST: "GET_USER_BY_ID_REQUEST",
  GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
  GET_USER_BY_ID_FAILURE: "GET_USER_BY_ID_FAILURE",

  GENERATE_USER_ID_REQUEST: "GENERATE_USER_ID_REQUEST",
  GENERATE_USER_ID_SUCCESS: "GENERATE_USER_ID_SUCCESS",

  GET_PATIENT_CREDENTIALS_REQUEST: "GET_PATIENT_CREDENTIALS_REQUEST",
  GET_PATIENT_CREDENTIALS_SUCCESS: "GET_PATIENT_CREDENTIALS_SUCCESS",
  GET_PATIENT_CREDENTIALS_FAILURE: "GET_PATIENT_CREDENTIALS_FAILURE",

  CREATE_PATIENT_LOGIN_LINK_REQUEST: "CREATE_PATIENT_LOGIN_LINK_REQUEST",
  CREATE_PATIENT_LOGIN_LINK_SUCCESS: "CREATE_PATIENT_LOGIN_LINK_SUCCESS",
  CREATE_PATIENT_LOGIN_LINK_FAILURE: "CREATE_PATIENT_LOGIN_LINK_FAILURE",

  CHECK_PATIENT_LOGIN_LINK_REQUEST: "CHECK_PATIENT_LOGIN_LINK_REQUEST",
  CHECK_PATIENT_LOGIN_LINK_SUCCESS: "CHECK_PATIENT_LOGIN_LINK_SUCCESS",
  CHECK_PATIENT_LOGIN_LINK_FAILURE: "CHECK_PATIENT_LOGIN_LINK_FAILURE",

  CHECK_LOGIN_LINK_FOR_EXISTING_PATIENT_REQUEST:
    "CHECK_LOGIN_LINK_FOR_EXISTING_PATIENT_REQUEST",
  CHECK_LOGIN_LINK_FOR_EXISTING_PATIENT_SUCCESS:
    "CHECK_LOGIN_LINK_FOR_EXISTING_PATIENT_SUCCESS",
  CHECK_LOGIN_LINK_FOR_EXISTING_PATIENT_FAILURE:
    "CHECK_LOGIN_LINK_FOR_EXISTING_PATIENT_FAILURE",
};
