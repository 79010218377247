import { API } from "../../services";
import { treatmentTypeActionTypes } from "./treatmentType.actionTypes";

const api = new API();

const createTreatmentType = (details, headers) => dispatch =>
  new Promise(function(resolve, reject) {
    const request = details => {
      return {
        type: treatmentTypeActionTypes.CREATE_TREATMENT_TYPE_REQUEST,
        details
      };
    };
    const success = data => {
      return {
        type: treatmentTypeActionTypes.CREATE_TREATMENT_TYPE_SUCCESS,
        data
      };
    };
    const failure = error => {
      return {
        type: treatmentTypeActionTypes.CREATE_TREATMENT_TYPE_FAILURE,
        error
      };
    };

    api.setHeaders({
      ...headers,
      systemHeader_subsysref: "createTreatmentType"
    });

    dispatch(request(details));

    api.createTreatmentType(details).then(
      response => {
        dispatch(success(response));
        dispatch(getTreatmentTypes(headers));
        resolve();
      },
      error => {
        dispatch(failure(error));
        reject(error);
      }
    );
  });

const updateTreatmentType = (id, details, headers) => dispatch => {
  return new Promise(function(resolve, reject) {
    const request = details => {
      return {
        type: treatmentTypeActionTypes.UPDATE_TREATMENT_TYPE_REQUEST,
        details
      };
    };
    const success = data => {
      return {
        type: treatmentTypeActionTypes.UPDATE_TREATMENT_TYPE_SUCCESS,
        data
      };
    };
    const failure = error => {
      return {
        type: treatmentTypeActionTypes.UPDATE_TREATMENT_TYPE_FAILURE,
        error
      };
    };

    api.setHeaders({
      ...headers,
      systemHeader_subsysref: "updateTreatmentType"
    });

    dispatch(request(details));

    api.updateTreatmentType(id, details).then(
      response => {
        dispatch(success(response));
        resolve();
      },
      error => {
        dispatch(failure(error));
        reject(error);
      }
    );
  });
};

const deactivateTreatmentType = (id, headers) => dispatch => {
  return new Promise(function(resolve, reject) {
    const request = () => {
      return {
        type: treatmentTypeActionTypes.DEACTIVATE_TREATMENT_TYPE_REQUEST
      };
    };
    const success = data => {
      return {
        type: treatmentTypeActionTypes.DEACTIVATE_TREATMENT_TYPE_SUCCESS,
        data
      };
    };
    const failure = error => {
      return {
        type: treatmentTypeActionTypes.DEACTIVATE_TREATMENT_TYPE_FAILURE,
        error
      };
    };

    api.setHeaders({
      ...headers,
      systemHeader_subsysref: "deactivateTreatmentType"
    });

    dispatch(request());

    if (id !== "") {
      api.deactivateTreatmentType(id).then(
        response => {
          dispatch(success(response));
          resolve();
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    }
  });
};

const activateTreatmentType = (id, headers) => dispatch => {
  new Promise(function(resolve, reject) {
    const request = () => {
      return { type: treatmentTypeActionTypes.ACTIVATE_TREATMENT_TYPE_REQUEST };
    };
    const success = data => {
      return {
        type: treatmentTypeActionTypes.ACTIVATE_TREATMENT_TYPE_SUCCESS,
        data
      };
    };
    const failure = error => {
      return {
        type: treatmentTypeActionTypes.ACTIVATE_TREATMENT_TYPE_FAILURE,
        error
      };
    };

    api.setHeaders({
      ...headers,
      systemHeader_subsysref: "activateTreatmentType"
    });

    dispatch(request());

    if (id !== "") {
      api.activateTreatmentType(id).then(
        response => {
          dispatch(success(response));
          resolve();
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    }
  });
};

const getTreatmentTypes = headers => {
  const request = () => {
    return { type: treatmentTypeActionTypes.GET_TREATMENT_TYPES_REQUEST };
  };
  const success = data => {
    return { type: treatmentTypeActionTypes.GET_TREATMENT_TYPES_SUCCESS, data };
  };
  const failure = error => {
    return {
      type: treatmentTypeActionTypes.GET_TREATMENT_TYPES_FAILURE,
      error
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getTreatmentTypes" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .getTreatmentTypes()
        .then(response => {
          dispatch(success(response.data));
          resolve(response.data);
        })
        .catch(error => {
          dispatch(failure(error));
          reject(error);
        });
    });
  };
};

const getTreatmentTypeById = (id, headers) => {
  const request = () => {
    return { type: treatmentTypeActionTypes.GET_TREATMENT_TYPE_BY_ID_REQUEST };
  };
  const success = data => {
    return {
      type: treatmentTypeActionTypes.GET_TREATMENT_TYPE_BY_ID_SUCCESS,
      data
    };
  };
  const failure = error => {
    return {
      type: treatmentTypeActionTypes.GET_TREATMENT_TYPE_BY_ID_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getTreatmentTypeById"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .getTreatmentTypeById(id)
        .then(response => {
          dispatch(success(response.data));
          resolve(response.data);
        })
        .catch(error => {
          dispatch(failure(error));
          resolve(error);
        });
    });
  };
};

const updateSelectedTreatmentType = (key, value) => {
  const request = (key, value) => {
    return {
      type: treatmentTypeActionTypes.UPDATE_SELECTED_TREATMENT_TYPE_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: treatmentTypeActionTypes.UPDATE_SELECTED_TREATMENT_TYPE_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return {
      type: treatmentTypeActionTypes.UPDATE_SELECTED_TREATMENT_TYPE_FAILURE
    };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedTreatmentType = () => {
  const request = () => {
    return {
      type: treatmentTypeActionTypes.CLEAR_SELECTED_TREATMENT_TYPE_REQUEST
    };
  };
  const success = () => {
    return {
      type: treatmentTypeActionTypes.CLEAR_SELECTED_TREATMENT_TYPE_SUCCESS
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

export const treatmentTypeActions = {
  createTreatmentType,
  updateTreatmentType,
  activateTreatmentType,
  deactivateTreatmentType,
  getTreatmentTypeById,
  getTreatmentTypes,
  updateSelectedTreatmentType,
  clearSelectedTreatmentType
};
