import React from "react";
import PatientsList from "../components/PatientsList";
import { connect } from "react-redux";
import { patientActions } from "../store/actions/patient.actions";
import { strings } from "../resources";
import PropTypes from "prop-types";
import "./css/react-table.css";
import "./css/PatientsList.css";

class PatientListContainer extends React.Component {
  state = {
    deletePatientModalOpen: false,
    loadingPatients: false,
    patientFillsInForm: true
  };

  componentDidMount = async () => {
    await this.fetchPatientData();
    this.props.updateSelectedPatientTab("", 0);
  };

  setTableLoading = status => {
    this.setState({
      loadingPatients: status
    });
  };

  handleDelete = () => {
    const { headers } = this.props;
    this.props.deletePatient(this.props.selectedPatientId, headers);
  };

  handleModalConfirm = modalClassName => {
    // Handles the user selecting the positive option on a modal
    switch (modalClassName) {
      case "deletePatientFromTableModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  setPatientIdThenSetDeleteModalVisibility = id => {
    this.props.setPatientId(id);
    this.setDeletePatientModalVisibility();
  };

  setDeletePatientModalVisibility = () => {
    this.setState({
      deletePatientModalOpen: !this.state.deletePatientModalOpen
    });
  };

  fetchPatientData = (searchTerm = null) => {
    return new Promise((resolve, reject) => {
      this.setTableLoading(true);
      const { headers } = this.props;
      this.props.getPatients(headers, searchTerm).then(result => {
        this.setTableLoading(false);
        resolve(result);
      });
    });
  };

  toggleFormFillerChange = () => {
    this.setState({
      patientFillsInForm: !this.state.patientFillsInForm
    });
  };

  render = () => {
    return (
      <div className="patientList">
        <PatientsList
          pageTitle={strings.header.patientList}
          patientListData={this.props.patientListData}
          fetchPatientData={this.fetchPatientData}
          deletePatientModalOpen={this.state.deletePatientModalOpen}
          handleDelete={this.handleDelete}
          handleModalConfirm={this.handleModalConfirm}
          handleStatusToggle={this.handleStatusToggle}
          headers={this.props.headers}
          history={this.props.history}
          selectedPatientId={this.props.selectedPatientId}
          setPatientIdThenSetDeleteModalVisibility={
            this.setPatientIdThenSetDeleteModalVisibility
          }
          setDeletePatientModalVisibility={this.setDeletePatientModalVisibility}
          loadingPatients={this.state.loadingPatients}
          toggleFormFillerChange={this.toggleFormFillerChange}
          patientFillsInForm={this.state.patientFillsInForm}
        />
      </div>
    );
  };
}

PatientListContainer.propTypes = {
  selectedPatientId: PropTypes.string,
  patientListData: PropTypes.array,
  getPatients: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { patientListData, selectedTab } = state.patients;
  const { selectedPatientId } = state.table;
  return {
    patientListData,
    selectedPatientId,
    selectedTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPatients: (headers, searchTerm) => {
      return dispatch(patientActions.getPatients(headers, searchTerm));
    },
    updateSelectedPatientTab: (patientId, tabNumber) => {
      dispatch(patientActions.updateSelectedPatientTab(patientId, tabNumber));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientListContainer);
