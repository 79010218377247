import React from "react";
import PropTypes from "prop-types"
import { Form } from "semantic-ui-react";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css'

const RangeSlider = props => {
  const sliderMarks = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10
  }
  return (
    <React.Fragment>
      <Form.Group>
        <label>
          <span
            className={
              props.error ? "rangeSliderErrorLabel" : "rangeSliderLabel"
            }
          >
            {props.label}
          </span>
          {props.required && <span className="requiredAsterisk"> *</span>}
        </label>
      </Form.Group>
      <Form.Group className="rangeSlider">
        <Slider
          id={props.name}
          value={props.value}
          min={0}
          max={10}
          step={1}
          marks={sliderMarks}
          onChange={data => {
            props.onChange({name: props.name, value: data});
          }}
          disabled={props.disabled}
        />
      </Form.Group>
    </React.Fragment>
  );
};

RangeSlider.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default RangeSlider;
