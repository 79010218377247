/*global awsEnvCognitoIdentityPoolId, awsEnvRegion, awsEnvCognitoUserPoolId, awsEnvCognitoClientId, awsEnvUsersApiEndpoint */

import AWS from "aws-sdk";
import Amplify from "aws-amplify";
import { CognitoUserPool } from "amazon-cognito-identity-js";

AWS.config.update({
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: awsEnvCognitoIdentityPoolId
  }),
  region: awsEnvRegion
});

export const cognitoIdentity = new AWS.CognitoIdentityServiceProvider({
  apiVersion: "2016-04-18"
});

export const config = {
  cognito: {
    region: awsEnvRegion,
    ClientId: awsEnvCognitoClientId,
    UserPoolId: awsEnvCognitoUserPoolId,
    IdentityPoolId: awsEnvCognitoIdentityPoolId
  },
  invokeApi: awsEnvUsersApiEndpoint + "users",
  captchaApi: awsEnvUsersApiEndpoint + "recaptcha"
};

export const userPool = new CognitoUserPool({
  UserPoolId: config.cognito.UserPoolId,
  ClientId: config.cognito.ClientId
});

export const amplifyConfig = () =>
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: awsEnvRegion,
      userPoolId: awsEnvCognitoUserPoolId,
      identityPoolId: awsEnvCognitoIdentityPoolId,
      userPoolWebClientId: awsEnvCognitoClientId
    }
  });


