import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  List,
  Message,
  Segment,
  TextArea,
} from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { strings } from "../resources";
import {
  skinPrepOptions,
  localAnaestheticsOptions,
} from "../resources/DropdownOptions";
import { ImageAnnotatorContainer } from "../../Images/containers";
import face from "../../Images/assets/images/face.png";
import {
  BasicModal,
  CheckboxGroup,
  CheckboxGroupViewList,
  YesNoInput,
  ProductsTable,
} from "./ui";
import {
  diagnosisOptions,
  treatmentOptions,
  treatmentPlanOptions,
} from "../resources/CheckboxOptions";
import "./css/TreatmentComponents.css";
import TreatmentInformation from "./ui/TreatmentInformation";
import ExaminationNotesEdit from "./ui/ExaminationNotesEdit";
import DoctorSignatureContainer from "../../Images/containers/DoctorSignatureContainer";
import { formatExaminationNotesView } from "../../Patients/helpers/formatExaminationNotesView";

class Treatment extends React.Component {
  render = () => {
    const examinationNotesViewDetails = formatExaminationNotesView(
      this.props.selectedTreatmentData
    );

    const errorFields = this.props.validationErrors.map((error) => {
      return error.field;
    });

    const isPopulatedArray = (property) => {
      if (!Array.isArray(property)) {
        return false;
      } else if (property.length === 0) {
        return false;
      } else {
        return true;
      }
    };

    const editButton = (
      <div className="editDeleteButtons" data-html2canvas-ignore="true">
        <Button.Group>
          <Button className="saveButton" onClick={this.props.handleModeSwitch}>
            {strings.button.editLabel}
          </Button>
          <Button.Or />
          <Button
            className="cancelButton"
            onClick={this.props.toggleConfirmDeleteModalVisibility}
            type="button"
          >
            {strings.button.deleteLabel}
          </Button>
        </Button.Group>
        <BasicModal
          confirmMessage={strings.modal.deleteTreatmentMessage}
          handleModalConfirm={this.props.handleModalConfirm}
          modalClassName="confirmDeleteModal"
          modalOpen={this.props.confirmDeleteModalOpen}
          showOkButton={false}
          showYesButton={true}
          showNoButton={true}
          toggleModal={this.props.toggleConfirmDeleteModalVisibility}
        />
      </div>
    );

    const cancelSaveButtons = (
      <div className="cancelSaveButtons" data-html2canvas-ignore="true">
        <Button.Group>
          <Button
            className="saveButton"
            onClick={this.props.toggleConfirmSaveModalVisibility}
          >
            {strings.button.saveLabel}
          </Button>
          <Button.Or />
          <Button
            className="cancelButton"
            onClick={this.props.toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={this.props.confirmSaveModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleConfirmSaveModalVisibility}
          />
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              this.props.mode === "create"
                ? this.props.handleModalConfirm
                : this.props.handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={this.props.cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleCancelChangesModalVisibility}
          />
        </Button.Group>
      </div>
    );

    const downloadButton = (props) => {
      return (
        <div className="downloadButtons" data-html2canvas-ignore="true">
          <Button.Group>
            <Button
              type="button"
              className="downloadButton"
              onClick={props.downloadFormPdf}
              loading={props.generatingPdf}
            >
              {strings.button.downloadFormLabel}
            </Button>
          </Button.Group>
        </div>
      );
    };

    const treatmentForm = this.props.selectedPatientData ? (
      <>
        <div className="treatmentInfoHeader">
          {strings.form.header.treatmentInfo}
        </div>
        <div className="treatmentInfo">
          <TreatmentInformation
            mode={this.props.mode}
            selectedPatientData={this.props.selectedPatientData}
            selectedTreatmentData={this.props.selectedTreatmentData}
            treatmentTypeListData={this.props.treatmentTypeListData}
            errorFields={errorFields}
            handleChange={this.props.handleChange}
            getDoctorOptions={this.props.getDoctorOptions}
          />
          {/* The original variable name previous_treatment_feedback has been unchanged
          because there are existing treatments that already have this field. */}
          {isPopulatedArray(
            this.props.selectedTreatmentData.previous_treatment_feedback
          ) && (
            <>
              <div className="sectionHeader">
                {strings.form.header.previousTreatmentOutcome}
              </div>
              <div className="viewModePreviousTreatmentOutcome">
                {this.props.selectedTreatmentData.previous_treatment_feedback.map(
                  (line) => (
                    <p>{line}</p>
                  )
                )}
              </div>
            </>
          )}
          {this.props.selectedTreatmentData.previousTreatmentTimestamp !=
            null &&
            this.props.selectedTreatmentData.previousTreatmentTimestamp > 0 && (
              <>
                <div className="sectionHeader">
                  {strings.form.header.medicalHistoryUpdate}
                </div>
                <div className="medicalHistoryUpdate">
                  <List relaxed>
                    {this.props.medicalHistoryUpdate.map((item) => (
                      <List.Item>{item}</List.Item>
                    ))}
                  </List>
                </div>
              </>
            )}

          <div className="sectionHeader">
            {strings.form.label.patientConcerns}
          </div>

          {this.props.mode === "create" || this.props.mode === "edit" ? (
            <TextArea
              name="patient_concerns"
              placeholder="Enter patient concerns"
              value={
                Array.isArray(this.props.selectedTreatmentData.patient_concerns)
                  ? this.props.selectedTreatmentData.patient_concerns.join("\n")
                  : this.props.selectedTreatmentData.patient_concerns
              }
              onChange={this.props.handleChange}
              rows={
                Array.isArray(
                  this.props.selectedTreatmentData.patient_concerns
                ) &&
                this.props.selectedTreatmentData.patient_concerns.length > 2
                  ? this.props.selectedTreatmentData.patient_concerns.length
                  : 3
              }
            />
          ) : (
            <div className="viewModeMedicalInfo">
              <List relaxed>
                {Array.isArray(
                  this.props.selectedTreatmentData.patient_concerns
                )
                  ? this.props.selectedTreatmentData.patient_concerns.map(
                      (item) => <List.Item>{item}</List.Item>
                    )
                  : this.props.selectedTreatmentData.patient_concerns}
              </List>
            </div>
          )}

          <div className="sectionHeader">
            {strings.form.header.patientExamination}
          </div>
          <div className="patientExamination">
            <Form.Group>
              <ImageAnnotatorContainer
                annotationImagePath={
                  this.props.treatmentId
                    ? `treatments/${this.props.treatmentId}/examinationAnnotation`
                    : ""
                }
                backgroundImage={face}
                blank={this.props.mode === "template"}
                header={strings.header.examinationAnnotation}
                headers={this.props.headers}
                readOnly={this.props.mode === "create" ? false : true}
                annotationName={"examinationAnnotation"}
                handleAnnotationWasModified={
                  this.props.handleAnnotationWasModified
                }
              />
            </Form.Group>

            <div className="examinationTextAreas">
              {this.props.mode === "create" || this.props.mode === "edit" ? (
                <ExaminationNotesEdit
                  mode={this.props.mode}
                  selectedTreatmentData={this.props.selectedTreatmentData}
                  errorFields={errorFields}
                  handleChange={this.props.handleChange}
                  handleCheckboxGroupChange={
                    this.props.handleCheckboxGroupChange
                  }
                  handleYesNoChange={this.props.handleYesNoChange}
                />
              ) : (
                <React.Fragment>
                  <Header size="tiny">
                    {strings.form.label.examinationViewModeHeader}
                  </Header>
                  <List relaxed>
                    {examinationNotesViewDetails.map((item) => (
                      <List.Item>{item}</List.Item>
                    ))}
                  </List>
                </React.Fragment>
              )}

              <div className="patientAdditionalDetails">
                <Header size="tiny">
                  {strings.form.label.additionalDetailsHeader}
                </Header>
              </div>

              <Form.TextArea
                className="doctorsNotesTextArea"
                name="doctors_notes"
                onChange={this.props.handleChange}
                rows={4}
                value={this.props.selectedTreatmentData.doctors_notes || ""}
              />

              {this.props.mode === "edit" || this.props.mode === "create" ? (
                <React.Fragment>
                  <CheckboxGroup
                    groupClassName="examinationDiagnosis"
                    checkboxClassName="examinationDiagnosisItem"
                    mode={this.props.mode}
                    error={errorFields.includes("diagnosis")}
                    label={strings.form.label.diagnosisHeader}
                    required
                    group="diagnosis"
                    data={this.props.selectedTreatmentData}
                    handleCheckboxChange={this.props.handleCheckboxGroupChange}
                    options={diagnosisOptions}
                  />
                  {this.props.selectedTreatmentData.diagnosis &&
                    this.props.selectedTreatmentData.diagnosis.other &&
                    this.props.selectedTreatmentData.diagnosis.other.checked ===
                      true && (
                      <>
                        <Form.TextArea
                          error={errorFields.includes("diagnosis_other")}
                          className="examinationDiagnosisOther groupChild"
                          label={strings.form.label.otherDetails}
                          name="diagnosis_other"
                          group="diagnosis"
                          child="other"
                          onChange={this.props.handleNestedChange}
                          placeholder={strings.form.placeholder.details}
                          required
                          value={
                            this.props.selectedTreatmentData.diagnosis_other ||
                            ""
                          }
                          rows={2}
                        />
                      </>
                    )}
                </React.Fragment>
              ) : (
                <div className="examinationDiagnosisViewList">
                  <Form.Field>
                    <label>
                      <span>{strings.form.label.diagnosisHeader}</span>
                    </label>
                  </Form.Field>
                  <CheckboxGroupViewList
                    name="diagnosis"
                    value={this.props.selectedTreatmentData.diagnosis}
                    prefix=""
                    noItemsText="No items found"
                    mapping
                  />
                </div>
              )}
            </div>
          </div>

          <div className="sectionHeader">
            {strings.form.header.treatmentDetails}
          </div>

          <div className="treatmentDetails">
            <Grid columns={2} className="treatmentGroupSection">
              <Grid.Column>
                {this.props.mode === "edit" || this.props.mode === "create" ? (
                  <React.Fragment>
                    <CheckboxGroup
                      groupClassName=""
                      checkboxClassName=""
                      mode={this.props.mode}
                      error={errorFields.includes("treatment_options")}
                      label={strings.form.label.treatmentOptionsHeader}
                      required
                      group="treatment_options"
                      data={this.props.selectedTreatmentData}
                      handleCheckboxChange={
                        this.props.handleCheckboxGroupChange
                      }
                      options={treatmentOptions}
                    />
                    {this.props.selectedTreatmentData.treatment_options &&
                      this.props.selectedTreatmentData.treatment_options
                        .other &&
                      this.props.selectedTreatmentData.treatment_options.other
                        .checked === true && (
                        <Form.TextArea
                          error={errorFields.includes(
                            "treatment_options_other"
                          )}
                          className="treatmentOther groupChild"
                          label={strings.form.label.otherDetails}
                          name="treatment_options_other"
                          group="treatment_options"
                          child="other"
                          onChange={this.props.handleNestedChange}
                          placeholder={strings.form.placeholder.details}
                          required
                          value={
                            this.props.selectedTreatmentData
                              .treatment_options_other || ""
                          }
                          rows={2}
                        />
                      )}
                  </React.Fragment>
                ) : (
                  <div className="treatmentViewList">
                    <Form.Field>
                      <label>
                        <span>{strings.form.label.treatmentOptionsHeader}</span>
                      </label>
                    </Form.Field>
                    <CheckboxGroupViewList
                      name="treatment_options"
                      value={this.props.selectedTreatmentData.treatment_options}
                      prefix=""
                      noItemsText="No items found"
                      mapping
                    />
                  </div>
                )}
              </Grid.Column>

              <Grid.Column>
                {this.props.mode === "edit" || this.props.mode === "create" ? (
                  <React.Fragment>
                    <CheckboxGroup
                      groupClassName=""
                      checkboxClassName=""
                      mode={this.props.mode}
                      error={errorFields.includes("treatment_plan")}
                      label={strings.form.label.treatmentPlanHeader}
                      required
                      group="treatment_plan"
                      data={this.props.selectedTreatmentData}
                      handleCheckboxChange={
                        this.props.handleCheckboxGroupChange
                      }
                      options={treatmentPlanOptions}
                    />
                    {this.props.selectedTreatmentData.treatment_plan &&
                      this.props.selectedTreatmentData.treatment_plan.other &&
                      this.props.selectedTreatmentData.treatment_plan.other
                        .checked === true && (
                        <Form.TextArea
                          error={errorFields.includes("treatment_plan_other")}
                          className="treatmentOther groupChild"
                          label={strings.form.label.otherDetails}
                          name="treatment_plan_other"
                          group="treatment_plan"
                          child="other"
                          onChange={this.props.handleNestedChange}
                          placeholder={strings.form.placeholder.details}
                          required
                          value={
                            this.props.selectedTreatmentData
                              .treatment_plan_other || ""
                          }
                          rows={2}
                        />
                      )}
                  </React.Fragment>
                ) : (
                  <div className="treatmentViewList">
                    <Form.Field>
                      <label>
                        <span>{strings.form.label.treatmentPlanHeader}</span>
                      </label>
                    </Form.Field>
                    <CheckboxGroupViewList
                      name="treatment_plan"
                      value={this.props.selectedTreatmentData.treatment_plan}
                      prefix=""
                      noItemsText="No items found"
                      mapping
                    />
                  </div>
                )}
              </Grid.Column>
            </Grid>
            <YesNoInput
              mode={this.props.mode}
              error={errorFields.includes("treatment_performed_today")}
              name="treatment_performed_today"
              label={strings.form.label.treatmentPerformedToday}
              value={
                this.props.selectedTreatmentData.treatment_performed_today || ""
              }
              changehandler={this.props.handleYesNoChange}
              required
            />
            {this.props.selectedTreatmentData.treatment_performed_today !==
              strings.form.label.radioNo && (
              <div>
                <Form.Group>
                  <ImageAnnotatorContainer
                    annotationImagePath={
                      this.props.treatmentId
                        ? `treatments/${this.props.treatmentId}/treatmentAnnotation`
                        : ""
                    }
                    backgroundImage={face}
                    blank={this.props.mode === "template"}
                    header={strings.header.treatmentAnnotation}
                    headers={this.props.headers}
                    readOnly={this.props.mode === "create" ? false : true}
                    annotationName={"treatmentAnnotation"}
                    handleAnnotationWasModified={
                      this.props.handleAnnotationWasModified
                    }
                  />
                  <div className="treatmentInformation">
                    {this.props.mode === "create" ||
                    this.props.mode === "edit" ? (
                      <Form.Dropdown
                        className="treatmentInformationItem"
                        error={errorFields.includes("skin_prep")}
                        label={strings.form.label.skinPrep}
                        placeholder={strings.form.placeholder.skinPrep}
                        selection
                        options={skinPrepOptions}
                        name="skin_prep"
                        value={this.props.selectedTreatmentData.skin_prep || ""}
                        onChange={this.props.handleChange}
                        required={
                          this.props.selectedTreatmentData
                            .treatment_performed_today ===
                          strings.form.label.radioYes
                        }
                        selectOnBlur={false}
                      />
                    ) : (
                      <Form.Input
                        className="treatmentInformationItem"
                        label={strings.form.label.skinPrep}
                        placeholder={strings.form.placeholder.skinPrep}
                        name="skin_prep"
                        value={this.props.selectedTreatmentData.skin_prep || ""}
                        required={
                          this.props.selectedTreatmentData
                            .treatment_performed_today ===
                          strings.form.label.radioYes
                        }
                      />
                    )}
                    {this.props.mode === "create" ||
                    this.props.mode === "edit" ? (
                      <Form.Dropdown
                        className="treatmentInformationItem"
                        error={errorFields.includes("local_anaesthetics")}
                        label={strings.form.label.localAnaesthetics}
                        placeholder={strings.form.placeholder.localAnaesthetics}
                        selection
                        options={localAnaestheticsOptions}
                        name="local_anaesthetics"
                        value={
                          this.props.selectedTreatmentData.local_anaesthetics ||
                          ""
                        }
                        onChange={this.props.handleChange}
                        required={
                          this.props.selectedTreatmentData
                            .treatment_performed_today ===
                          strings.form.label.radioYes
                        }
                        selectOnBlur={false}
                      />
                    ) : (
                      <Form.Input
                        className="treatmentInformationItem"
                        error={errorFields.includes("local_anaesthetics")}
                        label={strings.form.label.localAnaesthetics}
                        name="local_anaesthetics"
                        onChange={this.props.handleChange}
                        placeholder={strings.form.placeholder.localAnaesthetics}
                        required={
                          this.props.selectedTreatmentData
                            .treatment_performed_today ===
                          strings.form.label.radioYes
                        }
                        value={
                          this.props.selectedTreatmentData.local_anaesthetics ||
                          ""
                        }
                      />
                    )}
                  </div>
                </Form.Group>

                <Segment className="productTableSegment" basic>
                  <ProductsTable 
                    errorFields={errorFields} 
                    handleProductCheckboxChange={this.props.handleProductCheckboxChange}
                    handleProductDataChange={this.props.handleProductDataChange}
                    handleProductSelectChange={this.props.handleProductSelectChange}
                    mode={this.props.mode}
                    productListData={this.props.productListData}
                    selectedTreatmentData={this.props.selectedTreatmentData}
                  />
                </Segment>
              </div>
            )}
            <Form.TextArea
              className="treatmentDetails"
              label={strings.form.label.additionalInformationHeader}
              name="treatment_details"
              onChange={this.props.handleChange}
              rows={4}
              value={this.props.selectedTreatmentData.treatment_details || ""}
            />
          </div>

          <div className="sectionHeader">
            {strings.form.header.postTreatment}
          </div>

          <div className="postTreatment">
            <Form.Group>
              <Form.TextArea
                label={strings.form.label.notes}
                name="notes"
                onChange={this.props.handleChange}
                rows={2}
                value={this.props.selectedTreatmentData.notes || ""}
                width={16}
              />
            </Form.Group>
            <Form.Group>
              <Form.Checkbox
                label={
                  this.props.mode === "view" || this.props.mode === "template"
                    ? strings.form.label.aftercareInstructionsSent
                    : strings.form.label.sendAftercareInstructions
                }
                name="send_aftercare_instructions"
                disabled={
                  this.props.mode === "view" ||
                  this.props.mode === "template" ||
                  this.props.selectedPatientData.communication_consent !== "Yes"
                }
                onChange={this.props.handleCheckboxChange}
                checked={
                  this.props.selectedTreatmentData
                    .send_aftercare_instructions || false
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Checkbox
                label={
                  this.props.mode === "create"
                    ? strings.form.label.discussedConsentIssuesWithPatient
                    : strings.form.label.consentIssuesWereDiscussed
                }
                name="discussed_consent_issues"
                disabled={this.props.mode !== "create"}
                onChange={this.props.handleCheckboxChange}
                checked={
                  this.props.selectedTreatmentData.discussed_consent_issues ||
                  false
                }
              />
            </Form.Group>
            {this.props.selectedTreatmentData.doctor_signature_name != null &&
              this.props.selectedTreatmentData.doctor_signature_path != null &&
              this.props.selectedTreatmentData.doctor_signature_applied_date !=
                null && (
                <DoctorSignatureContainer
                  containerWidth={240}
                  containerHeight={150}
                  dateDoctorSigned={
                    this.props.selectedTreatmentData
                      .doctor_signature_applied_date
                  }
                  doctorName={
                    this.props.selectedTreatmentData.doctor_signature_name
                  }
                  formId={null}
                  headers={null}
                  signaturePathAttachedToForm={
                    this.props.selectedTreatmentData.doctor_signature_path
                  }
                />
              )}
          </div>
        </div>
      </>
    ) : (
      <Redirect to="/patients" />
    );

    return (
      <div className="TreatmentContainer" id="formDiv">
        {this.props.validationErrors.length > 0 && (
          <div className="errorMessageContainer">
            <Message
              compact
              error
              header={strings.form.label.errorHeader}
              list={this.props.validationErrors.map((error) => {
                return error.error;
              })}
            />
          </div>
        )}
        <div className="pageHeader">{this.props.pageTitle}</div>
        <Form
          className="details"
          onSubmit={this.props.toggleConfirmSaveModalVisibility}
        >
          {this.props.mode === "view" && (
            <fieldset disabled className="treatmentFormFieldset">
              {treatmentForm}
            </fieldset>
          )}
          {this.props.mode !== "view" && (
            <fieldset className="treatmentFormFieldset">
              {treatmentForm}
            </fieldset>
          )}
        </Form>
        <div className="centerContent">
          {this.props.mode === "view" && editButton}
          {this.props.mode === "edit" && cancelSaveButtons}
          {this.props.mode === "create" && cancelSaveButtons}
          <Divider hidden />
          {downloadButton(this.props)}
        </div>
      </div>
    );
  };
}

Treatment.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedTreatmentData: PropTypes.object.isRequired,
  selectedPatientData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  //confirmDeleteModalOpen: PropTypes.bool.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  //toggleConfirmDeleteModalVisibility: PropTypes.func.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  //handleModeSwitch: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  handleNumberOnlyInputKeyDown: PropTypes.func.isRequired,
  handleAnnotationWasModified: PropTypes.func.isRequired,
};

export default Treatment;
