//Returns an array of strings corresponding to the patient's medical history changes
export const formatChangesToMedicalHistory = data => {
  let output = [];
  if (data == null) {
    return output;
  }

  if (data.medical_conditions_changes_toggle === "No") {
    output.push("No changes to the medical history.");
  } else if (data.medical_conditions_changes_toggle === "Yes") {
    output.push(
      "The patient has the following changes to their medical history: "
    );
    data.medical_conditions.map(item => output.push(item));
  } else if (data.medical_conditions != null) {
    output.push("CHANGES EXIST");
  } else {
    output.push("No changes to the medical history.");
  }

  if (data.dermatological_diseases_changes_toggle === "No") {
    output.push("No changes to the dermatological history.");
  } else if (data.dermatological_diseases_changes_toggle === "Yes") {
    output.push(
      "The patient has been diagnosed with the following dermatological conditions:"
    );
    data.dermatological_diseases.map(item => output.push(item));
  } else if (data.dermatological_diseases != null) {
    output.push("CHANGES EXIST");
  } else {
    output.push("No changes to the dermatological history.");
  }

  if (data.neurological_conditions_changes_toggle === "No") {
    output.push("No changes to the neurological history.");
  } else if (data.neurological_conditions_changes_toggle === "Yes") {
    output.push(
      "The patient has been diagnosed with the following neurological conditions:"
    );
    data.neurological_conditions.map(item => output.push(item));
  } else if (data.neurological_conditions != null) {
    output.push("CHANGES EXIST");
  } else {
    output.push("No changes to the neurological history.");
  }

  if (data.supplements_changes_toggle === "No") {
    output.push("No changes to the herbal medicines history.");
  } else if (data.supplements_changes_toggle === "Yes") {
    output.push(
      "The patient has started the following herbal / homeopathic medications:"
    );
    data.supplements.map(item => output.push(item));
  } else if (data.supplements != null) {
    output.push("CHANGES EXIST");
  } else {
    output.push("No changes to the herbal medicines history.");
  }

  if (data.regular_medications_changes_toggle === "No") {
    output.push("No changes to the regular medicines history.");
  } else if (data.regular_medications_changes_toggle === "Yes") {
    output.push("The patient has started the following regular medications: ");
    data.regular_medications.map(item => output.push(item));
  } else if (data.regular_medications != null) {
    output.push("CHANGES EXIST");
  } else {
    output.push("No changes to the regular medicines history.");
  }

  if (data.drug_allergies_changes_toggle === "No") {
    output.push("No changes to the drug allergies history.");
  } else if (data.drug_allergies_changes_toggle === "Yes") {
    output.push("The patient has developed the following new drug allergies: ");
    data.drug_allergies.map(item => output.push(item));
  } else if (data.drug_allergies != null) {
    output.push("CHANGES EXIST");
  } else {
    output.push("No changes to the drug allergies history.");
  }

  if (data.general_allergies_changes_toggle === "No") {
    output.push("No changes to the general allergies history.");
  } else if (data.general_allergies_changes_toggle === "Yes") {
    output.push(
      "The patient has developed the following new general allergies: "
    );
    data.general_allergies.map(item => output.push(item));
  } else if (data.general_allergies != null) {
    output.push("CHANGES EXIST");
  } else {
    output.push("No changes to the general allergies history.");
  }

  return output;
};
