import { imageActionTypes } from "../actions/image.actionTypes";
import { imageHelper } from "../../helpers/imageHelper";

const initialState = {
  annotationsImages: {},
  annotationsObject: {},
  doctorSignature: {},
  error: "",
  fullsizedImageData: [],
  loadingFullsizedImage: false,
  loadingImageData: false,
  localData: [],
  patientSignature: {},
  photoFileName: "",
  remoteData: [],
  response: "",
  request: "",
  signatureObject: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case imageActionTypes.PURGE_STATE:
      return { ...initialState };

    case imageActionTypes.GET_IMAGES_REQUEST:
      return { ...state, request: action.payload };
    case imageActionTypes.GET_IMAGES_SUCCESS:
      return {
        ...state,
        remoteData: imageHelper.mapToRemoteData(action.payload),
        localData: imageHelper.mapToLocalData(action.payload)
      };
    case imageActionTypes.GET_IMAGES_FAILURE:
      return { ...state, error: action.payload };

    case imageActionTypes.GET_PATIENT_SIGNATURE_REQUEST:
      return { ...state, request: action.payload };
    case imageActionTypes.GET_PATIENT_SIGNATURE_SUCCESS:
      return { ...state, patientSignature: action.payload[0] };
    case imageActionTypes.GET_PATIENT_SIGNATURE_FAILURE:
      return { ...state, error: action.payload };

    case imageActionTypes.GET_DOCTOR_SIGNATURE_REQUEST:
      return { ...state, request: action.payload };
    case imageActionTypes.GET_DOCTOR_SIGNATURE_SUCCESS:
      return { ...state, doctorSignature: action.payload[0] };
    case imageActionTypes.GET_DOCTOR_SIGNATURE_FAILURE:
      return { ...state, error: action.payload };

    case imageActionTypes.UPDATE_LOCAL_IMAGES_SUCCESS:
      const newLocalDataUpdate = state.localData.slice();
      newLocalDataUpdate[action.payload.key] = action.payload.value;
      return { ...state, localData: newLocalDataUpdate };

    case imageActionTypes.SET_FILE_TYPE_WARNING_VISIBLE_REQUEST:
      return { ...state, fileTypeWarningVisible: action.payload };

    case imageActionTypes.UPLOAD_IMAGE_REQUEST:
      return { ...state, request: action.payload };
    case imageActionTypes.UPLOAD_IMAGE_SUCCESS:
      return { ...state, response: action.payload };
    case imageActionTypes.UPLOAD_IMAGE_FAILURE:
      return { ...state, error: action.payload };

    case imageActionTypes.DELETE_IMAGE_REQUEST:
      return { ...state, request: action.payload };
    case imageActionTypes.DELETE_IMAGE_SUCCESS:
      return { ...state, response: action.payload };
    case imageActionTypes.DELETE_IMAGE_FAILURE:
      return { ...state, error: action.payload };

    case imageActionTypes.UPDATE_IMAGE_REQUEST:
      return { ...state, request: action.payload };
    case imageActionTypes.UPDATE_IMAGE_SUCCESS:
      // When updating a single image, manually graft these changes onto remoteData
      // this means we don't have to re-fetch all images again to have an up to date copy of the serverside data
      const updatedRemoteDataImage = {
        imageName: action.payload.imagePath.substr(
          action.payload.imagePath.lastIndexOf("/") + 1
        ),
        ...action.payload.metadata
      };
      const remoteDataImageIndex = state.remoteData.findIndex(image => {
        return image.imageName === updatedRemoteDataImage.imageName;
      });
      const newRemoteData = state.remoteData.slice();
      newRemoteData[remoteDataImageIndex] = updatedRemoteDataImage;
      return { ...state, remoteData: newRemoteData };
    case imageActionTypes.UPDATE_IMAGE_FAILURE:
      return { ...state, error: action.payload };

    case imageActionTypes.SET_LOADING_IMAGES_SUCCESS:
      return { ...state, loadingImageData: action.payload };

    case imageActionTypes.UPDATE_SIGNATURE_OBJECT_SUCCESS:
      return { ...state, signatureObject: action.payload };

    case imageActionTypes.UPDATE_ANNOTATION_OBJECT_SUCCESS:
      let newAnnotationsObject = Object.assign({}, state.annotationsObject);
      if (Object.keys(action.payload.upload).length > 0) {
        newAnnotationsObject[action.payload.name] = action.payload.upload;
        return { ...state, annotationsObject: newAnnotationsObject };
      } else {
        delete newAnnotationsObject[action.payload.name];
        return { ...state, annotationsObject: newAnnotationsObject };
      }

    case imageActionTypes.GET_ANNOTATION_IMAGE_REQUEST:
      return { ...state, request: action.payload };
    case imageActionTypes.GET_ANNOTATION_IMAGE_SUCCESS:
      let newAnnotationsImage = Object.assign({}, state.annotationsImages);
      if (Object.keys(action.payload.response).length > 0) {
        newAnnotationsImage[action.payload.name] = action.payload.response;
        return { ...state, annotationsImages: newAnnotationsImage };
      } else {
        delete newAnnotationsImage[action.payload.name];
        return { ...state, annotationsImages: newAnnotationsImage };
      }
    case imageActionTypes.GET_ANNOTATION_IMAGE_FAILURE:
      return { ...state, error: action.payload };

    case imageActionTypes.UPDATE_PHOTO_FILE_NAME_SUCCESS:
      return { ...state, photoFileName: action.payload };

    case imageActionTypes.RESET_LOCAL_IMAGE_DATA_SUCCESS:
      return { ...state, localData: [], remoteData: [] };

    case imageActionTypes.GET_SINGLE_IMAGE_REQUEST:
      return { ...state, request: action.payload };
    case imageActionTypes.GET_SINGLE_IMAGE_SUCCESS:
      return { ...state, fullsizedImageData: action.payload };
    case imageActionTypes.GET_SINGLE_IMAGE_FAILURE:
      return { ...state, error: action.payload };

    case imageActionTypes.SET_LOADING_FULLSIZED_IMAGE_SUCCESS:
      return { ...state, loadingFullsizedImage: action.payload };

    case imageActionTypes.CLEAR_DOCTOR_SIGNATURE_SUCCESS:
      return { ...state, doctorSignature: {} };

    case imageActionTypes.CLEAR_ANNOTATIONS_OBJECT_SUCCESS:
      return { ...state, annotationsObject: {} };

    default:
      return state;
  }
}
