import React from "react";
import TableComponent from "../../components/ProductTable";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { productActions } from "../../store/actions/product.actions";
import { strings } from "../../resources";
import { dateFormatter } from "../../helpers";
import PropTypes from "prop-types";
import "./css/ProductsTable.css";

class ProductListContainer extends React.Component {
  state = {
    onlyShowActive: false,
    onlyShowInactive: false,
  };

  componentDidMount = async () => {
    if (this.props.productListData[0]) {
      await this.props.getProducts(this.props.headers);
    }
  };

  // Need 2 seperate toggles to prevent looping
  toggleShowActive = () => {
    this.handleFilterChange("onlyShowActive");
  };

  toggleShowInactive = () => {
    this.handleFilterChange("onlyShowInactive");
  };

  handleFilterChange = async (source) => {
    if (this.state[source] === true) {
      this.setState({
        onlyShowActive: false,
        onlyShowInactive: false,
      });
      await this.props.getProducts(this.props.headers);
    } else {
      this.setState({
        onlyShowActive: source === "onlyShowActive",
        onlyShowInactive: source === "onlyShowInactive",
      });
      await this.props.getProducts(
        this.props.headers,
        source === "onlyShowActive"
      );
    }
  };

  render = () => {
    let data = this.props.productListData;
    data.forEach(function (product) {
      if (product["created_date"]) {
        product[
          "created_date_formatted"
        ] = dateFormatter.isoToFormattedDateTime(product["created_date"]);
      }
    });
    return (
      <div>
        <div style={{ display: this.props.loadingPage ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          className="clientList"
          style={{ display: this.props.loadingPage ? "none" : "" }}
        >
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            productListData={data}
            onlyShowActive={this.state.onlyShowActive}
            onlyShowInactive={this.state.onlyShowInactive}
            toggleShowActive={this.toggleShowActive}
            toggleShowInactive={this.toggleShowInactive}
          />
        </div>
      </div>
    );
  };
}

ProductListContainer.propTypes = {
  productListData: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getProducts: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { products } = state;
  const { productListData, loadingPage } = products;
  return {
    productListData,
    loadingPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: (headers, active) => {
      dispatch(productActions.getProducts(headers, active));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductListContainer);
