export const treatmentActionTypes = {
  CREATE_TREATMENT_REQUEST: "CREATE_TREATMENT_REQUEST",
  CREATE_TREATMENT_SUCCESS: "CREATE_TREATMENT_SUCCESS",
  CREATE_TREATMENT_FAILURE: "CREATE_TREATMENT_FAILURE",

  DELETE_TREATMENT_REQUEST: "DELETE_TREATMENT_REQUEST",
  DELETE_TREATMENT_SUCCESS: "DELETE_TREATMENT_SUCCESS",
  DELETE_TREATMENT_FAILURE: "DELETE_TREATMENT_FAILURE",

  DELETE_FROM_SELECTED_TREATMENT_REQUEST: "DELETE_FROM_SELECTED_TREATMENT_REQUEST",
  DELETE_FROM_SELECTED_TREATMENT_SUCCESS: "DELETE_FROM_SELECTED_TREATMENT_SUCCESS",
  DELETE_FROM_SELECTED_TREATMENT_FAILURE: "DELETE_FROM_SELECTED_TREATMENT_FAILURE",

  UPDATE_SELECTED_TREATMENT_REQUEST: "UPDATE_SELECTED_TREATMENT_REQUEST",
  UPDATE_SELECTED_TREATMENT_SUCCESS: "UPDATE_SELECTED_TREATMENT_SUCCESS",
  UPDATE_SELECTED_TREATMENT_FAILURE: "UPDATE_SELECTED_TREATMENT_FAILURE",

  CLEAR_SELECTED_TREATMENT_REQUEST: "CLEAR_SELECTED_TREATMENT_REQUEST",
  CLEAR_SELECTED_TREATMENT_SUCCESS: "CLEAR_SELECTED_TREATMENT_SUCCESS",
  CLEAR_SELECTED_TREATMENT_FAILURE: "CLEAR_SELECTED_TREATMENT_FAILURE",

  GET_TREATMENTS_REQUEST: "GET_TREATMENTS_REQUEST",
  GET_TREATMENTS_SUCCESS: "GET_TREATMENTS_SUCCESS",
  GET_TREATMENTS_FAILURE: "GET_TREATMENTS_FAILURE",

  UPDATE_TREATMENT_REQUEST: "UPDATE_TREATMENT_REQUEST",
  UPDATE_TREATMENT_SUCCESS: "UPDATE_TREATMENT_SUCCESS",
  UPDATE_TREATMENT_FAILURE: "UPDATE_TREATMENT_FAILURE",

  GET_TREATMENT_BY_ID_REQUEST: "GET_TREATMENT_BY_ID_REQUEST",
  GET_TREATMENT_BY_ID_SUCCESS: "GET_TREATMENT_BY_ID_SUCCESS",
  GET_TREATMENT_BY_ID_FAILURE: "GET_TREATMENT_BY_ID_FAILURE"
};
