import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Form, Container, Button, Message } from "semantic-ui-react";
import { BasicModal } from "./ui";
import { enums, strings } from "../resources";
import { enums as roles } from "../../App/resources/Enums";
import ShowIfAuthorised from "../../App/components/ShowIfAuthorised";
import {
  FormNewPatientRegistration,
  FormAestheticInterest,
  FormFollowUp,
  FormReturningPatient,
  FormConsentCovid,
  FormConsentBotox,
  FormConsentUpperBlepharoplasty,
  FormConsentUpperLowerBlepharoplasty,
  FormConsentCO2RE,
  FormConsentProfound,
  FormConsentFaceAndNeck,
  FormConsentDermalFillers,
} from "./forms/index";

class FormEdit extends React.Component {
  formList = {
    newPatientRegistration: FormNewPatientRegistration,
    aestheticInterest: FormAestheticInterest,
    followUp: FormFollowUp,
    returningPatient: FormReturningPatient,
    consentCovid: FormConsentCovid,
    consentBotox: FormConsentBotox,
    consentUpperBlepharoplasty: FormConsentUpperBlepharoplasty,
    consentUpperLowerBlepharoplasty: FormConsentUpperLowerBlepharoplasty,
    consentCO2RE: FormConsentCO2RE,
    consentProfound: FormConsentProfound,
    consentFaceAndNeck: FormConsentFaceAndNeck,
    consentDermalFillers: FormConsentDermalFillers,
  };

  getFormComponent = (formType) => {
    if (formType in this.formList) {
      return this.formList[formType];
    } else {
      return null;
    }
  };

  render = () => {
    const FormContents = this.getFormComponent(this.props.formType);

    const lookupFormName = (formType) => {
      let value = strings.form.header.invalidFormType;
      let ft = null;
      for (ft in enums.formType) {
        if (
          enums.formType[ft]["type"] === formType ||
          enums.formType[ft]["link"] === formType
        ) {
          value = enums.formType[ft]["name"];
        }
      }
      return value;
    };

    const cancelSaveButtons = (
      <React.Fragment>
        <div className="cancelSaveDownloadButtons">
          <Button.Group>
            <Button className="saveButton">{strings.button.saveLabel}</Button>
            <Button.Or />
            <Button
              className="cancelButton"
              type="button"
              onClick={this.props.toggleCancelChangesModalVisibility}
            >
              {strings.button.cancelLabel}
            </Button>
          </Button.Group>

          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={this.props.confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleConfirmSaveModalVisibility}
          />
          <BasicModal
            confirmMessage={this.props.cancelMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="cancelChangesModal"
            modalOpen={this.props.cancelChangesModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleCancelChangesModalVisibility}
          />
        </div>
      </React.Fragment>
    );

    const downloadButton = (
      <div className="editDeleteButtons" data-html2canvas-ignore="true">
        <Button.Group>
          <Button
            type="button"
            className="downloadButton"
            onClick={this.props.downloadFormPdf}
            loading={this.props.generatingPdf}
          >
            {strings.button.downloadFormLabel}
          </Button>
        </Button.Group>
      </div>
    );

    return (
      <Container className="FormContainer" id="formDiv">
        {this.getFormComponent(this.props.formType) === null && (
          <Redirect to="/notFound" />
        )}
        {this.getFormComponent(this.props.formType) !== null && (
          <>
            {this.props.validationErrors.length > 0 && (
              <div className="errorMessageContainer">
                <Message
                  compact
                  error
                  header={strings.form.label.errorHeader}
                  list={this.props.validationErrors.map((error) => {
                    return error.error;
                  })}
                />
              </div>
            )}
            {!this.props.multipleFormEditMode && (
              <div className="FormHeader">
                {lookupFormName(this.props.formType)}
              </div>
            )}
            <Form
              className="FormBody"
              onSubmit={this.props.toggleConfirmSaveModalVisibility}
              noValidate
            >
              <fieldset
                disabled={
                  this.props.mode === "view" || this.props.mode === "template"
                }
              >
                <FormContents {...this.props} />
              </fieldset>

              <div className="formButtons">
                {this.props.mode === "edit" && cancelSaveButtons}
                {this.props.mode === "create" && cancelSaveButtons}

                <ShowIfAuthorised
                  allowedRoles={[roles.role.PULSION, roles.role.ADMIN]}
                >
                  {(this.props.mode === "view" ||
                    this.props.mode === "template") &&
                    downloadButton}
                </ShowIfAuthorised>
              </div>
            </Form>
          </>
        )}
      </Container>
    );
  };
}

FormEdit.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedFormData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
};

export default FormEdit;
