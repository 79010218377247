import React from "react";
import PropTypes from "prop-types";
import { Container } from "semantic-ui-react";
import { connect } from "react-redux";
import { authActions } from "../../App/store/actions/auth.actions";
import { userActions } from "../../Users/store/actions/user.actions";
import { withRouter } from "react-router-dom";
import PreConsultationEmailLink from "../components/PreConsultationEmailLink";
import { dateStringToIsoDateTimeString } from "../../App/helpers/DateTimeFormatConversion";
import formatDateInput from "../helpers/FormatDateInput";
import "./css/PreConsultation.css";

class PreConsultationEmailLinkContainer extends React.Component {
  state = {
    isExistingPatient:
      this.props.location.state &&
      this.props.location.state.patientId &&
      this.props.location.state.patientData,
    patientId: this.props.location.state
      ? this.props.location.state.patientId
      : null,
    patientData: this.props.location.state
      ? this.props.location.state.patientData
      : null,
    selectedFormTypes: this.props.location.state
      ? this.props.location.state.selectedFormTypes
      : [],
    selectedFormNames: this.props.location.state
      ? this.props.location.state.selectedFormNames
      : [],
    newPatientData: {},
    cancelModalOpen: false,
    error: false,
  };

  toggleCancelModalVisibility = () => {
    this.setState({
      cancelModalOpen: !this.state.cancelModalOpen,
    });
  };

  handleModalConfirm = (modalClassName) => {
    switch (modalClassName) {
      case "cancelModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  handleChange = (e, data) => {
    const newData = { ...this.state.newPatientData, [data.name]: data.value };
    this.setState({
      newPatientData: newData,
      error: false,
    });
  };

  handleDateChange = async (event, data) => {
    let dataToSubmit = "";
    let currentValue = this.state.verifyData[data.name];
    // Only look to add dashes if there is something to format, and if the user
    // is typing rather than deleting
    if (currentValue && data.value.length > 0 && data.value.length > currentValue.length) {
      dataToSubmit = formatDateInput(data.value);
    } else {
      dataToSubmit = data.value;
    }
    const newData = { ...this.state.verifyData, [data.name]: dataToSubmit };
    this.setState({
      verifyData: newData,
      error: false
    });
  };

  handleDateKeyDown = (event) => {
    // Filter the input to the date box - restrict it to numbers, the dash separator,
    // the backspace key (keyCode 8), and the delete key (keyCode 46)
    if (isNaN(event.key) && event.key !== "-" && event.keyCode !== 8 && event.keyCode !== 46) {
      event.preventDefault();
    }
  };

  handleSubmit = () => {
    this.generateEmailToPatient();
  };

  handleCancel = () => {
    this.preConsultationCancelled();
  };

  generateEmailToPatient = async () => {
    let patientData = this.state.newPatientData;
    let nextPage = "/patients/";
    if (this.state.isExistingPatient) {
      patientData = this.state.patientData;
      nextPage = `/patients/view/${this.state.patientId}`;
    }

    patientData.selectedFormTypes = this.state.selectedFormTypes;
    patientData.selectedFormNames = this.state.selectedFormNames;
    patientData.date_of_birth = dateStringToIsoDateTimeString(
      this.state.patientData ? this.state.patientData.date_of_birth : undefined
    );
    console.log("patientData", patientData);
    patientData.patient_id = this.state.patientId;

    // generate an email to the patient and then redirect the receptionist back to the patient list or patient record
    var result = await this.props.createPatientLoginLink(
      patientData,
      this.props.headers
    );
    console.log("results", result);

    this.props.history.push({
      pathname: nextPage,
      state: {
        patientId: this.state.patientId,
      },
    });
  };

  preConsultationCancelled = async () => {
    let nextPage = "/patients/";
    if (this.state.isExistingPatient) {
      nextPage = `/patients/view/${this.state.patientId}`;
    }

    this.props.history.push({
      pathname: nextPage,
      state: {
        patientId: this.state.patientId,
      },
    });
  };

  render = () => {
    return (
      <React.Fragment>
        <Container className="preConsultationEmailLinkContainer">
          <PreConsultationEmailLink
            isExistingPatient={this.state.isExistingPatient}
            data={
              this.state.isExistingPatient
                ? this.state.patientData
                : this.state.newPatientData
            }
            error={this.state.error}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            handleDateKeyDown={this.handleDateKeyDown}
            handleSubmit={this.handleSubmit}
            handleModalConfirm={this.handleModalConfirm}
            toggleCancelModalVisibility={this.toggleCancelModalVisibility}
            cancelModalOpen={this.state.cancelModalOpen}
          />
        </Container>
      </React.Fragment>
    );
  };
}

PreConsultationEmailLinkContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
  getPatientCredentials: PropTypes.func.isRequired,
  createPatientLoginLink: PropTypes.func.isRequired,
  checkPatientLoginLink: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (username, password) => {
      dispatch(authActions.signIn(username, password));
    },
    getPatientCredentials: (headers) => {
      return dispatch(userActions.getPatientCredentials(headers));
    },
    createPatientLoginLink: (details, headers) => {
      return dispatch(userActions.createPatientLoginLink(details, headers));
    },
    checkPatientLoginLink: (details, headers) => {
      return dispatch(userActions.checkPatientLoginLink(details, headers));
    },
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(PreConsultationEmailLinkContainer)
);
