import { strings } from "..";
import { regexes } from "../Regexes";

const dateRegex = new RegExp(regexes.dateRegex);

const consentProfoundSchema = {
  signed_by_patient: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.signed_by_patient
  },
  signature_data_url: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.signature,
    required: true,
    validate: (field, data) => {
      if (Object.keys(data[field]).length === 0) {
        return strings.form.validation.errorMessageReason.missingSignature;
      } else {
        return true;
      }
    }
  },
  date_patient_signed: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.date_patient_signed,
    validate: (field, data) => {
      if (dateRegex.test(data[field])) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason.wrongDateFormat;
      }
    }
  }
};

export default consentProfoundSchema;
