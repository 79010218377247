import React from "react";
import "./Photograph.css";

const photograph = props => (
  <div className="photoContainer">
    <img src={props.image} alt="patient photograph" />
  </div>
);

export default photograph;
