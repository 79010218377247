import React from "react";
import {
  Dropdown,
  Grid,
  Header,
  Checkbox,
  Button,
  Icon
} from "semantic-ui-react";
import "./css/FormTableFunctions.css";
import { enums, strings } from "../../resources";
import { enums as roles } from "../../../App/resources/Enums";
import ShowIfAuthorised from "../../../App/components/ShowIfAuthorised";
import PropTypes from "prop-types";

const getAllFormOptions = () => {
  let key = 0;
  let formOptions = [
    {
      key: key,
      text: "All Forms",
      value: null
    }
  ];
  for (let item in enums.formType) {
    key++;
    formOptions.push({
      key: key,
      text: enums.formType[item].name,
      value: enums.formType[item].link
    });
  }
  return formOptions;
};

const getFormListForExistingPatient = () => {
  let formOptions = [];
  for (let item in enums.formType) {
    formOptions.push({
      reference: item,
      name: enums.formType[item].name,
      link: enums.formType[item].link
    });
  }
  return formOptions.filter(
    item => item.reference !== "NEW_PATIENT_REGISTRATION"
  );
};

class TableFunctions extends React.Component {
  handleMenuSelect = path => {
    this.props.history.push({
      pathname: path,
      state: {
        patientData: this.props.patientData,
        patientId: this.props.patientData.id,
        formId: null,
        mode: "create"
      }
    });
  };

  openPreConsultationSelection = () => {
    this.props.history.push({
      pathname: "/forms/preconsultation/select/",
      state: {
        patientData: this.props.patientData,
        patientId: this.props.patientData.id
      }
    });
  };

  render = () => {
    return (
      <Grid columns={2} className="TableFunctions">
        <Grid.Column width={7} floated="left">
          <Header as="h1">{this.props.pageTitle}</Header>
          <Dropdown
            placeholder="All Forms"
            selection
            style={{ float: "left" }}
            options={getAllFormOptions()}
            onChange={(e, data) => {
              this.props.setFormType(data.value);
            }}
          />
        </Grid.Column>

        <Grid.Column width={7} floated="right">
          <ShowIfAuthorised
            allowedRoles={[
              roles.role.PULSION,
              roles.role.ADMIN,
              roles.role.DOCTOR,
              roles.role.RECEPTIONIST
            ]}
          >
            <div className="formListNewFormSelection">
              <Checkbox
                toggle
                label={strings.header.patientFillsInForm}
                className="addPatientSourceToggle"
                checked={this.props.patientFillsInForm}
                onChange={this.props.toggleFormFillerChange}
              />
            </div>
            <div style={{ clear: "both" }} />
            <div className="formListNewFormSelection">
              {!this.props.patientFillsInForm ? (
                <Dropdown
                  text={strings.button.addFormLabel}
                  icon="tasks"
                  labeled
                  button
                  className="primary icon newFormButton"
                  direction="left"
                >
                  <Dropdown.Menu>
                    {getFormListForExistingPatient().map(item => (
                      <Dropdown.Item
                        text={item.name}
                        onClick={() =>
                          this.handleMenuSelect(`/forms/${item.link}`)
                        }
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Button
                  icon
                  labelPosition="left"
                  className="primary icon newFormButton"
                  onClick={() => this.openPreConsultationSelection()}
                >
                  <Icon name="tasks" />
                  {strings.button.preConsultationLabel}
                </Button>
              )}
            </div>
          </ShowIfAuthorised>
        </Grid.Column>
      </Grid>
    );
  };
}

TableFunctions.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  setFormType: PropTypes.func.isRequired,
  patientData: PropTypes.object.isRequired
};

export default TableFunctions;
