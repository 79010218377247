import React from "react";
import { Grid, Checkbox, Icon, Button, Header } from "semantic-ui-react";
import { SearchBar } from ".";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { enums } from "../../../Forms/resources";
import { enums as roles } from "../../../App/resources/Enums";
import ShowIfAuthorised from "../../../App/components/ShowIfAuthorised";
import "./css/PatientTableFunctions.css";

class TableFunctions extends React.Component {
  handleNewPatientOnly = () => {
    this.props.history.push({
      pathname: `/forms/${enums.formType.NEW_PATIENT_REGISTRATION.link}`,
      state: {
        mode: "create"
      }
    });
  };

  openPreConsultationSelection = () => {
    this.props.history.push({
      pathname: "/forms/preconsultation/select/"
    });
  };

  render = () => {
    return (
      <div className="TableFunctions">
        <Grid>
          <Grid.Column width={4}>
            <Header as="h1" className="listHeader">
              {strings.header.patientList}
            </Header>
          </Grid.Column>
          <Grid.Column width={8}>
            <SearchBar
              className="searchBar"
              headers={this.props.headers}
              history={this.props.history}
              fetchPatientData={this.props.fetchPatientData}
              loadingPatients={this.props.loadingPatients}
            />
          </Grid.Column>
          <Grid.Column width={4} floated="right">
            <ShowIfAuthorised
              allowedRoles={[
                roles.role.PULSION,
                roles.role.ADMIN,
                roles.role.DOCTOR,
                roles.role.RECEPTIONIST
              ]}
            >
              <div className="formListNewFormSelection">
                <Checkbox
                  toggle
                  label={strings.header.patientFillsInForm}
                  className="addPatientSourceToggle"
                  checked={this.props.patientFillsInForm}
                  onChange={this.props.toggleFormFillerChange}
                />
              </div>
              <div style={{ clear: "both" }} />
              <div className="formListNewFormSelection">
                <Button
                  primary
                  className="createButton addPatient"
                  onClick={() => {
                    if (!this.props.patientFillsInForm) {
                      this.handleNewPatientOnly();
                    } else {
                      this.openPreConsultationSelection();
                    }
                  }}
                >
                  <Icon name="user" />
                  {strings.button.addPatientLabel}
                </Button>
              </div>
            </ShowIfAuthorised>
          </Grid.Column>
        </Grid>
      </div>
    );
  };
}

TableFunctions.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default TableFunctions;
