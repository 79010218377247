import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { strings } from "../../resources";
import BasicModal from "../SemanticModal";

const SaveCancelButtons = props => {
  return (
    <div className="cancelSaveButtons" data-html2canvas-ignore="true">
    <Button.Group>
      <Button
        className="saveButton"
        onClick={props.toggleConfirmSaveModalVisibility}
      >
        {strings.button.saveLabel}
      </Button>
      <Button.Or />
      <Button
        className="cancelButton"
        onClick={props.toggleCancelChangesModalVisibility}
        type="button"
      >
        {strings.button.cancelLabel}
      </Button>
      <BasicModal
        confirmMessage={strings.modal.saveChangesMessage}
        handleModalConfirm={props.handleModalConfirm}
        modalClassName="confirmSaveModal"
        modalOpen={props.confirmSaveModalOpen}
        showOkButton={false}
        showYesButton={true}
        showNoButton={true}
        toggleModal={props.toggleConfirmSaveModalVisibility}
      />
      <BasicModal
        confirmMessage={strings.modal.cancelChangesMessage}
        handleModalConfirm={
          props.mode === "create"
            ? props.handleModalConfirm
            : props.handleModeSwitch
        }
        modalClassName="cancelChangesModal"
        modalOpen={props.cancelChangesModalOpen}
        showOkButton={false}
        showYesButton={true}
        showNoButton={true}
        toggleModal={props.toggleCancelChangesModalVisibility}
      />
    </Button.Group>
  </div>
  );
};

SaveCancelButtons.propTypes = {
  mode: PropTypes.string.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  handleModeSwitch: PropTypes.func.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired
};

export default SaveCancelButtons;
