import axios from "./axios-patients";

export class API {
  constructor() {
    this.headers = {};
    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = { ...this.headers, ...headers };
  };

  createPatient = details => {
    const config = {
      headers: this.headers
    };
    return axios
      .post("", JSON.stringify(details), config)
      .catch(error => console.log("Error creating patient: ", error.message));
  };

  updatePatient = (id, details) => {
    const config = {
      headers: this.headers
    };
    return axios.put(`/${id}`, JSON.stringify(details), config);
  };

  activatePatient = id => {
    const config = {
      headers: this.headers
    };
    return axios.put(`activate/${id}`, null, config);
  };

  deactivatePatient = id => {
    const config = {
      headers: this.headers
    };
    return axios.put(`deactivate/${id}`, "", config);
  };

  deletePatient = id => {
    const config = {
      headers: this.headers
    };
    return axios.delete(`/${id}`, null, config);
  };

  getPatients = searchTerm => {
    let url = "";
    if (searchTerm) {
      url += `?search=${searchTerm}`;
    }

    const config = {
      headers: this.headers
    };

    return axios
      .get(url, config)
      .catch(error => console.log("Error getting patients: ", error.message));
  };

  getPatientById = id => {
    const config = {
      headers: this.headers
    };
    return axios.get(`/${id}`, config);
  };

  getPatientHistoryById = (id, startTimeStamp = null) => {
    let url = `/${id}/history`;
    if (startTimeStamp) {
      url += `?startTimeStamp=${startTimeStamp}`;
    }
    const config = {
      headers: this.headers
    };
    return axios.get(url, config);
  };

  applyDoctorSignature = (id, details) => {
    const config = {
      headers: this.headers
    };
    return axios
      .put(`/signature/${id}`, JSON.stringify(details), config)
      .then(response => {
        console.log(response);
      })
      .catch(error =>
        console.log(
          "Error applying doctor signature to patient record: ",
          error.message
        )
      );
  };
}
