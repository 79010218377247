import React from "react";
import { DateInput } from "semantic-ui-calendar-react";
import { strings } from "../../resources";
import { dateEpochToDateTimeString } from "../../../App/helpers/DateTimeFormatConversion";

const DatePicker = (props) => (
  <DateInput
    error={props.error}
    label={props.label}
    className={props.className}
    placeholder={strings.patientRecord.edit.placeholder.date}
    iconPosition="left"
    name={props.name}
    closable={props.closable}
    onChange={(e, data) => {
      props.onChange(e, data);
    }}
    onKeyDown={props.onKeyDown}
    value={props.value}
    required={props.required}
    startMode="year"
    minDate={
      props.noPastDates ? dateEpochToDateTimeString(new Date(), "-") : ""
    }
    maxDate={
      props.noFutureDates ? dateEpochToDateTimeString(new Date(), "-") : ""
    }
  />
);

export default DatePicker;
