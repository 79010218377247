import React, {Component} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Icon, Form } from 'semantic-ui-react'

class TagDropdown extends Component {
  componentDidMount = async () => {

  }

  mapTagsToOptions() {
      return [{key: 0, text: '', value: ''}]
  }

  handleChange = async (e, data) => {
    let key = {
      name: this.props.tagId,
      value: data.value
    }
    this.props.handleChange(e, key)
  }

  render() {
    let data = this.mapTagsToOptions()
    if (Object.keys(data).length > 0) {
      return (
        <Form.Dropdown
          style={this.props.style || {}}
          placeholder={this.props.placeholder || ''}
          label={this.props.label}
          width={this.props.width}
          multiple={this.props.multiple || false}
          options={data}
          selection
          search={this.props.search}
          closeOnChange={true}
          closeOnBlur={true}
          onChange={this.handleChange}
          value={this.getValue(this.props.value)}
          required={this.props.required}
          text={this.getText(this.props.value)}
        />
      )
    } else {
      return null
    }
  }

  getValue = (value) => {
    if (typeof value === 'undefined') {
      if (this.props.multiple) {
        return []
      } else {
        console.log(`return 2 for ${this.props.tagId}`, value)
        return ''
      }
    } else if (typeof value === 'string') {
      if (this.props.multiple) {
        return []
      } else {
        return value
      }
    } else {
      return value
    }
  }

  getText = (value) => {
    if (typeof value === 'object') {
      return value.join(' ')
    } else {
      return value
    }
  }

  renderItemContent = (tagObj) => {
    return (
      <React.Fragment>
        <Icon
          name='delete'
          className={this.props.tagId + tagObj.taguuid}
          size='small'
          color='red'
        />
        {tagObj.tag}
      </React.Fragment>
    )
  }
}

TagDropdown.propTypes = {
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  required: PropTypes.bool.isRequired,
  search: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  tagData: PropTypes.object.isRequired,
  tagId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagDropdown)
