import { strings } from "../";
import { regexes } from "../Regexes";

const dateRegex = new RegExp(regexes.dateRegex)

const newProductSchema = {
  product_name: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.productName
  },
  product_type: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.productType
  },
  expiry_date: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.expiryDate,
    validate: (field, data) => {
      if (dateRegex.test(data[field])) {
        return true
      } else {
        return strings.form.validation.errorMessageReason.wrongDateFormat
      }
    }
  }
};

export default newProductSchema;
