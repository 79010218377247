import React from "react";
import { Button } from "semantic-ui-react";
import { strings } from "../../resources/Strings";
import { BasicModal } from ".";
import { enums as roles } from "../../../App/resources/Enums";
import ShowIfAuthorised from "../../../App/components/ShowIfAuthorised";

// If mode provided = view, show edit/delete buttons, otherwise show save/cancel buttons
const PatientTabControls = props => {
  return (
    <div>
      <ShowIfAuthorised
        allowedRoles={[roles.role.PULSION, roles.role.ADMIN, roles.role.DOCTOR]}
      >
        {props.mode === "view" && editActivationButtons(props)}
      </ShowIfAuthorised>
      {props.mode !== "view" && cancelSaveButtons(props)}
      {downloadButton(props)}
    </div>
  );
};

const editActivationButtons = props => {
  return (
    <div className="editActivationButtons" data-html2canvas-ignore="true">
      <Button.Group>
        <Button
          className="saveButton"
          onClick={() => {
            props.updateMode("edit");
          }}
          disabled={!props.patientIsActive}
        >
          {strings.button.editLabel}
        </Button>
        <Button.Or />
        <Button
          className="cancelButton"
          onClick={props.toggleActivationModalVisibility}
          type="button"
        >
          {props.patientIsActive && strings.button.deactivateLabel}
          {!props.patientIsActive && strings.button.activateLabel}
        </Button>
      </Button.Group>
      <BasicModal
        confirmMessage={
          props.patientIsActive
            ? strings.modal.deactivatePatientMessage
            : strings.modal.activatePatientMessage
        }
        handleModalConfirm={() => {
          if (props.patientIsActive) {
            props.handleDeactivate();
          } else {
            props.handleActivate();
          }
        }}
        modalClassName="confirmDeleteModal"
        modalOpen={props.activationModalOpen}
        showOkButton={false}
        showYesButton={true}
        showNoButton={true}
        toggleModal={props.toggleActivationModalVisibility}
      />
    </div>
  );
};

const cancelSaveButtons = props => {
  return (
    <div className="cancelSaveButtons" data-html2canvas-ignore="true">
      <Button.Group>
        <Button
          className="saveButton"
          onClick={props.toggleConfirmSaveModalVisibility}
        >
          {strings.button.saveLabel}
        </Button>
        <Button.Or />
        <Button
          className="cancelButton"
          onClick={props.toggleCancelChangesModalVisibility}
          type="button"
        >
          {strings.button.cancelLabel}
        </Button>
        <BasicModal
          confirmMessage={strings.modal.saveChangesMessage}
          handleModalConfirm={props.handleSubmit}
          modalClassName="confirmSaveModal"
          modalOpen={props.confirmSaveModalOpen}
          showOkButton={false}
          showYesButton={true}
          showNoButton={true}
          toggleModal={props.toggleConfirmSaveModalVisibility}
        />
        <BasicModal
          confirmMessage={strings.modal.cancelChangesMessage}
          handleModalConfirm={props.handleCancel}
          modalClassName="cancelChangesModal"
          modalOpen={props.cancelChangesModalOpen}
          showOkButton={false}
          showYesButton={true}
          showNoButton={true}
          toggleModal={props.toggleCancelChangesModalVisibility}
        />
      </Button.Group>
    </div>
  );
};

const downloadButton = props => {
  return (
    <div className="downloadButtons" data-html2canvas-ignore="true">
      <Button.Group>
        <Button
          type="button"
          className="downloadButton"
          onClick={props.downloadFormPdf}
          loading={props.generatingPdf}
        >
          {strings.button.downloadFormLabel}
        </Button>
      </Button.Group>
    </div>
  );
};

export default PatientTabControls;
