import { formActionTypes } from "../actions/form.actionTypes";

const initialState = {
  formListData: {},
  fileDeleteModalState: { open: false, name: "Not Found", event: null },
  loadingPage: false,
  response: {},
  selectedFormData: {},
  valid: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case formActionTypes.GET_FORM_BY_ID_REQUEST:
      return { ...state, loadingPage: true, response: null };
    case formActionTypes.GET_FORM_BY_ID_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        selectedFormData: action.payload
      };
    case formActionTypes.GET_FORM_BY_ID_FAILURE:
      return { ...state, loadingPage: false, response: action.payload };

    case formActionTypes.GET_FORMS_REQUEST:
      return { ...state, loadingPage: true, response: null };
    case formActionTypes.GET_FORMS_SUCCESS:
      return { ...state, loadingPage: false, formListData: action.payload };
    case formActionTypes.GET_FORMS_FAILURE:
      return { ...state, loadingPage: false, response: action.type };

    case formActionTypes.GET_FORMS_BY_TYPE_REQUEST:
      return { ...state, loadingPage: true, response: null };
    case formActionTypes.GET_FORMS_BY_TYPE_SUCCESS:
      return { ...state, loadingPage: false, formListData: action.payload };
    case formActionTypes.GET_FORMS_BY_TYPE_FAILURE:
      return { ...state, loadingPage: false, response: action.type };

    case formActionTypes.CREATE_FORM_REQUEST:
      return { ...state, loadingPage: true, response: null };
    case formActionTypes.CREATE_FORM_SUCCESS:
      return { ...state, loadingPage: false, response: action.payload };
    case formActionTypes.CREATE_FORM_FAILURE:
      return { ...state, loadingPage: false, response: action.payload };

    case formActionTypes.UPDATE_FORM_REQUEST:
      return { ...state, loadingPage: true, response: null };
    case formActionTypes.UPDATE_FORM_SUCCESS:
      return { ...state, loadingPage: false, response: action.payload };
    case formActionTypes.UPDATE_FORM_FAILURE:
      return { ...state, loadingPage: false, response: action.payload };

    case formActionTypes.DELETE_FORM_REQUEST:
      return { ...state, loadingPage: true, response: null };
    case formActionTypes.DELETE_FORM_SUCCESS:
      return { ...state, loadingPage: false, response: action.payload };
    case formActionTypes.DELETE_FORM_FAILURE:
      return { ...state, loadingPage: false, response: action.payload };

    case formActionTypes.VALIDATE_FORM_REQUEST:
      return { ...state, response: null };
    case formActionTypes.VALIDATE_FORM_SUCCESS:
      return { ...state, valid: action.payload };
    case formActionTypes.VALIDATE_FORM_FAILURE:
      return { ...state, response: action.type };

    case formActionTypes.UPDATE_SELECTED_FORM_REQUEST:
      return { ...state };
    case formActionTypes.UPDATE_SELECTED_FORM_SUCCESS:
      return {
        ...state,
        selectedFormData: {
          ...state.selectedFormData,
          [action.key]: action.value
        }
      };
    case formActionTypes.UPDATE_SELECTED_FORM_FAILURE:
      return { ...state };

    case formActionTypes.DELETE_FROM_SELECTED_FORM_REQUEST:
      return { ...state };
    case formActionTypes.DELETE_FROM_SELECTED_FORM_SUCCESS:
      const newFormData = Object.assign({}, state.selectedFormData)
      delete newFormData[action.key]
      return {
        ...state,
        selectedFormData: newFormData
      };
    case formActionTypes.DELETE_FROM_SELECTED_FORM_FAILURE:
      return { ...state };

    case formActionTypes.CLEAR_SELECTED_FORM_REQUEST:
      return { ...state };
    case formActionTypes.CLEAR_SELECTED_FORM_SUCCESS:
      return { ...state, selectedFormData: {} };
    case formActionTypes.CLEAR_SELECTED_FORM_FAILURE:
      return { ...state };

    case formActionTypes.UPDATE_FORM_LOCAL_DATA_REQUEST:
      return { ...state, response: null };
    case formActionTypes.UPDATE_FORM_LOCAL_DATA_SUCCESS:
      return {
        ...state,
        selectedFormData: action.payload,
        response: action.payload
      };
    case formActionTypes.UPDATE_FORM_LOCAL_DATA_FAILURE:
      return { ...state, response: action.payload };

    default:
      return state;
  }
}
