import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../resources";
import { YesNoInput, DatePicker, PatientTabControls, CheckboxGroup, CountryInput } from "./ui";

class PersonalDetailsTab extends React.Component {
  render = () => {
    const errorFields = this.props.validationErrors.map(error => {
      return error.field
    })
    return (
      <React.Fragment>
        <Form
          className="details"
          onSubmit={this.props.toggleConfirmSaveModalVisibility}
        >
          <fieldset disabled={this.props.mode === "view"}>
            <div className="personalInformation">
              <Form.Group>
                <Form.Input
                  label={strings.patientRecord.view.label.firstName}
                  error={errorFields.includes("first_name")}
                  name="first_name"
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.view.placeholder.firstName}
                  required
                  value={this.props.selectedPatientData.first_name || ""}
                  width={8}
                />
                <Form.Input
                  className="noLabelInput"
                  error={errorFields.includes("surname")}
                  name="surname"
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.view.placeholder.surname}
                  required
                  value={this.props.selectedPatientData.surname || ""}
                  width={8}
                />
              </Form.Group>
              <Form.Group>
                <DatePicker
                  className="dateOfBirth"
                  error={errorFields.includes("date_of_birth")}
                  name="date_of_birth"
                  onChange={this.props.handleDateChange}
                  onKeyDown={e => this.props.handleDateKeyDown(e)}
                  required
                  label={strings.patientRecord.view.label.dateOfBirth}
                  value={this.props.selectedPatientData.date_of_birth || ""}
                  noFutureDates
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  label={strings.patientRecord.view.label.emailAddress}
                  error={errorFields.includes("email_address")}
                  name="email_address"
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.view.placeholder.emailAddress}
                  //required
                  value={this.props.selectedPatientData.email_address || ""}
                  width={16}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  label={strings.patientRecord.view.label.address}
                  error={errorFields.includes("postal_address")}
                  name="postal_address"
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.view.placeholder.address}
                  //required
                  value={this.props.selectedPatientData.postal_address || ""}
                  width={12}
                />
                <Form.Input
                  label={strings.patientRecord.view.label.postcode}
                  error={errorFields.includes("postcode")}
                  name="postcode"
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.view.placeholder.postcode}
                  //required
                  value={this.props.selectedPatientData.postcode || ""}
                  width={4}
                />
              </Form.Group>
              <Form.Group>
                <CountryInput
                  required={true}
                  label={strings.patientRecord.view.label.country}
                  placeholder={strings.patientRecord.view.placeholder.country}
                  name="country"
                  error={errorFields.includes("country")}
                  onChange={this.props.handleChange}
                  value={this.props.selectedPatientData.country || ""}
                  width={8}
                  disabled={this.props.mode === "view"}
                />
                <Form.Input
                  error={errorFields.includes("town_or_city")}
                  label={strings.patientRecord.view.label.townOrCity}
                  name="town_or_city"
                  required={true}
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.view.placeholder.townOrCity}
                  value={this.props.selectedPatientData.town_or_city || ""}
                  width={8}
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input
                  error={errorFields.includes("phone_number")}
                  label={strings.patientRecord.view.label.mainPhoneNumber}
                  name="phone_number"
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.view.placeholder.mainPhoneNumber}
                  required
                  value={this.props.selectedPatientData.phone_number || ""}
                />
                <Form.Input
                  error={errorFields.includes("mobile_phone_number")}
                  label={strings.patientRecord.view.label.mobilePhoneNumber}
                  name="mobile_phone_number"
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.view.placeholder.mobilePhoneNumber}
                  required
                  value={this.props.selectedPatientData.mobile_phone_number || ""}
                />
                <Form.Input
                  label={strings.patientRecord.view.label.occupation}
                  name="occupation"
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.view.placeholder.occupation}
                  //required
                  value={this.props.selectedPatientData.occupation || ""}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  label={strings.patientRecord.view.label.emergencyContactName}
                  error={errorFields.includes("emergency_contact_name")}
                  name="emergency_contact_name"
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.view.placeholder.emergencyContactName}
                  value={
                    this.props.selectedPatientData.emergency_contact_name || ""
                  }
                  width={8}
                />
                <Form.Input
                  label={strings.patientRecord.view.label.emergencyContactPhoneNumber}
                  error={errorFields.includes("emergency_contact_phone_number")}
                  name="emergency_contact_phone_number"
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.view.placeholder.phoneNumber}
                  value={
                    this.props.selectedPatientData
                      .emergency_contact_phone_number || ""
                  }
                  width={8}
                />
              </Form.Group>
            </div>
            <div className="privacyHeader patientSectionHeader">
              {strings.patientRecord.header.privacy}
            </div>
            <div className="privacy">
            <CheckboxGroup
              horizontal={false}
              mode={this.props.mode}
              label={strings.patientRecord.view.label.consentPhotographicInformation}
              required
              error={errorFields.includes("image_consent")}
              group="image_consent"
              data={this.props.selectedPatientData}
              handleCheckboxChange={this.props.handleCheckboxChange}
              options={[
                {
                  key: "consent_share_images",
                  label: strings.patientRecord.view.label.consentShareImages
                },
                {
                  key: "consent_show_images_to_other_patients",
                  label: strings.patientRecord.view.label.consentShowImagesToOtherPatients
                },
                {
                  key: "consent_show_images_on_website",
                  label: strings.patientRecord.view.label.consentShowImagesOnWebsite
                },
                {
                  key: "consent_show_images_on_social_media",
                  label: strings.patientRecord.view.label.consentShowImagesOnSocialMedia
                }
              ]}
            />
            <YesNoInput
              mode={this.props.mode}
              error={errorFields.includes("communication_consent")}
              name="communication_consent"
              label={strings.patientRecord.view.label.consentSendTextAndEmail}
              value={this.props.selectedPatientData.communication_consent}
              changehandler={this.props.handleYesNoChange}
              required
            />
            </div>
          </fieldset>
        </Form>
        <PatientTabControls
          patientIsActive={this.props.selectedPatientData["active"]}
          {...this.props}
        />
      </React.Fragment>
    );
  };
}

PersonalDetailsTab.propTypes = {
  selectedPatientData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default PersonalDetailsTab;
