import LocalizedStrings from "react-localization";

export const enums = new LocalizedStrings({
  en: {
    role: {
      PULSION: "pulsion",
      ADMIN: "admin",
      DOCTOR: "doctor",
      RECEPTIONIST: "receptionist",
      PATIENT: "patient"
    }
  }
});
