import { API } from "../../services";
import { imageActionTypes } from "./image.actionTypes";

const api = new API();

const purgeState = () => {
  const purge = () => {
    return { type: imageActionTypes.PURGE_STATE };
  };

  return dispatch => {
    dispatch(purge());
  };
};

const getImages = (path, headers) => {
  const request = path => {
    return { type: imageActionTypes.GET_IMAGES_REQUEST, payload: path };
  };
  const success = response => {
    return { type: imageActionTypes.GET_IMAGES_SUCCESS, payload: response };
  };
  const failure = error => {
    return { type: imageActionTypes.GET_IMAGES_FAILURE, payload: error };
  };

  return dispatch => {
    dispatch(request(path));
    return new Promise((resolve, reject) => {
      api
        .getRequest(path, headers)
        .then(response => {
          dispatch(success(response.data.content));
          resolve(response.data.content);
        })
        .catch(error => {
          dispatch(failure(error));
          resolve(error);
        });
    });
  };
};

const getPatientSignature = (path, headers) => {
  const request = path => {
    return {
      type: imageActionTypes.GET_PATIENT_SIGNATURE_REQUEST,
      payload: path
    };
  };
  const success = response => {
    return {
      type: imageActionTypes.GET_PATIENT_SIGNATURE_SUCCESS,
      payload: response
    };
  };
  const failure = error => {
    return {
      type: imageActionTypes.GET_PATIENT_SIGNATURE_FAILURE,
      payload: error
    };
  };

  return dispatch => {
    dispatch(request(path));
    return new Promise((resolve, reject) => {
      api
        .getRequest(path, headers)
        .then(response => {
          dispatch(success(response.data.content));
          resolve(response.data.content);
        })
        .catch(error => {
          dispatch(failure(error));
          resolve(error);
        });
    });
  };
};

const getDoctorSignature = (path, headers) => {
  const request = path => {
    return {
      type: imageActionTypes.GET_DOCTOR_SIGNATURE_REQUEST,
      payload: path
    };
  };
  const success = response => {
    return {
      type: imageActionTypes.GET_DOCTOR_SIGNATURE_SUCCESS,
      payload: response
    };
  };
  const failure = error => {
    return {
      type: imageActionTypes.GET_DOCTOR_SIGNATURE_FAILURE,
      payload: error
    };
  };

  return dispatch => {
    dispatch(request(path));
    return new Promise((resolve, reject) => {
      api
        .getRequest(path, headers)
        .then(response => {
          dispatch(success(response.data.content));
          resolve(response.data.content);
        })
        .catch(error => {
          dispatch(failure(error));
          resolve(error);
        });
    });
  };
};

const setLoadingImages = status => {
  const success = status => {
    return {
      type: imageActionTypes.SET_LOADING_IMAGES_SUCCESS,
      payload: status
    };
  };

  return dispatch => {
    dispatch(success(status));
  };
};

const updateLocalImages = (index, newVal) => {
  const success = data => {
    return {
      type: imageActionTypes.UPDATE_LOCAL_IMAGES_SUCCESS,
      payload: data
    };
  };

  const data = {
    key: index,
    value: newVal
  };
  return dispatch => {
    dispatch(success(data));
  };
};

const setFileTypeWarningVisible = visible => {
  const success = visible => {
    return {
      type: imageActionTypes.SET_FILE_TYPE_WARNING_VISIBLE_REQUEST,
      payload: visible
    };
  };

  return dispatch => {
    dispatch(success(visible));
  };
};

const uploadImage = (upload, headers) => {
  const request = upload => {
    return { type: imageActionTypes.UPLOAD_IMAGE_REQUEST, payload: upload };
  };
  const success = response => {
    return { type: imageActionTypes.UPLOAD_IMAGE_SUCCESS, payload: response };
  };
  const failure = error => {
    return { type: imageActionTypes.UPLOAD_IMAGE_FAILURE, payload: error };
  };

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .postRequest(upload, headers)
        .then(response => {
          dispatch(success(response.data.content));
          resolve(response.data.content);
        })
        .catch(error => {
          dispatch(failure(error));
          resolve(error);
        });
    });
  };
};

const deleteImage = (imagePath, headers) => {
  const request = data => {
    return { type: imageActionTypes.DELETE_IMAGE_REQUEST, payload: data };
  };
  const success = data => {
    return { type: imageActionTypes.DELETE_IMAGE_SUCCESS, payload: data };
  };
  const failure = error => {
    return { type: imageActionTypes.DELETE_IMAGE_FAILURE, payload: error };
  };

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .deleteRequest(imagePath, headers)
        .then(response => {
          dispatch(success(response.data.content));
          resolve(response.data.content);
        })
        .catch(error => {
          dispatch(failure(error));
          resolve(error);
        });
    });
  };
};

const updateImage = (update, headers) => {
  const request = data => {
    return { type: imageActionTypes.UPDATE_IMAGE_REQUEST, payload: data };
  };
  const success = data => {
    return { type: imageActionTypes.UPDATE_IMAGE_SUCCESS, payload: data };
  };
  const failure = error => {
    return { type: imageActionTypes.UPDATE_IMAGE_FAILURE, payload: error };
  };
  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .putRequest(update, headers)
        .then(response => {
          dispatch(success(update));
          resolve(update);
        })
        .catch(error => {
          dispatch(failure(error));
          resolve(error);
        });
    });
  };
};

const updateSignatureObject = (upload, headers) => {
  const success = upload => {
    return {
      type: imageActionTypes.UPDATE_SIGNATURE_OBJECT_SUCCESS,
      payload: upload
    };
  };

  return dispatch => {
    dispatch(success(upload));
  };
};

const updateAnnotationObject = (name, upload, headers) => {
  const success = upload => {
    return {
      type: imageActionTypes.UPDATE_ANNOTATION_OBJECT_SUCCESS,
      payload: {
        name: name,
        upload: upload
      }
    };
  };

  return dispatch => {
    dispatch(success(upload));
  };
};

const getAnnotationImage = (name, path, headers) => {
  const request = path => {
    return {
      type: imageActionTypes.GET_ANNOTATION_IMAGE_REQUEST,
      payload: path
    };
  };
  const success = response => {
    return {
      type: imageActionTypes.GET_ANNOTATION_IMAGE_SUCCESS,
      payload: {
        name: name,
        response: response
      }
    };
  };
  const failure = error => {
    return {
      type: imageActionTypes.GET_ANNOTATION_IMAGE_FAILURE,
      payload: error
    };
  };

  return dispatch => {
    dispatch(request(path));
    return new Promise((resolve, reject) => {
      api
        .getRequest(path, headers)
        .then(response => {
          dispatch(success(response.data.content));
          resolve(response.data.content);
        })
        .catch(error => {
          dispatch(failure(error));
          resolve(error);
        });
    });
  };
};

const updatePhotoFileName = name => {
  const success = name => {
    return {
      type: imageActionTypes.UPDATE_PHOTO_FILE_NAME_SUCCESS,
      payload: name
    };
  };

  return dispatch => {
    dispatch(success(name));
  };
};

const resetLocalImageData = () => {
  const success = () => {
    return {
      type: imageActionTypes.RESET_LOCAL_IMAGE_DATA_SUCCESS
    };
  };

  return dispatch => {
    dispatch(success());
  };
};

const getSingleImage = (path, name, headers) => {
  const request = path => {
    return { type: imageActionTypes.GET_SINGLE_IMAGE_REQUEST, payload: path };
  };
  const success = response => {
    return {
      type: imageActionTypes.GET_SINGLE_IMAGE_SUCCESS,
      payload: response
    };
  };
  const failure = error => {
    return { type: imageActionTypes.GET_SINGLE_IMAGE_FAILURE, payload: error };
  };

  return dispatch => {
    dispatch(request(path));
    return new Promise((resolve, reject) => {
      api
        .getSingleImageRequest(path, name, headers)
        .then(response => {
          dispatch(success(response.data.content));
          resolve(response.data.content);
        })
        .catch(error => {
          dispatch(failure(error));
          resolve(error);
        });
    });
  };
};

const setLoadingFullsizedImage = status => {
  const success = status => {
    return {
      type: imageActionTypes.SET_LOADING_FULLSIZED_IMAGE_SUCCESS,
      payload: status
    };
  };

  return dispatch => {
    dispatch(success(status));
  };
};

const clearDoctorSignature = () => {
  const success = () => {
    return {
      type: imageActionTypes.CLEAR_DOCTOR_SIGNATURE_SUCCESS
    };
  };

  return dispatch => {
    dispatch(success());
  };
};

const clearAnnotationsObject = () => {
  const success = () => {
    return {
      type: imageActionTypes.CLEAR_ANNOTATIONS_OBJECT_SUCCESS
    };
  };

  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(success());
      resolve();
    });
  };
};

export const imageActions = {
  purgeState,
  getImages,
  updateLocalImages,
  setLoadingImages,
  uploadImage,
  setFileTypeWarningVisible,
  deleteImage,
  updateImage,
  getPatientSignature,
  getDoctorSignature,
  updateSignatureObject,
  updateAnnotationObject,
  getAnnotationImage,
  updatePhotoFileName,
  resetLocalImageData,
  getSingleImage,
  setLoadingFullsizedImage,
  clearDoctorSignature,
  clearAnnotationsObject
};
