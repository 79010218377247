import { strings } from "../resources/Strings";
import {
  checkResponse,
  checkRangeResponse,
  checkGroupResponse
} from "./checkUserResponseHelpers";

//Returns an array of strings corresponding to the patient's aesthetic concerns
export const formatAestheticConcerns = data => {
  let output = [];

  let patientConcerns = checkGroupResponse(
    data,
    "reasons",
    "",
    strings.patientRecord.mappings.noCurrentConcerns,
    "none",
    false,
    ". "
  );

  output.push(
    `${strings.patientRecord.mappings.patientConcerns} "${patientConcerns}"`
  );

  const botheredByAppearanceResult = checkRangeResponse(
    data,
    "bothered_by_appearance",
    [
      strings.patientRecord.mappings.botheredByAppearance1to2,
      strings.patientRecord.mappings.botheredByAppearance1to2,
      strings.patientRecord.mappings.botheredByAppearance3to4,
      strings.patientRecord.mappings.botheredByAppearance3to4,
      strings.patientRecord.mappings.botheredByAppearance5to7,
      strings.patientRecord.mappings.botheredByAppearance5to7,
      strings.patientRecord.mappings.botheredByAppearance5to7,
      strings.patientRecord.mappings.botheredByAppearance8to10,
      strings.patientRecord.mappings.botheredByAppearance8to10,
      strings.patientRecord.mappings.botheredByAppearance8to10
    ]
  );
  if (botheredByAppearanceResult != null) {
    output.push(botheredByAppearanceResult);
  }

  const thinksAboutAppearanceResult = `${
    strings.patientRecord.mappings.thinksAboutAppearance
  } ${checkResponse(data, "how_often_appearance").toLowerCase()}`;
  if (!thinksAboutAppearanceResult.includes("no response found")) {
    output.push(thinksAboutAppearanceResult);
  }

  if (data.leave_house_toggle === "Yes") {
    output.push(strings.patientRecord.mappings.leaveHouse);
  }

  if (data.avoids_social_situations_toggle === "Yes") {
    output.push(strings.patientRecord.mappings.avoidsSocialSituations);
  }

  if (data.avoids_photographs_toggle === "Yes") {
    output.push(strings.patientRecord.mappings.avoidsPhotographs);
  }

  if (data.avoids_certain_angles_toggle === "Yes") {
    output.push(strings.patientRecord.mappings.avoidsCertainAngles);
  }

  if (data.wears_makeup_toggle === "Yes") {
    output.push(strings.patientRecord.mappings.wearsMakeup);
  }

  let result = checkGroupResponse(data, "treatments", "", "", "lower");
  if (result != null && result !== "") {
    output.push(output.push(result));
  }

  return output;
};
