import axios from "./axios-products";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createProduct = details => {
    const config = {
      headers: this.headers
    };
    return axios
      .post("", JSON.stringify(details), config)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log("Error creating product: ", error.message));
  };

  updateProduct = (id, details) => {
    const config = {
      headers: this.headers
    };
    return axios
      .put(`/${id}`, JSON.stringify(details), config)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log("Error updating product: ", error.message));
  };

  activateProduct = id => {
    const config = {
      headers: this.headers
    };
    return axios
      .put(`/status/${id}`, JSON.stringify({ active: true }), config)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log("Error activating product: ", error.message));
  };

  deactivateProduct = id => {
    const config = {
      headers: this.headers
    };
    return axios
      .put(`/status/${id}`, JSON.stringify({ active: false }), config)
      .then(response => {
        console.log(response);
      })
      .catch(error =>
        console.log("Error deactivating product: ", error.message)
      );
  };

  getProducts = (active, expiresAfter) => {
    const config = {
      headers: this.headers
    };

    let queryStringComponents = [];
    if (active !== undefined && active !== null && active !== "") {
      queryStringComponents.push(`active=${active}`);
    }
    if (expiresAfter !== undefined && expiresAfter !== null && expiresAfter !== "") {
      queryStringComponents.push(`expiresAfter=${expiresAfter}`);
    }

    let queryString =
      queryStringComponents.length > 0
        ? "?" + queryStringComponents.join("&")
        : "";

    return axios
      .get(queryString, config)
      .catch(error => console.log("Error getting products: ", error.message));
  };

  getProductById = id => {
    const config = {
      headers: this.headers
    };
    return axios
      .get(`/${id}`, config)
      .catch(error => console.log("Error getting product: ", error.message));
  };
}
