import React from "react";
import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import { imageActions } from "../store/actions/image.actions";
import { formActions } from "../../Forms/store/actions/form.actions";
import PropTypes from "prop-types";
import DoctorSignature from "../components/DoctorSignature";
import { strings } from "../resources";

class DoctorSignatureContainer extends React.Component {
  /**
   *  DoctorSignatureContainer - Used to show and apply doctor signatures
   */

  componentDidMount = async () => {
    await this.props.clearDoctorSignature();
    if (this.props.signaturePathAttachedToForm) {
      this.props.getDoctorSignature(
        this.props.signaturePathAttachedToForm,
        this.props.headers
      );
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.loadingPage && !this.props.loadingPage) {
      this.props.getDoctorSignature(
        this.props.signaturePathAttachedToForm,
        this.props.headers
      );
    }
  };

  render = () => {
    return (
      <div>
        {this.props.loadingPage && (
          <Loader active>{strings.header.loading}</Loader>
        )}
        {!this.props.loadingPage && (
          <DoctorSignature
            containerWidth={this.props.containerWidth}
            containerHeight={this.props.containerHeight}
            dateDoctorSigned={this.props.dateDoctorSigned}
            doctorName={this.props.doctorName}
            handleClear={this.handleClear}
            signatureURL={this.props.doctorSignature}
          />
        )}
      </div>
    );
  };
}

DoctorSignatureContainer.propTypes = {
  blank: PropTypes.bool, //whether the container should appear empty
  containerHeight: PropTypes.number.isRequired, //width of signature area in pixels (default 240)
  containerWidth: PropTypes.number.isRequired, //width of signature area in pixels (default 150)
  signaturePathAttachedToForm: PropTypes.string //path on S3 of where either to store or retrieve the signature image
};

const mapStateToProps = state => {
  const { images, auth, forms } = state;
  const { loadingImageData, doctorSignature } = images;
  const { signaturePathOfLoggedInUser } = auth;
  const { selectedFormData, loadingPage } = forms;
  return {
    loadingImageData,
    doctorSignature,
    signaturePathOfLoggedInUser,
    selectedFormData,
    loadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    applyDoctorSignature: (id, details, headers) => {
      dispatch(formActions.applyDoctorSignature(id, details, headers));
    },
    clearDoctorSignature: () => {
      dispatch(imageActions.clearDoctorSignature());
    },
    getDoctorSignature: (path, headers) => {
      return dispatch(imageActions.getDoctorSignature(path, headers));
    },
    updateSelectedForm: (key, value) => {
      dispatch(formActions.updateSelectedForm(key, value));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoctorSignatureContainer);
