import React from "react";
import PropTypes from "prop-types";
import { Button, Divider, Form, Message } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { SaveCancelButtons } from "../../App/components/Buttons";
import { strings } from "../resources";
import { YesNoInput } from "./ui";
import "./css/TreatmentComponents.css";
import DoctorSignatureContainer from "../../Images/containers/DoctorSignatureContainer";

class FollowUpNoteEditable extends React.Component {
  render = () => {
    const errorFields = this.props.validationErrors.map((error) => {
      return error.field;
    });

    const saveCancelButtons = (
      <SaveCancelButtons
        mode={this.props.mode}
        handleModalConfirm={this.props.handleModalConfirm}
        handleModeSwitch={this.props.handleModeSwitch}
        confirmSaveModalOpen={this.props.confirmSaveModalOpen}
        cancelChangesModalOpen={this.props.cancelChangesModalOpen}
        toggleConfirmSaveModalVisibility={this.props.toggleConfirmSaveModalVisibility}
        toggleCancelChangesModalVisibility={this.props.toggleCancelChangesModalVisibility}
      ></SaveCancelButtons>
    )
  
    const downloadButton = (props) => {
      return (
        <div className="downloadButtons" data-html2canvas-ignore="true">
          <Button.Group>
            <Button
              type="button"
              className="downloadButton"
              onClick={props.downloadFormPdf}
              loading={props.generatingPdf}
            >
              {strings.button.downloadFormLabel}
            </Button>
          </Button.Group>
        </div>
      );
    };

    const followUpNoteForm = this.props.selectedPatientData ? (
      <>

        {(this.props.mode === "create" || this.props.mode === "edit") && (
          <>
            <div className="treatmentInfoHeader">
              {strings.form.header.followUpNote}
            </div>
            <YesNoInput
              mode={this.props.mode}
              error={errorFields.includes("was_expected_outcome")}
              name="was_expected_outcome"
              label={strings.form.label.wasExpectedOutcome}
              value={this.props.selectedTreatmentData.was_expected_outcome || ""}
              changehandler={this.props.handleYesNoChange}
              required
            />
            <YesNoInput
              mode={this.props.mode}
              error={errorFields.includes("were_there_complications")}
              name="were_there_complications"
              label={strings.form.label.wereThereComplications}
              value={this.props.selectedTreatmentData.were_there_complications || ""}
              changehandler={this.props.handleYesNoChange}
              required
            />
          </>
        )}
        
        <div className="sectionHeader">
          {strings.form.header.followUpNoteReport}
        </div>

        <div className="addtionalOutcomeDetails">
          <Form.Group>
            {this.props.selectedTreatmentData.was_expected_outcome ===
              strings.form.label.radioYes && (
              <div className="followUpNoteReport">
                {strings.form.patientMappings.expectedOutcome}
              </div>
            )}
            {this.props.selectedTreatmentData.was_expected_outcome ===
              strings.form.label.radioNo && (
              <div className="followUpNoteReport">
                {strings.form.patientMappings.notExpectedOutcome}
              </div>
            )}
          </Form.Group>

          <Form.Group>
            {this.props.selectedTreatmentData.were_there_complications ===
              strings.form.label.radioYes && (
              <div className="followUpNoteReport">
                {strings.form.patientMappings.experiencedComplications}
              </div>
            )}
            {this.props.selectedTreatmentData.were_there_complications ===
              strings.form.label.radioNo && (
              <div className="followUpNoteReport">
                {strings.form.patientMappings.experiencedNoComplications}
              </div>
            )}
          </Form.Group>

          <Form.Group>
            <Form.TextArea
              label={strings.form.label.additionalOutcomeDetails}
              name="additional_outcome_details"
              onChange={this.props.handleChange}
              rows={2}
              value={
                this.props.selectedTreatmentData.additional_outcome_details ||
                ""
              }
              width={16}
            />
          </Form.Group>
          {this.props.selectedTreatmentData.doctor_signature_name != null &&
            this.props.selectedTreatmentData.doctor_signature_path != null &&
            this.props.selectedTreatmentData.doctor_signature_applied_date !=
              null && (
              <DoctorSignatureContainer
                containerWidth={240}
                containerHeight={150}
                dateDoctorSigned={
                  this.props.selectedTreatmentData.doctor_signature_applied_date
                }
                doctorName={
                  this.props.selectedTreatmentData.doctor_signature_name
                }
                formId={null}
                headers={null}
                signaturePathAttachedToForm={
                  this.props.selectedTreatmentData.doctor_signature_path
                }
              />
            )}
        </div>
      </>
    ) : (
      <Redirect to="/patients" />
    );

    return (
      <div className="FollowUpNoteContainer" id="formDiv">
        {this.props.validationErrors.length > 0 && (
          <div className="errorMessageContainer">
            <Message
              compact
              error
              header={strings.form.label.errorHeader}
              list={this.props.validationErrors.map((error) => {
                return error.error;
              })}
            />
          </div>
        )}
        <div className="pageHeader">{this.props.pageTitle}</div>
        <Form
          className="details"
          onSubmit={this.props.toggleConfirmSaveModalVisibility}
        >
        <fieldset className="treatmentFormFieldset">
          {followUpNoteForm}
        </fieldset>
        </Form>
        <div className="centerContent">
          {saveCancelButtons}
          <Divider hidden />
          {downloadButton(this.props)}
        </div>
      </div>
    );
  };
}

FollowUpNoteEditable.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedTreatmentData: PropTypes.object.isRequired,
  selectedPatientData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModeSwitch: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
};

export default FollowUpNoteEditable;
