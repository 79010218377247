import React from "react";
import { DateInput } from "semantic-ui-calendar-react";
import { strings } from "../../resources";
import { dateEpochToDateTimeString } from "../../../App/helpers/DateTimeFormatConversion";

const DatePicker = (props) => {
  return (
    <DateInput
      error={props.error}
      label={props.label}
      className={props.className}
      placeholder={strings.form.placeholder.date}
      iconPosition="left"
      name={props.name}
      closable={props.closable}
      onChange={(e, data) => {
        props.onChange(e, data);
      }}
      onKeyDown={props.onKeyDown}
      value={props.value}
      required={props.required}
      startMode="year"
      disabled={props.disabled}
      minDate={
        props.noPastDates ? dateEpochToDateTimeString(new Date(), "-") : ""
      }
      maxDate={
        props.noFutureDates ? dateEpochToDateTimeString(new Date(), "-") : ""
      }
      width={props.width}
    />
  );
};

export default DatePicker;
