import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Responsive, Segment, Sidebar } from "semantic-ui-react";

import TopMenuBar from "../Navigation/Menu/TopMenuBar";
import DesktopSidebar from "../Navigation/Sidebar/DesktopSidebar";
import MobileSidebar from "../Navigation/Sidebar/MobileSidebar";
import { authActions } from "../../store/actions/auth.actions";
import "./css/ResponsiveContainer.css";

class ResponsiveContainer extends React.Component {
  state = {
    //activeItem: "patients",
    isMobile: true,
    sidebarOpened: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.setState({
      sidebarOpened: false
    });
  }

  toggleSidebar = () =>
    this.setState({ sidebarOpened: !this.state.sidebarOpened });

  getLayoutClassname = () => {
    if (this.state.sidebarOpened) return "sidebarOpen";
    else if (this.props.isLoggedIn) return "sidebarClosed";
    else return "loggedOut";
  };

  render() {
    const desktopView = (
      <>
        <TopMenuBar
          mobile={this.isMobile}
          toggleSidebar={this.toggleSidebar}
          isLoggedIn={this.props.isLoggedIn}
          role={this.props.role}
          logout={this.props.logout}
        />
        {this.props.isLoggedIn && (
          <DesktopSidebar
            activeItem={this.state.activeItem}
            toggleSidebar={this.toggleSidebar}
            sidebarOpened={this.state.sidebarOpened}
            role={this.props.role}
          />
        )}
        <Sidebar.Pusher>
          <Segment
            basic
            className={this.props.isLoggedIn ? "sidebarPusher" : "loggedOut"}
          >
            {this.props.children}
          </Segment>
        </Sidebar.Pusher>
      </>
    );

    const tabletView = (
      <>
        <TopMenuBar
          mobile={this.isMobile}
          toggleSidebar={this.toggleSidebar}
          isLoggedIn={this.props.isLoggedIn}
          role={this.props.role}
          logout={this.props.logout}
        />
        {this.props.isLoggedIn && (
          <MobileSidebar
            activeItem={this.state.activeItem}
            toggleSidebar={this.toggleSidebar}
            sidebarOpened={this.state.sidebarOpened}
            role={this.props.role}
          />
        )}
        <Sidebar.Pusher>
          <Segment basic className={this.getLayoutClassname()}>
            {this.props.children}
          </Segment>
        </Sidebar.Pusher>
      </>
    );

    return (
      <>
        <Responsive as={Sidebar.Pushable} minWidth="1669">
          {desktopView}
        </Responsive>

        <Responsive as={Sidebar.Pushable} maxWidth="1668">
          {tabletView}
        </Responsive>
      </>
    );
  }
}

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool.isRequired,
  role: PropTypes.string,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { isLoggedIn, role } = state.auth;

  return {
    isLoggedIn,
    role
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(authActions.signOut());
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResponsiveContainer)
);
