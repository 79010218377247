import React from "react";
import ReactTable from "react-table";
import { FormTemplateTableFunctions } from "./ui";
import { strings } from "../resources";
import PropTypes from "prop-types";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      return {
        onClick: () => {
          if (rowInfo) {
            this.props.history.push({
              pathname: "/forms/" + rowInfo.original.form_type,
              state: { patientId: null, mode: "template" }
            });
          }
        }
      };
    }
    return {}; // getTdProps requires a return statement
  };

  render = () => {
    let data = this.props.formListData;

    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    return (
      <div>
        <FormTemplateTableFunctions
          pageTitle={this.props.pageTitle}
          history={this.props.history}
          setFormType={this.props.setFormType}
          patientId={this.props.patient_id}
        />
        <div>
          <ReactTable
            data={data.results}
            columns={[
              {
                Header: strings.table.header.formType,
                accessor: "form_name",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(40)
              }
            ]}
            className="-striped -highlight"
            defaultPageSize={10}
            manual
            onPageChange={index => {
              this.props.setPage(index);
            }}
            loading={this.props.loadingTable}
            sortable={false}
            filterable={false}
            showPageJump={false}
            showPageSizeOptions={false}
            getTdProps={this.getTdProps}
            getTrProps={this.getTrProps}
            minRows="1"
            noDataText="No results found."
            pageSizeOptions={[10, 20, 30]}
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  formListData: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default TableComponent;
