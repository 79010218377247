import React from "react";
import ModifyProduct from "../../components/ModifyProduct";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { productActions } from "../../store/actions/product.actions";
import PropTypes from "prop-types";
import validateProductInput from "../../helpers/productValidator";
import formatDateInput from "../../../Forms/helpers/FormatDateInput";

class ProductCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    validationErrors: []
  };

  componentDidMount = async () => {
    this.props.clearSelectedProduct();
  };

  handleSubmit = () => {
    this.props.createProduct(
      this.props.selectedProductData,
      this.props.headers,
      this.props.history
    );
  };

  handleDateChange = async (event, data) => {
    let dataToSubmit = "";
    let currentValue = this.props.selectedProductData[data.name];
    // Only look to add dashes if there is something to format, and if the user
    // is typing rather than deleting
    if (currentValue && data.value.length > 0 && data.value.length > currentValue.length) {
      dataToSubmit = formatDateInput(data.value);
    } else {
      dataToSubmit = data.value;
    }
    await this.props.updateSelectedProduct(data.name, dataToSubmit);
    this.removeFieldError(data.name);
  };

  handleDateKeyDown = event => {
    // Filter the input to the date box - restrict it to numbers, the dash separator,
    // the backspace key (keyCode 8), and the delete key (keyCode 46)
    if (isNaN(event.key) && event.key !== "-" && event.keyCode !== 8 && event.keyCode !== 46) {
      event.preventDefault();
    }
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    this.props.updateSelectedProduct(data.name, data.value);
    this.removeFieldError(data.name);
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  removeFieldError = field => {
    const newErrors = this.state.validationErrors.filter(error => {
      return error.field !== field;
    });
    this.setState({
      validationErrors: newErrors
    });
  };

  toggleConfirmSaveModalVisibility = async () => {
    const submitData = Object.assign({}, this.props.selectedProductData);
    Object.keys(submitData).forEach(field => {
      if (submitData[field] === "") {
        delete submitData[field];
      }
    });
     // Validate the data before showing the modal
     const validationResult = await validateProductInput(
      submitData,
      "newProduct"
    );
    if (validationResult.passedValidation === true) {
      this.setState({
        confirmSaveModalOpen: !this.state.confirmSaveModalOpen
      });
    } else {
      // If failed validation, don't show modal, add errors to state and scroll to top
      window.scrollTo(0, 0);
      this.setState({
        validationErrors: validationResult.errors.slice()
      });
    }
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  render = () => {
    return (
      <div>
        <div style={{ display: this.props.loadingPage ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: this.props.loadingPage ? "none" : "" }}>
          <ModifyProduct
            mode="create"
            pageTitle={strings.header.createProduct}
            selectedProductData={this.props.selectedProductData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            handleDateChange={this.handleDateChange}
            handleDateKeyDown={this.handleDateKeyDown}
            validationErrors={this.state.validationErrors}
          />
        </div>
      </div>
    );
  };
}

ProductCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedProductData: PropTypes.object.isRequired,
  updateSelectedProduct: PropTypes.func.isRequired,
  clearSelectedProduct: PropTypes.func.isRequired,
  createProduct: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { products } = state;
  const { loadingPage, selectedProductData } = products;
  return {
    loadingPage,
    selectedProductData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createProduct: (data, headers, history) => {
      dispatch(productActions.createProduct(data, headers))
        .then(() => history.push("/products"))
        .catch(error => console.log(error));
    },
    updateSelectedProduct: (key, value) => {
      dispatch(productActions.updateSelectedProduct(key, value));
    },
    clearSelectedProduct: () => {
      dispatch(productActions.clearSelectedProduct());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCreateContainer);
