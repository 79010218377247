import React from "react";
import PropTypes from "prop-types";
import TreatmentType from "../../components/TreatmentType";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { treatmentTypeActions } from "../../store/actions/treatmentType.actions";
import validateTreatmentInput from "../../helpers/treatmentValidator";

class TreatmentTypeContainer extends React.Component {
  state = {
    mode: (() => {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.mode
      )
        return this.props.location.state.mode;
      else if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id
      )
        return "view";
      else return "create";
    })(),
    confirmSaveModalOpen: false,
    activationModalOpen: false,
    cancelChangesModalOpen: false,
    validationErrors: []
  };

  componentDidMount = async () => {
    if (this.state.mode === "create") {
      this.props.clearSelectedTreatmentType();
      await this.props.updateSelectedTreatmentType(
        "period_until_next_treatment_units",
        "Days"
      );
      await this.props.updateSelectedTreatmentType(
        "text_notification_timeframe_units",
        "Days"
      );
    } else {
      await this.props.getTreatmentTypeById(
        this.props.match.params.id,
        this.props.headers
      );
    }
  };

  handleSubmit = () => {
    // Remove the empty fields before submitting
    const submitData = Object.assign({}, this.props.selectedTreatmentTypeData);
    Object.keys(submitData).forEach(field => {
      if (submitData[field] === "") {
        delete submitData[field];
      }
    });
    if (this.state.mode === "create") {
      this.props.createTreatmentType(
        submitData,
        this.props.headers,
        this.props.history
      );
    } else {
      this.props.updateTreatmentType(
        this.props.match.params.id,
        submitData,
        this.props.headers,
        this.props.history
      );
    }
  };

  handleActivate = async () => {
    await this.props.activateTreatmentType(
      this.props.selectedTreatmentTypeData.id,
      this.props.headers
    );
    await this.props.getTreatmentTypeById(
      this.props.match.params.id,
      this.props.headers
    );
  };

  handleDeactivate = async () => {
    await this.props.deactivateTreatmentType(
      this.props.selectedTreatmentTypeData.id,
      this.props.headers
    );
    await this.props.getTreatmentTypeById(
      this.props.match.params.id,
      this.props.headers
    );
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    this.removeFieldError(data.name);
    this.props.updateSelectedTreatmentType(data.name, data.value);
  };

  handleModeSwitch = () => {
    if (this.state.mode === "edit") {
      this.setState({ mode: "view" });
      // restore original data if the changes to the form are cancelled
      this.props.getTreatmentTypeById(
        this.props.match.params.id,
        this.props.headers
      );
      this.setState({
        validationErrors: []
      });
    } else if (this.state.mode === "view") {
      this.setState({ mode: "edit" });
    }
  };

  removeExistingSelections = options => {
    return options.filter(item => {
      for (let i in this.props.selectedTreatmentTypeData.doctors_and_prices) {
        if (
          this.props.selectedTreatmentTypeData.doctors_and_prices[i].doctor ===
          item.text
        )
          return false;
      }
      return true;
    });
  };

  removeFieldError = field => {
    const newErrors = this.state.validationErrors.filter(error => {
      return error.field !== field;
    });
    this.setState({
      validationErrors: newErrors
    });
  };

  addDoctor = () => {
    if (
      this.props.selectedTreatmentTypeData.doctor === null ||
      this.props.selectedTreatmentTypeData.doctor === undefined ||
      this.props.selectedTreatmentTypeData.doctor === "" ||
      this.props.selectedTreatmentTypeData.price === ""
    ) {
      return;
    }

    const doctorsAndPricesNewRow = {
      doctor: this.props.selectedTreatmentTypeData.doctor,
      price: this.props.selectedTreatmentTypeData.price
    };

    let doctorsAndPricesOriginalRows = [];
    if (
      Array.isArray(this.props.selectedTreatmentTypeData.doctors_and_prices)
    ) {
      doctorsAndPricesOriginalRows = [
        ...this.props.selectedTreatmentTypeData.doctors_and_prices
      ];
    } else if (
      this.props.selectedTreatmentTypeData.doctors_and_prices !== null &&
      this.props.selectedTreatmentTypeData.doctors_and_prices !== undefined &&
      this.props.selectedTreatmentTypeData.doctors_and_prices !== ""
    ) {
      doctorsAndPricesOriginalRows = [
        this.props.selectedTreatmentTypeData.doctors_and_prices
      ];
    }

    this.props.updateSelectedTreatmentType(
      "doctors_and_prices",
      [doctorsAndPricesNewRow, ...doctorsAndPricesOriginalRows].sort(
        (a, b) => a.doctor.localeCompare(b.doctor) //Sort the doctor names in alphabetical order, for consistency
      )
    );
    this.removeFieldError("doctors_and_prices");
    this.props.updateSelectedTreatmentType("doctor", "");
    this.props.updateSelectedTreatmentType("price", "");
  };

  removeDoctor = item => {
    let doctorsAndPricesOriginalRows = [];
    if (
      Array.isArray(this.props.selectedTreatmentTypeData.doctors_and_prices)
    ) {
      doctorsAndPricesOriginalRows = [
        ...this.props.selectedTreatmentTypeData.doctors_and_prices
      ];

      let doctorsAndPricesUpdatedRows = doctorsAndPricesOriginalRows.filter(
        row => (row.doctor === item ? false : true)
      );
      this.props.updateSelectedTreatmentType(
        "doctors_and_prices",
        doctorsAndPricesUpdatedRows
      );
    }
  };

  handleNumberOnlyInputKeyDown = event => {
    if (
      event.keyCode !== 8 &&
      event.keyCode !== 0 &&
      event.keyCode !== 13 &&
      (event.keyCode < 48 || event.keyCode > 57)
    ) {
      event.preventDefault();
    }
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        if (this.props.selectedTreatmentTypeData.active) {
          this.handleDeactivate();
        } else {
          this.handleActivate();
        }
        break;
      default:
        break;
    }
  };

  toggleActivationModalVisibility = () => {
    this.setState({
      activationModalOpen: !this.state.activationModalOpen
    });
  };

  toggleConfirmSaveModalVisibility = async () => {
    const submitData = Object.assign({}, this.props.selectedTreatmentTypeData);
    Object.keys(submitData).forEach(field => {
      if (submitData[field] === "") {
        delete submitData[field];
      }
    });
    // Validate the data before showing the modal
    const validationResult = await validateTreatmentInput(
      submitData,
      "newTreatmentType"
    );
    if (validationResult.passedValidation === true) {
      this.setState({
        confirmSaveModalOpen: !this.state.confirmSaveModalOpen
      });
    } else {
      // If failed validation, don't show modal, add errors to state and scroll to top
      window.scrollTo(0, 0);
      this.setState({
        validationErrors: validationResult.errors.slice()
      });
    }
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  render = () => {
    return (
      <div>
        <div style={{ display: this.props.loadingPage ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: this.props.loadingPage ? "none" : "" }}>
          <TreatmentType
            mode={this.state.mode}
            pageHeader={
              this.state.mode === "create"
                ? strings.header.createTreatment
                : strings.header.editTreatment
            }
            selectedTreatmentTypeData={this.props.selectedTreatmentTypeData}
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleActivationModalVisibility={
              this.toggleActivationModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            handleNumberOnlyInputKeyDown={this.handleNumberOnlyInputKeyDown}
            doctorsAndPrices={
              Array.isArray(
                this.props.selectedTreatmentTypeData.doctors_and_prices
              )
                ? this.props.selectedTreatmentTypeData.doctors_and_prices
                : []
            }
            updateDoctorsAndPrices={this.updateDoctorsAndPrices}
            addDoctor={this.addDoctor}
            removeDoctor={this.removeDoctor}
            activationModalOpen={this.state.activationModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            validationErrors={this.state.validationErrors}
          />
        </div>
      </div>
    );
  };
}

TreatmentTypeContainer.propTypes = {
  //match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedTreatmentTypeData: PropTypes.object.isRequired,
  getTreatmentTypeById: PropTypes.func.isRequired,
  createTreatmentType: PropTypes.func.isRequired,
  updateTreatmentType: PropTypes.func.isRequired,
  deactivateTreatmentType: PropTypes.func.isRequired,
  activateTreatmentType: PropTypes.func.isRequired,
  updateSelectedTreatmentType: PropTypes.func.isRequired,
  clearSelectedTreatmentType: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { treatments } = state;
  const { loadingPage, selectedTreatmentTypeData } = treatments.treatmentTypes;
  return {
    loadingPage,
    selectedTreatmentTypeData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTreatmentTypeById: (id, headers) => {
      return dispatch(
        treatmentTypeActions.getTreatmentTypeById(id, headers)
      ).then(result => {
        // Ensure we have an empty or populated array of items for doctors_and_prices
        console.log(result);
        if (result === null || result === undefined || result === "") {
          result = {};
        }
        if (
          result.doctors_and_prices === null ||
          result.doctors_and_prices === undefined ||
          result.doctors_and_prices === ""
        ) {
          result.doctors_and_prices = [];
        } else if (!Array.isArray(result.doctors_and_prices)) {
          result.doctors_and_prices = [result.doctors_and_prices];
        }
      });
    },
    createTreatmentType: (data, headers, history) => {
      dispatch(treatmentTypeActions.createTreatmentType(data, headers))
        .then(() => history.push("/treatmentTypes"))
        .catch(error => console.log(error));
    },
    updateTreatmentType: (id, data, headers, history) => {
      dispatch(treatmentTypeActions.updateTreatmentType(id, data, headers))
        .then(() => history.push("/treatmentTypes"))
        .catch(error => console.log(error));
    },
    activateTreatmentType: (id, headers) => {
      return dispatch(treatmentTypeActions.activateTreatmentType(id, headers));
    },
    deactivateTreatmentType: (id, headers) => {
      return dispatch(
        treatmentTypeActions.deactivateTreatmentType(id, headers)
      );
    },
    updateSelectedTreatmentType: (key, value) => {
      dispatch(treatmentTypeActions.updateSelectedTreatmentType(key, value));
    },
    clearSelectedTreatmentType: () => {
      dispatch(treatmentTypeActions.clearSelectedTreatmentType());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreatmentTypeContainer);
