import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import "../css/TreatmentComponents.css";

const TreatmentInformation = (props) => {
  // Remove follow up notes
  let indexToRemove = props.treatmentTypeListData.indexOf(
    props.treatmentTypeListData.find(
      (type) => type.treatment_category === strings.types.followUpNote
    )
  );
  if (indexToRemove >= 0) {
    props.treatmentTypeListData.splice(indexToRemove, 1)
  }
  const treatmentTypeOptions = props.treatmentTypeListData.map(
    (item, index) => ({
      key: index + 1,
      text: item.treatment_type,
      value: item.id,
    })
  );

  return (
    <>
      <Form.Group>
        <Form.Field width={8}>
          <label>
            {strings.form.label.firstName}:{" "}
            {props.selectedPatientData.first_name || ""}{" "}
            {props.selectedPatientData.surname || ""}
          </label>
        </Form.Field>
        <Form.Field width={8}>
          <label>
            {strings.form.label.dateOfBirth}:{" "}
            {props.selectedPatientData.date_of_birth || ""}
          </label>
        </Form.Field>
      </Form.Group>

      {props.mode === "create" || props.mode === "edit" ? (
        <Form.Group>
          <Form.Dropdown
            error={props.errorFields.includes("treatment_type")}
            label="Treatment Type"
            placeholder="Select Treatment Type"
            selection
            options={treatmentTypeOptions}
            multiple
            name="treatment_type"
            noResultsMessage="No treatment types have been set up"
            value={props.selectedTreatmentData.treatment_type || []}
            onChange={props.handleChange}
            required
            selectOnBlur={false}
            width={8}
          />
          <Form.Dropdown
            error={props.errorFields.includes("doctor")}
            label="Doctor"
            placeholder="Select Doctor"
            selection
            options={props.getDoctorOptions()}
            name="doctor"
            noResultsMessage="No Doctors have been set up for the selected treatment type"
            value={props.selectedTreatmentData.doctor || ""}
            onChange={props.handleChange}
            required
            selectOnBlur={false}
            width={8}
          />
        </Form.Group>
      ) : (
        <Form.Group>
          <Form.Dropdown
            label="Treatment Type"
            placeholder="Select Treatment Type"
            selection
            options={treatmentTypeOptions}
            multiple
            disabled
            name="treatment_type"
            noResultsMessage="No treatment types have been set up"
            value={props.selectedTreatmentData.treatment_type || []}
            onChange={props.handleChange}
            required
            selectOnBlur={false}
            width={8}
          />
          <Form.Input
            label="Doctor"
            placeholder="Select Doctor"
            name="doctor"
            disabled
            noResultsMessage="No Doctors have been set up for the selected treatment type"
            value={props.selectedTreatmentData.doctor || ""}
            required
            width={8}
          />
        </Form.Group>
      )}

      <Form.Group>
        <Form.Field width={8}>
          <label>
            {strings.form.label.treatmentPrice}:{" "}
            {props.selectedTreatmentData.price ||
              strings.form.label.undefinedTreatmentPrice}
          </label>
        </Form.Field>
      </Form.Group>
    </>
  );
};

TreatmentInformation.protoTypes = {
  mode: PropTypes.string.isRequired,
  selectedPatientData: PropTypes.object.isRequired,
  selectedTreatmentData: PropTypes.object.isRequired,
  treatmentTypeListData: PropTypes.object.isRequired,
  errorFields: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  getDoctorOptions: PropTypes.func.isRequired,
};

export default TreatmentInformation;
