const mapToRemoteData = fetchedData => {
  let mappedRemoteData = [];
  fetchedData.forEach(image => {
    const remoteImageEntry = {
      imageName: image.imageName,
      caption: image.metadata.caption || "",
      takenOnDate: image.metadata.takenOnDate || "",
      uploadedOnDate: image.metadata.uploadedOnDate || ""
    };
    mappedRemoteData.push(remoteImageEntry);
  });
  return mappedRemoteData;
};

const mapToLocalData = fetchedData => {
  let mappedLocalData = [];
  fetchedData.forEach(image => {
    const localImageEntry = {
      imageName: image.imageName,
      url: image.url,
      caption: image.metadata.caption || "",
      takenOnDate: image.metadata.takenOnDate || "",
      uploadedOnDate: image.metadata.uploadedOnDate || "",
      imageContent: ""
    };
    mappedLocalData.push(localImageEntry);
  });
  return mappedLocalData;
};

const getDiffs = (localData, remoteData, imagePath) => {
  let imagesToUpload = [];
  let imagesToModify = [];
  localData.forEach(localImage => {
    if (!localImage.new) {
      const matchToRemote = getImageWithName(remoteData, localImage.imageName);
      if (localImage.caption !== matchToRemote.caption) {
        const modification = {
          imagePath: `${imagePath}${localImage.imageName}`,
          metadata: {
            caption: localImage.caption,
            takenOnDate: localImage.takenOnDate,
            uploadedOnDate: localImage.uploadedOnDate
          }
        };
        imagesToModify.push(modification);
      }
    } else {
      const upload = {
        imagePath: `${imagePath}${localImage.imageName}`,
        imageContent: localImage.imageContent,
        metadata: {
          caption: localImage.caption,
          takenOnDate: localImage.takenOnDate,
          uploadedOnDate: localImage.uploadedOnDate
        }
      };
      imagesToUpload.push(upload);
    }
  });

  let imagesToDelete = [];
  remoteData.forEach(remoteImage => {
    const matchToLocal = getImageWithName(localData, remoteImage.imageName);
    if (!matchToLocal) {
      imagesToDelete.push({
        imagePath: `${imagePath}${remoteImage.imageName}`
      });
    }
  });
  const diffActions = {
    uploads: imagesToUpload,
    deletes: imagesToDelete,
    updates: imagesToModify
  };
  return diffActions;
};

const getImageWithName = (imageData, imageName) => {
  let imageObj = {};
  imageData.forEach(image => {
    if (image.imageName === imageName) {
      imageObj = Object.assign({}, image);
    }
  });
  if (Object.keys(imageObj).length > 0) {
    return imageObj;
  } else {
    return false;
  }
};

export const imageHelper = {
  mapToLocalData,
  mapToRemoteData,
  getDiffs
};
