import { API } from "../../services";
import { treatmentActionTypes } from "./treatment.actionTypes";

const api = new API();

const createTreatment = (details, patientId, headers) => dispatch =>
  new Promise(function(resolve, reject) {
    const request = details => {
      return { type: treatmentActionTypes.CREATE_TREATMENT_REQUEST, details };
    };
    const success = data => {
      return { type: treatmentActionTypes.CREATE_TREATMENT_SUCCESS, data };
    };
    const failure = error => {
      return { type: treatmentActionTypes.CREATE_TREATMENT_FAILURE, error };
    };

    api.setHeaders({ ...headers, systemHeader_subsysref: "createTreatment" });

    dispatch(request(details));

    api.createTreatment(details, patientId).then(
      response => {
        dispatch(success(response));
        resolve();
      },
      error => {
        dispatch(failure(error));
        reject(error);
      }
    );
  });

const updateTreatment = (id, patientId, details, headers) => dispatch =>
  new Promise(function(resolve, reject) {
    const request = details => {
      return { type: treatmentActionTypes.UPDATE_TREATMENT_REQUEST, details };
    };
    const success = data => {
      return { type: treatmentActionTypes.UPDATE_TREATMENT_SUCCESS, data };
    };
    const failure = error => {
      return { type: treatmentActionTypes.UPDATE_TREATMENT_FAILURE, error };
    };

    api.setHeaders({ ...headers, systemHeader_subsysref: "updateTreatment" });

    dispatch(request(details));

    api.updateTreatment(id, patientId, details).then(
      response => {
        dispatch(success(response));
        resolve();
      },
      error => {
        dispatch(failure(error));
        reject(error);
      }
    );
  });

const deleteTreatment = (id, patientId, headers) => dispatch =>
  new Promise(function(resolve, reject) {
    const request = () => {
      return { type: treatmentActionTypes.DELETE_TREATMENT_REQUEST };
    };
    const success = data => {
      return { type: treatmentActionTypes.DELETE_TREATMENT_SUCCESS, data };
    };
    const failure = error => {
      return { type: treatmentActionTypes.DELETE_TREATMENT_FAILURE, error };
    };

    api.setHeaders({ ...headers, systemHeader_subsysref: "deleteTreatment" });

    dispatch(request());

    if (id !== "") {
      api.deleteTreatment(id, patientId).then(
        response => {
          dispatch(success(response));
          resolve();
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    }
  });

const getTreatments = (patientId, headers, excludeFollowUpNotes = false) => {
  const request = () => {
    return { type: treatmentActionTypes.GET_TREATMENTS_REQUEST };
  };
  const success = data => {
    return { type: treatmentActionTypes.GET_TREATMENTS_SUCCESS, data };
  };
  const failure = error => {
    return { type: treatmentActionTypes.GET_TREATMENTS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getTreatments" });

  return async dispatch => {
    dispatch(request());

    await api.getTreatments(patientId, excludeFollowUpNotes).then(
      response => {
        dispatch(success(response.data.results));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
};

const getTreatmentById = (id, patientId, headers) => {
  const request = () => {
    return { type: treatmentActionTypes.GET_TREATMENT_BY_ID_REQUEST };
  };
  const success = data => {
    return { type: treatmentActionTypes.GET_TREATMENT_BY_ID_SUCCESS, data };
  };
  const failure = error => {
    return { type: treatmentActionTypes.GET_TREATMENT_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getTreatmentById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .getTreatmentById(id, patientId)
        .then(response => {
          dispatch(success(response.data));
          resolve(response.data);
        })
        .catch(error => {
          dispatch(failure(error));
          resolve(error);
        });
    });
  };
};

const updateSelectedTreatment = (key, value) => {
  const request = (key, value) => {
    return {
      type: treatmentActionTypes.UPDATE_SELECTED_TREATMENT_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: treatmentActionTypes.UPDATE_SELECTED_TREATMENT_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: treatmentActionTypes.UPDATE_SELECTED_TREATMENT_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const deleteFromSelectedTreatment = key => {
  const request = key => {
    return { type: treatmentActionTypes.DELETE_FROM_SELECTED_TREATMENT_REQUEST, key };
  };
  const success = key => {
    return { type: treatmentActionTypes.DELETE_FROM_SELECTED_TREATMENT_SUCCESS, key };
  };
  const failure = () => {
    return { type: treatmentActionTypes.DELETE_FROM_SELECTED_TREATMENT_FAILURE };
  };

  return dispatch => {
    dispatch(request(key));
    if (key) {
      dispatch(success(key));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedTreatment = () => {
  const request = () => {
    return { type: treatmentActionTypes.CLEAR_SELECTED_TREATMENT_REQUEST };
  };
  const success = () => {
    return { type: treatmentActionTypes.CLEAR_SELECTED_TREATMENT_SUCCESS };
  };

  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      dispatch(success());
      resolve()
    })
  };
};

export const treatmentActions = {
  createTreatment,
  updateTreatment,
  deleteTreatment,
  getTreatmentById,
  getTreatments,
  updateSelectedTreatment,
  deleteFromSelectedTreatment,
  clearSelectedTreatment
};
