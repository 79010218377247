import React from "react";
import PropTypes from "prop-types";
import FollowUpNoteEditable from "../../components/FollowUpNoteEditable";
import FollowUpNoteView from "../../components/FollowUpNoteView";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { treatmentActions } from "../../store/actions/treatment.actions";
import { treatmentTypeActions } from "../../store/actions/treatmentType.actions";
import { formActions } from "../../../Forms/store/actions/form.actions";
import validateTreatmentInput from "../../helpers/treatmentValidator";
import downloadPdf from "../../../Forms/helpers/formToPdfFile";

class FollowUpNoteContainer extends React.Component {
  state = {
    loadingPage: false,
    //Get the passed through values from props.location.state if they are available
    mode: this.props.location?.state?.mode
      ? this.props.location.state.mode
      : "view",
    treatmentId: this.props.location?.state?.treatmentId
      ? this.props.location.state.treatmentId
      : null,
    patientId: this.props.location?.state?.patientId
      ? this.props.location.state.patientId
      : null,
    selectedPatientData: this.props.location?.state?.selectedPatientData
      ? this.props.location.state.selectedPatientData
      : null,
    selectedTreatmentTypeData: null,
    confirmDeleteModalOpen: false,
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    validationErrors: [],
    hasMedicalHistoryChanges: false,
    medicalHistoryUpdate: [],
    generatingPdf: false,
    annotationStatus: [],
    productListData: [],
  };

  componentDidMount = async () => {
    this.setState({ loadingPage: true });
    await this.props.getTreatmentTypes(this.props.headers);
    if (this.state.mode === "create") {
      await this.props.clearSelectedTreatment();
      // Directly setting the type rather than deriving it from the retrieved
      // types prevents errors when getting the treatment types fails
      this.props.updateSelectedTreatment("treatment_type", [
        strings.types.followUpNoteId,
      ]);
    } else if (this.state.patientId) {
      await this.props.getTreatmentById(
        this.props.match.params.id,
        this.state.patientId,
        this.props.headers
      );
    }
    // If the current user is a doctor and no existing doctor signature details exist on the record:
    if (
      this.props.role === "doctor" &&
      this.props.selectedTreatmentData.doctor_signature_name === undefined
    ) {
      await this.addDoctorSignatureDetails();
    }
    this.setState({ loadingPage: false });
  };

  downloadFormPdf = async () => {
    // Set state (passed to FormEdit to set a loader on download button)
    this.setState({ generatingPdf: true });
    // Download a Pdf of the div with id 'formDiv' and filename formType
    await downloadPdf(document, strings.types.followUpNote);
    this.setState({ generatingPdf: false });
  };

  // Returns a copy of the treatment type object for the provided type
  getTreatmentTypeData = (types) => {
    const selectedIndexesOfTreatmentType = [];
    const selectedTreatmentTypes = [];

    types.forEach((type) => {
      let indexOfTreatmentType = this.props.treatmentTypeListData
        .map((treatmentType) => {
          return treatmentType.id;
        })
        .indexOf(type);

      if (indexOfTreatmentType >= 0) {
        selectedTreatmentTypes.push(
          Object.assign(
            {},
            this.props.treatmentTypeListData[indexOfTreatmentType]
          )
        );
      }

      selectedIndexesOfTreatmentType.push(indexOfTreatmentType);
    });

    return selectedTreatmentTypes;
  };

  handleYesNoChange = async (event, data) => {
    this.removeFieldError(data.name);
    if (data.clearChild && data.childrenFields) {
      data.childrenFields.forEach((childField) => {
        this.props.deleteFromSelectedTreatment(childField);
      });
    }
    this.props.updateSelectedTreatment(data.name, data.value);
  };

  handleSubmit = async () => {
    this.setState({ loadingPage: true });
    // Remove the empty fields before submitting
    const submitData = Object.assign({}, this.props.selectedTreatmentData);
    Object.keys(submitData).forEach((field) => {
      if (submitData[field] === "" || submitData[field] === null) {
        delete submitData[field];
      }
    });

    const treatmentTypeData = this.getTreatmentTypeData(
      this.props.selectedTreatmentData.treatment_type
    );

    if (Array.isArray(treatmentTypeData)) {
      submitData["selectedTreatmentTypes"] = [];
      treatmentTypeData.map((item) =>
        submitData["selectedTreatmentTypes"].push({
          id: item.id,
          treatment_type: item.treatment_type,
          aftercare_instructions: item.aftercare_instructions,
          text_notification_timeframe: item.text_notification_timeframe,
          text_notification_timeframe_units:
            item.text_notification_timeframe_units,
          text_notification_wording: item.text_notification_wording,
        })
      );
    }

    if (this.state.selectedPatientData) {
      submitData[
        "mobile_phone_number"
      ] = this.state.selectedPatientData.mobile_phone_number;
      submitData[
        "email_address"
      ] = this.state.selectedPatientData.email_address;
      submitData[
        "communication_consent"
      ] = this.state.selectedPatientData.communication_consent;
    }

    if (this.state.mode === "create") {
      await this.props.createTreatment(
        submitData,
        this.state.patientId,
        this.props.headers
      );
    } else {
      await this.props.updateTreatment(
        this.props.match.params.id,
        this.state.patientId,
        submitData,
        this.props.headers
      );
    }
    this.setState({ loadingPage: false });
    this.props.history.push(`/patients/view/${this.state.patientId}`);
  };

  handleDelete = async () => {
    this.props.deleteTreatment(
      this.props.selectedTreatmentData.id,
      this.state.patientId,
      this.props.headers,
      this.props.history
    );
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    this.removeFieldError(data.name);

    await this.props.updateSelectedTreatment(data.name, data.value);
  };

  handleModeSwitch = async () => {
    // Products in the table depends on the view mode
    this.setState({
      productListData: [],
    });
    if (this.state.mode === "edit") {
      // restore original data if the changes to the form are cancelled
      await this.props.getTreatmentById(
        this.props.match.params.id,
        this.state.patientId,
        this.props.headers
      );
      this.setState({ mode: "view", validationErrors: [] });
    } else {
      this.setState({ mode: "edit" });
    }
  };

  handleModalConfirm = (modalClassName) => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "confirmDeleteModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  removeFieldError = (field) => {
    const newErrors = this.state.validationErrors.filter((error) => {
      return error.field !== field;
    });
    this.setState({
      validationErrors: newErrors,
    });
  };

  addDoctorSignatureDetails = async () => {
    if (this.props.role === "doctor") {
      this.props.updateSelectedTreatment(
        "doctor_signature_name",
        `${this.props.givenName} ${this.props.familyName}`
      );
      this.props.updateSelectedTreatment(
        "doctor_signature_path",
        this.props.signaturePathOfLoggedInUser
      );
      const timestamp = new Date().getTime();
      const doctor_signature_applied_date = new Date(timestamp).toISOString();
      this.props.updateSelectedTreatment(
        "doctor_signature_applied_date",
        doctor_signature_applied_date
      );
    }
  };

  toggleConfirmSaveModalVisibility = async () => {
    const submitData = Object.assign({}, this.props.selectedTreatmentData);
    Object.keys(submitData).forEach((field) => {
      if (submitData[field] === "") {
        delete submitData[field];
      }
    });
    // Validate the data before showing the modal
    const validationResult = await validateTreatmentInput(
      submitData,
      "followUpNote"
    );
    if (validationResult.passedValidation === true) {
      this.setState({
        confirmSaveModalOpen: !this.state.confirmSaveModalOpen,
      });
    } else {
      // If failed validation, don't show modal, add errors to state and scroll to top
      window.scrollTo(0, 0);
      this.setState({
        validationErrors: validationResult.errors.slice(),
      });
    }
  };

  toggleConfirmDeleteModalVisibility = () => {
    this.setState({
      confirmDeleteModalOpen: !this.state.confirmDeleteModalOpen,
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen,
    });
  };

  render = () => {
    return (
      <div>
        {(this.state.loadingPage || this.props.loadingPage) && (
          <Loader active>{strings.header.loading}</Loader>
        )}
        {!(this.state.loadingPage || this.props.loadingPage) && this.state.mode === "view" && (
          <FollowUpNoteView
            mode={this.state.mode}
            pageTitle={strings.header.editFollowUpNote}
            selectedPatientData={this.state.selectedPatientData}
            selectedTreatmentData={this.props.selectedTreatmentData}
            toggleConfirmDeleteModalVisibility={
              this.toggleConfirmDeleteModalVisibility
            }
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            confirmDeleteModalOpen={this.state.confirmDeleteModalOpen}
            validationErrors={this.state.validationErrors}
            treatmentId={this.state.treatmentId}
            headers={this.props.headers}
            generatingPdf={this.state.generatingPdf}
            downloadFormPdf={this.downloadFormPdf}
          />
        )}
        {!(this.state.loadingPage || this.props.loadingPage) && this.state.mode !== "view" && (
          <FollowUpNoteEditable
            mode={this.state.mode}
            pageTitle={
              this.state.mode === "create"
                ? strings.header.createFollowUpNote
                : strings.header.editFollowUpNote
            }
            selectedPatientData={this.state.selectedPatientData}
            selectedTreatmentData={this.props.selectedTreatmentData}
            toggleConfirmSaveModalVisibility={this.toggleConfirmSaveModalVisibility}
            toggleCancelChangesModalVisibility={this.toggleCancelChangesModalVisibility}
            handleChange={this.handleChange}
            handleYesNoChange={this.handleYesNoChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            validationErrors={this.state.validationErrors}
            treatmentId={this.state.treatmentId}
            headers={this.props.headers}
            generatingPdf={this.state.generatingPdf}
            downloadFormPdf={this.downloadFormPdf}
          />
        )}
      </div>
    );
  };
}

FollowUpNoteContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedTreatmentData: PropTypes.object.isRequired,
  createTreatment: PropTypes.func.isRequired,
  updateTreatment: PropTypes.func.isRequired,
  deleteTreatment: PropTypes.func.isRequired,
  getTreatmentById: PropTypes.func.isRequired,
  updateSelectedTreatment: PropTypes.func.isRequired,
  clearSelectedTreatment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { loadingPage, selectedTreatmentData } = state.treatments.treatments;
  const { treatmentTypeListData } = state.treatments.treatmentTypes;
  const { familyName, givenName, signaturePathOfLoggedInUser } = state.auth;

  return {
    loadingPage,
    selectedTreatmentData,
    treatmentTypeListData,
    familyName,
    givenName,
    signaturePathOfLoggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTreatmentById: (id, patientId, headers) => {
      return dispatch(
        treatmentActions.getTreatmentById(id, patientId, headers)
      );
    },
    createTreatment: async (data, patientId, headers) => {
      return dispatch(
        treatmentActions.createTreatment(data, patientId, headers)
      );
    },
    deleteFromSelectedTreatment: (key, value) => {
      dispatch(treatmentActions.deleteFromSelectedTreatment(key, value));
    },
    updateTreatment: async (id, patientId, data, headers, patientData) => {
      return dispatch(
        treatmentActions.updateTreatment(id, patientId, data, headers)
      );
    },
    deleteTreatment: (id, patientId, headers, history) => {
      dispatch(treatmentActions.deleteTreatment(id, patientId, headers))
        .then(() =>
          history.push({
            pathname: `/patients/view/${patientId}`,
            state: {
              patientId: patientId,
              mode: "view",
            },
          })
        )
        .catch((error) => console.log(error));
    },
    updateSelectedTreatment: (key, value) => {
      dispatch(treatmentActions.updateSelectedTreatment(key, value));
    },
    clearSelectedTreatment: () => {
      return dispatch(treatmentActions.clearSelectedTreatment());
    },
    getTreatmentTypes: (headers) => {
      return dispatch(treatmentTypeActions.getTreatmentTypes(headers));
    },
    applyDoctorSignature: (id, details, headers) => {
      return dispatch(formActions.applyDoctorSignature(id, details, headers));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowUpNoteContainer);
