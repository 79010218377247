import React from "react";
import PropTypes from "prop-types";
import { List } from "semantic-ui-react";
import { formatExaminationNotesView } from "../../helpers/formatExaminationNotesView";

const ExaminationNotesView = (props) => {
  const data = props.selectedPatientData;
  const examinationNotesViewDetails = formatExaminationNotesView(data);

  return (
    <div className="viewModeMedicalInfo">
      <List relaxed>
        {examinationNotesViewDetails.map((item) => (
          <List.Item key={item}>{item}</List.Item>
        ))}
      </List>
    </div>
  );
};

ExaminationNotesView.protoTypes = {
  selectedPatientData: PropTypes.object.isRequired,
};

export default ExaminationNotesView;
