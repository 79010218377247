import React, { Component } from "react";
import { ImageManagerContainer } from "../containers"
import "./PatientImages.css";

class PatientImages extends Component {
  render = () => {
    return (
      <div className="patientImages clearfix">
        <ImageManagerContainer
          headers={this.props.headers}
          imagePath={`patients/${this.props.patientId}`}
          {...this.props}
        />
      </div>
    );
  };
}

export default PatientImages;
