import React from "react";
import EventListener, { withOptions } from "react-event-listener";
import { Segment, Button, Icon, Header } from "semantic-ui-react";
import { imageActions } from "../store/actions/image.actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./css/ImageAnnotator.css";
import { dateHelper } from "../helpers";
import axios from "axios";
import face from "../assets/images/face.png";

const highlighterStyle = {
  LineWidth: 8,
  StrokeStyle: "rgba(0, 0, 0, 0.6)",
};
const pencilStyle = {
  LineWidth: 3,
  StrokeStyle: "#000",
};
const eraserStyle = {
  LineWidth: 22,
  StrokeStyle: "rgba(255, 255, 255, 1)",
};
const defaultCanvasWidth = 400;
const defaultCanvasHeight = 600;

class ImageAnnotator extends React.Component {
  // Used to prevent memory leaks but running this.setState on unmounted components
  _isMounted = false;

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.contextRef = React.createRef();
  }

  state = {
    canvas: null,
    canvasX: 0,
    canvasY: 0,
    lastMouseX: 0,
    lastMouseY: 0,
    mouseIsDown: false,
    toolType: "highlight",
    linesArray: [], //This holds an array of points, e.g. {x:139, y:140, type:"highlight"}, that represent lines drawn on the image, with null for end of a line.
    canvasHeight: defaultCanvasHeight,
    canvasWidth: defaultCanvasWidth,
  };

  componentDidMount = async () => {
    this._isMounted = true;
    window.addEventListener("orientationchange", this.handleOrientationChange);

    await this.props.clearAnnotationsObject();
    if (this.props.readOnly && this.props.annotationImagePath) {
      //Read mode
      await this.props.getAnnotationImage(
        this.props.annotationName,
        this.props.annotationImagePath,
        this.props.headers
      );
    } else if (!this.props.readOnly) {
      //Create mode
      this.setState({
        canvas: this.canvasRef,
        canvasX: parseInt(this.canvasRef.getBoundingClientRect().left),
        canvasY: parseInt(this.canvasRef.getBoundingClientRect().top),
      });
      await this.saveImageToRedux();
      await this.props.handleAnnotationWasModified(
        this.props.annotationName,
        "unedited"
      );
      if (document.getElementById(this.props.annotationName)) {
        this.setState({
          canvasHeight: document.getElementById(this.props.annotationName)
            .offsetHeight,
          canvasWidth: document.getElementById(this.props.annotationName)
            .offsetWidth,
        });
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleOrientationChange = () => {
    // Using an additional listener for resize makes this wait until the device
    // rotation is complete, and all elements have their new sizes
    let afterOrientationChange = async () => {
      // adjust the lines if the canvas size has changed (particularly when a mobile
      // device has been rotated)

      // Only update and calculate if one of the dimensions have changed
      if (
        this._isMounted &&
        document.getElementById(this.props.annotationName) &&
        (this.state.canvasHeight !==
          document.getElementById(this.props.annotationName).offsetHeight ||
          this.state.canvasWidth !==
            document.getElementById(this.props.annotationName).offsetWidth)
      ) {
        let newHeight = document.getElementById(this.props.annotationName)
          .offsetHeight;
        let newWidth = document.getElementById(this.props.annotationName)
          .offsetWidth;
        let heightRatio = newHeight / this.state.canvasHeight;
        let widthRatio = newWidth / this.state.canvasWidth;
        // The "newLinesArray" is an array of canvas data points for a drawn line, e.g. {x:139, y:140, type:"highlight"}
        // A null entry represents a line end
        let newLinesArray = this.state.linesArray;
        newLinesArray.forEach((coordinates, index) => {
          if (coordinates) {
            let newX = coordinates.x * widthRatio;
            let newY = coordinates.y * heightRatio;
            newLinesArray[index].x = newX;
            newLinesArray[index].y = newY;
          } else {
            newLinesArray[index] = null;
          }
        });

        this.setState({
          linesArray: newLinesArray,
          canvasHeight: newHeight,
          canvasWidth: newWidth,
        });
        this.redrawCanvas();
      }
      window.removeEventListener("resize", afterOrientationChange);
    };
    window.addEventListener("resize", afterOrientationChange);
  };

  addToLinesArray = (newCoordinates) => {
    return new Promise((resolve, reject) => {
      let editableTools = ["highlight", "pencil", "erase"];
      if (editableTools.includes(this.state.toolType)) {
        this.setState(
          {
            linesArray: [...this.state.linesArray, newCoordinates],
          },
          () => {
            resolve();
          }
        );
      }
    });
  };

  saveImageToRedux = async () => {
    let imageData = await this.getDataUrlFromCanvas();
    let annotationObject = await this.getBlobFromDataUrl(imageData);
    await this.props.updateAnnotationObject(
      this.props.annotationName,
      annotationObject,
      this.props.headers
    );
  };

  clearCanvas = async () => {
    this.contextRef.clearRect(
      0,
      0,
      this.contextRef.canvas.width,
      this.contextRef.canvas.height
    );

    this.setState({
      linesArray: [],
    });

    await this.props.handleAnnotationWasModified(
      this.props.annotationName,
      "cleared"
    );
  };

  // Renders the overlay canvas onto an offscreen canvas on top of the background image and outputs the dataURL
  getDataUrlFromCanvas = () => {
    return new Promise((resolve, reject) => {
      const offscreenCanvas = document.createElement("canvas");
      offscreenCanvas.width = this.state.canvasWidth || defaultCanvasWidth;
      offscreenCanvas.height = this.state.canvasHeight || defaultCanvasHeight;

      const offscreenContext = offscreenCanvas.getContext("2d");
      const backgroundImage = new Image();
      backgroundImage.onload = () => {
        offscreenContext.drawImage(
          backgroundImage,
          0,
          0,
          this.state.canvasWidth,
          this.state.canvasHeight
        );
        offscreenContext.drawImage(this.canvasRef, 0, 0);
        resolve(offscreenCanvas.toDataURL());
      };
      backgroundImage.src = this.props.backgroundImage;
    });
  };

  getImageUrl = () => {
    let url = "";
    for (let imageName in this.props.annotationsImages) {
      if (imageName === this.props.annotationName) {
        url = this.props.annotationsImages[imageName][0].url;
      }
    }
    return url;
  };

  getTouchPos = (event) => {
    if (event.touches) {
      if (event.touches.length === 1) {
        const touch = event.touches[0];
        return {
          touchX:
            touch.pageX -
            (this.canvasRef.getBoundingClientRect().left + window.scrollX),
          touchY:
            touch.pageY -
            (this.canvasRef.getBoundingClientRect().top + window.scrollY),
        };
      }
    }
    return null;
  };

  getBlobFromDataUrl = (dataUrl) => {
    return new Promise((resolve, reject) => {
      axios.get(dataUrl, { responseType: "blob" }).then(async (response) => {
        if (response.data) {
          let currentDate = dateHelper.getTodaysDateFormatted();
          const uploadObject = {
            imageContent: response.data,
            metadata: {
              caption: "",
              takenOnDate: currentDate,
              uploadedOnDate: currentDate,
            },
          };
          resolve(uploadObject);
        } else {
          console.log("image is empty");
          resolve({});
        }
      });
    });
  };

  handleDrawEnd = () => {
    // add null entry to lines array to mark the end of a continuous line
    let newState = {
      mouseIsDown: false,
    };

    let editableTools = ["highlight", "pencil", "erase"];
    if (editableTools.includes(this.state.toolType)) {
      newState.linesArray = [...this.state.linesArray, null];
    }

    this.setState(newState);
    this.redrawCanvas();
    this.saveImageToRedux();
  };

  handleMouseDown = async (event) => {
    this.setMouseIsDown(true);
    this.adjustCanvasForScroll();

    const currentMousePosition = {
      x: event.clientX - this.state.canvasX,
      y: event.clientY - this.state.canvasY,
      type: this.state.toolType,
    };
    const offsetMousePosition = {
      x: currentMousePosition.x + 1,
      y: currentMousePosition.y + 1,
      type: this.state.toolType,
    };

    await this.addToLinesArray(currentMousePosition);
    await this.addToLinesArray(offsetMousePosition);
    this.redrawCanvas();
    await this.props.handleAnnotationWasModified(
      this.props.annotationName,
      "mouse edit"
    );
  };

  handleMouseMove = (event) => {
    this.adjustCanvasForScroll();

    if (this.state.mouseIsDown) {
      const currentMousePosition = {
        x: event.clientX - this.state.canvasX,
        y: event.clientY - this.state.canvasY,
        type: this.state.toolType,
      };

      this.addToLinesArray(currentMousePosition);
      this.redrawCanvas();
    }
  };

  handleMouseUpOrLeave = () => {
    this.handleDrawEnd();
  };

  handleTouchEnd = (event) => {
    event.preventDefault();
    this.handleDrawEnd();
  };

  handleTouchMove = (event) => {
    event.preventDefault();
    this.adjustCanvasForScroll();

    if (this.state.mouseIsDown) {
      const touchPos = this.getTouchPos(event);
      const currentMousePosition = {
        x: touchPos.touchX,
        y: touchPos.touchY,
        type: this.state.toolType,
      };

      this.addToLinesArray(currentMousePosition);
      this.redrawCanvas();
    }
  };

  handleTouchStart = async (event) => {
    event.preventDefault();
    await this.props.handleAnnotationWasModified(
      this.props.annotationName,
      "touchscreen edit"
    );
    this.setMouseIsDown(true);
    this.adjustCanvasForScroll();
    const touchPos = this.getTouchPos(event);
    const currentMousePosition = {
      x: touchPos.touchX,
      y: touchPos.touchY,
      type: this.state.toolType,
    };
    const offsetMousePosition = {
      x: currentMousePosition.x + 1,
      y: currentMousePosition.y + 1,
      type: this.state.toolType,
    };
    await this.addToLinesArray(currentMousePosition);
    await this.addToLinesArray(offsetMousePosition);
    this.redrawCanvas();
  };

  redrawCanvas = () => {
    // clear canvas pre-draw
    this.contextRef.clearRect(
      0,
      0,
      this.contextRef.canvas.width,
      this.contextRef.canvas.height
    );

    // foreach point in line array
    this.state.linesArray.forEach((coordinates, index) => {
      // if the start of the line exists (is not null)
      if (coordinates) {
        // if this is the start of a new line, update the canvas stroke style
        if (!this.state.linesArray[index - 1]) {
          this.contextRef.beginPath();
          this.contextRef.lineJoin = "round";
          this.contextRef.lineCap = "round";
          switch (coordinates.type) {
            case "highlight":
              this.contextRef.globalCompositeOperation = "destination-over";
              this.contextRef.strokeStyle = highlighterStyle.StrokeStyle;
              this.contextRef.lineWidth = highlighterStyle.LineWidth;
              break;
            case "pencil":
              this.contextRef.globalCompositeOperation = "source-over";
              this.contextRef.strokeStyle = pencilStyle.StrokeStyle;
              this.contextRef.lineWidth = pencilStyle.LineWidth;
              break;
            case "erase":
              this.contextRef.globalCompositeOperation = "destination-out";
              this.contextRef.strokeStyle = eraserStyle.StrokeStyle;
              this.contextRef.lineWidth = eraserStyle.LineWidth;
              break;
            default:
              break;
          }
        }

        this.contextRef.moveTo(coordinates.x, coordinates.y);
        // if the next point is not null, draw from the start point to the next point
        if (this.state.linesArray[index + 1]) {
          this.contextRef.lineTo(
            this.state.linesArray[index + 1].x,
            this.state.linesArray[index + 1].y
          );
        }

        // Apply stroke to the canvas only when the line is going to end (next point is null)
        if (!this.state.linesArray[index + 1]) {
          this.contextRef.stroke();
        }
      }
    });
  };

  setMouseIsDown = (isDown) => {
    this.setState({
      mouseIsDown: isDown,
    });
  };

  adjustCanvasForScroll = () => {
    // re-adjust canvasX and canvasY to account for scrolling windows
    this.setState({
      canvas: this.canvasRef,
      canvasX: parseInt(this.canvasRef.getBoundingClientRect().left),
      canvasY: parseInt(this.canvasRef.getBoundingClientRect().top),
    });
  };

  render = () => {
    return (
      <React.Fragment>
        <Header as="h5">{this.props.header || ""}</Header>
        {/*
        This EventListener has to be used as iOS safari at time of writing doesn't support the touch-action: none CSS rule needed to disable scrolling while drawing
        The way around this is to set up active event listeners that prevent the default functionality (in this case scrolling)
        React doesn't have active event listener support so the library https://www.npmjs.com/package/react-event-listener is used to wrap this
        */}

        {this.props.readOnly && (
          <img
            className="backgroundImage"
            crossOrigin="anonymous"
            src={
              this.props.blank
                ? face
                : this.getImageUrl()
                ? this.getImageUrl()
                : face
            }
            alt="Annotatable face"
          />
        )}
        {!this.props.readOnly && (
          <React.Fragment>
            {this.props.annotationName &&
              document.getElementById(this.props.annotationName) && (
                <EventListener
                  target={document.getElementById(this.props.annotationName)}
                  onTouchStart={withOptions(this.handleTouchStart, {
                    passive: false,
                  })}
                  onTouchMove={withOptions(this.handleTouchMove, {
                    passive: false,
                  })}
                  onTouchEnd={withOptions(this.handleTouchEnd, {
                    passive: false,
                  })}
                />
              )}
            <Segment compact textAlign="center" basic>
              <Segment attached className="canvasSegment">
                <canvas
                  className="annotationCanvas"
                  id={this.props.annotationName}
                  touch-action="none"
                  width={
                    document.getElementById(this.props.annotationName)
                      ? document.getElementById(this.props.annotationName)
                          .offsetWidth
                      : defaultCanvasWidth
                  }
                  height={
                    document.getElementById(this.props.annotationName)
                      ? document.getElementById(this.props.annotationName)
                          .offsetHeight
                      : defaultCanvasHeight
                  }
                  onMouseDown={this.handleMouseDown}
                  onMouseMove={this.handleMouseMove}
                  onMouseUp={this.handleMouseUpOrLeave}
                  onMouseLeave={this.handleMouseUpOrLeave}
                  ref={(c) => {
                    if (c) {
                      this.canvasRef = c;
                      this.contextRef = c.getContext("2d");
                    }
                  }}
                />
                <img
                  className="backgroundImage"
                  crossOrigin="anonymous"
                  src={this.props.backgroundImage}
                  alt="Annotatable face"
                />
              </Segment>
              <Button.Group attached="bottom">
                <Button
                  className="highlightButton"
                  primary={this.state.toolType === "highlight"}
                  onClick={() => this.setState({ toolType: "highlight" })}
                  type="button"
                  icon
                >
                  <Icon name="paint brush" />
                </Button>
                <Button
                  className="pencilButton"
                  primary={this.state.toolType === "pencil"}
                  onClick={() => this.setState({ toolType: "pencil" })}
                  type="button"
                  icon
                >
                  <Icon name="pencil" />
                </Button>
                <Button
                  className="eraseButton"
                  primary={this.state.toolType === "erase"}
                  onClick={() => this.setState({ toolType: "erase" })}
                  type="button"
                  icon
                >
                  <Icon name="erase" />
                </Button>
                <Button
                  className="clearButton"
                  active={false}
                  onClick={() => this.clearCanvas()}
                  type="button"
                  icon
                >
                  <Icon name="trash" />
                </Button>
              </Button.Group>
            </Segment>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };
}

ImageAnnotator.propTypes = {
  annotationImagePath: PropTypes.string.isRequired,
  canvasWidth: PropTypes.number,
  canvasHeight: PropTypes.number,
  header: PropTypes.string,
  headers: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  annotationName: PropTypes.string.isRequired,
  handleAnnotationWasModified: PropTypes.func.isRequired, //This allows the data records to include info about which images were annotated
};

const mapStateToProps = state => {
  const { images } = state;
  const { annotationsImages } = images;
  return {
    annotationsImages
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAnnotationObject: (annotationName, upload, headers) => {
      return dispatch(
        imageActions.updateAnnotationObject(annotationName, upload, headers)
      );
    },
    getAnnotationImage: (annotationName, path, headers) => {
      return dispatch(
        imageActions.getAnnotationImage(annotationName, path, headers)
      );
    },
    clearAnnotationsObject: async () => {
      return await dispatch(imageActions.clearAnnotationsObject());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageAnnotator);
