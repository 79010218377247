import { strings } from "../";

const followUpNoteSchema = {
  was_expected_outcome: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.wasExpectedOutcome,
  },
  were_there_complications: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.wereThereComplications,
  },
};

export default followUpNoteSchema;
