import React from "react";
import PropTypes from "prop-types";
import { List } from "semantic-ui-react";
import { formatAestheticConcerns } from "../../helpers/formatAestheticConcerns";

const AestheticConcernsView = props => {
  const concerns = formatAestheticConcerns(props.selectedPatientData);

  return (
    <div className="viewModeMedicalInfo">
      <List relaxed>
        {concerns.map(item => (
          <List.Item>{item}</List.Item>
        ))}
      </List>
    </div>
  );
};

AestheticConcernsView.protoTypes = {
  selectedPatientData: PropTypes.object.isRequired
};

export default AestheticConcernsView;
