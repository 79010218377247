import { strings } from "./Strings";

export const medicalConditionOptions = [
  {
    key: "blood_pressure",
    label: strings.patientRecord.edit.label.medicalConditionBloodPressure,
  },
  {
    key: "chest_pains",
    label: strings.patientRecord.edit.label.medicalConditionChestPains,
  },
  {
    key: "heart_attack",
    label: strings.patientRecord.edit.label.medicalConditionHeartAttack,
  },
  {
    key: "asthma",
    label: strings.patientRecord.edit.label.medicalConditionAsthma,
  },
  {
    key: "copd",
    label: strings.patientRecord.edit.label.medicalConditionCOPD,
  },
  {
    key: "osteoarthritis",
    label: strings.patientRecord.edit.label.medicalConditionOsteoarthritis,
  },
  {
    key: "rheumatoid",
    label: strings.patientRecord.edit.label.medicalConditionRheumatoid,
  },
  {
    key: "breast_cancer",
    label: strings.patientRecord.edit.label.medicalConditionBreastCancer,
  },
  {
    key: "colon_cancer",
    label: strings.patientRecord.edit.label.medicalConditionColonCancer,
  },
  {
    key: "lung_cancer",
    label: strings.patientRecord.edit.label.medicalConditionLungCancer,
  },
  {
    key: "ulcerative_colitis",
    label: strings.patientRecord.edit.label.medicalConditionUlcerativeColitis,
  },
  {
    key: "crohns",
    label: strings.patientRecord.edit.label.medicalConditionCrohns,
  },
  {
    key: "diabetes_i",
    label: strings.patientRecord.edit.label.medicalConditionDiabetesI,
  },
  {
    key: "diabetes_ii",
    label: strings.patientRecord.edit.label.medicalConditionDiabetesII,
  },
  {
    key: "other",
    label: strings.patientRecord.edit.label.medicalConditionOther,
  },
];

export const dermatologicalDiseaseOptions = [
  {
    key: "eczema",
    label: strings.patientRecord.edit.label.dermatologicalDiseasesEczema,
  },
  {
    key: "psoriasis",
    label: strings.patientRecord.edit.label.dermatologicalDiseasesPsoriasis,
  },
  {
    key: "urticaria",
    label: strings.patientRecord.edit.label.dermatologicalDiseasesUrticaria,
  },
  {
    key: "vitiligo",
    label: strings.patientRecord.edit.label.dermatologicalDiseasesVitiligo,
  },
  {
    key: "other",
    label: strings.patientRecord.edit.label.dermatologicalDiseasesOther,
  },
];

export const neurologicalConditionOptions = [
  {
    key: "myasthenia",
    label: strings.patientRecord.edit.label.neurologicalConditionsMyasthenia,
  },
  {
    key: "multiple_sclerosis",
    label:
      strings.patientRecord.edit.label.neurologicalConditionsMultipleSclerosis,
  },
  {
    key: "mnd",
    label: strings.patientRecord.edit.label.neurologicalConditionsMND,
  },
  {
    key: "bells_palsy",
    label: strings.patientRecord.edit.label.neurologicalConditionsBellsPalsy,
  },
  {
    key: "other",
    label: strings.patientRecord.edit.label.neurologicalConditionsOther,
  },
];

export const dermalFillerTypeOptions = [
  {
    key: "juvederm",
    label: strings.patientRecord.edit.label.dermalFillerTypeJuvederm,
  },
  {
    key: "emervel",
    label: strings.patientRecord.edit.label.dermalFillerTypeEmervel,
  },
  {
    key: "restylane",
    label: strings.patientRecord.edit.label.dermalFillerTypeRestylane,
  },
  {
    key: "aquamid",
    label: strings.patientRecord.edit.label.dermalFillerTypeAquamid,
  },
  {
    key: "belotero",
    label: strings.patientRecord.edit.label.dermalFillerTypeBelotero,
  },
  {
    key: "bioalcamid",
    label: strings.patientRecord.edit.label.dermalFillerTypeBioalcamid,
  },
  {
    key: "radiesse",
    label: strings.patientRecord.edit.label.dermalFillerTypeRadiesse,
  },
  {
    key: "sculptra",
    label: strings.patientRecord.edit.label.dermalFillerTypeSculptra,
  },
  {
    key: "teosyal",
    label: strings.patientRecord.edit.label.dermalFillerTypeTeosyal,
  },
  {
    key: "other",
    label: strings.patientRecord.edit.label.dermalFillerTypeOther,
  },
];

export const previousProcedureOptions = [
  {
    key: "laser",
    label: strings.patientRecord.edit.label.previousProceduresLaser,
  },
  {
    key: "facelift",
    label: strings.patientRecord.edit.label.previousProceduresFacelift,
  },
  {
    key: "eyelid",
    label: strings.patientRecord.edit.label.previousProceduresEyelid,
  },
  {
    key: "breast",
    label: strings.patientRecord.edit.label.previousProceduresBreast,
  },
  {
    key: "liposuction",
    label: strings.patientRecord.edit.label.previousProceduresLiposuction,
  },
  {
    key: "tummy",
    label: strings.patientRecord.edit.label.previousProceduresTummy,
  },
];

export const skinConditionOptions = [
  {
    key: "pigmentation",
    label: strings.patientRecord.edit.label.skinConditionPigmentation,
  },
  {
    key: "telangiectasia",
    label: strings.patientRecord.edit.label.skinConditionTelangiectasia,
  },
  {
    key: "elastosis",
    label: strings.patientRecord.edit.label.skinConditionElastosis,
  },
  {
    key: "dynamic_rhytids",
    label: strings.patientRecord.edit.label.skinConditionDynamicRhytids,
  },
  {
    key: "static_rhytids",
    label: strings.patientRecord.edit.label.skinConditionStaticRhytids,
  },
];

export const smokingConditionOptions = [
  {
    key: "skin_tone",
    label: strings.patientRecord.edit.label.smokingConditionSkinTone,
  },
  {
    key: "elastic_recoil",
    label: strings.patientRecord.edit.label.smokingConditionElasticRecoil,
  },
  {
    key: "perioral_rhytids",
    label: strings.patientRecord.edit.label.smokingConditionPerioralRhytids,
  },
];

export const facialVolumeDetailOptions = [
  {
    key: "forehead",
    label: strings.patientRecord.edit.label.facialVolumeForehead,
  },
  {
    key: "temples",
    label: strings.patientRecord.edit.label.facialVolumeTemples,
  },
  {
    key: "brow",
    label: strings.patientRecord.edit.label.facialVolumeBrow,
  },
  {
    key: "infraorbital_region",
    label: strings.patientRecord.edit.label.facialVolumeInfraorbitalRegion,
  },
  {
    key: "malar_eminence",
    label: strings.patientRecord.edit.label.facialVolumeMalarEminence,
  },
  {
    key: "mid_face",
    label: strings.patientRecord.edit.label.facialVolumeMidFace,
  },
  {
    key: "lateral_cheek",
    label: strings.patientRecord.edit.label.facialVolumeLateralCheek,
  },
  {
    key: "submalar",
    label: strings.patientRecord.edit.label.facialVolumeSubmalar,
  },
  {
    key: "nasogenian",
    label: strings.patientRecord.edit.label.facialVolumeNasogenian,
  },
  {
    key: "perioral",
    label: strings.patientRecord.edit.label.facialVolumePerioral,
  },
  {
    key: "mandible",
    label: strings.patientRecord.edit.label.facialVolumeMandible,
  },
];

export const abnormalConditionOptions = [
  {
    key: "nasal_hump",
    label: strings.patientRecord.edit.label.abnormalNasalHump,
  },
  {
    key: "pseudohump",
    label: strings.patientRecord.edit.label.abnormalPseudohump,
  },
  {
    key: "nasal_tip",
    label: strings.patientRecord.edit.label.abnormalNasalTip,
  },
  {
    key: "thin_lips",
    label: strings.patientRecord.edit.label.abnormalThinLips,
  },
  {
    key: "chin",
    label: strings.patientRecord.edit.label.abnormalChin,
  },
];
