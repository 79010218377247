import { tableActionTypes } from "../actions/table.actionTypes";

const initialState = {
  selectedFormId: "",
  selectedFormData: {},
  loadingTableData: false,
  toggleStatusModalMessage: "",
  error: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case tableActionTypes.SET_FORM_ID_REQUEST:
      return { ...state, error: null };
    case tableActionTypes.SET_FORM_ID_SUCCESS:
      return { ...state, selectedFormId: action.payload };
    case tableActionTypes.SET_FORM_ID_FAILURE:
      return { ...state, error: action.payload };

    case tableActionTypes.SET_SELECTED_FORM_DATA_REQUEST:
      return { ...state, error: null };
    case tableActionTypes.SET_SELECTED_FORM_DATA_SUCCESS:
      return { ...state, selectedFormData: action.payload };
    case tableActionTypes.SET_SELECTED_FORM_DATA_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
