import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "./components/Layout";
import Routes from "./routes";
import { config } from "../_config/aws";
import { authActions } from "./store/actions/auth.actions";
import "babel-polyfill";
import "./App.css";

class App extends Component {
  componentWillMount = async () => {
    window.scrollTo(0, 0);
    if (!this.props.isLoggedIn) {
      await this.props.refreshSignInStatus();
    }
    try {
      // If there is a current user session in local storage, update redux state accordingly
      await this.props.checkCurrentAuthenticatedUser();
    } catch (err) {
      // Call sign out if authentication check throws error
      await this.props.signOut();
    }
  };

  render() {
    const isLoggedIn = this.props.isLoggedIn;
    const role = this.props.role;
    let headers = {};
    if (JSON.parse(localStorage.getItem("state"))) {
      headers = JSON.parse(localStorage.getItem("state")).headers;
    }

    const injectedProps = {
      headers,
      isLoggedIn,
      role,
      cognitoUserPool: config.cognito.UserPoolId,
      cognitoClient: config.cognito.ClientId,
      signOut: this.props.signOut
    };

    return (
      <div className="App">
        <Layout>
          <Routes injectedProps={injectedProps} />
        </Layout>
      </div>
    );
  }
}

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  role: PropTypes.string,
  refreshSignInStatus: PropTypes.func.isRequired,
  checkCurrentAuthenticatedUser: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { isLoggedIn, role } = state.auth;

  return {
    isLoggedIn,
    role
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshSignInStatus: () => {
      dispatch(authActions.refreshSignInStatus());
    },
    checkCurrentAuthenticatedUser: () => {
      return dispatch(authActions.checkCurrentAuthenticatedUser());
    },
    signOut: () => {
      return dispatch(authActions.signOut());
    }
  };
};

const connectedApp = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
export { connectedApp as App };
