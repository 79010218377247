import React from "react";
import { Form, Segment, Dimmer, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { authActions } from "../../store/actions/auth.actions";
import { strings } from "../../resources";
import "./css/login.css";
import logo from "../../assets/images/logo.png";

class LoginContainer extends React.Component {
  state = {
    username: "",
    password: ""
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoggedIn) {
      if (
        nextProps.location &&
        nextProps.location.state &&
        nextProps.location.state.from &&
        nextProps.location.state.from.pathname
      ) {
        this.props.history.push(nextProps.location.state.from.pathname);
      } else {
        this.props.history.push("/patients");
      }
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleKeyDown = event => {
    if (event.keyCode === 13) {
      this.handleSubmit(event);
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.signIn(this.state.username, this.state.password);
  };

  render() {
    return (
      <div className="Login">
        <div className="ui middle aligned center aligned grid">
          <div id="LoginColumn" className="column">
            <br />
            <h2 className="ui image header">
              <center>
                <div className="field">
                  <img
                    className="image"
                    src={logo}
                    alt="Dr Darren McKeown"
                    width="80%"
                    height="80%"
                  />
                </div>
              </center>
              <br />
              <div className="content">{strings.header.login}</div>
            </h2>
            <Dimmer.Dimmable
              as={Segment}
              blurring
              dimmed={this.props.loggingIn}
            >
              <Dimmer inverted active={this.props.loggingIn}>
                <Loader size="large">Signing in..</Loader>
              </Dimmer>
              <Segment>
                <Form>
                  <Form.Group>
                    <Form.Input
                      onKeyDown={event => {
                        this.handleKeyDown(event);
                      }}
                      name="username"
                      icon="user"
                      iconPosition="left"
                      onChange={this.handleChange}
                      placeholder={strings.form.placeholder.username}
                      required
                      value={this.state.username || ""}
                      width={16}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      onKeyDown={event => {
                        this.handleKeyDown(event);
                      }}
                      name="password"
                      type="password"
                      icon="lock"
                      iconPosition="left"
                      onChange={this.handleChange}
                      placeholder={strings.form.placeholder.password}
                      required
                      value={this.state.password || ""}
                      width={16}
                    />
                  </Form.Group>
                </Form>
                <button
                  className="ui fluid large submit button"
                  id="LoginButton"
                  onClick={this.handleSubmit}
                >
                  {strings.button.loginLabel}
                </button>
              </Segment>
              {this.props.error && (
                <div className="ui negative message">{this.props.error}</div>
              )}
            </Dimmer.Dimmable>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { loggingIn, isLoggedIn, headers, error } = state.auth;

  return {
    loggingIn,
    isLoggedIn,
    headers,
    error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: (username, password) => {
      dispatch(authActions.signIn(username, password));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginContainer)
);
