import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import {
  medicalConditionsOptions,
  dermatologicalDiseaseOptions,
  neurologicalConditionOptions,
  dermalFillerTypeOptions,
  previousProcedureOptions,
  photographicConsentOptions,
} from "../../resources/CheckboxOptions";
import { YesNoInput, DatePicker, CheckboxGroup, CountryInput } from "../ui";
import { SignatureFieldContainer } from "../../../Images/containers";
import { dateEpochToDateTimeString } from "../../../App/helpers/DateTimeFormatConversion";

class FormNewPatientRegistration extends React.Component {
  componentWillMount = () => {
    if (this.props.mode === "create" || this.props.mode === "edit") {
      let todaysDateStr = dateEpochToDateTimeString(new Date(), "-");
      this.props.updateSelectedForm("date_patient_signed", todaysDateStr);
    }
  };

  render = () => {
    const errorFields = this.props.validationErrors.map((error) => {
      return error.field;
    });
    return (
      <React.Fragment>
        <div className="patientFormSectionHeader">
          {strings.form.header.personalInformation}
        </div>
        <Form.Group>
          <Form.Input
            error={errorFields.includes("first_name")}
            label={strings.form.label.firstName}
            name="first_name"
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.firstName}
            required
            value={this.props.selectedFormData.first_name || ""}
            width={8}
          />
          <Form.Input
            error={errorFields.includes("surname")}
            className="noLabelInput"
            name="surname"
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.surname}
            required
            value={this.props.selectedFormData.surname || ""}
            width={8}
          />
        </Form.Group>
        <Form.Group>
          <DatePicker
            error={errorFields.includes("date_of_birth")}
            className="dateOfBirth"
            name="date_of_birth"
            onChange={this.props.handleDateChange}
            onKeyDown={(e) => this.props.handleDateKeyDown(e)}
            required
            label={strings.form.label.dateOfBirth}
            value={this.props.selectedFormData.date_of_birth || ""}
            noFutureDates
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            error={errorFields.includes("email_address")}
            label={strings.form.label.emailAddress}
            name="email_address"
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.emailAddress}
            required
            value={this.props.selectedFormData.email_address || ""}
            width={16}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            error={errorFields.includes("postal_address")}
            label={strings.form.label.postalAddress}
            name="postal_address"
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.postalAddress}
            required
            value={this.props.selectedFormData.postal_address || ""}
            width={12}
          />
          <Form.Input
            error={errorFields.includes("postcode")}
            label={strings.form.label.postcode}
            name="postcode"
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.postcode}
            required
            value={this.props.selectedFormData.postcode || ""}
            width={4}
          />
        </Form.Group>
        <Form.Group>
          <CountryInput
            required={true}
            label={strings.form.label.country}
            placeholder={strings.form.label.country}
            name="country"
            error={errorFields.includes("country")}
            onChange={this.props.handleChange}
            value={this.props.selectedFormData.country || ""}
            width={8}
            disabled={this.props.mode === "view"}
          />
          <Form.Input
            error={errorFields.includes("town_or_city")}
            label={strings.form.label.townOrCity}
            name="town_or_city"
            required={true}
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.townOrCity}
            value={this.props.selectedFormData.town_or_city || ""}
            width={8}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            error={errorFields.includes("phone_number")}
            label={strings.form.label.mainPhoneNumber}
            name="phone_number"
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.mainPhoneNumber}
            required
            value={this.props.selectedFormData.phone_number || ""}
          />
          <Form.Input
            error={errorFields.includes("mobile_phone_number")}
            label={strings.form.label.mobilePhoneNumber}
            name="mobile_phone_number"
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.mobilePhoneNumber}
            required
            value={this.props.selectedFormData.mobile_phone_number || ""}
          />
          <Form.Input
            label={strings.form.label.occupation}
            name="occupation"
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.occupation}
            //required
            value={this.props.selectedFormData.occupation || ""}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            error={errorFields.includes("gp_name")}
            label={strings.form.label.gpName}
            name="gp_name"
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.gpName}
            required
            value={this.props.selectedFormData.gp_name || ""}
            width={8}
          />
          <Form.Input
            error={errorFields.includes("gp_address")}
            label={strings.form.label.gpAddress}
            name="gp_address"
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.gpAddress}
            required
            value={this.props.selectedFormData.gp_address || ""}
            width={8}
          />
        </Form.Group>
        <Form.Group>
          <Form.Field
            className="textField"
            content={strings.form.label.gpContact}
          />
        </Form.Group>
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("gp_contact_approval")}
          name="gp_contact_approval"
          label={strings.form.label.gpConsent}
          value={this.props.selectedFormData.gp_contact_approval}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <Form.Group>
          <Form.Field
            className="textField"
            content={strings.form.label.emergencyContact}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            error={errorFields.includes("emergency_contact_name")}
            label={strings.form.label.emergencyContactName}
            name="emergency_contact_name"
            required
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.emergencyContactName}
            value={this.props.selectedFormData.emergency_contact_name || ""}
            width={8}
          />
          <Form.Input
            error={errorFields.includes("emergency_contact_phone_number")}
            label={strings.form.label.emergencyContactPhoneNumber}
            name="emergency_contact_phone_number"
            required
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.phoneNumber}
            value={
              this.props.selectedFormData.emergency_contact_phone_number || ""
            }
            width={8}
          />
        </Form.Group>
        <div className="patientFormSectionHeader">
          {strings.form.header.medicalHistory}
        </div>
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("medical_conditions_toggle")}
          name="medical_conditions_toggle"
          label={strings.form.label.medicalConditionsToggle}
          value={this.props.selectedFormData.medical_conditions_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["medical_conditions"]}
          childElement={
            <React.Fragment>
              <CheckboxGroup
                mode={this.props.mode}
                error={errorFields.includes("medical_conditions")}
                required
                label={strings.form.label.complicationsFollowingTreatment}
                group="medical_conditions"
                data={this.props.selectedFormData}
                handleCheckboxChange={this.props.handleCheckboxGroupChange}
                options={medicalConditionsOptions}
              />
              <Form.Group>
                {this.props.selectedFormData.medical_conditions &&
                  this.props.selectedFormData.medical_conditions.other &&
                  this.props.selectedFormData.medical_conditions.other
                    .checked === true && (
                    <Form.TextArea
                      error={errorFields.includes("medical_conditions_other")}
                      className="groupChild"
                      label={strings.form.label.complicationsOtherDetails}
                      name="medical_conditions_other"
                      group="medical_conditions"
                      child="other"
                      onChange={this.props.handleNestedChange}
                      placeholder={strings.form.placeholder.details}
                      required
                      value={
                        this.props.selectedFormData.medical_conditions_other ||
                        ""
                      }
                      rows={2}
                      width={16}
                    />
                  )}
              </Form.Group>
            </React.Fragment>
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("dermatological_diseases_toggle")}
          name="dermatological_diseases_toggle"
          label={strings.form.label.dermatologicalDiseasesToggle}
          value={this.props.selectedFormData.dermatological_diseases_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["dermatological_diseases"]}
          childElement={
            <React.Fragment>
              <CheckboxGroup
                mode={this.props.mode}
                error={errorFields.includes("dermatological_diseases")}
                required
                label={strings.form.label.dermatologicalDiseases}
                group="dermatological_diseases"
                data={this.props.selectedFormData}
                handleCheckboxChange={this.props.handleCheckboxGroupChange}
                options={dermatologicalDiseaseOptions}
              />
              <Form.Group>
                {this.props.selectedFormData.dermatological_diseases &&
                  this.props.selectedFormData.dermatological_diseases.other &&
                  this.props.selectedFormData.dermatological_diseases.other
                    .checked === true && (
                    <Form.TextArea
                      error={errorFields.includes(
                        "dermatological_diseases_other"
                      )}
                      className="groupChild"
                      label={strings.form.label.complicationsOtherDetails}
                      name="dermatological_diseases_other"
                      group="dermatological_diseases"
                      child="other"
                      onChange={this.props.handleNestedChange}
                      placeholder={strings.form.placeholder.details}
                      required
                      value={
                        this.props.selectedFormData
                          .dermatological_diseases_other || ""
                      }
                      rows={2}
                      width={16}
                    />
                  )}
              </Form.Group>
            </React.Fragment>
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("neurological_conditions_toggle")}
          name="neurological_conditions_toggle"
          label={strings.form.label.neurologicalConditionsToggle}
          value={this.props.selectedFormData.neurological_conditions_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["neurological_conditions"]}
          childElement={
            <React.Fragment>
              <CheckboxGroup
                mode={this.props.mode}
                error={errorFields.includes("neurological_conditions")}
                required
                label={strings.form.label.neurologicalConditions}
                group="neurological_conditions"
                data={this.props.selectedFormData}
                handleCheckboxChange={this.props.handleCheckboxGroupChange}
                options={neurologicalConditionOptions}
              />
              <Form.Group>
                {this.props.selectedFormData.neurological_conditions &&
                  this.props.selectedFormData.neurological_conditions.other &&
                  this.props.selectedFormData.neurological_conditions.other
                    .checked === true && (
                    <Form.TextArea
                      error={errorFields.includes(
                        "neurological_conditions_other"
                      )}
                      className="groupChild"
                      label={strings.form.label.complicationsOtherDetails}
                      name="neurological_conditions_other"
                      group="neurological_conditions"
                      child="other"
                      onChange={this.props.handleNestedChange}
                      placeholder={strings.form.placeholder.details}
                      required
                      value={
                        this.props.selectedFormData
                          .neurological_conditions_other || ""
                      }
                      rows={2}
                      width={16}
                    />
                  )}
              </Form.Group>
            </React.Fragment>
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("regular_medications_toggle")}
          name="regular_medications_toggle"
          label={strings.form.label.regularMedicationsToggle}
          value={this.props.selectedFormData.regular_medications_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["regular_medications"]}
          childElement={
            <Form.TextArea
              error={errorFields.includes("regular_medications")}
              label={strings.form.label.regularMedications}
              name="regular_medications"
              onChange={this.props.handleChange}
              placeholder={strings.form.placeholder.details}
              required
              value={this.props.selectedFormData.regular_medications || ""}
              rows={2}
              width={16}
            />
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("supplements_toggle")}
          name="supplements_toggle"
          label={strings.form.label.supplementsToggle}
          value={this.props.selectedFormData.supplements_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["supplements"]}
          childElement={
            <Form.TextArea
              error={errorFields.includes("supplements")}
              label={strings.form.label.supplements}
              name="supplements"
              onChange={this.props.handleChange}
              placeholder={strings.form.placeholder.details}
              required
              value={this.props.selectedFormData.supplements || ""}
              rows={2}
              width={16}
            />
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("drug_allergies_toggle")}
          name="drug_allergies_toggle"
          label={strings.form.label.drugAllergiesToggle}
          value={this.props.selectedFormData.drug_allergies_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["drug_allergies"]}
          childElement={
            <Form.TextArea
              error={errorFields.includes("drug_allergies")}
              label={strings.form.label.drugAllergies}
              name="drug_allergies"
              onChange={this.props.handleChange}
              placeholder={strings.form.placeholder.details}
              required
              value={this.props.selectedFormData.drug_allergies || ""}
              rows={2}
              width={16}
            />
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("general_allergies_toggle")}
          name="general_allergies_toggle"
          label={strings.form.label.generalAllergiesToggle}
          value={this.props.selectedFormData.general_allergies_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["general_allergies"]}
          childElement={
            <Form.TextArea
              label={strings.form.label.generalAllergies}
              error={errorFields.includes("general_allergies")}
              name="general_allergies"
              onChange={this.props.handleChange}
              placeholder={strings.form.placeholder.details}
              required
              value={this.props.selectedFormData.general_allergies || ""}
              rows={2}
              width={16}
            />
          }
        />
        <div className="patientFormSectionHeader">
          {strings.form.header.mentalHealthHistory}
        </div>
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("mental_health_disorders_toggle")}
          name="mental_health_disorders_toggle"
          label={strings.form.label.mentalHealthDisordersToggle}
          value={this.props.selectedFormData.mental_health_disorders_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["mental_health_disorders"]}
          childElement={
            <Form.TextArea
              label={strings.form.label.mentalHealthDisorders}
              error={errorFields.includes("mental_health_disorders")}
              name="mental_health_disorders"
              onChange={this.props.handleChange}
              placeholder={strings.form.placeholder.details}
              required
              value={this.props.selectedFormData.mental_health_disorders || ""}
              rows={2}
              width={16}
            />
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("seen_psychiatrist")}
          name="seen_psychiatrist"
          label={strings.form.label.seenPsychiatrist}
          value={this.props.selectedFormData.seen_psychiatrist}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("psychiatric_medications_toggle")}
          name="psychiatric_medications_toggle"
          label={strings.form.label.psychiatricMedicationsToggle}
          value={this.props.selectedFormData.psychiatric_medications_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["psychiatric_medications"]}
          childElement={
            <Form.TextArea
              error={errorFields.includes("psychiatric_medications")}
              label={strings.form.label.psychiatricMedications}
              name="psychiatric_medications"
              onChange={this.props.handleChange}
              placeholder={strings.form.placeholder.details}
              required
              value={this.props.selectedFormData.psychiatric_medications || ""}
              rows={2}
              width={16}
            />
          }
        />
        <div className="patientFormSectionHeader">
          {strings.form.header.lifestyleHistory}
        </div>
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("smoked_previously")}
          name="smoked_previously"
          label={strings.form.label.smokedPreviously}
          value={this.props.selectedFormData.smoked_previously}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("smokes_currently")}
          name="smokes_currently"
          label={strings.form.label.smokesCurrently}
          value={this.props.selectedFormData.smokes_currently}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("uses_sunbeds")}
          name="uses_sunbeds"
          label={strings.form.label.usesSunbeds}
          value={this.props.selectedFormData.uses_sunbeds}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("gets_suntans")}
          name="gets_suntans"
          label={strings.form.label.getsSuntans}
          value={this.props.selectedFormData.gets_suntans}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <div className="patientFormSectionHeader">
          {strings.form.header.aestheticHistory}
        </div>
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("botox_previous_use")}
          name="botox_previous_use"
          label={strings.form.label.botoxPreviousUse}
          value={this.props.selectedFormData.botox_previous_use}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={[
            "botox_number_of_past_treatments",
            "botox_when_last_treated",
            "botox_complications",
          ]}
          childElement={
            <React.Fragment>
              <Form.Group>
                <Form.Input
                  error={errorFields.includes(
                    "botox_number_of_past_treatments"
                  )}
                  label={strings.form.label.botoxNumberOfPastTreatments}
                  min="1"
                  name="botox_number_of_past_treatments"
                  onChange={this.props.handleChange}
                  required
                  value={
                    this.props.selectedFormData
                      .botox_number_of_past_treatments || ""
                  }
                  width={4}
                  type="number"
                />
              </Form.Group>
              <Form.Group>
                <DatePicker
                  error={errorFields.includes("botox_when_last_treated")}
                  className="yesNoDateInput"
                  label={strings.form.label.botoxWhenLastTreated}
                  name="botox_when_last_treated"
                  closable={true}
                  value={
                    this.props.selectedFormData.botox_when_last_treated || ""
                  }
                  onChange={this.props.handleDateChange}
                  onKeyDown={this.props.handleDateKeyDown}
                  required
                  noFutureDates
                  type="number"
                />
              </Form.Group>
              <Form.Group>
                <YesNoInput
                  mode={this.props.mode}
                  error={errorFields.includes("botox_complications")}
                  name="botox_complications"
                  label={strings.form.label.botoxComplications}
                  value={this.props.selectedFormData.botox_complications}
                  changehandler={this.props.handleYesNoChange}
                  required
                />
              </Form.Group>
            </React.Fragment>
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("dermal_fillers_previous_use")}
          name="dermal_fillers_previous_use"
          label={strings.form.label.dermalFillersPreviousUse}
          value={this.props.selectedFormData.dermal_fillers_previous_use}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={[
            "dermal_fillers_number_of_past_treatments",
            "dermal_fillers_when_last_treated",
            "dermal_filler_complications",
          ]}
          childElement={
            <React.Fragment>
              <Form.Group>
                <Form.Input
                  error={errorFields.includes(
                    "dermal_fillers_number_of_past_treatments"
                  )}
                  label={strings.form.label.dermalFillersNumberOfPastTreatments}
                  min="1"
                  name="dermal_fillers_number_of_past_treatments"
                  onChange={this.props.handleChange}
                  required
                  value={
                    this.props.selectedFormData
                      .dermal_fillers_number_of_past_treatments || ""
                  }
                  width={4}
                  type="number"
                />
              </Form.Group>
              <Form.Group>
                <DatePicker
                  error={errorFields.includes(
                    "dermal_fillers_when_last_treated"
                  )}
                  label={strings.form.label.dermalFillersWhenLastTreated}
                  className="yesNoDateInput"
                  name="dermal_fillers_when_last_treated"
                  closable={true}
                  value={
                    this.props.selectedFormData
                      .dermal_fillers_when_last_treated || ""
                  }
                  onChange={this.props.handleDateChange}
                  onKeyDown={this.props.handleDateKeyDown}
                  required
                  type="number"
                  noFutureDates
                />
              </Form.Group>
              <Form.Group>
                <YesNoInput
                  mode={this.props.mode}
                  error={errorFields.includes("dermal_fillers_complications")}
                  name="dermal_fillers_complications"
                  label={strings.form.label.dermalFillersComplications}
                  value={
                    this.props.selectedFormData.dermal_fillers_complications
                  }
                  changehandler={this.props.handleYesNoChange}
                  required
                />
              </Form.Group>
              <CheckboxGroup
                mode={this.props.mode}
                error={errorFields.includes("dermal_filler_types")}
                label={strings.form.label.dermalFillersTypesQuestion}
                required
                horizontal
                group="dermal_filler_types"
                data={this.props.selectedFormData}
                handleCheckboxChange={this.props.handleCheckboxGroupChange}
                options={dermalFillerTypeOptions}
              />
            </React.Fragment>
          }
        />
        <CheckboxGroup
          mode={this.props.mode}
          error={errorFields.includes("previous_procedures")}
          label={strings.form.label.previousProceduresQuestion}
          horizontal
          group="previous_procedures"
          data={this.props.selectedFormData}
          handleCheckboxChange={this.props.handleCheckboxGroupChange}
          options={previousProcedureOptions}
        />
        <div className="patientFormSectionHeader">
          {strings.form.header.pregnancyHistory}
        </div>
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("pregnancy")}
          name="pregnancy"
          label={strings.form.label.pregnancy}
          value={this.props.selectedFormData.pregnancy}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("breastfeeding")}
          name="breastfeeding"
          label={strings.form.label.breastfeeding}
          value={this.props.selectedFormData.breastfeeding}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <div className="patientFormSectionHeader">
          {strings.form.header.privacy}
        </div>
        <Form.Field
          className="textField"
          content={strings.form.label.consentPhotographicInformation}
        />
        <CheckboxGroup
          mode={this.props.mode}
          error={errorFields.includes("image_consent")}
          label={strings.form.label.consentPhotographicQuestion}
          required
          group="image_consent"
          data={this.props.selectedFormData}
          handleCheckboxChange={this.props.handleCheckboxGroupChange}
          options={photographicConsentOptions}
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("communication_consent")}
          name="communication_consent"
          label={strings.form.label.consentSendTextAndEmail}
          value={this.props.selectedFormData.communication_consent}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <Form.Field
          className="textField"
          content={strings.form.label.consentDeclaration}
        />
        <SignatureFieldContainer
          mode={this.props.mode}
          error={errorFields.includes("signature_data_url")}
          headers={this.props.headers}
          readOnly={this.props.mode === "create" ? false : true}
          signatureImagePath={
            this.props.formId ? `signatures/forms/${this.props.formId}` : ""
          }
        />
        <Form.Group>
          <DatePicker
            error={errorFields.includes("date_patient_signed")}
            className="datePatientSigned"
            name="date_patient_signed"
            onChange={this.props.handleDateChange}
            onKeyDown={(e) => this.props.handleDateKeyDown(e)}
            required
            label={strings.form.label.datePatientSigned}
            value={this.props.selectedFormData.date_patient_signed || ""}
          />
        </Form.Group>
      </React.Fragment>
    );
  };
}

FormNewPatientRegistration.propTypes = {
  mode: PropTypes.string.isRequired, //create, view or edit
  selectedFormData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
};

export default FormNewPatientRegistration;
