import axios from 'axios';
import { config } from '../../_config'
import axiosInterceptors from '../../_axios/interceptors'

const instance = axios.create({
    baseURL: config.formsEndpoint
});

instance.interceptors.request.use(axiosInterceptors.onRequestSuccess, axiosInterceptors.onRequestFail);
instance.interceptors.response.use(axiosInterceptors.onResponseSuccess, axiosInterceptors.onResponseFail);

export default instance;
