import React from "react";
import PropTypes from "prop-types";
import { Divider, Form } from "semantic-ui-react";
import { strings } from "../resources";
import {
  YesNoInput,
  PatientTabControls,
  DatePicker,
  CheckboxGroup,
} from "./ui";
import {
  medicalConditionOptions,
  dermatologicalDiseaseOptions,
  neurologicalConditionOptions,
  dermalFillerTypeOptions,
  previousProcedureOptions,
} from "../resources/CheckboxOptions";
import ExaminationNotesEdit from "./ui/ExaminationNotesEdit";
import { DoctorSignatureContainer } from "../../Images/containers";

class EditModeMedicalRecordTab extends React.Component {
  render = () => {
    const errorFields = this.props.validationErrors.map((error) => {
      return error.field;
    });

    return (
      <React.Fragment>
        <Form
          className="details"
          onSubmit={this.props.toggleConfirmSaveModalVisibility}
        >
          <fieldset disabled={this.props.mode === "view"}>
            <div className="medicalInformation">
              <div className="gpHeader patientSectionHeader">
                {strings.patientRecord.header.gpInfo}
              </div>
              <Form.Group>
                <Form.Input
                  error={errorFields.includes("gp_name")}
                  label={strings.patientRecord.edit.label.gpName}
                  name="gp_name"
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.edit.placeholder.gpName}
                  required
                  value={this.props.selectedPatientData.gp_name || ""}
                  width={8}
                />
                <Form.Input
                  error={errorFields.includes("gp_address")}
                  label={strings.patientRecord.edit.label.gpAddress}
                  name="gp_address"
                  onChange={this.props.handleChange}
                  placeholder={strings.patientRecord.edit.placeholder.gpAddress}
                  required
                  value={this.props.selectedPatientData.gp_address || ""}
                  width={8}
                />
              </Form.Group>
              <YesNoInput
                error={errorFields.includes("gp_contact_approval")}
                name="gp_contact_approval"
                label={strings.patientRecord.edit.label.gpConsent}
                value={this.props.selectedPatientData.gp_contact_approval}
                changehandler={this.props.handleYesNoChange}
                required
              />

              <div className="medicalHistory patientSectionHeader">
                {strings.patientRecord.header.medicalHistory}
              </div>
              <YesNoInput
                mode={this.props.mode}
                error={errorFields.includes("medical_conditions_toggle")}
                name="medical_conditions_toggle"
                label={strings.patientRecord.edit.label.medicalConditionsToggle}
                value={this.props.selectedPatientData.medical_conditions_toggle}
                changehandler={this.props.handleYesNoChange}
                required
                showChildWhenValue="Yes"
                childrenFields={["medical_conditions"]}
                childElement={
                  <React.Fragment>
                    <CheckboxGroup
                      mode={this.props.mode}
                      error={errorFields.includes("medical_conditions")}
                      required
                      label={strings.patientRecord.edit.label.medicalConditions}
                      group="medical_conditions"
                      data={this.props.selectedPatientData}
                      handleCheckboxChange={
                        this.props.handleCheckboxGroupChange
                      }
                      options={medicalConditionOptions}
                    />
                    <Form.Group>
                      {this.props.selectedPatientData.medical_conditions &&
                        this.props.selectedPatientData.medical_conditions
                          .other &&
                        this.props.selectedPatientData.medical_conditions.other
                          .checked === true && (
                          <Form.TextArea
                            error={errorFields.includes(
                              "medical_conditions_other"
                            )}
                            className="groupChild"
                            label={
                              strings.patientRecord.edit.label
                                .complicationsOtherDetails
                            }
                            name="medical_conditions_other"
                            group="medical_conditions"
                            child="other"
                            onChange={this.props.handleNestedChange}
                            placeholder={
                              strings.patientRecord.edit.placeholder.details
                            }
                            required
                            value={
                              this.props.selectedPatientData
                                .medical_conditions_other || ""
                            }
                            rows={2}
                            width={16}
                          />
                        )}
                    </Form.Group>
                  </React.Fragment>
                }
              />

              <YesNoInput
                mode={this.props.mode}
                error={errorFields.includes("dermatological_diseases_toggle")}
                name="dermatological_diseases_toggle"
                label={
                  strings.patientRecord.edit.label.dermatologicalDiseasesToggle
                }
                value={
                  this.props.selectedPatientData.dermatological_diseases_toggle
                }
                changehandler={this.props.handleYesNoChange}
                required
                showChildWhenValue="Yes"
                childrenFields={["dermatological_diseases"]}
                childElement={
                  <React.Fragment>
                    <CheckboxGroup
                      mode={this.props.mode}
                      error={errorFields.includes("dermatological_diseases")}
                      required
                      label={
                        strings.patientRecord.edit.label.dermatologicalDiseases
                      }
                      group="dermatological_diseases"
                      data={this.props.selectedPatientData}
                      handleCheckboxChange={
                        this.props.handleCheckboxGroupChange
                      }
                      options={dermatologicalDiseaseOptions}
                    />
                    <Form.Group>
                      {this.props.selectedPatientData.dermatological_diseases &&
                        this.props.selectedPatientData.dermatological_diseases
                          .other &&
                        this.props.selectedPatientData.dermatological_diseases
                          .other.checked === true && (
                          <Form.TextArea
                            error={errorFields.includes(
                              "dermatological_diseases_other"
                            )}
                            className="groupChild"
                            label={
                              strings.patientRecord.edit.label
                                .complicationsOtherDetails
                            }
                            name="dermatological_diseases_other"
                            group="dermatological_diseases"
                            child="other"
                            onChange={this.props.handleNestedChange}
                            placeholder={
                              strings.patientRecord.edit.placeholder.details
                            }
                            required
                            value={
                              this.props.selectedPatientData
                                .dermatological_diseases_other || ""
                            }
                            rows={2}
                            width={16}
                          />
                        )}
                    </Form.Group>
                  </React.Fragment>
                }
              />
              <YesNoInput
                mode={this.props.mode}
                error={errorFields.includes("neurological_conditions_toggle")}
                name="neurological_conditions_toggle"
                label={
                  strings.patientRecord.edit.label.neurologicalConditionsToggle
                }
                value={
                  this.props.selectedPatientData.neurological_conditions_toggle
                }
                changehandler={this.props.handleYesNoChange}
                required
                showChildWhenValue="Yes"
                childrenFields={["neurological_conditions"]}
                childElement={
                  <React.Fragment>
                    <CheckboxGroup
                      mode={this.props.mode}
                      error={errorFields.includes("neurological_conditions")}
                      required
                      label={
                        strings.patientRecord.edit.label.neurologicalConditions
                      }
                      group="neurological_conditions"
                      data={this.props.selectedPatientData}
                      handleCheckboxChange={
                        this.props.handleCheckboxGroupChange
                      }
                      options={neurologicalConditionOptions}
                    />
                    <Form.Group>
                      {this.props.selectedPatientData.neurological_conditions &&
                        this.props.selectedPatientData.neurological_conditions
                          .other &&
                        this.props.selectedPatientData.neurological_conditions
                          .other.checked === true && (
                          <Form.TextArea
                            error={errorFields.includes(
                              "neurological_conditions_other"
                            )}
                            className="groupChild"
                            label={
                              strings.patientRecord.edit.label
                                .complicationsOtherDetails
                            }
                            name="neurological_conditions_other"
                            group="neurological_conditions"
                            child="other"
                            onChange={this.props.handleNestedChange}
                            placeholder={
                              strings.patientRecord.edit.placeholder.details
                            }
                            required
                            value={
                              this.props.selectedPatientData
                                .neurological_conditions_other || ""
                            }
                            rows={2}
                            width={16}
                          />
                        )}
                    </Form.Group>
                  </React.Fragment>
                }
              />
              <YesNoInput
                error={errorFields.includes("regular_medications_toggle")}
                name="regular_medications_toggle"
                label={
                  strings.patientRecord.edit.label.regularMedicationsToggle
                }
                value={
                  this.props.selectedPatientData.regular_medications_toggle
                }
                changehandler={this.props.handleYesNoChange}
                required
                showChildWhenValue="Yes"
                childrenFields={["regular_medications"]}
                childElement={
                  <Form.TextArea
                    label={strings.patientRecord.edit.label.regularMedications}
                    error={errorFields.includes("regular_medications")}
                    name="regular_medications"
                    onChange={this.props.handleChange}
                    placeholder={strings.patientRecord.edit.placeholder.details}
                    required
                    value={
                      this.props.selectedPatientData.regular_medications || ""
                    }
                    rows={2}
                    width={16}
                  />
                }
              />
              <YesNoInput
                error={errorFields.includes("supplements_toggle")}
                name="supplements_toggle"
                label={strings.patientRecord.edit.label.supplementsToggle}
                value={this.props.selectedPatientData.supplements_toggle}
                changehandler={this.props.handleYesNoChange}
                required
                showChildWhenValue="Yes"
                childrenFields={["supplements"]}
                childElement={
                  <Form.TextArea
                    label={strings.patientRecord.edit.label.supplements}
                    error={errorFields.includes("supplements")}
                    name="supplements"
                    onChange={this.props.handleChange}
                    placeholder={strings.patientRecord.edit.placeholder.details}
                    required
                    value={this.props.selectedPatientData.supplements || ""}
                    rows={2}
                    width={16}
                  />
                }
              />
              <YesNoInput
                error={errorFields.includes("drug_allergies_toggle")}
                name="drug_allergies_toggle"
                label={strings.patientRecord.edit.label.drugAllergiesToggle}
                value={this.props.selectedPatientData.drug_allergies_toggle}
                changehandler={this.props.handleYesNoChange}
                required
                showChildWhenValue="Yes"
                childrenFields={["drug_allergies"]}
                childElement={
                  <Form.TextArea
                    label={strings.patientRecord.edit.label.drugAllergies}
                    error={errorFields.includes("drug_allergies")}
                    name="drug_allergies"
                    onChange={this.props.handleChange}
                    placeholder={strings.patientRecord.edit.placeholder.details}
                    required
                    value={this.props.selectedPatientData.drug_allergies || ""}
                    rows={2}
                    width={16}
                  />
                }
              />
              <YesNoInput
                error={errorFields.includes("general_allergies_toggle")}
                name="general_allergies_toggle"
                label={strings.patientRecord.edit.label.generalAllergiesToggle}
                value={this.props.selectedPatientData.general_allergies_toggle}
                changehandler={this.props.handleYesNoChange}
                required
                showChildWhenValue="Yes"
                childrenFields={["general_allergies"]}
                childElement={
                  <Form.TextArea
                    label={strings.patientRecord.edit.label.generalAllergies}
                    error={errorFields.includes("general_allergies")}
                    name="general_allergies"
                    onChange={this.props.handleChange}
                    placeholder={strings.patientRecord.edit.placeholder.details}
                    required
                    value={
                      this.props.selectedPatientData.general_allergies || ""
                    }
                    rows={2}
                    width={16}
                  />
                }
              />
              <div className="mentalHealthHistory patientSectionHeader">
                {strings.patientRecord.header.mentalHealthHistory}
              </div>
              <YesNoInput
                error={errorFields.includes("mental_health_disorders_toggle")}
                name="mental_health_disorders_toggle"
                label={
                  strings.patientRecord.edit.label.mentalHealthDisordersToggle
                }
                value={
                  this.props.selectedPatientData.mental_health_disorders_toggle
                }
                changehandler={this.props.handleYesNoChange}
                required
                showChildWhenValue="Yes"
                childrenFields={["mental_health_disorders"]}
                childElement={
                  <Form.TextArea
                    label={
                      strings.patientRecord.edit.label.mentalHealthDisorders
                    }
                    error={errorFields.includes("mental_health_disorders")}
                    name="mental_health_disorders"
                    onChange={this.props.handleChange}
                    placeholder={strings.patientRecord.edit.placeholder.details}
                    required
                    value={
                      this.props.selectedPatientData.mental_health_disorders ||
                      ""
                    }
                    rows={2}
                    width={16}
                  />
                }
              />
              <YesNoInput
                error={errorFields.includes("seen_psychiatrist")}
                name="seen_psychiatrist"
                label={strings.patientRecord.edit.label.seenPsychiatrist}
                value={this.props.selectedPatientData.seen_psychiatrist}
                changehandler={this.props.handleYesNoChange}
                required
              />
              <YesNoInput
                error={errorFields.includes("psychiatric_medications_toggle")}
                name="psychiatric_medications_toggle"
                label={
                  strings.patientRecord.edit.label.psychiatricMedicationsToggle
                }
                value={
                  this.props.selectedPatientData.psychiatric_medications_toggle
                }
                changehandler={this.props.handleYesNoChange}
                required
                showChildWhenValue="Yes"
                childrenFields={["psychiatric_medications"]}
                childElement={
                  <Form.TextArea
                    label={
                      strings.patientRecord.edit.label.psychiatricMedications
                    }
                    error={errorFields.includes("psychiatric_medications")}
                    name="psychiatric_medications"
                    onChange={this.props.handleChange}
                    placeholder={strings.patientRecord.edit.placeholder.details}
                    required
                    value={
                      this.props.selectedPatientData.psychiatric_medications ||
                      ""
                    }
                    rows={2}
                    width={16}
                  />
                }
              />
              <div className="lifestyleHistory patientSectionHeader">
                {strings.patientRecord.header.pregnancy}
              </div>
              <YesNoInput
                error={errorFields.includes("pregnancy")}
                name="pregnancy"
                label={strings.patientRecord.edit.label.pregnancy}
                value={this.props.selectedPatientData.pregnancy}
                changehandler={this.props.handleYesNoChange}
                required
              />
              <YesNoInput
                error={errorFields.includes("breastfeeding")}
                name="breastfeeding"
                label={strings.patientRecord.edit.label.breastfeeding}
                value={this.props.selectedPatientData.breastfeeding}
                changehandler={this.props.handleYesNoChange}
                required
              />
              <div className="lifestyleHistory patientSectionHeader">
                {strings.patientRecord.header.lifestyleHistory}
              </div>
              <YesNoInput
                error={errorFields.includes("smoked_previously")}
                name="smoked_previously"
                label={strings.patientRecord.edit.label.patientHasSmoked}
                value={this.props.selectedPatientData.smoked_previously}
                changehandler={this.props.handleYesNoChange}
                required
              />
              <YesNoInput
                error={errorFields.includes("smokes_currently")}
                name="smokes_currently"
                label={strings.patientRecord.edit.label.patientDoesSmoke}
                value={this.props.selectedPatientData.smokes_currently}
                changehandler={this.props.handleYesNoChange}
                required
              />
              <YesNoInput
                error={errorFields.includes("uses_sunbeds")}
                name="uses_sunbeds"
                label={strings.patientRecord.edit.label.usesSunbeds}
                value={this.props.selectedPatientData.uses_sunbeds}
                changehandler={this.props.handleYesNoChange}
                required
              />
              <YesNoInput
                error={errorFields.includes("gets_suntans")}
                name="gets_suntans"
                label={strings.patientRecord.edit.label.tansFace}
                value={this.props.selectedPatientData.gets_suntans}
                changehandler={this.props.handleYesNoChange}
                required
              />
              <div className="aestheticHistory patientSectionHeader">
                {strings.patientRecord.header.treatmentHistory}
              </div>
              <YesNoInput
                error={errorFields.includes("botox_previous_use")}
                name="botox_previous_use"
                label={strings.patientRecord.edit.label.botoxPreviousUse}
                value={this.props.selectedPatientData.botox_previous_use}
                changehandler={this.props.handleYesNoChange}
                required
                showChildWhenValue="Yes"
                childrenFields={[
                  "botox_number_of_past_treatments",
                  "botox_when_last_treated",
                  "botox_complications",
                ]}
                childElement={
                  <React.Fragment>
                    <Form.Group>
                      <Form.Input
                        label={
                          strings.patientRecord.edit.label
                            .botoxNumberOfPastTreatments
                        }
                        error={errorFields.includes(
                          "botox_number_of_past_treatments"
                        )}
                        name="botox_number_of_past_treatments"
                        onChange={this.props.handleChange}
                        required
                        value={
                          this.props.selectedPatientData
                            .botox_number_of_past_treatments || ""
                        }
                        width={4}
                        type="number"
                      />
                    </Form.Group>
                    <Form.Group>
                      <DatePicker
                        label={
                          strings.patientRecord.edit.label.botoxWhenLastTreated
                        }
                        error={errorFields.includes("botox_when_last_treated")}
                        name="botox_when_last_treated"
                        closable={true}
                        value={
                          this.props.selectedPatientData
                            .botox_when_last_treated || ""
                        }
                        onChange={this.props.handleDateChange}
                        onKeyDown={this.props.handleDateKeyDown}
                        required
                        noFutureDates
                        type="number"
                      />
                    </Form.Group>
                    <Form.Group>
                      <YesNoInput
                        error={errorFields.includes("botox_complications")}
                        name="botox_complications"
                        label={
                          strings.patientRecord.edit.label.botoxComplications
                        }
                        value={
                          this.props.selectedPatientData.botox_complications
                        }
                        changehandler={this.props.handleYesNoChange}
                        required
                      />
                    </Form.Group>
                  </React.Fragment>
                }
              />
              <YesNoInput
                error={errorFields.includes("dermal_fillers_previous_use")}
                name="dermal_fillers_previous_use"
                label={
                  strings.patientRecord.edit.label.dermalFillersPreviousUse
                }
                value={
                  this.props.selectedPatientData.dermal_fillers_previous_use
                }
                changehandler={this.props.handleYesNoChange}
                required
                showChildWhenValue="Yes"
                childrenFields={[
                  "dermal_fillers_number_of_past_treatments",
                  "dermal_fillers_when_last_treated",
                  "dermal_filler_complications",
                ]}
                childElement={
                  <React.Fragment>
                    <Form.Group>
                      <Form.Input
                        label={
                          strings.patientRecord.edit.label
                            .dermalFillersNumberOfPastTreatments
                        }
                        error={errorFields.includes(
                          "dermal_fillers_number_of_past_treatments"
                        )}
                        name="dermal_fillers_number_of_past_treatments"
                        onChange={this.props.handleChange}
                        required
                        value={
                          this.props.selectedPatientData
                            .dermal_fillers_number_of_past_treatments || ""
                        }
                        width={4}
                        type="number"
                      />
                    </Form.Group>
                    <Form.Group>
                      <DatePicker
                        label={
                          strings.patientRecord.edit.label
                            .dermalFillersWhenLastTreated
                        }
                        error={errorFields.includes(
                          "dermal_fillers_when_last_treated"
                        )}
                        name="dermal_fillers_when_last_treated"
                        closable={true}
                        value={
                          this.props.selectedPatientData
                            .dermal_fillers_when_last_treated || ""
                        }
                        onChange={this.props.handleDateChange}
                        onKeyDown={this.props.handleDateKeyDown}
                        required
                        noFutureDates
                        type="number"
                      />
                    </Form.Group>
                    <Form.Group>
                      <YesNoInput
                        error={errorFields.includes(
                          "dermal_fillers_complications"
                        )}
                        name="dermal_fillers_complications"
                        label={
                          strings.patientRecord.edit.label
                            .dermalFillersComplications
                        }
                        value={
                          this.props.selectedPatientData
                            .dermal_fillers_complications
                        }
                        changehandler={this.props.handleYesNoChange}
                        required
                      />
                    </Form.Group>
                    <CheckboxGroup
                      label={
                        strings.patientRecord.edit.label.dermalFillersTypes
                      }
                      required
                      horizontal
                      error={errorFields.includes("dermal_filler_types")}
                      group="dermal_filler_types"
                      data={this.props.selectedPatientData}
                      handleCheckboxChange={
                        this.props.handleCheckboxGroupChange
                      }
                      options={dermalFillerTypeOptions}
                    />
                  </React.Fragment>
                }
              />
              <CheckboxGroup
                label={
                  strings.patientRecord.edit.label.previousProceduresQuestion
                }
                horizontal
                error={errorFields.includes("previous_procedures")}
                group="previous_procedures"
                data={this.props.selectedPatientData}
                handleCheckboxChange={this.props.handleCheckboxGroupChange}
                options={previousProcedureOptions}
              />

              <div className="aestheticHistory patientSectionHeader">
                {strings.patientRecord.header.examinationNotesHistory}
              </div>

              <Form.Checkbox
                error={errorFields.includes("has_had_examination")}
                disabled={
                  this.props.mode === "view" || this.props.mode === "template"
                }
                label={strings.patientRecord.edit.label.hasHadExamination}
                name="has_had_examination"
                checked={
                  this.props.selectedPatientData.has_had_examination || false
                }
                onChange={this.props.handleCheckboxChange}
              />

              {this.props.selectedPatientData.has_had_examination && (
                <ExaminationNotesEdit
                  mode={this.props.mode}
                  selectedPatientData={this.props.selectedPatientData}
                  errorFields={errorFields}
                  handleChange={this.props.handleChange}
                  handleCheckboxGroupChange={
                    this.props.handleCheckboxGroupChange
                  }
                  handleYesNoChange={this.props.handleYesNoChange}
                />
              )}
            </div>
          </fieldset>
        </Form>
        <Divider hidden />
        {this.props.selectedPatientData.doctor_signature_name != null &&
          this.props.selectedPatientData.doctor_signature_path != null &&
          this.props.selectedPatientData.doctor_signature_applied_date !=
            null && (
            <DoctorSignatureContainer
              containerWidth={240}
              containerHeight={150}
              dateDoctorSigned={
                this.props.selectedPatientData.doctor_signature_applied_date
              }
              doctorName={this.props.selectedPatientData.doctor_signature_name}
              formId={null}
              headers={null}
              signaturePathAttachedToForm={
                this.props.selectedPatientData.doctor_signature_path
              }
            />
          )}
        <PatientTabControls
          patientIsActive={this.props.selectedPatientData["active"]}
          {...this.props}
        />
      </React.Fragment>
    );
  };
}

EditModeMedicalRecordTab.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  selectedPatientData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default EditModeMedicalRecordTab;
