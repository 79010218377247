import React from "react";
import PropTypes from "prop-types";
import { Container } from "semantic-ui-react";
import { strings } from "../resources";
import { connect } from "react-redux";
import { authActions } from "../../App/store/actions/auth.actions";
import { userActions } from "../../Users/store/actions/user.actions";
import { withRouter } from "react-router-dom";
import PreConsultationVerify from "../components/PreConsultationVerify";
import comparePatientNameAndDob from "../helpers/ComparePatientNameAndDob";
import formatDateInput from "../helpers/FormatDateInput";
import "./css/PreConsultation.css";

class PreConsultationVerifyContainer extends React.Component {
  state = {
    patientId: this.props.location.state
      ? this.props.location.state.patientId
      : null,
    patientData: this.props.location.state
      ? this.props.location.state.patientData
      : null,
    selectedFormTypes: this.props.location.state
      ? this.props.location.state.selectedFormTypes
      : [],
    selectedFormNames: this.props.location.state
      ? this.props.location.state.selectedFormNames
      : [],
    verifyData: {},
    cancelModalOpen: false,
    error: false
  };

  componentWillMount = () => {
    // if page has loaded without patient data or ID, send user back to patients list
    if (!this.state.patientId || !this.state.patientData) {
      this.props.history.push({
        pathname: "/patients/"
      });
    }
  };

  toggleCancelModalVisibility = () => {
    this.setState({
      cancelModalOpen: !this.state.cancelModalOpen
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "cancelModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  handleChange = (e, data) => {
    const newData = { ...this.state.verifyData, [data.name]: data.value };
    this.setState({
      verifyData: newData,
      error: false
    });
  };

  handleDateChange = async (event, data) => {
    let dataToSubmit = "";
    let currentValue = this.state.verifyData[data.name];
    // Only look to add dashes if there is something to format, and if the user
    // is typing rather than deleting
    if (currentValue && data.value.length > 0 && data.value.length > currentValue.length) {
      dataToSubmit = formatDateInput(data.value);
    } else {
      dataToSubmit = data.value;
    }
    const newData = { ...this.state.verifyData, [data.name]: dataToSubmit };
    this.setState({
      verifyData: newData,
      error: false
    });
  };

  handleDateKeyDown = event => {
    // Filter the input to the date box - restrict it to numbers, the dash separator,
    // the backspace key (keyCode 8), and the delete key (keyCode 46)
    if (isNaN(event.key) && event.key !== "-" && event.keyCode !== 8 && event.keyCode !== 46) {
      event.preventDefault();
    }
  };

  handleSubmit = () => {
    // Check if the details provided match the client details
    let patientDataMatch = comparePatientNameAndDob(
      this.state.verifyData["first_name"],
      this.state.verifyData["surname"],
      this.state.verifyData["date_of_birth"],
      this.state.patientData["first_name"],
      this.state.patientData["surname"],
      this.state.patientData["date_of_birth"]
    );

    if (patientDataMatch) {
      this.openPreConsultationProgress();
    } else {
      this.setState({
        error: true
      });
    }
  };

  handleCancel = () => {
    this.openPreConsultationComplete();
  };

  openPreConsultationProgress = async () => {
    // send user to fill in the forms
    this.props.history.push({
      pathname: "/forms/preconsultation/progress/",
      state: {
        patientId: this.state.patientId,
        patientData: this.state.patientData,
        selectedFormTypes: this.state.selectedFormTypes,
        selectedFormNames: this.state.selectedFormNames,
        mode: "create"
      }
    });
  };

  openPreConsultationComplete = async () => {
    // send user to fill in the forms
    this.props.history.push({
      pathname: "/forms/preconsultation/complete/",
      state: {
        patientId: this.state.patientId
      }
    });
  };

  render = () => {
    return (
      <React.Fragment>
        <Container className="preConsultationVerifyContainer">
          <div className="preconsultationHeader">
            {strings.header.verifyPreconsultation}
          </div>
          <PreConsultationVerify
            data={this.state.verifyData}
            error={this.state.error}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            handleDateKeyDown={this.handleDateKeyDown}
            handleSubmit={this.handleSubmit}
            handleModalConfirm={this.handleModalConfirm}
            toggleCancelModalVisibility={this.toggleCancelModalVisibility}
            cancelModalOpen={this.state.cancelModalOpen}
          />
        </Container>
      </React.Fragment>
    );
  };
}

PreConsultationVerifyContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
  getPatientCredentials: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: (username, password) => {
      dispatch(authActions.signIn(username, password));
    },
    getPatientCredentials: headers => {
      return dispatch(userActions.getPatientCredentials(headers));
    }
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(PreConsultationVerifyContainer)
);
