import React from "react";
import PropTypes from "prop-types";
import { Tab, Icon, Header } from "semantic-ui-react";
import PersonalDetailsTab from "./PersonalDetailsTab";
import ViewModeMedicalRecordTab from "./ViewModeMedicalRecordTab";
import EditModeMedicalRecordTab from "./EditModeMedicalRecordTab";
import { strings } from "../resources";
import { FormListContainer } from "../../Forms/containers";
import { TreatmentListContainer } from "../../Treatments/containers";
import PatientImages from "../../Images/components/PatientImages";

class PatientView extends React.Component {
  render = () => {
    const personalDetailsTab = () => (
      <Tab.Pane attached={false}>
        <PersonalDetailsTab {...this.props} />
      </Tab.Pane>
    );

    const medicalRecordTab = () => (
      <Tab.Pane attached={false}>
        {this.props.mode === "view" && (
          <ViewModeMedicalRecordTab {...this.props} />
        )}
        {this.props.mode === "edit" && (
          <EditModeMedicalRecordTab {...this.props} />
        )}
      </Tab.Pane>
    );

    const treatmentsTab = () => (
      <Tab.Pane attached={false}>
        <TreatmentListContainer
          patientId={this.props.selectedPatientData.id}
          {...this.props}
        />
      </Tab.Pane>
    );

    const formListTab = () => (
      <Tab.Pane attached={false}>
        <FormListContainer
          patientData={this.props.selectedPatientData}
          {...this.props}
        />
      </Tab.Pane>
    );

    const photoTab = () => (
      <Tab.Pane attached={false}>
        <PatientImages
          headers={this.props.headers}
          patientId={this.props.selectedPatientData["id"]}
        />
      </Tab.Pane>
    );

    const panes = [
      {
        menuItem: "Original Medical Record",
        render: medicalRecordTab
      },
      {
        menuItem: "Personal Details",
        render: personalDetailsTab
      },
      {
        menuItem: "Treatments",
        render: treatmentsTab
      },
      {
        menuItem: "Forms",
        render: formListTab
      },
      {
        menuItem: "Photographs",
        render: photoTab
      }
    ];

    const headerText = `${this.props.selectedPatientData.first_name} ${this.props.selectedPatientData.surname}, DOB ${this.props.selectedPatientData.date_of_birth}`;
    const pregnantWarning =
      this.props.selectedPatientData.pregnancy === "Yes" ||
      this.props.selectedPatientData.breastfeeding === "Yes";

    return (
      <div className="viewPatientContainer" id="formDiv">
        <Header as="h3">
          <Icon name="user" />
          <Header.Content>{headerText}</Header.Content>
        </Header>
        {pregnantWarning && (
          <Header as="h3" color="red">
            <Icon name="exclamation" />
            {strings.patientRecord.header.pregnancyWarning}
          </Header>
        )}
        <Tab
          menu={{ widths: panes.length, attached: false, tabular: false }}
          panes={panes}
          onTabChange={this.props.handleTabChange}
          // If the patient ID of the last tab change is the one currently open, navigate to last open tab, otherwise go to first tab
          activeIndex={this.props.selectedTab.tabNumber || 0}
        />
      </div>
    );
  };
}

PatientView.propTypes = {
  selectedPatientData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default PatientView;
