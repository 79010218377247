import React from "react";
import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import ImageAnnotator from "../components/ImageAnnotator";
import { strings } from "../resources";

class ImageAnnotatorContainer extends React.Component {
  /**
   *  ImageAnnotatorContainer
   *
   * Used to annotated images, or allow an image to be annotated upon and
   * uploaded to S3
   *
   */

  render = () => {
    return (
      <React.Fragment>
        {this.props.loadingImageData ? (
          <Loader active>{strings.header.loading}</Loader>
        ) : (
          <ImageAnnotator
            annotationImagePath={this.props.annotationImagePath}
            backgroundImage={this.props.backgroundImage}
            blank={this.props.blank}
            header={this.props.header}
            headers={this.props.headers}
            annotationName={this.props.annotationName}
            readOnly={this.props.readOnly}
            handleAnnotationWasModified={this.props.handleAnnotationWasModified}
          />
        )}
      </React.Fragment>
    );
  };
}

ImageAnnotatorContainer.propTypes = {
  annotationImagePath: PropTypes.string.isRequired, //path on S3 of where either to store or retrieve the image *required*
  backgroundImage: PropTypes.string.isRequired, // URL of image to appear on the background of the canvas
  blank: PropTypes.bool.isRequired, // whether the container should appear empty
  header: PropTypes.string, // text to appear above the image
  headers: PropTypes.object.isRequired, // headers to put on Redux/API requests
  readOnly: PropTypes.bool.isRequired, // whether or not the image can be annotated on
  annotationName: PropTypes.string.isRequired,
  handleAnnotationWasModified: PropTypes.func.isRequired, //This allows the data records to include info about which images were annotated
};

const mapStateToProps = (state) => {
  const { images } = state;
  const {
    loadingImageData,
  } = images;
  return {
    loadingImageData,
  };
};

export default connect(
  mapStateToProps,
  {}
)(ImageAnnotatorContainer);
