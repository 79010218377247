import moment from "moment-timezone";
import { Auth } from "aws-amplify";
import { User, SystemHeaders } from "../classes";
import { userPool } from "../../_config/aws";
import { systemHeaders } from "../../_config/systemHeaders";

const generateSystemHeaders = ({ userid, group, username, jwtToken }) => {
  return new Promise((resolve, reject) => {
    let headers = Object.assign({}, systemHeaders, {
      systemHeader_client: "DrDM",
      systemHeader_sysref: "DrDMWebApp",
      systemHeader_subsysref: "UserLogin",
      systemHeader_userid: userid,
      systemHeader_permissions: group,
      systemHeader_username: username,
      systemHeader_date: moment()
        .tz("Europe/London")
        .format("DD-MM-YYYY HH:mm:ss"),
      systemHeader_correlationId: "1",
      systemHeader_parents: "None",
      Authorization: jwtToken,
    });

    try {
      SystemHeaders.store({
        headers: headers,
      });
      // Remove authorization header before it is put into the Redux store
      // Done to prevent discrepancies from the same data being held in different places
      delete headers.Authorization;
      resolve(headers);
    } catch (error) {
      reject(error);
    }
  });
};

const setSystemHeader = (header, value) => {
  return new Promise((resolve, reject) => {
    // load headers from localStorage
    let state = SystemHeaders.load();

    // set (or update) the header
    state.headers[header] = value;

    // update "systemHeader_date" header with current timestamp
    state.headers["systemHeader_date"] = moment()
      .tz("Europe/London")
      .format("DD-MM-YYYY HH:mm:ss");

    try {
      // save headers to localStorage and return them
      SystemHeaders.store(state);
      resolve(state.headers);
    } catch (error) {
      reject(error);
    }
  });
};

const loadSystemHeaders = () => {
  return SystemHeaders.load();
};

const signUp = (
  username,
  password,
  email,
  role,
  signature_path,
  given_name,
  family_name
) => {
  return new Promise((resolve, reject) => {
    Auth.signUp({
      username,
      password,
      attributes: {
        email,
        "custom:role": role,
        "custom:signature_path": signature_path,
        given_name,
        family_name,
      },
    })
      .then((user) => {
        resolve(user);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const signInWithLink = (usernamePasswordOpts) => {
  return new Promise((resolve, reject) => {
    const userPromise = Auth.signIn(usernamePasswordOpts);
    const userAttributesPromise = userPromise.then((user) => {
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        reject("new password is needed");
      }
      return Auth.userAttributes(user);
    });
    const currentSessionPromise = userPromise.then((user) => {
      return Auth.currentSession();
    });

    Promise.all([userPromise, userAttributesPromise, currentSessionPromise])
      .then((values) => {
        const user = values[0];
        const role = values[1].find((item) => item.Name === "custom:role");
        const signaturePath = values[1].find(
          (item) => item.Name === "custom:signature_path"
        );
        const givenName = values[1].find((item) => item.Name === "given_name");
        const familyName = values[1].find(
          (item) => item.Name === "family_name"
        );
        const jwtToken = values[2].getIdToken().getJwtToken();

        const userDetails = {
          userId: user.getUsername(),
          username: user.getUsername(),
          group: role.Value,
          signaturePath: signaturePath.Value,
          givenName: givenName.Value,
          familyName: familyName.Value,
          jwtToken: jwtToken,
        };
        resolve(userDetails);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const signIn = (username, password) => {
  return new Promise((resolve, reject) => {
    const userPromise = Auth.signIn(username, password);
    const userAttributesPromise = userPromise.then((user) => {
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        reject("new password is needed");
      }
      return Auth.userAttributes(user);
    });
    const currentSessionPromise = userPromise.then((user) => {
      return Auth.currentSession();
    });

    Promise.all([userPromise, userAttributesPromise, currentSessionPromise])
      .then((values) => {
        const user = values[0];
        const role = values[1].find((item) => item.Name === "custom:role");
        const signaturePath = values[1].find(
          (item) => item.Name === "custom:signature_path"
        );
        const givenName = values[1].find((item) => item.Name === "given_name");
        const familyName = values[1].find(
          (item) => item.Name === "family_name"
        );
        const jwtToken = values[2].getIdToken().getJwtToken();

        const userDetails = {
          userId: user.getUsername(),
          username: user.getUsername(),
          group: role.Value,
          signaturePath: signaturePath.Value,
          givenName: givenName.Value,
          familyName: familyName.Value,
          jwtToken: jwtToken,
        };
        resolve(userDetails);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const signOut = () => {
  return new Promise((resolve, reject) => {
    Auth.signOut().catch((error) => {
      console.log(error);
      reject(error);
    });

    try {
      let cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.signOut();
      }
      SystemHeaders.purge();
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

const getCurrentUserSession = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        resolve(session);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getCurrentAuthenticatedUser = () => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const refreshTokens = () => {
  return new Promise((resolve, reject) => {
    try {
      let cognitoUser = User.refreshTokens();
      resolve(cognitoUser);
    } catch (error) {
      reject(error);
    }
  });
};

const changePassword = (oldPassword, newPassword, newPasswordConfirmation) => {
  return new Promise((resolve, reject) => {
    try {
      User.changePassword(oldPassword, newPassword, newPasswordConfirmation);
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const authService = {
  generateSystemHeaders,
  setSystemHeader,
  loadSystemHeaders,
  refreshTokens,
  signUp,
  signInWithLink,
  signIn,
  signOut,
  getCurrentUserSession,
  getCurrentAuthenticatedUser,
  changePassword,
};
