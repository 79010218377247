export const fieldsToCopy = [
  "diagnosis",
  "diagnosis_other",
  "doctors_notes",
  "examination_abnormal",
  "examination_abnormal_conditions",
  "examination_facial_volume",
  "examination_facial_volume_details",
  "examination_fitzpatrick_skin_type",
  "examination_glogau_skin_group",
  "examination_skin_condition",
  "examination_skin_conditions",
  "examination_smoking",
  "examination_smoking_conditions",
  "treatment_options",
  "treatment_options_other",
  "treatment_plan",
  "treatment_plan_other",
];

export const fieldsToInsert = [
  "has_had_examination",
  "examination_fitzpatrick_skin_type",
  "examination_glogau_skin_group",
  "examination_skin_condition",
  "examination_skin_conditions",
  "examination_smoking",
  "examination_smoking_conditions",
  "examination_facial_volume",
  "examination_facial_volume_details",
  "examination_abnormal",
  "examination_abnormal_conditions",
];