export const formActionTypes = {
  GET_FORM_BY_ID_REQUEST: "GET_FORM_BY_ID_REQUEST",
  GET_FORM_BY_ID_SUCCESS: "GET_FORM_BY_ID_SUCCESS",
  GET_FORM_BY_ID_FAILURE: "GET_FORM_BY_ID_FAILURE",

  GET_FORMS_REQUEST: "GET_FORMS_REQUEST",
  GET_FORMS_SUCCESS: "GET_FORMS_SUCCESS",
  GET_FORMS_FAILURE: "GET_FORMS_FAILURE",

  GET_FORMS_BY_TYPE_REQUEST: "GET_FORMS_BY_TYPE_REQUEST",
  GET_FORMS_BY_TYPE_SUCCESS: "GET_FORMS_BY_TYPE_SUCCESS",
  GET_FORMS_BY_TYPE_FAILURE: "GET_FORMS_BY_TYPE_FAILURE",

  CREATE_FORM_REQUEST: "CREATE_FORM_REQUEST",
  CREATE_FORM_SUCCESS: "CREATE_FORM_SUCCESS",
  CREATE_FORM_FAILURE: "CREATE_FORM_FAILURE",

  UPDATE_SELECTED_FORM_REQUEST: "UPDATE_SELECTED_FORM_REQUEST",
  UPDATE_SELECTED_FORM_SUCCESS: "UPDATE_SELECTED_FORM_SUCCESS",
  UPDATE_SELECTED_FORM_FAILURE: "UPDATE_SELECTED_FORM_FAILURE",

  DELETE_FROM_SELECTED_FORM_REQUEST: "DELETE_FROM_SELECTED_FORM_REQUEST",
  DELETE_FROM_SELECTED_FORM_SUCCESS: "DELETE_FROM_SELECTED_FORM_SUCCESS",
  DELETE_FROM_SELECTED_FORM_FAILURE: "DELETE_FROM_SELECTED_FORM_FAILURE",

  DELETE_FORM_REQUEST: "DELETE_FORM_REQUEST",
  DELETE_FORM_SUCCESS: "DELETE_FORM_SUCCESS",
  DELETE_FORM_FAILURE: "DELETE_FORM_FAILURE",

  VALIDATE_FORM_REQUEST: "VALIDATE_FORM_REQUEST",
  VALIDATE_FORM_SUCCESS: "VALIDATE_FORM_SUCCESS",
  VALIDATE_FORM_FAILURE: "VALIDATE_FORM_FAILURE",

  CLEAR_SELECTED_FORM_REQUEST: "CLEAR_SELECTED_FORM_REQUEST",
  CLEAR_SELECTED_FORM_SUCCESS: "CLEAR_SELECTED_FORM_SUCCESS",
  CLEAR_SELECTED_FORM_FAILURE: "CLEAR_SELECTED_FORM_FAILURE",

  UPDATE_FORM_REQUEST: "UPDATE_FORM_REQUEST",
  UPDATE_FORM_SUCCESS: "UPDATE_FORM_SUCCESS",
  UPDATE_FORM_FAILURE: "UPDATE_FORM_FAILURE",

  APPLY_DOCTOR_SIGNATURE_REQUEST: "APPLY_DOCTOR_SIGNATURE_REQUEST",
  APPLY_DOCTOR_SIGNATURE_SUCCESS: "APPLY_DOCTOR_SIGNATURE_SUCCESS",
  APPLY_DOCTOR_SIGNATURE_FAILURE: "APPLY_DOCTOR_SIGNATURE_FAILURE"
};
