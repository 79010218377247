import { strings } from "../";
import { regexes } from "../Regexes";

const dateRegex = new RegExp(regexes.dateRegex)

const returningPatientSchema = {
  previous_treatment_satisfaction: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .previous_treatment_satisfaction,
    required: true
  },
  previous_treatment_complications_yes_no: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .previous_treatment_complications_yes_no,
    required: true
  },
  previous_treatment_complications: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .previous_treatment_complications,
    requireIf: data => {
      return getRequiredDependent(
        "previous_treatment_complications_yes_no",
        "Yes",
        data
      );
    },
    validate: (field, data) => {
      const atLeastOneOptionSelected = Object.keys(data[field]).some(item => {
        return data[field][item].checked;
      });
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    }
  },
  previous_treatment_complications_other: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .previous_treatment_complications_other,
    requireIf: data => {
      return (
        data.hasOwnProperty("previous_treatment_complications") &&
        data.previous_treatment_complications &&
        data.previous_treatment_complications.other &&
        data.previous_treatment_complications.other.checked === true
      );
    }
  },
  previous_treatment_confidence: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.previous_treatment_confidence,
    required: true
  },
  medical_conditions_changes_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .medical_conditions_changes_toggle,
    required: true
  },
  medical_conditions: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.medical_conditions,
    requireIf: data => {
      return getRequiredDependent(
        "medical_conditions_changes_toggle",
        "Yes",
        data
      );
    },
    validate: (field, data) => {
      const atLeastOneOptionSelected = data[field]
        ? Object.keys(data[field]).some(item => {
            return data[field][item].checked;
          })
        : false;
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    }
  },
  medical_conditions_other: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.medical_conditions_other,
    requireIf: data => {
      return (
        data.hasOwnProperty("medical_conditions") &&
        data.medical_conditions &&
        data.medical_conditions.other &&
        data.medical_conditions.other.checked === true
      );
    }
  },
  dermatological_diseases_changes_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .dermatological_diseases_changes_toggle,
    required: true
  },
  dermatological_diseases: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.dermatological_diseases,
    requireIf: data => {
      return getRequiredDependent(
        "dermatological_diseases_changes_toggle",
        "Yes",
        data
      );
    },
    validate: (field, data) => {
      const atLeastOneOptionSelected = data[field]
        ? Object.keys(data[field]).some(item => {
            return data[field][item].checked;
          })
        : false;
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    }
  },
  dermatological_diseases_other: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.dermatological_diseases_other,
    requireIf: data => {
      return (
        data.hasOwnProperty("dermatological_diseases") &&
        data.dermatological_diseases &&
        data.dermatological_diseases.other &&
        data.dermatological_diseases.other.checked === true
      );
    }
  },
  neurological_conditions_changes_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .neurological_conditions_changes_toggle,
    required: true
  },
  neurological_conditions: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.neurological_conditions,
    requireIf: data => {
      return getRequiredDependent(
        "neurological_conditions_changes_toggle",
        "Yes",
        data
      );
    },
    validate: (field, data) => {
      const atLeastOneOptionSelected = data[field]
        ? Object.keys(data[field]).some(item => {
            return data[field][item].checked;
          })
        : false;
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    }
  },
  neurological_conditions_other: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.neurological_conditions_other,
    requireIf: data => {
      return (
        data.hasOwnProperty("neurological_conditions") &&
        data.neurological_conditions &&
        data.neurological_conditions.other &&
        data.neurological_conditions.other.checked === true
      );
    }
  },
  supplements_changes_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.supplements_changes_toggle,
    required: true
  },
  supplements: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.supplements,
    requireIf: data => {
      return getRequiredDependent("supplements_changes_toggle", "Yes", data);
    }
  },
  regular_medications_changes_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .regular_medications_changes_toggle,
    required: true
  },
  regular_medications: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.regular_medications,
    requireIf: data => {
      return getRequiredDependent(
        "regular_medications_changes_toggle",
        "Yes",
        data
      );
    }
  },
  drug_allergies_changes_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.drug_allergies_changes_toggle,
    required: true
  },
  drug_allergies: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.drug_allergies,
    requireIf: data => {
      return getRequiredDependent("drug_allergies_changes_toggle", "Yes", data);
    }
  },
  general_allergies_changes_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .general_allergies_changes_toggle,
    required: true
  },
  general_allergies: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.general_allergies,
    requireIf: data => {
      return getRequiredDependent(
        "general_allergies_changes_toggle",
        "Yes",
        data
      );
    }
  },
  reasons: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.reasons,
    required: true,
    validate: (field, data) => {
      const atLeastOneOptionSelected = Object.keys(data[field]).some(item => {
        return data[field][item].checked;
      });
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    }
  },
  reasons_other: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.other_reason_details,
    requireIf: data => {
      return (
        data.hasOwnProperty("reasons") &&
        data.reasons.other &&
        data.reasons.other.checked === true
      );
    }
  },
  bothered_by_appearance: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.bothered_by_appearance,
    required: true
  },
  how_often_appearance: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.how_often_appearance,
    required: true
  },
  leave_house_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.leave_house_toggle,
    required: true
  },
  avoids_social_situations_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .avoids_social_situations_toggle,
    required: true
  },
  avoids_photographs_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.avoids_photographs_toggle,
    required: true
  },
  avoids_certain_angles_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.avoids_certain_angles_toggle,
    required: true
  },
  wears_makeup_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.wears_makeup_toggle,
    required: true
  },
  signed_by_patient: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.signed_by_patient
  },
  signature_data_url: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.signature,
    required: true,
    validate: (field, data) => {
      if (Object.keys(data[field]).length === 0) {
        return strings.form.validation.errorMessageReason.missingSignature;
      } else {
        return true;
      }
    }
  },
  date_patient_signed: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.date_patient_signed,
    validate: (field, data) => {
      if (dateRegex.test(data[field])) {
        return true
      } else {
        return strings.form.validation.errorMessageReason.wrongDateFormat
      }
    }
  }
};

const getRequiredDependent = (requiredIfField, requiredIfValue, data) => {
  // a field is required if it's dependent field has a value and that value is not equal to requiredIfValue
  if (data.hasOwnProperty(requiredIfField)) {
    if (data[requiredIfField] === requiredIfValue) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export default returningPatientSchema;
