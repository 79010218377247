import React from "react";
import PropTypes from "prop-types";
import { Form, List } from "semantic-ui-react";
import { strings } from "../../resources";
import {
  SignatureFieldContainer,
  DoctorSignatureContainer,
} from "../../../Images/containers";
import { DatePicker } from "../ui";
import { dateEpochToDateTimeString } from "../../../App/helpers/DateTimeFormatConversion";

const complications = [
  {
    header: strings.form.consentWording.FaceAndNeckBleedingHeader,
    content: strings.form.consentWording.FaceAndNeckBleedingContent,
  },
  {
    header: strings.form.consentWording.FaceAndNeckInfectionHeader,
    content: strings.form.consentWording.FaceAndNeckInfectionContent,
  },
  {
    header: strings.form.consentWording.FaceAndNeckPainHeader,
    content: strings.form.consentWording.FaceAndNeckPainContent,
  },
  {
    header: strings.form.consentWording.FaceAndNeckNumbnessHeader,
    content: strings.form.consentWording.FaceAndNeckNumbnessContent,
  },
  {
    header: strings.form.consentWording.FaceAndNeckNerveDamageHeader,
    content: strings.form.consentWording.FaceAndNeckNerveDamageContent,
  },
  {
    header: strings.form.consentWording.FaceAndNeckAsymmetryHeader,
    content: strings.form.consentWording.FaceAndNeckAsymmetryContent,
  },
  {
    header: strings.form.consentWording.FaceAndNeckScarringHeader,
    content: strings.form.consentWording.FaceAndNeckScarringContent,
  },
  {
    header: strings.form.consentWording.FaceAndNeckEarDeformityHeader,
    content: strings.form.consentWording.FaceAndNeckEarDeformityContent,
  },
  {
    header: strings.form.consentWording.FaceAndNeckReoperationHeader,
    content: strings.form.consentWording.FaceAndNeckReoperationContent,
  },
];

class FormConsentFaceAndNeck extends React.Component {
  componentWillMount = () => {
    if (this.props.mode === "create" || this.props.mode === "edit") {
      let todaysDateStr = dateEpochToDateTimeString(new Date(), "-");
      this.props.updateSelectedForm("date_patient_signed", todaysDateStr);
    }
  };

  render = () => {
    const errorFields = this.props.validationErrors.map((error) => {
      return error.field;
    });
    return (
      <div className="createFormContainer">
        <div className="patientFormSectionHeader">
          {strings.form.header.consentFaceAndNeck}
        </div>
        <div className="personalInformation">
          <Form.Group>
            <Form.Field
              className="textField"
              content={strings.form.consentWording.FaceAndNeckOpening}
            />
          </Form.Group>
          <List bulleted>
            {complications.map((complication) => (
              <List.Item key={complication.header}>
                <List.Header>{complication.header}</List.Header>
                {complication.content}
              </List.Item>
            ))}
          </List>
          <Form.Group>
            <Form.Field
              className="textField"
              content={strings.form.consentWording.FaceAndNeckFurtherRisks}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              className="textField"
              content={strings.form.consentWording.FaceAndNeckClosing}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label={strings.form.label.patientPrintName}
              error={errorFields.includes("signed_by_patient")}
              name="signed_by_patient"
              onChange={this.props.handleChange}
              placeholder={strings.form.placeholder.patientPrintName}
              required
              value={this.props.selectedFormData.signed_by_patient || ""}
              width={8}
            />
          </Form.Group>
          <SignatureFieldContainer
            blank={this.props.mode === "template"}
            headers={this.props.headers}
            error={errorFields.includes("signature_data_url")}
            readOnly={this.props.mode === "create" ? false : true}
            signatureImagePath={
              this.props.formId ? `signatures/forms/${this.props.formId}` : ""
            }
          />
          <Form.Group>
            <DatePicker
              error={errorFields.includes("date_patient_signed")}
              className="datePatientSigned"
              name="date_patient_signed"
              onChange={this.props.handleDateChange}
              onKeyDown={(e) => this.props.handleDateKeyDown(e)}
              required
              label={strings.form.label.datePatientSigned}
              value={this.props.selectedFormData.date_patient_signed || ""}
            />
          </Form.Group>
          {(this.props.selectedFormData.doctor_signature_path ||
            this.props.mode === "template") && (
            <Form.Group>
              <DoctorSignatureContainer
                containerWidth={240}
                containerHeight={150}
                dateDoctorSigned={this.props.dateDoctorSigned}
                doctorName={this.props.doctorName}
                formId={this.props.formId}
                headers={this.props.headers}
                signaturePathAttachedToForm={
                  this.props.selectedFormData.doctor_signature_path
                }
              />
            </Form.Group>
          )}
        </div>
      </div>
    );
  };
}

FormConsentFaceAndNeck.propTypes = {
  mode: PropTypes.string.isRequired, //create, view or edit
  selectedFormData: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
};

export default FormConsentFaceAndNeck;
