import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { TableFunctions } from "./ui";
import { strings } from "../resources";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      return {
        onClick: () => {
          if (rowInfo) {
            // Change the path if the treatment is a follow up note
            const isFollowUpNote =
              rowInfo.original.selectedTreatmentTypes.length === 1 &&
              rowInfo.original.selectedTreatmentTypes[0].treatment_type ===
                strings.types.followUpNote;
            const pathEnd = isFollowUpNote
              ? `followUpNote/${rowInfo.original.id}`
              : rowInfo.original.id;
            this.props.history.push({
              pathname: `/treatments/${pathEnd}`,
              state: {
                patientId: rowInfo.original.patient_id,
                selectedPatientData: this.props.selectedPatientData,
                treatmentId: rowInfo.original.id,
                tab: "treatments",
                mode: "view",
              },
            });
          }
        },
      };
    }
    return {}; // getTdProps requires a return statement
  };

  render = () => {
    const getAllTreatmentOptions = () => {
      let key = 0;
      let treatmentOptions = [
        {
          key: key,
          text: "All Treatments",
          value: null,
        },
      ];
      let treatmentTypes = this.props.treatmentListData.map((item) => {
        if (Array.isArray(item.selectedTreatmentTypes)) {
          return item.selectedTreatmentTypes
            .map((treatment) => treatment.treatment_type)
            .filter((item) => item !== undefined);
        }
        // Arrow functions requires a return
        return "";
      });

      let treatmentTypesList = []
        .concat(...treatmentTypes)
        .filter((item) => item !== undefined)
        .sort(); //combine any multiple treatment names into a single array, sort and remove any undefined

      let treatmentTypesForPatient = treatmentTypesList.filter(
        (item, pos, self) => self.indexOf(item) === pos //Remove duplicates
      );

      for (let item in treatmentTypesForPatient) {
        key++;
        treatmentOptions.push({
          key: key,
          text: treatmentTypesForPatient[item],
          value: treatmentTypesForPatient[item],
        });
      }
      return treatmentOptions;
    };

    let data = this.props.treatmentListData.filter(
      (treatment) =>
        this.props.selectedTreatmentType === null ||
        (Array.isArray(treatment.selectedTreatmentTypes) &&
          treatment.selectedTreatmentTypes
            .map((item) => item.treatment_type)
            .includes(this.props.selectedTreatmentType))
    );
    data.forEach((treatment) => {
      // Add a string if there is no product
      if (
        !treatment.hasOwnProperty("selected_product_names")
      ) {
        treatment["selected_product_names"] = "No treatment performed";
      }
    });

    const cellTruncate = (length) => {
      return (row) => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    const joinArrayAndTruncateCell = (length) => {
      return (row) => {
        let joinedValueRow = {
          ...row,
          value: row.value
            ? Array.isArray(row.value)
              ? row.value.join(", ")
              : row.value
            : "",
        };
        return cellTruncate(length)(joinedValueRow);
      };
    };

    const selectTreatmentFromArrayAndTruncateCell = (length) => {
      return (row) => {
        let joinedValueRow = {
          ...row,
          value: row.value
            ? Array.isArray(row.value)
              ? row.value.map((item) => item.treatment_type).join(", ")
              : row.value.treatment_type
            : "",
        };
        return cellTruncate(length)(joinedValueRow);
      };
    };

    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          setTreatmentType={this.props.setTreatmentType}
          selectedTreatmentType={this.props.selectedTreatmentType}
          getAllTreatmentOptions={getAllTreatmentOptions}
          patientId={this.props.patientId}
          selectedPatientData={this.props.selectedPatientData}
        />
        <div>
          <ReactTable
            data={data}
            columns={[
              {
                Header: strings.table.header.treatmentType,
                accessor: "selectedTreatmentTypes",
                headerStyle: { textAlign: "left" },
                Cell: selectTreatmentFromArrayAndTruncateCell(40),
              },
              {
                Header: strings.table.header.selectedProducts,
                accessor: "selected_product_names",
                headerStyle: { textAlign: "left" },
                Cell: joinArrayAndTruncateCell(40),
              },
              {
                Header: strings.table.header.doctor,
                accessor: "doctor",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(20),
              },
              {
                Header: strings.table.header.createdDate,
                id: "sortableDate",
                accessor: "created",
                headerStyle: { textAlign: "left" },
                Cell: ({ value, row }) =>
                  String(row["_original"].created_date_formatted),
              },
            ]}
            className="-striped -highlight"
            defaultPageSize={10}
            minRows="1"
            getTdProps={this.getTdProps}
            getTrProps={this.getTrProps}
            noDataText="No results found."
            pageSizeOptions={[10, 20, 30]}
            defaultSorted={[
              {
                id: "sortableDate",
                desc: true,
              },
            ]}
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  treatmentListData: PropTypes.array.isRequired,
  setTreatmentType: PropTypes.func,
  selectedTreatmentType: PropTypes.string,
  patientId: PropTypes.string.isRequired,
  selectedPatientData: PropTypes.object.isRequired,
};

export default TableComponent;
