import LocalizedStrings from "react-localization";

export const enums = new LocalizedStrings({
  en: {
    formType: {
      NEW_PATIENT_REGISTRATION: {
        type: "NEW_PATIENT_REGISTRATION",
        component: "FormNewPatientRegistration",
        link: "newPatientRegistration",
        name: "New Patient Registration",
        currentVersion: "1.0",
      },
      AESTHETIC_INTEREST: {
        type: "AESTHETIC_INTEREST",
        component: "FormAestheticInterest",
        link: "aestheticInterest",
        name: "Aesthetic Interest",
        currentVersion: "1.0",
      },
      FOLLOW_UP: {
        type: "FOLLOW_UP",
        component: "FormFollowUp",
        link: "followUp",
        name: "Follow Up",
        currentVersion: "1.0",
      },
      RETURNING_PATIENT: {
        type: "RETURNING_PATIENT",
        component: "FormReturningPatient",
        link: "returningPatient",
        name: "Returning Patient",
        currentVersion: "1.0",
      },
      CONSENT_COVID: {
        type: "CONSENT_COVID",
        component: "FormConsentCovid",
        link: "consentCovid",
        name: "Consent - Covid",
        currentVersion: "1.0",
      },
      CONSENT_BOTOX: {
        type: "CONSENT_BOTOX",
        component: "FormConsentBotox",
        link: "consentBotox",
        name: "Consent - Botox",
        currentVersion: "1.0",
      },
      CONSENT_UPPER_BLEPHAROPLASTY: {
        type: "CONSENT_UPPER_BLEPHAROPLASTY",
        component: "FormConsentUpperBlepharoplasty",
        link: "consentUpperBlepharoplasty",
        name: "Consent - Upper Blepharoplasty",
        currentVersion: "1.0",
      },
      CONSENT_UPPER_LOWER_BLEPHAROPLASTY: {
        type: "CONSENT_UPPER_LOWER_BLEPHAROPLASTY",
        component: "FormConsentUpperLowerBlepharoplasty",
        link: "consentUpperLowerBlepharoplasty",
        name: "Consent - Upper & Lower Blepharoplasty",
        currentVersion: "1.0",
      },
      CONSENT_CO2RE: {
        type: "CONSENT_CO2RE",
        component: "FormConsentCO2RE",
        link: "consentCO2RE",
        name: "Consent - CO2RE",
        currentVersion: "1.0",
      },
      CONSENT_PROFOUND: {
        type: "CONSENT_PROFOUND",
        component: "FormConsentProfound",
        link: "consentProfound",
        name: "Consent - Profound",
        currentVersion: "1.0",
      },
      CONSENT_FACE_AND_NECK: {
        type: "CONSENT_FACE_AND_NECK",
        component: "FormConsentFaceAndNeck",
        link: "consentFaceAndNeck",
        name: "Consent - Face and Neck",
        currentVersion: "1.0",
      },
      CONSENT_DERMAL_FILLERS: {
        type: "CONSENT_DERMAL_FILLERS",
        component: "FormConsentDermalFillers",
        link: "consentDermalFillers",
        name: "Consent - Dermal Fillers",
        currentVersion: "1.0",
      },
    },
  },
});
