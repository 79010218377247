import { userActionTypes } from "../actions/user.actionTypes";

const initialState = {
  loadingPage: false,
  selectedUserData: {},
  userListData: [{}],
  userToDelete: "",
  result: [],
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.CREATE_USER_REQUEST:
      return { ...state };
    case userActionTypes.CREATE_USER_SUCCESS:
      return { ...state, result: action.data.body };
    case userActionTypes.CREATE_USER_FAILURE:
      return { ...state, error: action.error };

    case userActionTypes.UPDATE_USER_REQUEST:
      return { ...state };
    case userActionTypes.UPDATE_USER_SUCCESS:
      return { ...state, result: action.data };
    case userActionTypes.UPDATE_USER_FAILURE:
      return { ...state, error: action.error };

    case userActionTypes.DELETE_USER_REQUEST:
      return { ...state };
    case userActionTypes.DELETE_USER_SUCCESS:
      return { ...state, result: action.data };
    case userActionTypes.DELETE_USER_FAILURE:
      return { ...state, error: action.error };

    case userActionTypes.GET_USERS_REQUEST:
      return { ...state };
    case userActionTypes.GET_USERS_SUCCESS:
      return { ...state, userListData: action.data.Users };
    case userActionTypes.GET_USERS_FAILURE:
      return { ...state, error: action.error };

    case userActionTypes.GET_USER_BY_ID_REQUEST:
      return { ...state };
    case userActionTypes.GET_USER_BY_ID_SUCCESS:
      return { ...state, selectedUserData: action.data };
    case userActionTypes.GET_USER_BY_ID_FAILURE:
      return { ...state, error: action.error };

    case userActionTypes.UPDATE_SELECTED_USER_REQUEST:
      return { ...state };
    case userActionTypes.UPDATE_SELECTED_USER_SUCCESS:
      return {
        ...state,
        selectedUserData: {
          ...state.selectedUserData,
          [action.key]: action.value,
        },
      };
    case userActionTypes.UPDATE_SELECTED_USER_FAILURE:
      return { ...state };

    case userActionTypes.CLEAR_SELECTED_USER_REQUEST:
      return { ...state };
    case userActionTypes.CLEAR_SELECTED_USER_SUCCESS:
      return { ...state, selectedUserData: {} };
    case userActionTypes.CLEAR_SELECTED_USER_FAILURE:
      return { ...state };

    case userActionTypes.GET_PATIENT_CREDENTIALS_REQUEST:
      return { ...state };
    case userActionTypes.GET_PATIENT_CREDENTIALS_SUCCESS:
      return { ...state };
    case userActionTypes.GET_PATIENT_CREDENTIALS_FAILURE:
      return { ...state, error: action.error };

    case userActionTypes.CREATE_PATIENT_LOGIN_LINK_REQUEST:
      return { ...state };
    case userActionTypes.CREATE_PATIENT_LOGIN_LINK_SUCCESS:
      return { ...state, result: action.data.body };
    case userActionTypes.CREATE_PATIENT_LOGIN_LINK_FAILURE:
      return { ...state, error: action.error };

    case userActionTypes.CHECK_PATIENT_LOGIN_LINK_REQUEST:
      return { ...state, loadingPage: true };
    case userActionTypes.CHECK_PATIENT_LOGIN_LINK_SUCCESS:
      return { ...state, result: action.data, loadingPage: false };
    case userActionTypes.CHECK_PATIENT_LOGIN_LINK_FAILURE:
      return { ...state, error: action.error, loadingPage: false };

    case userActionTypes.CHECK_LOGIN_LINK_FOR_EXISTING_PATIENT_REQUEST:
      return { ...state };
    case userActionTypes.CHECK_LOGIN_LINK_FOR_EXISTING_PATIENT_SUCCESS:
      return {
        ...state,
        isExistingPatient: action.data.isExistingPatient,
        isValidLink: action.data.valid,
      };
    case userActionTypes.CHECK_LOGIN_LINK_FOR_EXISTING_PATIENT_FAILURE:
      return { ...state, error: action.error, isValidLink: false };

    default:
      return state;
  }
};
