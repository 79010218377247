import { strings } from "./Strings";

export const skinConditionOptions = [
  {
    key: "pigmentation",
    label: strings.form.label.skinConditionPigmentation
  },
  {
    key: "telangiectasia",
    label: strings.form.label.skinConditionTelangiectasia
  },
  {
    key: "elastosis",
    label: strings.form.label.skinConditionElastosis
  },
  {
    key: "dynamic_rhytids",
    label: strings.form.label.skinConditionDynamicRhytids
  },
  {
    key: "static_rhytids",
    label: strings.form.label.skinConditionStaticRhytids
  }
];

export const smokingConditionOptions = [
  {
    key: "skin_tone",
    label: strings.form.label.smokingConditionSkinTone
  },
  {
    key: "elastic_recoil",
    label: strings.form.label.smokingConditionElasticRecoil
  },
  {
    key: "perioral_rhytids",
    label: strings.form.label.smokingConditionPerioralRhytids
  }
];

export const facialVolumeDetailOptions = [
  {
    key: "forehead",
    label: strings.form.label.facialVolumeForehead
  },
  {
    key: "temples",
    label: strings.form.label.facialVolumeTemples
  },
  {
    key: "brow",
    label: strings.form.label.facialVolumeBrow
  },
  {
    key: "infraorbital_region",
    label: strings.form.label.facialVolumeInfraorbitalRegion
  },
  {
    key: "malar_eminence",
    label: strings.form.label.facialVolumeMalarEminence
  },
  {
    key: "mid_face",
    label: strings.form.label.facialVolumeMidFace
  },
  {
    key: "lateral_cheek",
    label: strings.form.label.facialVolumeLateralCheek
  },
  {
    key: "submalar",
    label: strings.form.label.facialVolumeSubmalar
  },
  {
    key: "nasogenian",
    label: strings.form.label.facialVolumeNasogenian
  },
  {
    key: "perioral",
    label: strings.form.label.facialVolumePerioral
  },
  {
    key: "mandible",
    label: strings.form.label.facialVolumeMandible
  }
];

export const abnormalConditionOptions = [
  {
    key: "nasal_hump",
    label: strings.form.label.abnormalNasalHump
  },
  {
    key: "pseudohump",
    label: strings.form.label.abnormalPseudohump
  },
  {
    key: "nasal_tip",
    label: strings.form.label.abnormalNasalTip
  },
  {
    key: "thin_lips",
    label: strings.form.label.abnormalThinLips
  },
  {
    key: "chin",
    label: strings.form.label.abnormalChin
  }
];

export const diagnosisOptions = [
  {
    key: "psychological",
    label: strings.form.label.diagnosisPsychological
  },
  {
    key: "photo_damage",
    label: strings.form.label.diagnosisPhotoDamage
  },
  {
    key: "skin_cancer",
    label: strings.form.label.diagnosisSkinCancer
  },
  {
    key: "dysmorphic",
    label: strings.form.label.diagnosisBodyDysmorphic
  },
  {
    key: "lipoatrophy",
    label: strings.form.label.diagnosisFacialLipoatrophy
  },
  {
    key: "dyschromia",
    label: strings.form.label.diagnosisDyschromia
  },
  {
    key: "pigmentation",
    label: strings.form.label.diagnosisPigmentation
  },
  {
    key: "telangiectasia",
    label: strings.form.label.diagnosisTelangiectasia
  },
  {
    key: "nicotinic",
    label: strings.form.label.diagnosisNicotinic
  },
  {
    key: "upper_dermatochalasis",
    label: strings.form.label.diagnosisUpperDermatochalasis
  },
  {
    key: "lower_dermatochalasis",
    label: strings.form.label.diagnosisLowerDermatochalasis
  },
  {
    key: "brow",
    label: strings.form.label.diagnosisBrow
  },
  {
    key: "nasal",
    label: strings.form.label.diagnosisNasal
  },
  {
    key: "microgenia",
    label: strings.form.label.diagnosisMicrogenia
  },
  {
    key: "depression",
    label: strings.form.label.diagnosisDepression
  },
  {
    key: "other",
    label: strings.form.label.diagnosisOther
  }
];

export const treatmentOptions = [
  {
    key: "emotional",
    label: strings.form.label.treatmentOptionsEmotional
  },
  {
    key: "chemo",
    label: strings.form.label.treatmentOptionsChemodenervation
  },
  {
    key: "hyalurinic",
    label: strings.form.label.treatmentOptionsHyalurinic
  },
  {
    key: "light",
    label: strings.form.label.treatmentOptionsLight
  },
  {
    key: "prescription",
    label: strings.form.label.treatmentOptionsPrescription
  },
  {
    key: "upperblepharo",
    label: strings.form.label.treatmentOptionsUpperBlepharoplasty
  },
  {
    key: "lowerblepharo",
    label: strings.form.label.treatmentOptionsLowerBlepharoplasty
  },
  {
    key: "rhytidectomy",
    label: strings.form.label.treatmentOptionsRhytidectomy
  },
  {
    key: "tightening",
    label: strings.form.label.treatmentOptionsSkinTightening
  },
  {
    key: "co2",
    label: strings.form.label.treatmentOptionsCO2
  },
  {
    key: "facial",
    label: strings.form.label.treatmentOptionsFacial
  },
  {
    key: "other",
    label: strings.form.label.treatmentOptionsOther
  }
];

export const treatmentPlanOptions = [
  {
    key: "emotional",
    label: strings.form.label.treatmentPlanEmotional
  },
  {
    key: "chemo",
    label: strings.form.label.treatmentPlanChemodenervation
  },
  {
    key: "hyalurinic",
    label: strings.form.label.treatmentPlanHyalurinic
  },
  {
    key: "light",
    label: strings.form.label.treatmentPlanLight
  },
  {
    key: "prescription",
    label: strings.form.label.treatmentPlanPrescription
  },
  {
    key: "upperblepharo",
    label: strings.form.label.treatmentPlanUpperBlepharoplasty
  },
  {
    key: "lowerblepharo",
    label: strings.form.label.treatmentPlanLowerBlepharoplasty
  },
  {
    key: "rhytidectomy",
    label: strings.form.label.treatmentPlanRhytidectomy
  },
  {
    key: "tightening",
    label: strings.form.label.treatmentPlanSkinTightening
  },
  {
    key: "co2",
    label: strings.form.label.treatmentPlanCO2
  },
  {
    key: "facial",
    label: strings.form.label.treatmentPlanFacial
  },
  {
    key: "other",
    label: strings.form.label.treatmentPlanOther
  }
];
