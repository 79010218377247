// This function creates a summary list of medical history changes, generated from the following fields:
//     medical_conditions (predefined options plus other with free text)
//     dermatological_diseases (predefined options plus other with free text)
//     neurological_conditions (predefined options plus other with free text)
//     general_allergies (free text field)
//     regular_medications (free text field)
//     drug_allergies (free text field)
//     supplements (free text field)
// The data comes from the only two forms that can change medical history (returningPatient and followUp) and from direct updates to the medical record
// These changes are extracted from the patients history database table (which also contains other records that aren't relevant here)
// See the unit tests for examples of the input and output data formats

export const combineChangesToMedicalHistory = (dataArray) => {
  //Treat a null input like an empty array
  if (dataArray === null || dataArray === []) {
    return {};
  }

  if (!validateInput(dataArray)) {
    return { error: "Could not compare the medical history" };
  }

  let output = {};

  //The section below combines each type of change in turn
  output = combineDetailsForCondition(dataArray, "medical_conditions", output);
  output = combineDetailsForCondition(
    dataArray,
    "dermatological_diseases",
    output
  );
  output = combineDetailsForCondition(
    dataArray,
    "neurological_conditions",
    output
  );
  output = combineDetailsForCondition(dataArray, "supplements", output);
  output = combineDetailsForCondition(dataArray, "regular_medications", output);
  output = combineDetailsForCondition(dataArray, "drug_allergies", output);
  output = combineDetailsForCondition(dataArray, "general_allergies", output);

  return output;
};

function validateInput(input) {
  if (!Array.isArray(input)) {
    console.log("Medical history not found");
    return false;
  }

  return true;
}

function combineDetailsForCondition(dataArray, conditionName, output) {
  output[conditionName + "_changes_toggle"] = "No";
  output[conditionName] = [];

  dataArray.forEach((item) => {
    //The format is different if the change comes from a patient record
    if (
      item.service === "patients" &&
      item.typeOfEvent === "update" &&
      item.data[conditionName] &&
      item.data[conditionName].newValue !== undefined
    ) {
      output[conditionName].push(
        extractPatientRecordUpdate(item, conditionName)
      );
      output[conditionName + "_changes_toggle"] = "Yes";
    }

    //Now process any changes that come from a forms record
    else if (
      item.service === "forms" &&
      (item.source === "followUp" || item.source === "returningPatient") &&
      item.data[conditionName + "_changes_toggle"] === "Yes"
    ) {
      output[conditionName].push(extractFormRecordUpdate(item, conditionName));
      output[conditionName + "_changes_toggle"] = "Yes";
    }
  });

  return output;
}

function extractPatientRecordUpdate(item, conditionName) {
  //If the item.data[conditionName].newValue is a string, we just use it.
  let extractedItems = item.data[conditionName].newValue;
  if (typeof extractedItems === "string") {
    extractedItems = extractedItems.replace("\n", ", ");
  }

  //If it is an object, we need to extract the list of names from it.
  if (typeof extractedItems !== "string") {
    let itemList = Object.values(extractedItems);
    let itemListNames = itemList.map((condition) => condition.label);
    if (itemListNames.includes("Other")) {
      let index = itemListNames.indexOf("Other");
      if (index !== -1) {
        itemListNames.splice(index, 1); //Remove the "Other" entry (which might not be at the end)
        itemListNames.push(
          //Then, re-add the "Other" entry at the end, with full details
          "Other (" + item.data[conditionName].newValue.other.content + ")"
        );
      }
    }
    extractedItems = itemListNames.join(", ");
  }

  return `\t${extractedItems} (Medical Record correction)`;
}

function extractFormRecordUpdate(item, conditionName) {
  //If the item.data[conditionName] is a string, we just use it.
  let extractedItems = item.data[conditionName];

  //If it is an object, we need to extract the list of names from it.
  if (typeof extractedItems !== "string") {
    let itemList = Object.values(extractedItems);
    let itemListNames = itemList.map((condition) => condition.label);
    if (itemListNames.includes("Other")) {
      let index = itemListNames.indexOf("Other");
      if (index !== -1) {
        itemListNames.splice(index, 1); //Remove the "Other" entry (which might not be at the end)
        itemListNames.push(
          //Then, re-add the "Other" entry at the end, with full details
          "Other (" + item.data[conditionName + "_other"] + ")"
        );
      }
    }
    extractedItems = itemListNames.join(", ");
  }
  return `\t${extractedItems}`;
}
