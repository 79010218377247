import React from "react";
import { Button, Icon, Modal, Input } from "semantic-ui-react";
import { strings } from "../resources";
import { connect } from "react-redux";
import { imageActions } from "../store/actions/image.actions";
import "./css/EnterPhotoNameModal.css";
import PropTypes from "prop-types";

class EnterPhotoNameModal extends React.Component {
  handleClose = () => {
    this.props.toggleModal();
  };

  handleChange = async (event, data) => {
    this.props.updatePhotoFileName(data.value);
  };

  handleSubmit = () => {
    this.props.toggleModal();
    this.props.handleModalConfirm(this.props.modalClassName);
  };

  render = () => {
    return (
      <Modal
        className={this.props.modalClassName}
        open={this.props.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          {strings.header.enterPhotoName}  
        </Modal.Header>
        <Modal.Content className="enterPhotoNameModalContent">
          <Input
            className="photoNameInput"
            label={strings.label.jpeg}
            labelPosition="right"
            onChange={this.handleChange}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            onClick={this.handleClose}
          >
            <Icon name="remove" /> {strings.button.cancelUploadLabel}
          </Button>
          <Button
            basic
            color="green"
            onClick={this.handleSubmit}
            disabled={!this.props.photoFileName}
          >
            <Icon name="checkmark" /> {strings.button.submitLabel}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

EnterPhotoNameModal.propTypes = {
  handleModalConfirm: PropTypes.func.isRequired,
  modalClassName: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { images } = state;
  const {
    photoFileName
  } = images;
  return {
    photoFileName
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePhotoFileName: name => {
      return dispatch(imageActions.updatePhotoFileName(name));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterPhotoNameModal);
