import React from "react";
import PropTypes from "prop-types";
import { Message, Container, Button, Icon } from "semantic-ui-react";
import { strings } from "../resources";
import { connect } from "react-redux";
import { authActions } from "../../App/store/actions/auth.actions";
import "./css/PreConsultation.css";

class PreConsultationCompleteContainer extends React.Component {
  state = {
    isRemotePatient: this.props.result
      ? this.props.result.user === "remote-patient"
      : false,
  };

  goBackToLogin = () => {
    //Log out the generic patient role (which will cause an automatic re-direct to the login page, so the receptionist can log back in)
    this.props.signOut();

    this.props.history.push({
      pathname: "/patients/",
    });
  };

  render = () => {
    return (
      <React.Fragment>
        <Container className="PreConsultationCompleteContainer">
          <div className="centerContent">
            <Message
              icon="check square outline"
              header={strings.header.completePreconsultation}
              content={
                this.state.isRemotePatient
                  ? strings.message.thankYou
                  : strings.message.returnDeviceToReceptionist
              }
              size="large"
              positive
              compact
            />
          </div>
          {!this.state.isRemotePatient && (
            <div className="preconsultationContinueButton">
              <Button
                icon
                labelPosition="left"
                className="primary icon preConsultationButton"
                onClick={() => this.goBackToLogin()}
              >
                <Icon name="arrow right" />
                {strings.button.continue}
              </Button>
            </div>
          )}
        </Container>
      </React.Fragment>
    );
  };
}

PreConsultationCompleteContainer.propTypes = {
  signOut: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { result } = state.users;
  return {
    result,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => {
      dispatch(authActions.signOut());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreConsultationCompleteContainer);
