import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      loading: "Loading Images",
      loadingImage: "Loading Image",
      signaturePleaseSign: "Please sign below",
      patientSignature: "Patient's Signature",
      doctorSignature: "Doctor's Signature",
      enterPhotoName: "Please enter photo name:"
    },
    label: {
      caption: "Caption",
      uploadedOn: "Uploaded On",
      takenOnDate: "Taken On",
      jpeg: ".jpeg",
      doctorPrintName: "Doctor's Name (Printed)",
      dateDoctorSigned: "Date Doctor Signed"
    },
    placeholder: {
      caption: "Caption",
      takenOnDate: "Date"
    },
    modal: {
      modifyImageMessage: "Are you sure you want to save these changes?",
      deleteImageMessage:
        "Are you sure you want to delete this image? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this treatment's status to active?",
      setInactiveMessage: "Set this treatment's status to inactive?"
    },
    button: {
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      uploadImageLabel: "Upload Image",
      undoLabel: "Undo",
      clearLabel: "Clear",
      submitLabel: "Submit",
      addPhotoLabel: "Add Photo",
      cancelUploadLabel: "Cancel Upload",
      applyDoctorSignatureLabel: "Apply Doctor's Signature"
    },
    photograph: {
      label: {
        uploadedOn: "Uploaded on"
      }
    },
    warning: {
      fileTypeWarning: "Please choose an image file"
    }
  }
});
