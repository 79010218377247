import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      patientList: "Patient List",
      createPatient: "New Patient Registration Form",
      editPatient: "Edit Patient",
      viewPatient: "Patient Information",
      loading: "Loading Information",
      patientFillsInForm: "Patient Fills in Form?",
    },
    patientRecord: {
      header: {
        gpInfo: "GP Information",
        medicalHistory: "Medical History",
        mentalHealthHistory: "Mental Health History",
        lifestyleHistory: "Lifestyle History",
        smokingHistory: "Smoking History",
        sunExposureHistory: "Sun Exposure History",
        treatmentHistory: "Treatment History",
        examinationNotesHistory: "Examination Notes",
        pregnancy: "Pregnancy",
        aestheticHistory: "Aesthetic History",
        aestheticConcerns: "Aesthetic Concerns",
        pregnancyWarning: "Patient may currently be pregnant/breastfeeding",
        privacy: "Privacy and Photographic Consent",
        previousProcedures: "Previous procedures",
      },
      mappings: {
        noMedicalConditions: "Patient has no significant medical conditions",
        noDermatologicalDiseases:
          "Patient has no significant dermatological history",
        noNeurologicalConditions:
          "Patient has no significant neurological conditions",
        noRegularMedications:
          "Patient does not currently take any regular medications",
        noSupplements: "Patient does not currently take any supplements",
        noDrugAllergies: "No known drug allergies",
        noGeneralAllergies: "No known general allergies",
        noMentalHealthDisorders: "No current psychiatric conditions",
        noPsychiatristSeen: "Patient has never been seen by a psychiatrist",
        psychiatristSeen: "Patient has previously been seen by a psychiatrist",
        noPsychiatricMedications:
          "Patient does not currently take any psychiatric medications",
        notCurrentSmoker: "Patient does not currently smoke",
        currentSmoker: "Patient currently smokes",
        notPreviousSmoker: "Patient has never smoked",
        previousSmoker: "Patient has a history of smoking",
        doesNotTanFace: "Patient avoids tanning their face in the sun.",
        doesTanFace: "Patient tans their face in the sun",
        doesNotUseSunbeds: "Patient has never used sun beds",
        doesUseSunbeds: "Patient has a history of using sun beds",
        noBotoxHistory: "Patient has never been treated with botox in the past",
        botoxComplications:
          "Patient has previously had complications from botox treatment",
        dermalFillerComplications:
          "Patient has previously had complications from dermal filler treatment.",
        noDermalFillerHistory:
          "Patient has never been treated with fillers in the past",
        dermalFillersPrefix:
          "Patient believes they have been treated with the following products: ",
        dermalFillersUnknown:
          "Patient does not know what dermal fillers they have previously received.",
        previousProceduresPrefix:
          "Patient has previously undergone the following procedures: ",
        noPreviousProcedures:
          "Patient has undergone no cosmetic surgical procedures",
        notPregnant: "Patient is not currently pregnant",
        pregnant:
          "Patient could be pregnant and is therefore not suitable for treatment right now",
        notBreastfeeding: "Patient is not currently breastfeeding",
        breastfeeding:
          "Patient is currently breastfeeding and is not currently suitable for treatment",
        noCurrentConcerns: "No current concerns",
        patientConcerns: "Patient's current concerns:",
        interestedTreatmentsPrefix: "Patient is interested in treatments: ",
        botheredByAppearance1to2:
          "The patient is currently not bothered, or only mildly bothered, by their appearance.",
        botheredByAppearance3to4:
          "The patient is currently mildly - moderately bothered by their appearance.",
        botheredByAppearance5to7:
          "The patient is currently moderately bothered by their appearance.",
        botheredByAppearance8to10:
          "The patient is currently severely bothered by their appearance.",
        affectedByAppearance1to2:
          "The appearance does not affect the patients social functioning.",
        affectedByAppearance3to4:
          "The appearance mildly affects the patients social functioning.",
        affectedByAppearance5to7:
          "The appearance moderately affects the patients social functioning.",
        affectedByAppearance8to10:
          "The appearance severely affects the patients social functioning.",
        thinksAboutAppearance: "The patient thinks about their appearance",
        fitzpatrickSkinTypeI: "The patient has Fitzpatrick skin type I",
        fitzpatrickSkinTypeII: "The patient has Fitzpatrick skin type II",
        fitzpatrickSkinTypeIII: "The patient has Fitzpatrick skin type III",
        fitzpatrickSkinTypeIV: "The patient has Fitzpatrick skin type IV",
        fitzpatrickSkinTypeV: "The patient has Fitzpatrick skin type V",
        fitzpatrickSkinTypeVI: "The patient has Fitzpatrick skin type VI",
        glogauSkinGroup1: "The Glogau skin group is 1",
        glogauSkinGroup2: "The Glogau skin group is 2",
        glogauSkinGroup3: "The Glogau skin group is 3",
        glogauSkinGroup4: "The Glogau skin group is 4",
        goodSkinCondition: "The overall skin condition is good",
        mildSkinCondition:
          "The overall skin condition demonstrates signs of mild of sun damage",
        moderateSkinCondition:
          "The overall skin condition demonstrates signs of moderate sun damage",
        severeSkinCondition:
          "The overall skin condition demonstrates signs of severe sun damage",
        noSignsOfSmoking: "There are no obvious signs of cigarette smoking",
        signsOfSmoking: "There are signs of nicotine damage to the skin",
        abnormalFeatures: "The patient shows abnormal features",
        volumeLossAreas: "Areas affected by volume loss include the",
        leaveHouse:
          "The patient sometimes avoids leaving the house because of their appearance",
        avoidsSocialSituations:
          "The patient will sometimes avoid social situations due to their appearance",
        avoidsPhotographs:
          "The patient will avoid having photographs taken because of concerns relating to their appearance",
        avoidsCertainAngles:
          "The patient will try to avoid being seen from certain angles to avoid people seeing their features",
        wearsMakeup:
          "The patient will use make up to camouflage the features of concern",
        satisfiedWithLastTreatment:
          "The patient was satisfied with the outcome of the last treatment",
        notSatisfiedWithLastTreatment:
          "The patient was not satisfied with the outcome of the last treatment",
        highlySatisfiedWithLastTreatment:
          "The patient was highly satisfied with the outcome of the last treatment",
        highlyDissatisfiedWithLastTreatment:
          "The patient was highly dissatisfied with the outcome of the last treatment",
        experiencedComplications:
          "The patient experienced the following complications from the last treatment: ",
        didNotExperienceComplications:
          "The patient did not experience any complications from the last treatment",
        improvedSelfConfidence:
          "The previous treatment improved the patient's self confidence",
        didNotImproveSelfConfidence:
          "The previous treatment did not improve the patient's self confidence",
      },
      view: {
        label: {
          firstName: "Name",
          emailAddress: "Email Address",
          mainPhoneNumber: "Main Phone Number",
          mobilePhoneNumber: "Mobile Phone Number",
          address: "Postal Address",
          postcode: "Postcode",
          country: "Country",
          townOrCity: "Town/City",
          dateOfBirth: "Date of Birth",
          occupation: "Occupation",
          gpName: "GP Name",
          gpAddress: "GP Address",
          emergencyContactName: "Emergency Contact Name",
          emergencyContactPhoneNumber: "Emergency Contact Number",
          consentPhotographicInformation:
            "If you decide to proceed with an aesthetic treatment we will take some photographs of you before and after your treatment. This forms an essential part of your medical record and we are unable to offer treatments to you without your consent to obtain these photographs. You should feel confident however that no one else will be able to see these photographs without your consent. Sometimes before and after pictures however can help other patients who are considering an aesthetic treatment. If you would consent to your photographs being shared with other patients then please indicate your preferences by checking the relevant boxes below:",
          consentShareImages:
            "Patient does not wish to share their images; please keep photographs strictly between doctor and patient",
          consentShowImagesToOtherPatients:
            "Patient consents to other patients being shown their photographs in private consulation only",
          consentShowImagesOnWebsite:
            "Patient consents to their photographs being displayed on the clinic website",
          consentShowImagesOnSocialMedia:
            "Patient consents to their photographs being displayed on the clinic social media sites",
          consentSendTextAndEmail:
            "Patient consents to receiving emails and texts relevant to their treatments",
        },
        placeholder: {
          firstName: "First Name",
          surname: "Last Name",
          emailAddress: "Email Address",
          mainPhoneNumber: "Main Phone Number",
          mobilePhoneNumber: "Mobile Phone Number",
          address: "Street Address",
          postcode: "Postcode",
          country: "Country",
          townOrCity: "Town/City",
          membership: "Membership",
          date: "DD-MM-YYYY",
          occupation: "Occupation",
          gpName: "GP Name",
          gpAddress: "GP Address",
          gpContactApproval: "Yes/No",
          emergencyContactName: "Emergency Contact Name",
        },
        mapping: {
          // These object keys are nested field key names corresponding to which fields to map in the view patient record
          medical_conditions: {
            blood_pressure: "Hypertension",
            chest_pains: "Angina",
            heart_attack: "Myocardial Infarction",
            asthma: "Asthma",
            copd: "COPD",
            osteoarthritis: "Osteoarthritis",
            rheumatoid: "Rheumatoid Arthritis",
            breast_cancer: "Breast Cancer",
            colon_cancer: "Colon Cancer",
            lung_cancer: "Lung Cancer",
            ulcerative_colitis: "Ulcerative Colitis",
            crohns: "Crohn's Disease",
            diabetes_i: "Diabetes I",
            diabetes_ii: "Diabetes II",
          },
        },
      },
      edit: {
        label: {
          medicalConditionBloodPressure: "High Blood Pressure",
          medicalConditionChestPains: "Chest Pains (Angina)",
          medicalConditionHeartAttack: "Heart Attack",
          medicalConditionAsthma: "Asthma",
          medicalConditionCOPD: "COPD",
          medicalConditionOsteoarthritis: "Osteoarthritis",
          medicalConditionRheumatoid: "Rheumatoid Arthritis",
          medicalConditionBreastCancer: "Breast Cancer",
          medicalConditionColonCancer: "Colon Cancer",
          medicalConditionLungCancer: "Lung Cancer",
          medicalConditionUlcerativeColitis: "Ulcerative Colitis",
          medicalConditionCrohns: "Crohn's Disease",
          medicalConditionDiabetesI: "Diabetes (Type 1)",
          medicalConditionDiabetesII: "Diabetes (Type 2)",
          medicalConditionOther: "Other",
          dermatologicalDiseasesEczema: "Eczema",
          dermatologicalDiseasesPsoriasis: "Psoriasis",
          dermatologicalDiseasesUrticaria: "Urticaria",
          dermatologicalDiseasesVitiligo: "Vitiligo",
          dermatologicalDiseasesOther: "Other",
          neurologicalConditionsMyasthenia: "Myasthenia Gravis",
          neurologicalConditionsMultipleSclerosis: "Multiple Sclerosis",
          neurologicalConditionsMND: "Motor Neurone Disease",
          neurologicalConditionsBellsPalsy: "Bell's Palsy",
          neurologicalConditionsOther: "Other",
          gpName: "GP Name",
          gpAddress: "GP Address",
          gpConsent: "Does the patient consent to their GP being contacted?",
          medicalConditionsToggle:
            "Does the patient currently suffer from any medical conditions?",
          medicalConditions: "Please specify details",
          dermatologicalDiseasesToggle:
            "Does the patient currently suffer from any dermatological diseases?",
          dermatologicalDiseases: "Please specify details",
          neurologicalConditionsToggle:
            "Does the patient currently suffer from any neurological conditions?",
          neurologicalConditions: "Please specify details",
          regularMedicationsToggle:
            "Does the patient currently take any regular medications?",
          regularMedications: "Please specify details",
          supplementsToggle: "Does the patient currently take any supplements?",
          supplements: "Please specify details",
          drugAllergiesToggle:
            "Does the patient suffer from any drug allergies?",
          drugAllergies: "Please specify details",
          generalAllergiesToggle:
            "Does the patient suffer from any general allergies?",
          generalAllergies: "Please specify details",
          mentalHealthDisordersToggle:
            "Does the patient suffer from any mental health disorders?",
          mentalHealthDisorders: "Please specify details",
          seenPsychiatrist: "Has the patient seen a psychiatrist?",
          psychiatricMedicationsToggle:
            "Does the patient take any psychiatric medications?",
          psychiatricMedications: "Please specify details",
          patientHasSmoked: "Has patient ever smoked?",
          patientDoesSmoke: "Does patient smoke currently?",
          usesSunbeds: "Has patient ever used sunbeds?",
          tansFace: "Does patient tan face in the sun?",
          pregnancy: "Could the patient be pregnant?",
          breastfeeding: "Is the patient breastfeeding?",
          botoxPreviousUse: "Has patient ever been treated with botox?",
          botoxNumberOfPastTreatments:
            "How many times has patient been treated with botox?",
          botoxWhenLastTreated: "When was the patient last treated with botox?",
          botoxComplications:
            "Has the patient ever experienced complications from botox?",
          dermalFillersPreviousUse:
            "Has patient ever been treated with dermal fillers?",
          dermalFillersNumberOfPastTreatments:
            "How many times has patient been treated with dermal fillers?",
          dermalFillersWhenLastTreated:
            "When was the patient last treated with dermal fillers?",
          dermalFillersComplications:
            "Has the patient ever experienced complications from dermal fillers?",
          dermalFillersTypes:
            "Please select all of the types of filler the patient has been treated with in the past",
          dermalFillerTypeJuvederm: "Juvederm",
          dermalFillerTypeEmervel: "Emervel",
          dermalFillerTypeRestylane: "Restylane",
          dermalFillerTypeAquamid: "Aquamid",
          dermalFillerTypeBelotero: "Belotero",
          dermalFillerTypeBioalcamid: "Bioalcamid",
          dermalFillerTypeRadiesse: "Radiesse",
          dermalFillerTypeSculptra: "Sculptra",
          dermalFillerTypeTeosyal: "Teosyal",
          dermalFillerTypeOther: "Other",
          previousProceduresQuestion:
            "Please select any procedures the patient has undergone previously",
          previousProceduresLaser: "Laser Resurfacing",
          previousProceduresFacelift: "Facelift",
          previousProceduresEyelid: "Eyelid Surgery (Blepharoplasty)",
          previousProceduresBreast: "Breast Surgery",
          previousProceduresLiposuction: "Liposuction",
          previousProceduresTummy: "Tummy Tuck",
          hasHadExamination: "Has patient had an examination?",
          fitzpatrickSkinTypeHeader: "Fitzpatrick Skin Type",
          glogauSkinGroupHeader: "Glogau Skin Group",
          skinConditionHeader: "Overall condition of the skin",
          skinConditionPigmentation: "Hyper Pigmentation",
          skinConditionTelangiectasia: "Telangiectasia",
          skinConditionElastosis: "Elastosis",
          skinConditionDynamicRhytids: "Dynamic Rhytids",
          skinConditionStaticRhytids: "Static Rhytids",
          smokingHeader: "Signs of smoking",
          smokingConditionSkinTone: "Poor Skin Tone",
          smokingConditionElasticRecoil: "Loss of Elastic Recoil",
          smokingConditionPerioralRhytids: "Perioral Rhytids",
          facialVolumeHeader: "Facial volume",
          facialVolumeForehead: "Forehead",
          facialVolumeTemples: "Temples",
          facialVolumeBrow: "Brow",
          facialVolumeInfraorbitalRegion: "Infraorbital Region",
          facialVolumeMalarEminence: "Malar Eminence",
          facialVolumeMidFace: "Mid-Face",
          facialVolumeLateralCheek: "Lateral Cheek Fat",
          facialVolumeSubmalar: "Submalar",
          facialVolumeNasogenian: "Nasogenian",
          facialVolumePerioral: "Perioral",
          facialVolumeMandible: "Mandible",
          abnormalHeader: "Abnormal features",
          abnormalConditionsHeader: "Abnormal features",
          abnormalNasalHump: "There is a strong nasal hump",
          abnormalPseudohump:
            "There is evidence of a pseudohump with lack of projection at the radix",
          abnormalNasalTip: "There is downturning of the nasal tip",
          abnormalThinLips:
            "The lips are thin and appear under developed in relation to the other facial features",
          abnormalChin: "The chin is underdeveloped",
          errorHeader:
            "There were some errors with your submission, please fix",
          errorMedicalHeader: "Medical Record Errors",
          errorPersonalHeader: "Personal Details Errors",
        },
        placeholder: {
          details: "Enter details",
          firstName: "First Name",
          surname: "Last Name",
          emailAddress: "Email Address",
          phoneNumber: "Phone Number",
          address: "Street Address",
          postcode: "Postcode",
          townOrCity: "Town/City",
          membership: "Membership",
          date: "DD-MM-YYYY",
          occupation: "Occupation",
          gpName: "GP Name",
          gpAddress: "GP Address",
          gpContactApproval: "Yes/No",
          emergencyContactName: "Emergency Contact Name",
        },
      },
      databaseValues: {
        fitzpatrickSkinTypeI: "I",
        fitzpatrickSkinTypeII: "II",
        fitzpatrickSkinTypeIII: "III",
        fitzpatrickSkinTypeIV: "IV",
        fitzpatrickSkinTypeV: "V",
        fitzpatrickSkinTypeVI: "VI",
        glogauSkinGroup1: "1",
        glogauSkinGroup2: "2",
        glogauSkinGroup3: "3",
        glogauSkinGroup4: "4",
        skinConditionGood: "Good",
        skinConditionMildDamage: "Mild Damage",
        skinConditionModerateDamage: "Moderate Damage",
        skinConditionSevereDamage: "Severe Damage",
        facialVolumeGood:
          "The overall facial volume is good, with minimal to no signs of lipoatrophy or bony retrusion",
        facialVolumeGoodMinimal:
          "The overall facial volume is good, with minimal signs of lipoatrophy",
        facialVolumeStartedDeteriorating:
          "The overall facial volume has started to deteriorate, with moderate signs of lipoatrophy and early bony retrusion",
        facialVolumeDeteriorated:
          "The facial volume has deteriorated, with severe signs of lipoatrophy and bony retrusion",
      },
      validation: {
        errorMessagePrefix: {
          first_name: "First Name",
          surname: "Surname",
          date_of_birth: "Date of Birth",
          email_address: "Email Address",
          postal_address: "Postal Address",
          postcode: "Postcode",
          phone_number: "Main Phone Number",
          mobile_phone_number: "Mobile Phone Number",
          townOrCity: "Town/City",
          gp_name: "GP Name",
          gp_address: "GP Address",
          gp_contact_consent: "GP Contact Consent",
          communication_consent: "Communication Consent",
          emergency_contact_name: "Emergency Contact Name",
          emergency_contact_phone_number: "Emergency Contact Phone Number",
          medical_conditions: "Medical Conditions",
          medical_conditions_other: "Other Medical Condition",
          medical_conditions_toggle: "Medical Conditions",
          medical_conditions_changes_toggle: "Medical Conditions",
          medical_condition_details: "Medical Condition Details",
          dermatological_diseases: "Dermatological Diseases",
          dermatological_diseases_other: "Other Dermatological Disease",
          dermatological_diseases_toggle: "Dermatological Diseases",
          dermatological_diseases_changes_toggle: "Dermatological Diseases",
          neurological_conditions: "Neurological Conditions",
          neurological_conditions_toggle: "Neurological Conditions",
          neurological_conditions_changes_toggle: "Neurological Conditions",
          neurological_conditions_other: "Other Neurological Condition",
          neurological_conditions_details: "Neurological Conditions Details",
          regular_medications: "Regular Medications",
          regular_medications_details: "Regular Medications Details",
          supplements: "Supplements",
          supplements_details: "Supplements Details",
          drug_allergies: "Drug Allergies",
          drug_allergies_details: "Drug Allergies Details",
          general_allergies: "General Allergies",
          general_allergies_details: "General Allergies Details",
          mental_health_disorders: "Mental Health Disorders",
          mental_health_disorder_details: "Mental Health Disorder Details",
          psychiatrist_seen: "Psychiatrist Seen",
          psychiatric_medications: "Psychiatric Medications",
          psychiatric_medications_details: "Psychiatric Medications Details",
          previously_smoked: "Previously Smoked",
          smokes_currently: "Smokes Currently",
          used_sunbeds: "Used Sunbeds",
          tans_face: "Tans Face",
          previous_botox_treatment: "Previous Botox Treatment",
          last_botox_treatment: "Last Botox Treatment",
          botox_treatment_count: "Botox Treatment Count",
          botox_complications: "Botox Complications",
          previous_dermal_filler_treatment: "Previous Dermal Filler Treatment",
          last_dermal_filler_treatment: "Last Dermal Filler Treatment",
          dermal_filler_treatments: "Dermal Filler Treatments",
          dermal_filler_treatment_count: "Dermal Filler Treatment Count",
          dermal_filler_complications: "Dermal Filler Complications",
          pregnancy: "Pregnancy",
          breastfeeding: "Breastfeeding",
          examination_fitzpatrick_skin_type: "Fitzpatrick Skin Type",
          examination_glogau_skin_group: "Glogau Ski Group",
          examination_skin_condition: "Skin Condition",
          examination_skin_conditions: "Skin Conditions",
          examination_smoking: "Shows Signs of Smoking",
          examination_smoking_conditions: "Signs of Smoking",
          examination_facial_volume: "Facial Volume",
          examination_facial_volume_details: "Facial Volume Details",
          examination_abnormal: "Facial Volume",
          examination_abnormal_conditions: "Facial Volume Conditions",
          skin_condition: "",
          reasons: "Visiting Reasons",
          other_reason_details: "Other Reason Details",
          bothered_by_appearance: "Bothered By Appearance",
          how_often_appearance: "Appearance Thought About Frequency",
          treatments_considered: "Treatments Considered",
        },
        errorMessageReason: {
          incompatibleImageConsent:
            "cannot reject and also grant consent for image use",
          requireAtLeastOneOption: "must have at least one option selected",
          wrongDateFormat: "must be a valid date in the format DD-MM-YYYY",
        },
        errorTab: {
          personal: "personal",
          medical: "medical",
        },
      },
    },
    modal: {
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      deactivatePatientMessage:
        "Are you sure you want to deactivate this patient?",
      activatePatientMessage: "Are you sure you want to activate this patient?",
      setActiveMessage: "Set this patient's status to active?",
      setInactiveMessage: "Set this patient's status to inactive?",
    },
    button: {
      downloadFormLabel: "Download Medical Record",
      editLabel: "Edit",
      deleteLabel: "Delete",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      cancelLabel: "Cancel",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addPatientLabel: "Add Patient",
      deletePatientLabel: "Delete Patient",
      logOutLabel: "Log Out",
    },
    tooltip: {
      editPatient: "Edit Patient",
      deletePatient: "Delete Patient",
      toggleStatus: "Click to change status",
      patientTypeWarning: "Patient Type cannot be changed after creation.",
    },
    table: {
      header: {
        firstName: "First Name",
        surname: "Last Name",
        dateOfBirth: "Date of Birth",
        emailAddress: "Email Address",
        phoneNumber: "Phone Number",
        address: "Address",
        townOrCity: "Town/City",
        postcode: "Post Code",
        status: "Active",
        actions: "Actions",
        ViewIcon: "View Record",
      },
      placeholder: {
        searchbar: "Enter terms",
      },
    },
  },
});
