import React from "react";
import { Header, Image, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../resources";
import PropTypes from "prop-types";
import "./css/DoctorSignature.css";
import { dateHelper } from "../helpers";

class DoctorSignature extends React.Component {
  render = () => {
    const defaultContainerWidth = 240;
    const defaultContainerHeight = 150;

    return (
      <div>
        <Form.Input
          className="doctorNamePrinted"
          label={strings.label.doctorPrintName}
          readOnly
          value={this.props.doctorName || ""}
        />
        <div className="doctorSignatureContainer">
          <Header as="h5" className="doctorSignatureHeader">
            {strings.header.doctorSignature}
          </Header>
          {this.props.signatureURL &&
          Object.keys(this.props.signatureURL).length > 0 ? (
            <Image
              className="doctorSignatureArea"
              crossOrigin="anonymous"
              src={this.props.signatureURL ? this.props.signatureURL.url : ""}
              width={this.props.containerWidth || defaultContainerWidth}
              height={this.props.containerHeight || defaultContainerHeight}
            />
          ) : (
            <div
              className="blankSignature"
              style={{
                width: this.props.containerWidth
                  ? this.props.containerWidth
                  : defaultContainerWidth,
                height: this.props.containerHeight
                  ? this.props.containerHeight
                  : defaultContainerHeight
              }}
            />
          )}
        </div>

        <Form.Input
          className="dateDoctorSigned"
          icon="calendar"
          iconPosition="left"
          label={strings.label.dateDoctorSigned}
          readOnly
          value={
            this.props.dateDoctorSigned
              ? dateHelper.getFormattedDateFromISOString(
                  this.props.dateDoctorSigned
                )
              : ""
          }
        />
      </div>
    );
  };
}

DoctorSignature.propTypes = {
  canvasHeight: PropTypes.number,
  canvasWidth: PropTypes.number
};

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(DoctorSignature);
