import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

const CheckboxGroup = props => {
  if (props.horizontal) {
    return (
      <React.Fragment>
        <Form.Field>
          <label>
            <span>
              <span className={props.error ? "errorLabel" : null}>
                {props.label}
              </span>
              {props.required && <span className="requiredAsterisk"> *</span>}
            </span>
          </label>
        </Form.Field>
        <Form.Group inline>
          <CheckboxGroupField {...props} />
        </Form.Group>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Form.Field>
          <label>
            <span>
              <span className={props.error ? "errorLabel" : null}>
                {props.label}
              </span>
              {props.required && <span className="requiredAsterisk"> *</span>}
            </span>
          </label>
        </Form.Field>
        <CheckboxGroupField {...props} />
      </React.Fragment>
    );
  }
};

const CheckboxGroupField = props => {
  const sortedOptions = props.options.sort(sortOptionsFunction);
  return (
    <React.Fragment>
      {sortedOptions.map((option, optionIndex) => {
        let optionChecked = false;
        if (props.data.hasOwnProperty(props.group) && props.data[props.group]) {
          if (props.data[props.group].hasOwnProperty(option.key)) {
            optionChecked = props.data[props.group][option.key].checked;
          }
        }
        return (
          <Form.Checkbox
            disabled={props.mode === "view" || props.mode === "template"}
            error={props.error}
            key={`${props.group}-${optionIndex}`}
            name={option.key}
            group={props.group}
            checked={optionChecked}
            label={option.label}
            onChange={props.handleCheckboxChange}
          />
        );
      })}
    </React.Fragment>
  );
};

const sortOptionsFunction = (optionA, optionB) => {
  const firstWord = optionA.label.toLowerCase();
  const secondWord = optionB.label.toLowerCase();
  // Always make "Other" the final option
  if (firstWord === "other") {
    return 1;
  } else if (secondWord === "other") {
    return -1;
  } else if (firstWord > secondWord) {
    return 1;
  } else if (firstWord === secondWord) {
    return 0;
  } else if (firstWord < secondWord) {
    return -1;
  } else {
    return 0;
  }
};

CheckboxGroup.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  horizontal: PropTypes.bool,
  error: PropTypes.bool,
  group: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired, // needed for checked status
  options: PropTypes.array.isRequired,
  mode: PropTypes.string // to check for disabled state
};

export default CheckboxGroup;
