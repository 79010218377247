import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Message } from "semantic-ui-react";
import { strings } from "../resources";
import { BasicModal } from ".";
import { DatePicker } from "./ui";
import "./css/ProductComponents.css";

class ModifyProduct extends React.Component {
  render = () => {
    const errorFields = this.props.validationErrors.map(error => {
      return error.field
    })

    const editActivationButtons = (
      <div className="centerContent">
        <Button.Group>
          <Button
            className="editButton"
            onClick={() => {
              this.props.handleModeSwitch("edit");
            }}
            disabled={!this.props.selectedProductData.active}
          >
            {strings.button.editLabel}
          </Button>
          <Button.Or />
          <Button
            className="activationButton"
            onClick={this.props.toggleActivationModalVisibility}
            type="button"
          >
            {this.props.selectedProductData.active &&
              strings.button.deactivateLabel}
            {!this.props.selectedProductData.active &&
              strings.button.activateLabel}
          </Button>
        </Button.Group>
        <BasicModal
          confirmMessage={
            this.props.selectedProductData.active
              ? strings.modal.deactivateProductMessage
              : strings.modal.activateProductMessage
          }
          handleModalConfirm={this.props.handleModalConfirm}
          modalClassName="activationModal"
          modalOpen={this.props.activationModalOpen}
          showOkButton={false}
          showYesButton={true}
          showNoButton={true}
          toggleModal={this.props.toggleActivationModalVisibility}
        />
      </div>
    );

    const cancelSaveButtons = (
      <div className="cancelSaveButtons">
        <Button.Group>
          <Button
            className="saveButton"
            onClick={this.props.toggleConfirmSaveModalVisibility}
          >
            {strings.button.saveLabel}
          </Button>
          <Button.Or />
          <Button
            className="cancelButton"
            onClick={this.props.toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={this.props.confirmSaveModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleConfirmSaveModalVisibility}
          />
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              this.props.mode === "create"
                ? this.props.handleModalConfirm
                : this.props.handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={this.props.cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleCancelChangesModalVisibility}
          />
        </Button.Group>
      </div>
    );

    const productForm = (
      <>
        <div className="productInfoHeader">
          {strings.form.header.productInfo}
        </div>
        <div className="productInfo">
          <Form.Group>
            <Form.Input
              error={errorFields.includes("product_name")}
              label={strings.form.label.productName}
              name="product_name"
              onChange={this.props.handleChange}
              placeholder={strings.form.placeholder.productName}
              required
              value={this.props.selectedProductData.product_name || ""}
              width={8}
            />
            <Form.Input
            error={errorFields.includes("product_type")}
              label={strings.form.label.productType}
              name="product_type"
              onChange={this.props.handleChange}
              placeholder={strings.form.placeholder.productType}
              required
              value={this.props.selectedProductData.product_type || ""}
              width={8}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              error={errorFields.includes("batch_name")}
              label={strings.form.label.batchName}
              name="batch_name"
              onChange={this.props.handleChange}
              placeholder={strings.form.placeholder.batchName}
              required
              value={this.props.selectedProductData.batch_name || ""}
              width={8}
            />
            <DatePicker
              error={errorFields.includes("expiry_date")}
              className="modifyProductBatchDatePicker"
              name="expiry_date"
              onChange={this.props.handleDateChange}
              onKeyDown={e => this.props.handleDateKeyDown(e)}
              required
              label={strings.form.label.expiryDate}
              value={this.props.selectedProductData.expiry_date || ""}
              noPastDates
            />
          </Form.Group>
        </div>
      </>
    );

    return (
      <div className="modifyProductContainer">
        {this.props.validationErrors.length > 0 && (
          <div className="errorMessageContainer">
            <Message
              compact
              error
              header={strings.form.label.errorHeader}
              list={this.props.validationErrors.map(error => {
                return error.error;
              })}
            />
          </div>
        )}
        <Form
          className="details"
          onSubmit={this.props.toggleConfirmSaveModalVisibility}
        >
          {this.props.mode === "view" && (
            <fieldset disabled>{productForm}</fieldset>
          )}
          {this.props.mode !== "view" && <fieldset>{productForm}</fieldset>}
        </Form>
        {this.props.mode === "view" && editActivationButtons}
        {this.props.mode === "edit" && cancelSaveButtons}
        {this.props.mode === "create" && cancelSaveButtons}
      </div>
    );
  };
}

ModifyProduct.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedProductData: PropTypes.object.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired
};

export default ModifyProduct;
