import { strings } from "../resources/Strings";

export const checkResponse = (
  data,
  fieldName,
  message = "No Response Found"
) => {
  if (data.hasOwnProperty(fieldName)) {
    return data[fieldName];
  } else {
    return message;
  }
};

// Return a numerical response if it exists in the data object
export const checkNumberResponse = (data, fieldName) => {
  if (data.hasOwnProperty(fieldName)) {
    return data[fieldName];
  } else {
    return "?";
  }
};

// Return a response from an array using the selected value as an array index
export const checkRangeResponse = (data, fieldName, valueArray) => {
  if (data.hasOwnProperty(fieldName)) {
    return valueArray[parseInt(data[fieldName]) - 1];
  } else {
    return null;
  }
};

// Return a comma separated string of the ticked checkboxes labels if the group exists in the data object
// List will be prefixed by itemsPresentPrefix string if it has any items, otherwise it will return noItemsText
// Items will be separated by itemSeparator
// If mapToStrings is passed as true, field labels will be replaced by the entry sharing the field name in strings.
export const checkGroupResponse = (
  data,
  fieldName,
  itemsPresentPrefix,
  noItemsText = "",
  caseConversion = "none",
  mapToStrings = false,
  itemSeparator = ", "
) => {
  if (!data.hasOwnProperty(fieldName)) {
    return noItemsText;
  }

  const groupObject = Object.assign({}, data[fieldName]);
  const mapToStringsOptions = strings.patientRecord.view.mapping;

  // Get an array of the labels for the ticked checkboxes in the group
  let selectedLabels = [];

  Object.keys(groupObject).forEach(groupObjectKey => {
    if (groupObject[groupObjectKey].checked === true) {
      //If the options included "other", the "other" value will be stored as content
      if (groupObject[groupObjectKey].content) {
        selectedLabels.push(groupObject[groupObjectKey].content);
      }

      //If this is a mapped label, take the value from the Strings resource
      else if (
        mapToStrings &&
        mapToStringsOptions.hasOwnProperty(fieldName) &&
        mapToStringsOptions[fieldName].hasOwnProperty(groupObjectKey)
      ) {
        selectedLabels.push(mapToStringsOptions[fieldName][groupObjectKey]);
      }

      //For all other cases, use the label as the selection value
      else {
        selectedLabels.push(groupObject[groupObjectKey].label);
      }
    }

    // If input field, add content
    if (groupObject[groupObjectKey].value) {
      if (groupObject[groupObjectKey].value.length > 0) {
        selectedLabels.push(groupObject[groupObjectKey].value);
      }
    }
  });

  if (selectedLabels.length === 0) {
    return noItemsText;
  }

  let outputListText = selectedLabels.join(`${itemSeparator}`);

  //If lower case conversion is selected, make all the items lowercase.
  //This would typically be used by a comma separated list, but a set of statements (e.g. separated by full stops) wouldn't require it.
  if (caseConversion === "lower") {
    outputListText = outputListText.toLowerCase();
  }

  return itemsPresentPrefix + outputListText;
};

// Return a date response if it exists in the data object
export const checkDateResponse = (data, fieldName) => {
  if (data.hasOwnProperty(fieldName)) {
    return data[fieldName];
  } else {
    return "??-??-????";
  }
};

// Returns the yes response arg if toggleField = "Yes", noResponse if = "No", otherwise say no response found
export const checkYesNoResponse = (
  data,
  toggleField,
  yesResponse,
  noResponse
) => {
  if (data.hasOwnProperty(toggleField)) {
    if (data[toggleField] === "Yes") {
      return yesResponse;
    } else {
      return noResponse;
    }
  } else {
    return "No Response Found";
  }
};

// Returns the matchResponse if field === compareToValue, noMatchResponse otherwise
export const checkIfResponse = (
  data,
  fieldName,
  compareToValue,
  matchResponse,
  noMatchResponse
) => {
  if (data.hasOwnProperty(fieldName)) {
    if (data[fieldName] === compareToValue) {
      return matchResponse;
    } else {
      return noMatchResponse;
    }
  } else {
    return "";
  }
};
