const formatDateInput = (dateInput) => {
  let firstRegex = /^([0-9]{2})$/g
  let secondRegex = /^([0-9]{2}[-][0-9]{2})$/g
  // Only add a dash if the input is either XX or XX-XX, where X is any digit
  // between 0-9 inclusive
  if (dateInput.match(firstRegex)) {
    dateInput += "-"
  }
  if (dateInput.match(secondRegex)) {
    dateInput += "-"
  }
  return dateInput
}
export default formatDateInput;