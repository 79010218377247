import React from "react";
import ModifyProduct from "../../components/ModifyProduct";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { productActions } from "../../store/actions/product.actions";
import PropTypes from "prop-types";
import validateProductInput from "../../helpers/productValidator";
import formatDateInput from "../../../Forms/helpers/FormatDateInput";

class ProductEditContainer extends React.Component {
  state = {
    mode: "view",
    activationModalOpen: false,
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    validationErrors: []
  };

  componentDidMount = async () => {
    await this.props.getProductById(
      this.props.match.params.id,
      this.props.headers
    );
  };

  handleDeactivate = async () => {
    await this.props.deactivateProduct(this.props.selectedProductData.id, this.props.headers);
    await this.props.getProductById(
      this.props.match.params.id,
      this.props.headers
    );
  };

  handleActivate = async () => {
    await this.props.activateProduct(this.props.selectedProductData.id, this.props.headers);
    await this.props.getProductById(
      this.props.match.params.id,
      this.props.headers
    );
  };

  handleDateChange = async (event, data) => {
    let dataToSubmit = "";
    let currentValue = this.props.selectedProductData[data.name];
    // Only look to add dashes if there is something to format, and if the user
    // is typing rather than deleting
    if (currentValue && data.value.length > 0 && data.value.length > currentValue.length) {
      dataToSubmit = formatDateInput(data.value);
    } else {
      dataToSubmit = data.value;
    }
    await this.props.updateSelectedProduct(data.name, dataToSubmit);
    this.removeFieldError(data.name);
  };

  handleDateKeyDown = event => {
    // Filter the input to the date box - restrict it to numbers, the dash separator,
    // the backspace key (keyCode 8), and the delete key (keyCode 46)
    if (isNaN(event.key) && event.key !== "-" && event.keyCode !== 8 && event.keyCode !== 46) {
      event.preventDefault();
    }
  };

  handleSubmit = () => {
    this.props.updateProduct(
      this.props.match.params.id,
      this.props.selectedProductData,
      this.props.headers,
      this.props.history
    );
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    this.removeFieldError(data.name);
    this.props.updateSelectedProduct(data.name, data.value);
  };

  handleModeSwitch = () => {
    if (this.state.mode === "edit") {
      this.setState({ mode: "view" });
    } else {
      this.setState({ mode: "edit" });
    }
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        if (this.props.selectedProductData.active) {
          this.handleDeactivate()
        } else {
          this.handleActivate()
        }
        break;
      default:
        break;
    }
  };

  removeFieldError = field => {
    const newErrors = this.state.validationErrors.filter(error => {
      return error.field !== field;
    });
    this.setState({
      validationErrors: newErrors
    });
  };

  toggleActivationModalVisibility = () => {
    this.setState({
      activationModalOpen: !this.state.activationModalOpen
    });
  };

  toggleConfirmSaveModalVisibility = async () => {
    const submitData = Object.assign({}, this.props.selectedProductData);
    Object.keys(submitData).forEach(field => {
      if (submitData[field] === "") {
        delete submitData[field];
      }
    });
    // Validate the data before showing the modal
    const validationResult = await validateProductInput(
      submitData,
      "newProduct"
    );
    if (validationResult.passedValidation === true) {
      this.setState({
        confirmSaveModalOpen: !this.state.confirmSaveModalOpen
      });
    } else {
      // If failed validation, don't show modal, add errors to state and scroll to top
      window.scrollTo(0, 0);
      this.setState({
        validationErrors: validationResult.errors.slice()
      });
    }
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  render = () => {
    return (
      <div>
        <div style={{ display: this.props.loadingPage ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: this.props.loadingPage ? "none" : "" }}>
          <ModifyProduct
            mode={this.state.mode}
            pageHeader={strings.header.editProduct}
            toggleActivationModalVisibility={
              this.toggleActivationModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            selectedProductData={this.props.selectedProductData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            handleDateChange={this.handleDateChange}
            handleDateKeyDown={this.handleDateKeyDown}
            activationModalOpen={this.state.activationModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            validationErrors={this.state.validationErrors}
          />
        </div>
      </div>
    );
  };
}

ProductEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedProductData: PropTypes.object.isRequired,
  getProductById: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  updateSelectedProduct: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { products } = state;
  const { loadingPage, selectedProductData } = products;
  return {
    loadingPage,
    selectedProductData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProductById: (id, headers) => {
      dispatch(productActions.getProductById(id, headers));
    },
    updateProduct: (id, data, headers, history) => {
      dispatch(productActions.updateProduct(id, data, headers))
        .then(() => history.push("/products"))
        .catch(error => console.log(error));
    },
    activateProduct: (id, headers) => {
      return dispatch(productActions.activateProduct(id, headers))
    },
    deactivateProduct: (id, headers) => {
      return dispatch(productActions.deactivateProduct(id, headers))
    },
    updateSelectedProduct: (key, value) => {
      dispatch(productActions.updateSelectedProduct(key, value));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductEditContainer);
