import React from "react";
import { Input } from "semantic-ui-react";
import { strings } from "../../resources";
import PropTypes from "prop-types";

class SearchBar extends React.Component {
  handleSearchCriteriaChange = async (event, key) => {
    const { headers } = this.props;
    let newData = Object.assign({}, this.props.searchCriteria);
    newData = key.value;
    if (newData.trim().length === 0) {
      newData = newData.trim();
    }
    await this.props.updateSearchCriteria(newData);
    this.props.searchForms(this.props.searchCriteria, headers);
  };

  render = () => {
    return (
      <Input
        className={this.props.className}
        icon="search"
        name="search_box"
        onChange={this.handleSearchCriteriaChange}
        placeholder={strings.form.placeholder.searchBar}
        value={this.props.searchCriteria}
      />
    );
  };
}

SearchBar.propTypes = {
  className: PropTypes.string.isRequired,
  searchForms: PropTypes.func.isRequired,
  searchCriteria: PropTypes.string.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired
};

export default SearchBar;
