import { API } from "../../services";
import { patientActionTypes } from "./patient.actionTypes";

const api = new API();
const uuid = require("uuid");

const createPatient = (details, headers) => {
  const request = () => {
    return { type: patientActionTypes.CREATE_PATIENT_REQUEST };
  };
  const success = data => {
    return { type: patientActionTypes.CREATE_PATIENT_SUCCESS, payload: data };
  };
  const failure = error => {
    return { type: patientActionTypes.CREATE_PATIENT_FAILURE, payload: error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createPatient" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .createPatient(details)
        .then(data => {
          dispatch(success(data));
          resolve(data.data);
        })
        .catch(error => {
          dispatch(failure(error));
          reject(error);
        });
    });
  };
};

const updatePatient = (id, details, headers) => {
  const request = details => {
    return { type: patientActionTypes.UPDATE_PATIENT_REQUEST, details };
  };
  const success = data => {
    return { type: patientActionTypes.UPDATE_PATIENT_SUCCESS, data };
  };
  const failure = error => {
    return { type: patientActionTypes.UPDATE_PATIENT_FAILURE, error };
  };
  const signatureSuccess = data => {
    return { type: patientActionTypes.APPLY_DOCTOR_SIGNATURE_SUCCESS };
  };
  const signatureFailure = error => {
    return { type: patientActionTypes.APPLY_DOCTOR_SIGNATURE_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "updatePatient" });

  return dispatch => {
    dispatch(request(details));
    return new Promise((resolve, reject) => {
      let doctorSignatureUpdateRequired = false;

      //Avoid saving doctor signature details on the record unless they are required
      if (details.doctor_signature_to_be_updated === true) {
        delete details.doctor_signature_to_be_updated;
        doctorSignatureUpdateRequired = true;
      } else {
        delete details.doctor_signature_to_be_updated;
        delete details.doctor_signature_name;
        delete details.doctor_signature_path;
      }

      let patientUpdate = api.updatePatient(id, details).then(
        response => {
          dispatch(success(response.data));
          if (doctorSignatureUpdateRequired) {
            return response.data;
          } else {
            resolve(response.data);
          }
        },
        error => dispatch(failure(error))
      );

      //If its required, the doctor signature update should be done when saving the form
      if (doctorSignatureUpdateRequired) {
        let signatureUpdate = api.applyDoctorSignature(id, details).then(
          response => {
            dispatch(signatureSuccess());
            return response;
          },
          error => {
            dispatch(signatureFailure(error));
            reject(error);
          }
        );

        Promise.all([patientUpdate, signatureUpdate]).then(result => {
          resolve(result[0]);
        });
      }
    });
  };
};

const activatePatient = (id, headers) => {
  const request = () => {
    return { type: patientActionTypes.ACTIVATE_PATIENT_REQUEST };
  };
  const success = data => {
    return { type: patientActionTypes.ACTIVATE_PATIENT_SUCCESS, data };
  };
  const failure = error => {
    return { type: patientActionTypes.ACTIVATE_PATIENT_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "activatePatient" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.activatePatient(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => dispatch(failure(error))
      );
    });
  };
};

const deactivatePatient = (id, headers) => {
  const request = () => {
    return { type: patientActionTypes.DEACTIVATE_PATIENT_REQUEST };
  };
  const success = data => {
    return { type: patientActionTypes.DEACTIVATE_PATIENT_SUCCESS, data };
  };
  const failure = error => {
    return { type: patientActionTypes.DEACTIVATE_PATIENT_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deactivatePatient" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.deactivatePatient(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => dispatch(failure(error))
      );
    });
  };
};

const deletePatient = (id, headers) => {
  const request = () => {
    return { type: patientActionTypes.DELETE_PATIENT_REQUEST };
  };
  const success = data => {
    return { type: patientActionTypes.DELETE_PATIENT_SUCCESS, data };
  };
  const failure = error => {
    return { type: patientActionTypes.DELETE_PATIENT_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deletePatient" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deletePatient(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getPatients = (headers, searchTerm) => {
  const request = () => {
    return { type: patientActionTypes.GET_PATIENTS_REQUEST };
  };
  const success = payload => {
    return { type: patientActionTypes.GET_PATIENTS_SUCCESS, payload };
  };
  const failure = error => {
    return { type: patientActionTypes.GET_PATIENTS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getPatients" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .getPatients(searchTerm)
        .then(response => {
          dispatch(success(response.data));
          resolve(response.data);
        })
        .catch(error => {
          dispatch(failure(error));
          reject(error);
        });
    });
  };
};

const getPatientById = (id, headers) => {
  const request = () => {
    return { type: patientActionTypes.GET_PATIENT_BY_ID_REQUEST };
  };
  const success = payload => {
    return { type: patientActionTypes.GET_PATIENT_BY_ID_SUCCESS, payload };
  };
  const failure = error => {
    return { type: patientActionTypes.GET_PATIENT_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getPatientById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getPatientById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const getPatientHistoryById = (id, startTimeStamp, headers) => {
  const request = () => {
    return { type: patientActionTypes.GET_PATIENT_HISTORY_BY_ID_REQUEST };
  };
  const success = payload => {
    return {
      type: patientActionTypes.GET_PATIENT_HISTORY_BY_ID_SUCCESS,
      payload
    };
  };
  const failure = error => {
    return {
      type: patientActionTypes.GET_PATIENT_HISTORY_BY_ID_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getPatientHistoryById"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getPatientHistoryById(id, startTimeStamp).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const applyDoctorSignature = (id, details, headers) => dispatch => {
  return new Promise(function(resolve, reject) {
    const request = details => {
      return {
        type: patientActionTypes.APPLY_DOCTOR_SIGNATURE_REQUEST,
        details
      };
    };
    const success = data => {
      return { type: patientActionTypes.APPLY_DOCTOR_SIGNATURE_SUCCESS, data };
    };
    const failure = error => {
      return { type: patientActionTypes.APPLY_DOCTOR_SIGNATURE_FAILURE, error };
    };

    api.setHeaders({
      ...headers,
      systemHeader_subsysref: "applyDoctorSignature"
    });

    dispatch(request(details));

    api.applyDoctorSignature(id, details).then(
      response => {
        dispatch(success(response));
        resolve();
      },
      error => {
        dispatch(failure(error));
        reject(error);
      }
    );
  });
};

const generatePatientID = () => {
  const request = () => {
    return { type: patientActionTypes.GENERATE_PATIENT_ID_REQUEST };
  };
  const success = data => {
    return { type: patientActionTypes.GENERATE_PATIENT_ID_SUCCESS, data };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(uuid.v1()));
  };
};

const updateSelectedPatient = (key, value) => {
  const request = (key, value) => {
    return {
      type: patientActionTypes.UPDATE_SELECTED_PATIENT_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: patientActionTypes.UPDATE_SELECTED_PATIENT_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: patientActionTypes.UPDATE_SELECTED_PATIENT_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const deleteFromSelectedPatient = key => {
  const request = key => {
    return {
      type: patientActionTypes.DELETE_FROM_SELECTED_PATIENT_REQUEST,
      key
    };
  };
  const success = key => {
    return {
      type: patientActionTypes.DELETE_FROM_SELECTED_PATIENT_SUCCESS,
      key
    };
  };
  const failure = () => {
    return { type: patientActionTypes.DELETE_FROM_SELECTED_PATIENT_FAILURE };
  };

  return dispatch => {
    dispatch(request(key));
    if (key) {
      dispatch(success(key));
    } else {
      dispatch(failure());
    }
  };
};

const updateSelectedPatientTab = (patientId, tabNumber) => {
  const request = () => {
    return {
      type: patientActionTypes.UPDATE_SELECTED_PATIENT_TAB_REQUEST
    };
  };
  const success = (patientId, tabNumber) => {
    return {
      type: patientActionTypes.UPDATE_SELECTED_PATIENT_TAB_SUCCESS,
      patientId,
      tabNumber
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(patientId, tabNumber));
  };
};

const clearSelectedPatient = () => {
  const request = () => {
    return { type: patientActionTypes.CLEAR_SELECTED_PATIENT_REQUEST };
  };
  const success = () => {
    return { type: patientActionTypes.CLEAR_SELECTED_PATIENT_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

export const patientActions = {
  createPatient,
  updatePatient,
  activatePatient,
  deactivatePatient,
  deletePatient,
  generatePatientID,
  deleteFromSelectedPatient,
  getPatientById,
  getPatientHistoryById,
  getPatients,
  applyDoctorSignature,
  updateSelectedPatient,
  updateSelectedPatientTab,
  clearSelectedPatient
};
