import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Loader } from "semantic-ui-react";
import { Container } from "semantic-ui-react";
import { connect } from "react-redux";
import { authActions } from "../../App/store/actions/auth.actions";
import { userActions } from "../../Users/store/actions/user.actions";
import { withRouter } from "react-router-dom";
import PreConsultationPatientLogin from "../components/PreConsultationPatientLogin";
import { strings } from "../resources";
import formatDateInput from "../helpers/FormatDateInput";
import "./css/PreConsultation.css";

class PreConsultationPatientLoginContainer extends React.Component {
  state = {
    linkId: this.props.match.params.id,
    patientId: this.props.location.state
      ? this.props.location.state.patientId
      : null,
    patientData: this.props.location.state
      ? this.props.location.state.patientData
      : null,
    selectedFormTypes: this.props.location.state
      ? this.props.location.state.selectedFormTypes
      : [],
    selectedFormNames: this.props.location.state
      ? this.props.location.state.selectedFormNames
      : [],
    verifyData: {},
    cancelModalOpen: false,
    error: false,
    showFailureMessage: false,
    loading: true,
  };

  componentDidMount = async () => {
    this.setState({
      loading: true,
    });
    await this.props.checkLoginLinkForExistingPatient(
      this.state.linkId,
      this.props.headers
    );
    this.setState({
      loading: false,
    });
  };

  toggleCancelModalVisibility = () => {
    this.setState({
      cancelModalOpen: !this.state.cancelModalOpen,
    });
  };

  handleModalConfirm = (modalClassName) => {
    switch (modalClassName) {
      case "cancelModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  handleChange = (e, data) => {
    const newData = { ...this.state.verifyData, [data.name]: data.value };
    this.setState({
      verifyData: newData,
      error: false,
    });
  };

  handleDateChange = async (event, data) => {
    let dataToSubmit = "";
    let currentValue = this.state.verifyData[data.name];
    // Only look to add dashes if there is something to format, and if the user
    // is typing rather than deleting
    if (
      currentValue &&
      data.value.length > 0 &&
      data.value.length > currentValue.length
    ) {
      dataToSubmit = formatDateInput(data.value);
    } else {
      dataToSubmit = data.value;
    }
    const newData = { ...this.state.verifyData, [data.name]: dataToSubmit };
    this.setState({
      verifyData: newData,
      error: false,
    });
  };

  handleDateKeyDown = (event) => {
    // Filter the input to the date box - restrict it to numbers, the dash separator,
    // the backspace key (keyCode 8), and the delete key (keyCode 46)
    if (
      isNaN(event.key) &&
      event.key !== "-" &&
      event.keyCode !== 8 &&
      event.keyCode !== 46
    ) {
      event.preventDefault();
    }
  };

  handleSubmit = async () => {
    // Check if the details provided match the patient details stored in the database
    let dob;

    try {
      dob = moment
        .utc(this.state.verifyData.date_of_birth, "DD-MM-YYYY")
        .toISOString();
    } catch {
      dob = null; //The new patient check doesn't use DOB
    }

    let details = {
      link_id: this.state.linkId,
      first_name: this.state.verifyData.first_name,
      surname: this.state.verifyData.surname,
      date_of_birth: dob,
      email_address: this.state.verifyData.email_address,
    };
    console.log("verifyData", this.state.verifyData);
    console.log("details", details);

    //Call the API to get the remote patient login details
    let result = await this.props.checkPatientLoginLink(
      details,
      this.props.headers
    );
    console.log("RESULT", result);

    //ToDo: log out the user (in case another role was already logged in)

    if (result && result.status) {
      //Log in the generic remote patient user (using the password here is a temporary measure - we need somthing more secure - see below)
      await this.props.signIn(result.user, result.password);

      // This isn't working yet - we can look up the password in the preAuthentication lambda, but we haven't found a way to set it there
      // It might be necessary to set up a custom auth flow, and use alternative lambda triggers to log in this user
      // We don't actually want the password to be provided to the UI, because we don't want the user to view the network
      // traffic and retrieve the password for future use, without a link.
      // let usernamePasswordOpts = {
      //   username: "remote-patient",
      //   password: "placeholder", //This will be replaced in the back end, based on the validation data being valid
      //   validationData: {
      //     sub: this.state.linkId, //It seems Cognito will ignore anything that isn't set up in the user pool, but we can pass id with sub
      //     given_name: this.state.verifyData.first_name,
      //     family_name: this.state.verifyData.surname,
      //     date_of_birth: dob,
      //     email: this.state.verifyData.email_address,
      //   },
      // };

      // //Log in the user with the username and validation data instead of a password
      // await this.props.signInWithLink(usernamePasswordOpts);

      //Setting the state here only works if a user was logged in when this page loaded, so the state data is passed via redux instead
      this.props.history.push({
        pathname: "/forms/preconsultation/progress/",
        // state: {
        //   patientId: this.state.patientId,
        //   patientData: this.state.patientData,
        //   selectedFormTypes: result.selectedFormTypes,
        //   selectedFormNames: result.selectedFormNames,
        //   mode: "create",
        // },
      });
    } else {
      console.log("The patient details did not match", result);
      this.setState({
        showFailureMessage: true,
      });
    }
  };

  handleCancel = () => {
    this.openPreConsultationComplete();
  };

  openPreConsultationComplete = async () => {
    // send user to fill in the forms
    this.props.history.push({
      pathname: "/forms/preconsultation/complete/",
      state: {
        patientId: this.state.patientId,
      },
    });
  };

  render = () => {
    return (
      <React.Fragment>
        <Container className="preConsultationVerifyContainer">
          {this.props.isValidLink === undefined ? (
            <Loader active>{strings.header.loading}</Loader>
          ) : (
            <PreConsultationPatientLogin
              loading={this.state.loading}
              data={this.state.verifyData}
              error={this.state.error}
              showFailureMessage={this.state.showFailureMessage}
              handleChange={this.handleChange}
              handleDateChange={this.handleDateChange}
              handleDateKeyDown={this.handleDateKeyDown}
              handleSubmit={this.handleSubmit}
              handleModalConfirm={this.handleModalConfirm}
              toggleCancelModalVisibility={this.toggleCancelModalVisibility}
              cancelModalOpen={this.state.cancelModalOpen}
              isValidLink={this.props.isValidLink}
              isExistingPatient={this.props.isExistingPatient}
            />
          )}
        </Container>
      </React.Fragment>
    );
  };
}

PreConsultationPatientLoginContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
  getPatientCredentials: PropTypes.func.isRequired,
  checkLoginLinkForExistingPatient: PropTypes.func.isRequired,
  checkPatientLoginLink: PropTypes.func.isRequired,
  isExistingPatient: PropTypes.bool,
  isValidLink: PropTypes.bool,
  result: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { isExistingPatient, isValidLink, result, loadingPage } = state.users;
  return {
    isExistingPatient,
    isValidLink,
    result,
    loadingPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (username, password) => {
      dispatch(authActions.signIn(username, password));
    },
    signInWithLink: (usernamePasswordOpts) => {
      dispatch(authActions.signInWithLink(usernamePasswordOpts));
    },
    getPatientCredentials: (headers) => {
      return dispatch(userActions.getPatientCredentials(headers));
    },
    checkLoginLinkForExistingPatient: (id, headers) => {
      return dispatch(
        userActions.checkLoginLinkForExistingPatient(id, headers)
      );
    },
    checkPatientLoginLink: (details, headers) => {
      return dispatch(userActions.checkPatientLoginLink(details, headers));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PreConsultationPatientLoginContainer)
);
