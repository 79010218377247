import React from "react";
import ReactTable from "react-table";
import { TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      return {
        onClick: () => {
          if (rowInfo) {
            this.props.history.push("/products/" + rowInfo.original.id);
          }
        }
      };
    }
    return {}; // getTdProps requires a return statement
  };

  render = () => {
    let data = this.props.productListData;

    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          onlyShowActive={this.props.onlyShowActive}
          onlyShowInactive={this.props.onlyShowInactive}
          toggleShowActive={this.props.toggleShowActive}
          toggleShowInactive={this.props.toggleShowInactive}
        />
        <div>
          <ReactTable
            data={data}
            columns={[
              {
                Header: strings.table.header.productName,
                accessor: "product_name",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(20)
              },
              {
                Header: strings.table.header.productType,
                accessor: "product_type",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(20)
              },
              {
                Header: strings.table.header.batchName,
                accessor: "batch_name",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(20)
              },
              {
                Header: strings.table.header.batchExpiryDate,
                accessor: "expiry_date",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(20)
              },
              {
                Header: strings.table.header.createdDate,
                accessor: "created_date_formatted",
                headerStyle: { textAlign: "left" },
                Cell: cellTruncate(20)
              },
              {
                Header: strings.table.header.status,
                accessor: "active",
                width: 70,
                headerStyle: { textAlign: "left" },
                Cell: row => (
                  <div style={{ textAlign: "center" }}>
                    <Icon
                      name={row.original.active === true ? "check" : "close"}
                      size="big"
                    />
                  </div>
                )
              }
            ]}
            defaultSorted={[
              {
                id: "active",
                desc: true
              },
              {
                id: "product_name",
                desc: false
              },
            ]}
            className="-striped -highlight"
            defaultPageSize={10}
            minRows="1"
            getTdProps={this.getTdProps}
            getTrProps={this.getTrProps}
            noDataText="No results found."
            pageSizeOptions={[10, 20, 30]}
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  productListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default TableComponent;
