import moment from "moment";

export const dateEpochToDateTimeString = (dateEpoch, separator = "/") => {
  const date = new Date(dateEpoch);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  let dateString = "";
  dateString =
    day >= 10
      ? `${dateString}${day}${separator}`
      : `${dateString}0${day}${separator}`;
  dateString =
    month >= 10
      ? `${dateString}${month}${separator}`
      : `${dateString}0${month}${separator}`;
  dateString += year;
  return dateString;
};

export const dateStringToIsoDateTimeString = (dateString) => {
  let isoDateTimeValue;
  try {
    isoDateTimeValue = moment.utc(dateString, "DD-MM-YYYY").toISOString();
  } catch {
    isoDateTimeValue = undefined;
  }
  return isoDateTimeValue;
};

export const isoDateTimeStringToDateString = (isDateTimeString) => {
  let dateValue;
  try {
    dateValue = moment(isDateTimeString).format("DD-MM-YYYY").toString();
  } catch {
    dateValue = undefined;
  }
  return dateValue;
};

export const isoDateTimeStringToDateTimeString = (isDateTimeString) => {
  let dateValue;
  try {
    dateValue = moment(isDateTimeString).format("DD-MM-YYYY HH:mm").toString();
  } catch {
    dateValue = undefined;
  }
  return dateValue;
};
