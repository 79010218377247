import React from "react";
import { Icon, Button, Grid, Header, Checkbox } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./css/ProductComponents.css";
import PropTypes from "prop-types";
import { strings } from "../resources";

class TableFunctions extends React.Component {
  render = () => {
    return (
      <Grid columns={3} className="TableFunctions">
        <Grid.Column>
          <Header as="h1">{strings.header.productList}</Header>
        </Grid.Column>
        <Grid.Column />
        <Grid.Column>
          <div className="formListNewFormSelection">
            <Checkbox
              toggle
              label={strings.header.activeFilter}
              className="showActiveProductsToggle"
              checked={this.props.onlyShowActive}
              onChange={this.props.toggleShowActive}
            />
          </div>
          <div style={{ clear: "both" }} />
          <div className="formListNewFormSelection">
            <Checkbox
              toggle
              label={strings.header.inactiveFilter}
              className="showActiveProductsToggle"
              checked={this.props.onlyShowInactive}
              onChange={this.props.toggleShowInactive}
            />
          </div>
          <div style={{ clear: "both" }} />
          <div className="formListNewFormSelection">
            <Link to={`/products/create`}>
              <Button primary className="addProductButton">
                <Icon name="building" />
                {strings.button.addProductLabel}
              </Button>
            </Link>
          </div>
        </Grid.Column>
      </Grid>
    );
  };
}

TableFunctions.propTypes = {
  toggleShowActive: PropTypes.func.isRequired
};

export default TableFunctions;
