import React from "react";
import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import { imageActions } from "../store/actions/image.actions";
import PropTypes from "prop-types";
import axios from "axios";
import ImageManager from "../components/ImageManager";
import { dateHelper } from "../helpers";
import { strings } from "../resources";
import { EXIF } from "exif-js";

class ImageManagerContainer extends React.Component {
  state = {
    cancelChangesModalOpen: false,
    confirmDeleteModalOpen: false,
    confirmSaveModalOpen: false,
    enterPhotoNameModalOpen: false,
    imageToCancel: {},
    imageToDelete: {},
    imageToSave: {},
    imageStates: [],
    takenPhotoEventData: {}
  };

  componentDidMount = async () => {
    this.props.setFileTypeWarningVisible(false);
    this.props.setLoadingImages(true);
    await this.props.getImages(
      `${this.props.imagePath}/thumbnails`,
      this.props.headers
    );
    this.props.setLoadingImages(false);
    let images = this.props.localData;
    let imageStatePairs = [];
    images.forEach(image => {
      let pair = { image: image.imageName, mode: "view" };
      imageStatePairs.push(pair);
    });
    this.setState({
      imageStates: imageStatePairs
    });
  };

  componentWillUnmount = () => {
    this.props.resetLocalImageData();
  };

  getViewModeByImage = image => {
    let viewMode = "";
    let imageModePairs = this.state.imageStates.slice();
    imageModePairs.forEach((pair, index) => {
      if (pair.image === image.imageName) {
        viewMode = imageModePairs[index].mode;
      }
    });
    return viewMode;
  };

  handleCancel = () => {
    this.handleModeSwitch(this.state.imageToCancel);
    this.restoreImagePreviousProperties(this.state.imageToCancel);
    this.toggleCancelChangesModalVisibility();
    if (!this.state.cancelChangesModalOpen) {
      this.setState({
        imageToCancel: {}
      });
    }
  };

  handleChange = (event, data, key) => {
    let newLocalImage = this.props.localData.slice()[key];
    newLocalImage[data.name] = data.value;
    this.props.updateLocalImages(key, newLocalImage);
  };

  handleDateKeyDown = event => {
    //Filter the input to the date box - restrict it to numbers, the dash separator and the backspace key (keyCode 8)
    if (isNaN(event.key) && event.key !== "-" && event.keyCode !== 8) {
      event.preventDefault();
    }
  };

  handleDelete = async () => {
    this.props.setLoadingImages(true);
    await this.props.deleteImage(
      this.props.imagePath +
        "/thumbnails/" +
        this.state.imageToDelete.imageName,
      this.props.headers
    );
    await this.props.deleteImage(
      this.props.imagePath + "/fullsized/" + this.state.imageToDelete.imageName,
      this.props.headers
    );
    if (!this.state.confirmDeleteModalOpen) {
      this.setState({
        imageToDelete: {}
      });
    }
    await this.props.getImages(
      `${this.props.imagePath}/thumbnails`,
      this.props.headers
    );
    this.props.setLoadingImages(false);
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleUpdate();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "confirmDeleteModal":
        this.handleDelete();
        break;
      case "enterPhotoNameModal":
        this.handleUpload(this.state.takenPhotoEventData, true);
        break;
      default:
        break;
    }
  };

  handleModeSwitch = image => {
    let imageModePairs = this.state.imageStates.slice();
    imageModePairs.forEach((pair, index) => {
      if (pair.image === image.imageName) {
        imageModePairs[index] = {
          image: image.imageName,
          mode: pair.mode === "view" ? "edit" : "view"
        };
      }
    });
    this.setState({
      imageStates: imageModePairs
    });
  };

  handleTakePhoto = event => {
    this.setState({ takenPhotoEventData: event.target.files[0] });
    this.toggleEnterPhotoNameModalVisibility();
  };

  handleUpdate = async () => {
    let image = this.state.imageToSave;
    const thumbnailUpdate = {
      imagePath: `${this.props.imagePath}/thumbnails/${image.imageName}`,
      metadata: {
        caption: image.caption,
        takenOnDate: image.takenOnDate,
        uploadedOnDate: image.uploadedOnDate
      }
    };
    const fullsizedUpdate = {
      imagePath: `${this.props.imagePath}/fullsized/${image.imageName}`,
      metadata: {
        caption: image.caption,
        takenOnDate: image.takenOnDate,
        uploadedOnDate: image.uploadedOnDate
      }
    };
    this.props.setLoadingImages(true);
    await this.props.updateImage(thumbnailUpdate, this.props.headers);
    await this.props.updateImage(fullsizedUpdate, this.props.headers);
    this.handleModeSwitch(this.state.imageToSave);
    if (!this.state.confirmSaveModalOpen) {
      this.setState({
        imageToSave: {}
      });
    }
    this.props.setLoadingImages(false);
  };

  handleThumbnailUpload = async (encodedImage, imageName, fromCamera) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      var thumbnailCanvas = document.createElement("canvas");
      var fullsizedCanvas = document.createElement("canvas");
      img.onload = async () => {
        fullsizedCanvas.width = img.width;
        fullsizedCanvas.height = img.height;
        fullsizedCanvas
          .getContext("2d")
          .drawImage(img, 0, 0, fullsizedCanvas.width, fullsizedCanvas.height);
        thumbnailCanvas.width = fullsizedCanvas.width * 0.2;
        thumbnailCanvas.height = fullsizedCanvas.height * 0.2;
        thumbnailCanvas
          .getContext("2d")
          .drawImage(
            fullsizedCanvas,
            0,
            0,
            thumbnailCanvas.width,
            thumbnailCanvas.height
          );
        fetch(thumbnailCanvas.toDataURL());
        axios
          .get(thumbnailCanvas.toDataURL(), { responseType: "blob" })
          .then(async response => {
            if (response.data) {
              let currentDate = dateHelper.getTodaysDateFormatted();
              const upload = {
                imagePath: `${this.props.imagePath}/thumbnails/${imageName}`,
                imageContent: response.data,
                metadata: {
                  caption: "",
                  takenOnDate: fromCamera ? currentDate : "",
                  uploadedOnDate: currentDate
                }
              };
              await this.props.uploadImage(upload, this.props.headers);
              resolve();
            } else {
              console.log("image is empty");
              reject();
            }
          });
      };
      img.src = encodedImage;
    });
  };

  handleFullsizedUpload = async (encodedImage, imageName, fromCamera) => {
    return new Promise((resolve, reject) => {
      axios.get(encodedImage, { responseType: "blob" }).then(async response => {
        if (response.data) {
          let currentDate = dateHelper.getTodaysDateFormatted();
          const upload = {
            imagePath: `${this.props.imagePath}/fullsized/${imageName}`,
            imageContent: response.data,
            metadata: {
              caption: "",
              takenOnDate: fromCamera ? currentDate : "",
              uploadedOnDate: currentDate
            }
          };
          await this.props.uploadImage(upload, this.props.headers);
          resolve();
        } else {
          console.log("image is empty");
          reject();
        }
      });
    });
  };

  // Renders an image with orientation EXIF data to canvas, rotate and return an image
  getRotationCorrectedImage = imageData => {
    return new Promise((resolve, reject) => {
      if (imageData.exifdata && imageData.exifdata.Orientation) {
        const offscreenCanvas = document.createElement("canvas");
        offscreenCanvas.width = imageData.exifdata.PixelXDimension;
        offscreenCanvas.height = imageData.exifdata.PixelYDimension;
        const offscreenContext = offscreenCanvas.getContext("2d");
        const rotationTransforms = {
          1: ctx => {
            // Do nothing
          },
          2: ctx => {
            ctx.scale(-1, 1);
          },
          3: ctx => {
            ctx.rotate((180 * Math.PI) / 180);
          },
          4: ctx => {
            ctx.rotate((180 * Math.PI) / 180);
            ctx.scale(-1, 1);
          },
          5: ctx => {
            ctx.rotate((90 * Math.PI) / 180);
            ctx.scale(-1, 1);
          },
          6: ctx => {
            ctx.rotate((90 * Math.PI) / 180);
          },
          7: ctx => {
            ctx.rotate((270 * Math.PI) / 180);
            ctx.scale(-1, 1);
          },
          8: ctx => {
            ctx.rotate((270 * Math.PI) / 180);
          }
        };
        const imageToCorrect = new Image();
        imageToCorrect.onload = () => {
          offscreenContext.translate(
            imageData.exifdata.PixelXDimension / 2,
            imageData.exifdata.PixelYDimension / 2
          );
          rotationTransforms[imageData.exifdata.Orientation](offscreenContext);
          offscreenContext.translate(
            -(imageData.exifdata.PixelXDimension / 2),
            -(imageData.exifdata.PixelYDimension / 2)
          );
          offscreenContext.drawImage(imageToCorrect, 0, 0);
          offscreenCanvas.toBlob(blob => {
            resolve(blob);
          });
        };
        imageToCorrect.src = URL.createObjectURL(imageData);
      } else {
        resolve(imageData);
      }
    });
  };

  handleUpload = async (data, fromCamera) => {
    if (data) {
      await new Promise((resolve, reject) => {
        EXIF.getData(data, () => {
          resolve();
        });
      });
      const correctedImageBlob = await this.getRotationCorrectedImage(data);

      const fileType = data.type;
      if (!fileType.match("image")) {
        this.props.setFileTypeWarningVisible(true);
      } else {
        this.props.setFileTypeWarningVisible(false);
        let imageName;
        if (fromCamera) {
          imageName = `${this.props.photoFileName}.jpeg`;
        } else {
          imageName = data.name;
        }
        const reader = new FileReader();
        reader.readAsDataURL(correctedImageBlob);
        reader.onload = async () => {
          const encodedImage = reader.result;
          this.props.setLoadingImages(true);
          try {
            await this.handleThumbnailUpload(
              encodedImage,
              imageName,
              fromCamera
            );
            await this.handleFullsizedUpload(
              encodedImage,
              imageName,
              fromCamera
            );
            await this.props.getImages(
              `${this.props.imagePath}/thumbnails`,
              this.props.headers
            );
            let images = this.props.localData;
            let imageStatePairs = [];
            images.forEach(image => {
              let pair = { image: image.imageName, mode: "view" };
              imageStatePairs.push(pair);
            });
            this.setState({
              imageStates: imageStatePairs
            });
            this.props.setLoadingImages(false);
          } catch (error) {
            console.log("error processing image: ", error);
          }
        };
      }
    }
  };

  isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/.test(
      navigator.platform
    );
  };

  prepareForCancel = image => {
    this.setState({
      imageToCancel: image
    });
    this.toggleCancelChangesModalVisibility();
  };

  prepareForDelete = image => {
    this.setState({
      imageToDelete: image
    });
    this.toggleConfirmDeleteModalVisibility();
  };

  prepareForSave = image => {
    this.setState({
      imageToSave: image
    });
    this.toggleConfirmSaveModalVisibility();
  };

  restoreImagePreviousProperties = image => {
    let remoteData = this.props.remoteData.slice();
    let dataToRestore = {};
    remoteData.forEach(remoteImage => {
      if (remoteImage.imageName === image.imageName) {
        dataToRestore = Object.assign({}, remoteImage);
      }
    });
    if (dataToRestore) {
      let localData = this.props.localData.slice();
      let localImageToRestore = {};
      let indexOfDataToReplace = -1;
      localData.forEach((localImage, index) => {
        if (localImage.imageName === dataToRestore.imageName) {
          localImageToRestore = Object.assign({}, localImage);
          indexOfDataToReplace = index;
        }
      });
      if (localImageToRestore && indexOfDataToReplace > -1) {
        localImageToRestore.caption = dataToRestore.caption;
        localImageToRestore.takenOnDate = dataToRestore.takenOnDate;
        this.props.updateLocalImages(indexOfDataToReplace, localImageToRestore);
      }
    }
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleConfirmDeleteModalVisibility = () => {
    this.setState({
      confirmDeleteModalOpen: !this.state.confirmDeleteModalOpen
    });
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleEnterPhotoNameModalVisibility = () => {
    this.setState({
      enterPhotoNameModalOpen: !this.state.enterPhotoNameModalOpen
    });
  };

  render = () => {
    return (
      <div>
        {this.props.loadingImageData && (
          <Loader active>{strings.header.loading}</Loader>
        )}
        {!this.props.loadingImageData && (
          <ImageManager
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmDeleteModalOpen={this.state.confirmDeleteModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enterPhotoNameModalOpen={this.state.enterPhotoNameModalOpen}
            getViewModeByImage={this.getViewModeByImage}
            fileTypeWarningVisible={this.props.fileTypeWarningVisible}
            handleChange={this.handleChange}
            handleDateKeyDown={this.handleDateKeyDown}
            handleDelete={this.handleDelete}
            handleModalConfirm={this.handleModalConfirm}
            handleModeSwitch={this.handleModeSwitch}
            handleTakePhoto={this.handleTakePhoto}
            handleUpload={this.handleUpload}
            headers={this.props.headers}
            imagePath={this.props.imagePath}
            imageStates={this.state.imageStates}
            isMobile={this.isMobile}
            localData={this.props.localData}
            prepareForCancel={this.prepareForCancel}
            prepareForDelete={this.prepareForDelete}
            prepareForSave={this.prepareForSave}
            remoteData={this.remoteData}
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmDeleteModalVisibility={
              this.toggleConfirmDeleteModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnterPhotoNameModalVisibility={
              this.toggleEnterPhotoNameModalVisibility
            }
          />
        )}
      </div>
    );
  };
}

ImageManagerContainer.propTypes = {
  headers: PropTypes.object,
  imagePath: PropTypes.string.isRequired,
  localData: PropTypes.array.isRequired,
  remoteData: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  const { images } = state;
  const {
    localData,
    remoteData,
    loadingImageData,
    fileTypeWarningVisible,
    photoFileName
  } = images;
  return {
    localData,
    remoteData,
    loadingImageData,
    fileTypeWarningVisible,
    photoFileName
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getImages: (path, headers) => {
      return dispatch(imageActions.getImages(path, headers));
    },
    updateLocalImages: (index, newVal) => {
      return dispatch(imageActions.updateLocalImages(index, newVal));
    },
    setLoadingImages: status => {
      return dispatch(imageActions.setLoadingImages(status));
    },
    uploadImage: (upload, headers) => {
      return dispatch(imageActions.uploadImage(upload, headers));
    },
    setFileTypeWarningVisible: visible => {
      return dispatch(imageActions.setFileTypeWarningVisible(visible));
    },
    deleteImage: (imageName, headers) => {
      return dispatch(imageActions.deleteImage(imageName, headers));
    },
    updateImage: (update, headers) => {
      return dispatch(imageActions.updateImage(update, headers));
    },
    resetLocalImageData: () => {
      return dispatch(imageActions.resetLocalImageData());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageManagerContainer);
