import { formSchemas } from "../resources/formSchemas/index";

export default (formData, formType) => {
  return new Promise((resolve, reject) => {
    const errors = [];
    const formSchema = formSchemas[formType];
    // First, validate all the fields present in the form data (if field should be validated))
    Object.keys(formData).forEach(field => {
      if (formSchema.hasOwnProperty(field)) {
        // If validation function provided, use it, otherwise field is ignored
        if (formSchema[field].hasOwnProperty("validate") === true) {
          const validationResult = formSchema[field].validate(field, formData);
          if (validationResult !== true) {
            errors.push({
              field: field,
              error: `${
                formSchema[field].errorMessagePrefix
              } ${validationResult}`
            });
          }
        }
      }
    });

    // Next, ensure all required fields in the schema are present in the data
    Object.keys(formSchema).forEach(schemaEntry => {
      if (formSchema[schemaEntry].required === true) {
        if (
          formData.hasOwnProperty(schemaEntry) !== true ||
          formData[schemaEntry] === null
        ) {
          errors.push({
            field: schemaEntry,
            error: `${formSchema[schemaEntry].errorMessagePrefix} is required`
          });
        }
      } else if (formSchema[schemaEntry].hasOwnProperty("requireIf")) {
        if (formSchema[schemaEntry].requireIf(formData)) {
          if (
            formData.hasOwnProperty(schemaEntry) !== true ||
            formData[schemaEntry] === null
          ) {
            errors.push({
              field: schemaEntry,
              error: `${formSchema[schemaEntry].errorMessagePrefix} is required`
            });
          }
        }
      }
    });

    const results = {
      passedValidation: errors.length === 0,
      errors
    };
    resolve(results);
  });
};
