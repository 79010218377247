import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, List } from "semantic-ui-react";
import { strings } from "../resources";
import { PatientTabControls } from "./ui";
import AestheticConcernsView from "./ui/AestheticConcernsView";
import TreatmentHistoryView from "./ui/TreatmentHistoryView";
import ExaminationNotesView from "./ui/ExaminationNotesView";
import { DoctorSignatureContainer } from "../../Images/containers";
import {
  checkResponse,
  checkGroupResponse,
  checkYesNoResponse
} from "../helpers/checkUserResponseHelpers";

class ViewModeMedicalRecordTab extends React.Component {
  render = () => {
    return (
      <React.Fragment>
        <div className="viewModeMedicalTab">
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <div className="patientSectionHeader">
                  {strings.patientRecord.header.gpInfo}
                </div>
                <div className="viewModeMedicalInfo">
                  <List relaxed>
                    <List.Item>
                      <span className="infoLabel">Name</span>
                      {checkResponse(this.props.selectedPatientData, "gp_name")}
                    </List.Item>
                    <List.Item>
                      <span className="infoLabel">Address</span>
                      {checkResponse(
                        this.props.selectedPatientData,
                        "gp_address"
                      )}
                    </List.Item>
                    <List.Item>
                      <span className="infoLabel">
                        Patient wishes GP to be contacted
                      </span>
                      {checkResponse(
                        this.props.selectedPatientData,
                        "gp_contact_approval"
                      )}
                    </List.Item>
                  </List>
                </div>
              </Grid.Column>

              <Grid.Column>
                <div className="patientSectionHeader">
                  {strings.patientRecord.header.pregnancy}
                </div>
                <div className="viewModeMedicalInfo">
                  <List relaxed>
                    <List.Item>
                      <span className="infoLabel">Pregnant</span>
                      {checkYesNoResponse(
                        this.props.selectedPatientData,
                        "pregnancy",
                        strings.patientRecord.mappings.pregnant,
                        strings.patientRecord.mappings.notPregnant
                      )}
                    </List.Item>
                    <List.Item>
                      <span className="infoLabel">Breastfeeding</span>
                      {checkYesNoResponse(
                        this.props.selectedPatientData,
                        "breastfeeding",
                        strings.patientRecord.mappings.breastfeeding,
                        strings.patientRecord.mappings.notBreastfeeding
                      )}
                    </List.Item>
                  </List>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column>
                <div className="patientSectionHeader">
                  {strings.patientRecord.header.smokingHistory}
                </div>
                <div className="viewModeMedicalInfo">
                  <List relaxed>
                    <List.Item>
                      <span className="infoLabel">Currently</span>
                      {checkYesNoResponse(
                        this.props.selectedPatientData,
                        "smokes_currently",
                        strings.patientRecord.mappings.currentSmoker,
                        strings.patientRecord.mappings.notCurrentSmoker
                      )}
                    </List.Item>
                    <List.Item>
                      <span className="infoLabel">Previously</span>
                      {checkYesNoResponse(
                        this.props.selectedPatientData,
                        "smoked_previously",
                        strings.patientRecord.mappings.previousSmoker,
                        strings.patientRecord.mappings.notPreviousSmoker
                      )}
                    </List.Item>
                  </List>
                </div>
              </Grid.Column>

              <Grid.Column>
                <div className="patientSectionHeader">
                  {strings.patientRecord.header.sunExposureHistory}
                </div>
                <div className="viewModeMedicalInfo">
                  <List relaxed>
                    <List.Item>
                      <span className="infoLabel">Tanning</span>
                      {checkYesNoResponse(
                        this.props.selectedPatientData,
                        "gets_suntans",
                        strings.patientRecord.mappings.doesTanFace,
                        strings.patientRecord.mappings.doesNotTanFace
                      )}
                    </List.Item>
                    <List.Item>
                      <span className="infoLabel">Sunbeds</span>
                      {checkYesNoResponse(
                        this.props.selectedPatientData,
                        "uses_sunbeds",
                        strings.patientRecord.mappings.doesUseSunbeds,
                        strings.patientRecord.mappings.doesNotUseSunbeds
                      )}
                    </List.Item>
                  </List>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className="patientSectionHeader">
            {strings.patientRecord.header.medicalHistory}
          </div>
          <div className="viewModeMedicalInfo">
            <List relaxed>
              <List.Item>
                <span className="infoLabel">Medical Conditions</span>
                {checkGroupResponse(
                  this.props.selectedPatientData,
                  "medical_conditions",
                  "",
                  strings.patientRecord.mappings.noMedicalConditions,
                  "none",
                  true
                )}
              </List.Item>
              <List.Item>
                <span className="infoLabel">Dermatological Diseases</span>
                {checkGroupResponse(
                  this.props.selectedPatientData,
                  "dermatological_diseases",
                  "",
                  strings.patientRecord.mappings.noDermatologicalDiseases,
                  "none"
                )}
              </List.Item>
              <List.Item>
                <span className="infoLabel">Neurological Conditions</span>
                {checkGroupResponse(
                  this.props.selectedPatientData,
                  "neurological_conditions",
                  "",
                  strings.patientRecord.mappings.noNeurologicalConditions,
                  "none"
                )}
              </List.Item>
              <List.Item>
                <span className="infoLabel">Regular Medications</span>
                {checkResponse(
                  this.props.selectedPatientData,
                  "regular_medications",
                  strings.patientRecord.mappings.noRegularMedications
                )}
              </List.Item>
              <List.Item>
                <span className="infoLabel">Supplements</span>
                {checkResponse(
                  this.props.selectedPatientData,
                  "supplements",
                  strings.patientRecord.mappings.noSupplements
                )}
              </List.Item>
              <List.Item>
                <span className="infoLabel">Drug Allergies</span>
                {checkResponse(
                  this.props.selectedPatientData,
                  "drug_allergies",
                  strings.patientRecord.mappings.noDrugAllergies
                )}
              </List.Item>
              <List.Item>
                <span className="infoLabel">General Allergies</span>
                {checkResponse(
                  this.props.selectedPatientData,
                  "general_allergies",
                  strings.patientRecord.mappings.noGeneralAllergies
                )}
              </List.Item>
            </List>
          </div>

          <div className="patientSectionHeader">
            {strings.patientRecord.header.mentalHealthHistory}
          </div>

          <div className="viewModeMedicalInfo">
            <List relaxed>
              <List.Item>
                <span className="infoLabel">Psychiatric Conditions</span>
                {checkYesNoResponse(
                  this.props.selectedPatientData,
                  "mental_health_disorders_toggle",
                  checkResponse(
                    this.props.selectedPatientData,
                    "mental_health_disorders"
                  ),
                  strings.patientRecord.mappings.noMentalHealthDisorders
                )}
              </List.Item>
              <List.Item>
                <span className="infoLabel">Psychiatric History</span>
                {checkYesNoResponse(
                  this.props.selectedPatientData,
                  "seen_psychiatrist",
                  strings.patientRecord.mappings.psychiatristSeen,
                  strings.patientRecord.mappings.noPsychiatristSeen
                )}
              </List.Item>
              <List.Item>
                <span className="infoLabel">Psychiatric Medications</span>
                {checkYesNoResponse(
                  this.props.selectedPatientData,
                  "psychiatric_medications_toggle",
                  checkResponse(
                    this.props.selectedPatientData,
                    "psychiatric_medications"
                  ),
                  strings.patientRecord.mappings.noPsychiatricMedications
                )}
              </List.Item>
            </List>
          </div>

          <div className="patientSectionHeader">
            {strings.patientRecord.header.treatmentHistory}
          </div>
          <TreatmentHistoryView
            selectedPatientData={this.props.selectedPatientData}
          />

          {this.props.selectedPatientData.hasOwnProperty("reasons") && (
            <React.Fragment>
              <div className="patientSectionHeader">
                {strings.patientRecord.header.aestheticConcerns}
              </div>
              <AestheticConcernsView
                selectedPatientData={this.props.selectedPatientData}
              />
            </React.Fragment>
          )}

          {this.props.selectedPatientData.hasOwnProperty(
            "examination_skin_condition"
          ) && (
            <>
              <div className="patientSectionHeader">
                {strings.patientRecord.header.examinationNotesHistory}
              </div>
              <ExaminationNotesView
                selectedPatientData={this.props.selectedPatientData}
              />
            </>
          )}
        </div>
        <Divider hidden />
        {this.props.selectedPatientData.doctor_signature_name != null &&
          this.props.selectedPatientData.doctor_signature_path != null &&
          this.props.selectedPatientData.doctor_signature_applied_date !=
            null && (
            <DoctorSignatureContainer
              containerWidth={240}
              containerHeight={150}
              dateDoctorSigned={
                this.props.selectedPatientData.doctor_signature_applied_date
              }
              doctorName={this.props.selectedPatientData.doctor_signature_name}
              formId={null}
              headers={null}
              signaturePathAttachedToForm={
                this.props.selectedPatientData.doctor_signature_path
              }
            />
          )}

        <PatientTabControls
          patientIsActive={this.props.selectedPatientData["active"]}
          {...this.props}
        />
      </React.Fragment>
    );
  };
}

ViewModeMedicalRecordTab.propTypes = {
  selectedPatientData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default ViewModeMedicalRecordTab;
