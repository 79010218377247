export const treatmentTypeCategoryOptions = [
  { key: 1, text: "Facial Improvement", value: "Facial Improvement" },
  { key: 2, text: "Skin Enhancement", value: "Skin Enhancement" },
  { key: 3, text: "Body Contouring", value: "Body Contouring" },
  { key: 4, text: "Other", value: "Other" }
];

export const timeUntilNextTreatmentOptions = [
  { key: 1, value: "Days", text: "Day(s)" },
  { key: 2, value: "Weeks", text: "Week(s)" },
  { key: 3, value: "Months", text: "Month(s)" }
];

export const doctorOptions = [
  { key: 1, text: "Dr Darren McKeown", value: "Dr Darren McKeown" },
  { key: 2, text: "Dr Deborah McMonigle", value: "Dr Deborah McMonigle" },
  { key: 3, text: "Dr Joanne Payne", value: "Dr Joanne Payne" },
  { key: 4, text: "Dr Rhona Cameron", value: "Dr Rhona Cameron" },
  { key: 5, text: "Dr Russell Bramhall", value: "Dr Russell Bramhall" }
];

export const skinPrepOptions = [
  { key: 1, text: "Alcohol", value: "Alcohol" },
  { key: 2, text: "Chlorhexidine", value: "Chlorhexidine" },
  { key: 3, text: "Hypochlorus Acid", value: "Hypochlorus Acid" }
];

export const injectionDeviceOptions = [
  { key: 1, text: "Needle", value: "Needle" },
  { key: 2, text: "Cannula", value: "Cannula" }
];

export const localAnaestheticsOptions = [
  { key: 1, text: "None", value: "None" },
  { key: 2, text: "Topical", value: "Topical" },
  { key: 3, text: "Local Infiltration 2% Lidocaine with Adrenaline", value: "Local Infiltration 2% Lidocaine with Adrenaline"},
  { key: 4, text: "Enerve Block 2% Lidocaine with Adrenaline", value: "Enerve Block 2% Lidocaine with Adrenaline"},
  { key: 5, text: "Local Infiltration 2% Lidocaine without Adrenaline", value: "Local Infiltration 2% Lidocaine without Adrenaline"},
  { key: 6, text: "Enerve Block 2% Lidocaine without Adrenaline", value: "Enerve Block 2% Lidocaine without Adrenaline"},
];