import React from "react";
import { withRouter } from "react-router-dom";
import FormsTemplateList from "../components/FormsTemplateList";
import { strings, enums } from "../resources";
import PropTypes from "prop-types";
import "./css/react-table.css";
import "./css/FormsList.css";

const getFormListData = () => {
  let results = [];
  for (let item in enums.formType) {
    results.push({
      form_name: enums.formType[item].name,
      form_type: enums.formType[item].link
    });
  }
  return { results: results };
};

const initialState = {
  loadingTable: false,
  pageSize: 10,
  currentPage: 0,
  startKeys: [],
  formType: null,
  formListData: getFormListData()
};

class FormTemplateListContainer extends React.Component {
  state = Object.assign({}, initialState);

  setLoading = state => {
    this.setState({ loadingTable: state });
  };

  setPage = index => {
    this.setState({ currentPage: index });
  };

  render = () => {
    return (
      <div className="formList">
        <FormsTemplateList
          formListData={this.state.formListData}
          pageTitle={strings.header.formTemplateList}
          headers={this.props.headers}
          history={this.props.history}
          setLoading={this.setLoading}
          setPage={this.setPage}
          loadingTable={this.state.loadingTable}
        />
      </div>
    );
  };
}

FormTemplateListContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(FormTemplateListContainer);
