import React from "react";
import { withRouter } from "react-router-dom";
import FormsList from "../components/FormsList";
import { connect } from "react-redux";
import { formActions } from "../store/actions/form.actions";
import { strings } from "../resources";
import PropTypes from "prop-types";
import "./css/react-table.css";
import "./css/FormsList.css";

const initialState = {
  loadingTable: false,
  deleteFormModalOpen: false,
  formType: null,
  patientFillsInForm: true
};

class FormListContainer extends React.Component {
  state = Object.assign({}, initialState);

  componentWillMount = async () => {
    await this.props.getForms(
      this.props.headers,
      this.props.patientData.id,
      this.state.formType
    );
  }

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "deleteFormFromTableModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  setLoading = state => {
    this.setState({ loadingTable: state });
  };

  setFormType = async formType => {
    // Reset the table state and set a new form type
    this.setState({
      ...initialState,
      formType: formType
    });
    this.props.getForms(
      this.props.headers,
      this.props.patientData.id,
      formType,
    );
  };

  setDeleteFormModalVisibility = () => {
    this.setState({
      deleteFormModalOpen: !this.state.deleteFormModalOpen
    });
  };

  toggleFormFillerChange = () => {
    this.setState({
      patientFillsInForm: !this.state.patientFillsInForm
    });
  }

  render = () => {
    return (
      <div className="formList">
        <FormsList
          formListData={this.props.formListData}
          pageTitle={strings.header.formList}
          patientData={this.props.patientData}
          deleteFormModalOpen={this.state.deleteFormModalOpen}
          handleDelete={this.handleDelete}
          handleModalConfirm={this.handleModalConfirm}
          headers={this.props.headers}
          history={this.props.history}
          setDeleteFormModalVisibility={this.setDeleteFormModalVisibility}
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          setLoading={this.setLoading}
          setFormType={this.setFormType}
          loadingTable={this.state.loadingTable}
          toggleFormFillerChange={this.toggleFormFillerChange}
          patientFillsInForm={this.state.patientFillsInForm}
        />
      </div>
    );
  };
}

FormListContainer.propTypes = {
  formListData: PropTypes.object,
  getForms: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { formListData } = state.forms;
  return {
    formListData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getForms: (headers, patientId, formType, pageSize, lastKey) => {
      return dispatch(
        formActions.getForms(headers, patientId, formType, pageSize, lastKey)
      );
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FormListContainer)
);
