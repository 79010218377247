import React from "react";
import PropTypes from "prop-types";
import Treatment from "../../components/Treatment";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { treatmentActions } from "../../store/actions/treatment.actions";
import { treatmentTypeActions } from "../../store/actions/treatmentType.actions";
import { patientActions } from "../../../Patients/store/actions/patient.actions";
import { productActions } from "../../../Products/store/actions/product.actions";
import { imageActions } from "../../../Images/store/actions/image.actions";
import { formActions } from "../../../Forms/store/actions/form.actions";
import { doctorOptions } from "../../resources/DropdownOptions";
import validateTreatmentInput from "../../helpers/treatmentValidator";
import { formatPreviousTreatmentOutcome } from "../../helpers/formatPreviousTreatmentOutcome";
import {
  getProductsFilteredByUsedInTreatment,
  addInactiveAndExpiredUsedProducts,
} from "../../helpers/productListHelper";
import { formatAestheticConcerns } from "../../../Patients/helpers/formatAestheticConcerns";
import { combineChangesToMedicalHistory } from "../../../Patients/helpers/combineChangesToMedicalHistory";
import { formatChangesToMedicalHistory } from "../../../Patients/helpers/formatChangesToMedicalHistory";
import downloadPdf from "../../../Forms/helpers/formToPdfFile";
import { dateEpochToDateTimeString } from "../../../App/helpers/DateTimeFormatConversion";
import { fieldsToCopy, fieldsToInsert } from "../../resources/FieldsList";

class TreatmentContainer extends React.Component {
  state = {
    loadingPage: false,
    //Get the passed through values from props.location.state if they are available
    mode: this.props.location.state
      ? this.props.location.state.mode
        ? this.props.location.state.mode
        : "view"
      : "view",
    treatmentId: this.props.location.state
      ? this.props.location.state.treatmentId
        ? this.props.location.state.treatmentId
        : null
      : null,
    patientId: this.props.location.state
      ? this.props.location.state.patientId
        ? this.props.location.state.patientId
        : null
      : null,
    selectedPatientData: this.props.location.state
      ? this.props.location.state.selectedPatientData
        ? this.props.location.state.selectedPatientData
        : null
      : null,
    selectedPatientHistoryData: this.props.location.state
      ? this.props.location.state.selectedPatientHistoryData
        ? this.props.location.state.selectedPatientHistoryData
        : null
      : null,
    selectedTreatmentTypeData: null,
    confirmDeleteModalOpen: false,
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    validationErrors: [],
    hasMedicalHistoryChanges: false,
    medicalHistoryUpdate: [],
    generatingPdf: false,
    annotationStatus: [],
    productListData: [],
  };

  componentDidMount = async () => {
    this.setState({ loadingPage: true });
    await this.props.getTreatmentTypes(this.props.headers);
    if (this.state.mode === "create") {
      await this.props.clearSelectedTreatment();
      // The original variable name previous_treatment_feedback has been unchanged
      // because there are existing treatments that already have this field.
      await this.props.updateSelectedTreatment(
        "previous_treatment_feedback",
        formatPreviousTreatmentOutcome(this.state.selectedPatientData)
      );

      // Get the list of treatments, so that we can find out the date of the previous treatment, or if this is the very first treatment
      // Exclude follow up notes
      await this.props.getTreatments(
        this.state.patientId,
        this.props.headers,
        true
      );
      // Get most recent treatment data
      this.props.treatmentListData.sort((a, b) =>
        a.created < b.created ? 1 : b.created < a.created ? -1 : 0
      );
      const previousTreatmentData = this.props.treatmentListData[0];
      // Copy some information from previous treatment
      if (previousTreatmentData) {
        await this.setUpPreviousTreatmentInfo(previousTreatmentData);
      } else {
        // Add a zero timestamp if there is no previous treatment (it would really be better to add the new patient reg form date)
        await this.props.updateSelectedTreatment(
          "previousTreatmentTimestamp",
          0
        );
      }
    } else if (this.state.patientId) {
      await this.props.getTreatmentById(
        this.props.match.params.id,
        this.state.patientId,
        this.props.headers
      );
    }
    let newDate = new Date();
    const yesterdaysDate = new Date(newDate.setDate(newDate.getDate() - 1));
    await this.props.getProducts(
      this.props.headers,
      true,
      // Submit yesterday's date to include products that expire today, since
      // the request gets products that expire after the given date
      dateEpochToDateTimeString(yesterdaysDate.getTime(), "-")
    );
    this.setState({
      productListData: await this.getProductTableData(),
    });
    //if the current user is a doctor and no existing doctor signature details exist on the record:
    if (
      this.props.role === "doctor" &&
      this.props.selectedTreatmentData.doctor_signature_name === undefined
    ) {
      await this.addDoctorSignatureDetails();
    }

    await this.props.getPatientHistoryById(
      this.state.patientId,
      this.props.selectedTreatmentData.previousTreatmentTimestamp,
      this.props.headers
    );

    //Extract the most recent patient concerns from the medical history
    if (
      this.state.mode === "create" &&
      this.props.selectedPatientHistoryData != null &&
      this.props.selectedPatientHistoryData.length > 0
    ) {
      let patientConcernsData = this.props.selectedPatientHistoryData.filter(
        (x) =>
          x.service === "forms" &&
          (x.source === "aestheticInterest" || x.source === "returningPatient")
      );
      let lastChangeTimestamps = patientConcernsData.map(
        (x) => x.eventTimeStamp
      );

      lastChangeTimestamps.push(0);
      const lastChangeTimestamp = Math.max(...lastChangeTimestamps);

      let patientConcerns = patientConcernsData.filter(
        (x) => x.eventTimeStamp === lastChangeTimestamp
      );

      if (patientConcerns.length > 0) {
        const concerns = formatAestheticConcerns(patientConcerns[0].data);
        await this.props.updateSelectedTreatment("patient_concerns", concerns);
      }
    }

    let medicalHistoryCombinedChanges = [];
    if (this.props.selectedPatientHistoryData !== null) {
      this.setState({ hasMedicalHistoryChanges: true });
      medicalHistoryCombinedChanges = combineChangesToMedicalHistory(
        this.props.selectedPatientHistoryData
      );
    }

    const medicalHistoryUpdate = formatChangesToMedicalHistory(
      medicalHistoryCombinedChanges
    );

    this.setState({ medicalHistoryUpdate: medicalHistoryUpdate });
    this.setState({ loadingPage: false });
  };

  setUpPreviousTreatmentInfo = async (previousTreatmentData) => {
    fieldsToCopy.forEach(async (field) => {
      await this.props.updateSelectedTreatment(
        field,
        previousTreatmentData[field]
      );
    });
    await this.props.updateSelectedTreatment(
      "previousTreatmentTimestamp",
      previousTreatmentData.created
    );
  };

  downloadFormPdf = async () => {
    // Set state (passed to FormEdit to set a loader on download button)
    this.setState({ generatingPdf: true });
    // Download a Pdf of the div with id 'formDiv' and filename formType
    await downloadPdf(document, strings.header.viewTreatment);
    this.setState({ generatingPdf: false });
  };

  // Returns a copy of the treatment type object for the provided type
  getTreatmentTypeData = (types) => {
    const selectedIndexesOfTreatmentType = [];
    const selectedTreatmentTypes = [];

    types.forEach((type) => {
      let indexOfTreatmentType = this.props.treatmentTypeListData
        .map((treatmentType) => {
          return treatmentType.id;
        })
        .indexOf(type);

      if (indexOfTreatmentType >= 0) {
        selectedTreatmentTypes.push(
          Object.assign(
            {},
            this.props.treatmentTypeListData[indexOfTreatmentType]
          )
        );
      }

      selectedIndexesOfTreatmentType.push(indexOfTreatmentType);
    });

    return selectedTreatmentTypes;
  };

  handleYesNoChange = async (event, data) => {
    this.removeFieldError(data.name);
    if (data.clearChild && data.childrenFields) {
      data.childrenFields.forEach((childField) => {
        this.props.deleteFromSelectedTreatment(childField);
      });
    }
    this.props.updateSelectedTreatment(data.name, data.value);
  };

  handleSubmit = async () => {
    this.setState({ loadingPage: true });
    // Remove the empty fields before submitting
    let submitData = Object.assign({}, this.props.selectedTreatmentData);
    Object.keys(submitData).forEach((field) => {
      if (submitData[field] === "" || submitData[field] === null) {
        delete submitData[field];
      }
    });

    submitData = this.updateDataBasedOnWasTreatmentPerformed(submitData);

    const treatmentTypeData = this.getTreatmentTypeData(
      this.props.selectedTreatmentData.treatment_type
    );

    if (Array.isArray(treatmentTypeData)) {
      submitData["selectedTreatmentTypes"] = [];
      treatmentTypeData.map((item) =>
        submitData["selectedTreatmentTypes"].push({
          id: item.id,
          treatment_type: item.treatment_type,
          aftercare_instructions: item.aftercare_instructions,
          text_notification_timeframe: item.text_notification_timeframe,
          text_notification_timeframe_units:
            item.text_notification_timeframe_units,
          text_notification_wording: item.text_notification_wording,
        })
      );
    }

    if (this.state.selectedPatientData) {
      submitData[
        "mobile_phone_number"
      ] = this.state.selectedPatientData.mobile_phone_number;
      submitData[
        "email_address"
      ] = this.state.selectedPatientData.email_address;
      submitData[
        "communication_consent"
      ] = this.state.selectedPatientData.communication_consent;
    }
    // Extract examination notes to insert into a patient update API call
    const patientUpdateData = {};
    fieldsToInsert.forEach(
      (item) =>
        (patientUpdateData[item] = submitData[item] ? submitData[item] : null)
    );

    if (this.state.mode === "create") {
      this.state.annotationStatus.forEach(
        (item) => (submitData[item.name] = item.status)
      );

      await this.props.createTreatment(
        submitData,
        this.state.patientId,
        this.props.headers,
        patientUpdateData
      );
      if (this.props.result && this.props.annotationsObject) {
        await this.handleAnnotationSubmission(
          this.props.annotationsObject,
          this.props.result.data.content.id
        );
      }
      if (
        this.props.selectedTreatmentData.hasOwnProperty(
          "discussed_consent_issues"
        ) &&
        this.props.selectedTreatmentData["discussed_consent_issues"] === true
      ) {
        // Apply doctor signature to all open consent forms
        await this.props.applyDoctorSignature(
          "signAllConsentForms",
          { patient_id: this.state.patientId },
          this.props.headers
        );
      }
    } else {
      await this.props.updateTreatment(
        this.props.match.params.id,
        this.state.patientId,
        submitData,
        this.props.headers,
        patientUpdateData
      );
    }
    this.setState({ loadingPage: false });
    this.props.history.push(`/patients/view/${this.state.patientId}`);
  };

  updateDataBasedOnWasTreatmentPerformed = (submitData) => {
    if (
      this.props.selectedTreatmentData["treatment_performed_today"] ===
      strings.form.label.radioYes
    ) {
      let selectedProductsArray = [];
      this.props.productListData.forEach((product) => {
        if (
          this.props.selectedTreatmentData.selected_products.hasOwnProperty(
            product.id
          )
        ) {
          selectedProductsArray.push(product.product_name);
        }
      });
      if (selectedProductsArray.length > 0) {
        submitData["selected_product_names"] = selectedProductsArray;
      }
    } else {
      // If no treatment was performed, remove data related to treatment procedure
      delete submitData["skin_prep"];
      delete submitData["local_anaesthetics"];
      delete submitData["selected_product_names"];
      delete submitData["selected_products"];
    }
    return submitData;
  };

  handleAnnotationWasModified = async (annotationName, modificationType) => {
    let annotationStatus = this.state.annotationStatus.filter(
      (item) => item.name !== annotationName
    );
    annotationStatus.push({
      name: annotationName,
      status: modificationType,
    });
    this.setState({ annotationStatus: annotationStatus });
  };

  handleAnnotationSubmission = async (annotationsObject, treatmentId) => {
    for (let name in annotationsObject) {
      let imageName = "face-annotation-" + name + "-" + treatmentId + ".jpeg";
      let currentAnnotationObject = Object.assign({}, annotationsObject[name]);
      currentAnnotationObject[
        "imagePath"
      ] = `treatments/${treatmentId}/${name}/${imageName}`;
      await this.props.uploadImage(currentAnnotationObject, this.props.headers);
    }
  };

  handleDelete = async () => {
    this.props.deleteTreatment(
      this.props.selectedTreatmentData.id,
      this.state.patientId,
      this.props.headers,
      this.props.history
    );
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    this.removeFieldError(data.name);

    if (data.name === "patient_concerns") {
      data.value = data.value.split("\n");
    }

    await this.props.updateSelectedTreatment(data.name, data.value);

    if (data.name === "doctor" || data.name === "treatment_type") {
      if (this.state.selectedPatientData.communication_consent === "Yes") {
        this.props.updateSelectedTreatment("send_aftercare_instructions", true);
      }
      let price = this.getPrice();
      if (price !== this.props.selectedTreatmentData.price) {
        this.props.updateSelectedTreatment("price", price);
      }
    } else if (data.name === "product_type") {
      const { productListData } = this.props;
      let productData = {};
      productListData.forEach((product) => {
        if (product.product_name === data.value) {
          productData = Object.assign({}, product);
        }
      });
      this.props.updateSelectedTreatment(
        "product_batch",
        productData.batch_name
      );
      this.props.updateSelectedTreatment(
        "product_expiry_date",
        productData.expiry_date
      );
    }
  };

  handleProductSelectChange = async (event, data) => {
    const currentProductData = Object.assign(
      {},
      this.props.selectedTreatmentData["selected_products"]
    );
    if (data.checked && !currentProductData[data.product_id]) {
      currentProductData[data.product_id] = {
        injection_devices: {},
      };
    } else if (!data.checked && currentProductData[data.product_id]) {
      delete currentProductData[data.product_id];
    }
    await this.props.updateSelectedTreatment(
      "selected_products",
      currentProductData
    );
    this.removeFieldError("selected_products");
  };

  handleProductDataChange = async (event, data) => {
    const currentProductData = Object.assign(
      {},
      this.props.selectedTreatmentData["selected_products"]
    );
    if (currentProductData[data.product_id]) {
      currentProductData[data.product_id][data.name] = data.value;
    }
    await this.props.updateSelectedTreatment(
      "selected_products",
      currentProductData
    );
    this.removeFieldError("selected_products");
  };

  handleProductCheckboxChange = async (event, data) => {
    const currentProductData = Object.assign(
      {},
      this.props.selectedTreatmentData["selected_products"]
    );
    if (currentProductData[data.product_id]) {
      currentProductData[data.product_id][data.group][data.name] = data.checked;
    }
    await this.props.updateSelectedTreatment(
      "selected_products",
      currentProductData
    );
    this.removeFieldError("selected_products");
  };

  // Adds the value into the form data and inserts it into a given group as the "content" property
  handleNestedChange = async (event, data) => {
    // Only handle the change if the parent group entry is correctly set
    if (
      this.props.selectedTreatmentData[data.group].hasOwnProperty(data.child)
    ) {
      let currentGroup = this.props.selectedTreatmentData[data.group];
      // Set the checkbox name in the object to the new checked state
      currentGroup[data.child].content = data.value;
      this.props.updateSelectedTreatment(data.group, currentGroup);
      this.props.updateSelectedTreatment(data.name, data.value);
      this.removeFieldError(data.name);
    }
  };

  handleCheckboxChange = (event, data) => {
    this.props.updateSelectedTreatment(data.name, data.checked);
  };

  handleCheckboxGroupChange = (event, value) => {
    // If array exists, take a copy, otherwise start a blank array
    let currentGroup = this.props.selectedTreatmentData.hasOwnProperty(
      value.group
    )
      ? this.props.selectedTreatmentData[value.group]
      : {};
    // Set the checkbox name in the object to the new checked state
    currentGroup[value.name] = {
      checked: value.checked,
      label: value.label,
    };
    this.props.updateSelectedTreatment(value.group, currentGroup);
    this.removeFieldError(value.group);
  };

  handleModeSwitch = async () => {
    // Products in the table depends on the view mode
    this.setState({
      productListData: [],
    });
    if (this.state.mode === "edit") {
      // restore original data if the changes to the form are cancelled
      await this.props.getTreatmentById(
        this.props.match.params.id,
        this.state.patientId,
        this.props.headers
      );
      // Reset product table data after updating the view mode
      this.setState({ mode: "view", validationErrors: [], }, async () => {
        this.setState({
          productListData: await this.getProductTableData(),
        });
      });
    } else {
      this.setState({ mode: "edit", }, async () => {
        this.setState({
          productListData: await this.getProductTableData(),
        });
      });
    }
  };

  getDoctorOptions = () => {
    //We need at least one selected treatment to show doctors
    if (
      this.props.selectedTreatmentData.treatment_type === null ||
      this.props.selectedTreatmentData.treatment_type === undefined ||
      this.props.selectedTreatmentData.treatment_type === []
    ) {
      return [];
    }
    let selectedTreatmentTypesData = this.props.treatmentTypeListData.filter(
      (item) =>
        this.props.selectedTreatmentData.treatment_type.includes(
          item.treatment_type
        )
    );

    const selectedTreatmentTypesDoctors = selectedTreatmentTypesData.map(
      (treatmentType) => {
        return treatmentType.doctors_and_prices;
      }
    );

    // For each doctor, loop through each selected treatment to check if the doctor is present
    let selectedTreatmentCommonDoctors = [];
    let selectedTreatmentCommonDoctorsNames = [];
    doctorOptions.forEach((doctorOption) => {
      let doctor = doctorOption.value;
      const doctorIsOnAllSelectedTreatments = selectedTreatmentTypesDoctors.every(
        (treatmentDoctors) => {
          return treatmentDoctors.some((entry) => entry.doctor === doctor);
        }
      );
      if (doctorIsOnAllSelectedTreatments) {
        selectedTreatmentCommonDoctors.push(doctorOption);
        selectedTreatmentCommonDoctorsNames.push(doctor);
      }
    });

    // If selected doctor isn't an available option, clear the selected doctor
    if (
      this.props.selectedTreatmentData.doctor &&
      !selectedTreatmentCommonDoctorsNames.includes(
        this.props.selectedTreatmentData.doctor
      )
    ) {
      this.props.updateSelectedTreatment("doctor", null);
    }

    return selectedTreatmentCommonDoctors;
  };

  getPrice = () => {
    //We need a selected treatment and a selected doctor to be able to show a price
    if (
      this.props.selectedTreatmentData.treatment_type == null ||
      (Array.isArray(this.props.selectedTreatmentData.treatment_type) &&
        this.props.selectedTreatmentData.treatment_type.length === 0) ||
      this.props.selectedTreatmentData.doctor == null ||
      (Array.isArray(this.props.selectedTreatmentData.doctor) &&
        this.props.selectedTreatmentData.doctor.length === 0)
    ) {
      return "";
    }

    let selectedTreatmentTypesData = this.props.treatmentTypeListData.filter(
      (item) =>
        this.props.selectedTreatmentData.treatment_type.includes(
          item.treatment_type
        )
    );

    if (selectedTreatmentTypesData == null) {
      return ""; //No treatment types currently selected
    }

    let selectedDoctorData = this.props.selectedTreatmentData.doctor;

    if (selectedDoctorData == null || selectedDoctorData === "") {
      return "";
    }

    let totalPrice = 0;
    selectedTreatmentTypesData.forEach((treatmentType) => {
      let treatmentDoctorPriceData = treatmentType["doctors_and_prices"].find(
        (priceEntry) => priceEntry.doctor === selectedDoctorData
      );
      if (treatmentDoctorPriceData) {
        totalPrice += +treatmentDoctorPriceData.price;
      }
    });
    return totalPrice;
  };

  getProductTableData = async () => {
    let selectedProductsListData = JSON.parse(
      JSON.stringify(this.props.productListData)
    );
    if (this.props.selectedTreatmentData.selected_products) {
      // If in view mode, only show products that were used in the treatment
      // regardless of status and expiry date
      // First, filter complete list of active and in-date products to only
      // show ones used in the treatment
      if (this.state.mode === "view") {
        selectedProductsListData = await getProductsFilteredByUsedInTreatment(this.props);
      }
      // Next, any product that was used in the treatment that is now inactive
      // or out-of-date is added to the list, regardless of view mode
      selectedProductsListData = await addInactiveAndExpiredUsedProducts(
        selectedProductsListData,
        this.props
      );
    }
    return selectedProductsListData;
  };

  handleNumberOnlyInputKeyDown = (event) => {
    if (
      event.keyCode !== 8 &&
      event.keyCode !== 0 &&
      event.keyCode !== 13 &&
      (event.keyCode < 48 || event.keyCode > 57)
    ) {
      event.preventDefault();
    }
  };

  handleModalConfirm = (modalClassName) => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "confirmDeleteModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  removeFieldError = (field) => {
    const newErrors = this.state.validationErrors.filter((error) => {
      return error.field !== field;
    });
    this.setState({
      validationErrors: newErrors,
    });
  };

  addDoctorSignatureDetails = async () => {
    if (this.props.role === "doctor") {
      this.props.updateSelectedTreatment(
        "doctor_signature_name",
        `${this.props.givenName} ${this.props.familyName}`
      );
      this.props.updateSelectedTreatment(
        "doctor_signature_path",
        this.props.signaturePathOfLoggedInUser
      );
      const timestamp = new Date().getTime();
      const doctor_signature_applied_date = new Date(timestamp).toISOString();
      this.props.updateSelectedTreatment(
        "doctor_signature_applied_date",
        doctor_signature_applied_date
      );
    }
  };

  toggleConfirmSaveModalVisibility = async () => {
    const submitData = Object.assign({}, this.props.selectedTreatmentData);
    Object.keys(submitData).forEach((field) => {
      if (submitData[field] === "") {
        delete submitData[field];
      }
    });
    // Validate the data before showing the modal
    const validationResult = await validateTreatmentInput(
      submitData,
      "newTreatment"
    );
    if (validationResult.passedValidation === true) {
      this.setState({
        confirmSaveModalOpen: !this.state.confirmSaveModalOpen,
      });
    } else {
      // If failed validation, don't show modal, add errors to state and scroll to top
      window.scrollTo(0, 0);
      this.setState({
        validationErrors: validationResult.errors.slice(),
      });
    }
  };

  toggleConfirmDeleteModalVisibility = () => {
    this.setState({
      confirmDeleteModalOpen: !this.state.confirmDeleteModalOpen,
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen,
    });
  };

  render = () => {
    return (
      <div>
        {this.state.loadingPage || this.props.loadingPage ? (
          <Loader active>{strings.header.loading}</Loader>
        ) : (
          <Treatment
            mode={this.state.mode}
            pageTitle={
              this.state.mode === "create"
                ? strings.header.createTreatment
                : strings.header.editTreatment
            }
            selectedPatientData={this.state.selectedPatientData}
            selectedTreatmentData={this.props.selectedTreatmentData}
            treatmentTypeListData={this.props.treatmentTypeListData}
            productListData={this.state.productListData}
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleConfirmDeleteModalVisibility={
              this.toggleConfirmDeleteModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            handleChange={this.handleChange}
            handleProductSelectChange={this.handleProductSelectChange}
            handleProductDataChange={this.handleProductDataChange}
            handleProductCheckboxChange={this.handleProductCheckboxChange}
            handleYesNoChange={this.handleYesNoChange}
            handleNestedChange={this.handleNestedChange}
            handleCheckboxChange={this.handleCheckboxChange}
            handleCheckboxGroupChange={this.handleCheckboxGroupChange}
            handleModeSwitch={this.handleModeSwitch}
            handleAnnotationWasModified={this.handleAnnotationWasModified}
            handleNumberOnlyInputKeyDown={this.handleNumberOnlyInputKeyDown}
            getDoctorOptions={this.getDoctorOptions}
            handleModalConfirm={this.handleModalConfirm}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            confirmDeleteModalOpen={this.state.confirmDeleteModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            validationErrors={this.state.validationErrors}
            treatmentId={this.state.treatmentId}
            headers={this.props.headers}
            hasMedicalHistoryChanges={this.state.hasMedicalHistoryChanges}
            medicalHistoryUpdate={this.state.medicalHistoryUpdate}
            generatingPdf={this.state.generatingPdf}
            downloadFormPdf={this.downloadFormPdf}
          />
        )}
      </div>
    );
  };
}

TreatmentContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedTreatmentData: PropTypes.object.isRequired,
  createTreatment: PropTypes.func.isRequired,
  updateTreatment: PropTypes.func.isRequired,
  deleteTreatment: PropTypes.func.isRequired,
  getTreatmentById: PropTypes.func.isRequired,
  updateSelectedTreatment: PropTypes.func.isRequired,
  clearSelectedTreatment: PropTypes.func.isRequired,
  getTreatmentTypes: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getProductById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { selectedPatientHistoryData } = state.patients;
  const { productListData, selectedProductData } = state.products;
  const { treatmentTypeListData } = state.treatments.treatmentTypes;
  const {
    loadingPage,
    selectedTreatmentData,
    treatmentListData,
    result,
  } = state.treatments.treatments;
  const { signatureObject, annotationsObject } = state.images;
  const { familyName, givenName, signaturePathOfLoggedInUser } = state.auth;

  return {
    selectedPatientHistoryData,
    treatmentTypeListData,
    productListData,
    selectedProductData,
    loadingPage,
    selectedTreatmentData,
    treatmentListData,
    result,
    signatureObject,
    annotationsObject,
    familyName,
    givenName,
    signaturePathOfLoggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTreatmentById: (id, patientId, headers) => {
      return dispatch(
        treatmentActions.getTreatmentById(id, patientId, headers)
      );
    },
    createTreatment: async (data, patientId, headers, patientData) => {
      if (data.previousTreatmentTimestamp === 0) {
        //Update patient record only for the very first treatment - not for subsequent treatments
        patientData.has_had_examination = true;
        await dispatch(
          patientActions.updatePatient(patientId, patientData, headers)
        );
      }
      return dispatch(
        treatmentActions.createTreatment(data, patientId, headers)
      );
    },
    deleteFromSelectedTreatment: (key, value) => {
      dispatch(treatmentActions.deleteFromSelectedTreatment(key, value));
    },
    updateTreatment: async (id, patientId, data, headers, patientData) => {
      if (data.previousTreatmentTimestamp === 0) {
        //Update patient record only for the very first treatment - not for subsequent treatments
        patientData.has_had_examination = true;
        await dispatch(
          patientActions.updatePatient(patientId, patientData, headers)
        );
      }

      return dispatch(
        treatmentActions.updateTreatment(id, patientId, data, headers)
      );
    },
    deleteTreatment: (id, patientId, headers, history) => {
      dispatch(treatmentActions.deleteTreatment(id, patientId, headers))
        .then(() =>
          history.push({
            pathname: `/patients/view/${patientId}`,
            state: {
              patientId: patientId,
              mode: "view",
            },
          })
        )
        .catch((error) => console.log(error));
    },
    updateSelectedTreatment: (key, value) => {
      dispatch(treatmentActions.updateSelectedTreatment(key, value));
    },
    clearSelectedTreatment: () => {
      return dispatch(treatmentActions.clearSelectedTreatment());
    },
    getTreatmentTypes: (headers) => {
      return dispatch(treatmentTypeActions.getTreatmentTypes(headers));
    },
    getTreatments: async (patientId, headers, excludeFollowUpNotes) => {
      await dispatch(
        treatmentActions.getTreatments(patientId, headers, excludeFollowUpNotes)
      );
    },
    getPatientHistoryById: (patientId, startTimeStamp, headers) => {
      return dispatch(
        patientActions.getPatientHistoryById(patientId, startTimeStamp, headers)
      );
    },
    getProducts: async (headers, active, expiresAfter) => {
      return await dispatch(
        productActions.getProducts(headers, active, expiresAfter)
      );
    },
    uploadImage: async (upload, headers) => {
      return await dispatch(imageActions.uploadImage(upload, headers));
    },
    getProductById: async (id, headers) => {
      return await dispatch(productActions.getProductById(id, headers));
    },
    applyDoctorSignature: (id, details, headers) => {
      return dispatch(formActions.applyDoctorSignature(id, details, headers));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentContainer);
