import React from "react";
import PropTypes from "prop-types";
import { Loader } from "semantic-ui-react";
import { enums, strings } from "../resources";
import { connect } from "react-redux";
import { formActions } from "../store/actions/form.actions";
import { imageActions } from "../../Images/store/actions/image.actions";
import "./css/FormEdit.css";
import FormEdit from "../components/FormEdit";
import { withRouter, Prompt } from "react-router-dom";
import { patientActions } from "../../Patients/store/actions/patient.actions";
import downloadPdf from "../helpers/formToPdfFile";
import validateFormInput from "../helpers/formValidator";
import formatDateInput from "../helpers/FormatDateInput";

class FormEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode:
        this.props.mode === "create"
          ? "create"
          : this.props.location.state
          ? this.props.location.state.mode
          : "view",
      formId: this.props.location.state
        ? this.props.location.state.formId
        : null,
      patientId: this.props.location.state
        ? this.props.location.state.patientId
        : this.props.patientId
        ? this.props.patientId
        : null,
      patientData: this.props.location.state
        ? this.props.location.state.patientData
        : this.props.patientData
        ? this.props.patientData
        : null,
      confirmDeleteModalOpen: false,
      confirmSaveModalOpen: false,
      cancelChangesModalOpen: false,
      generatingPdf: false,
      validationErrors: [],
      loading: false,
      redirecting: false,
      patientRecordFields: {},
      annotationStatus: [],
    };
  }

  componentWillMount = async () => {
    if (this.state.mode === "create" || this.state.mode === "template") {
      this.props.clearSelectedForm();
    }

    if (this.state.mode === "create" && this.props.formType !== null) {
      this.props.updateSelectedForm("form_type", this.props.formType);
      this.props.updateSelectedForm(
        "form_version",
        this.getNewFormVersion(this.props.formType)
      );
    }

    if (
      (this.state.mode === "view" || this.state.mode === "edit") &&
      this.state.patientId !== null
    ) {
      await this.props.getFormById(
        this.state.formId,
        this.state.patientId,
        this.props.headers
      );
    }
    if (
      this.state.mode === "view" &&
      this.props.selectedFormData &&
      this.props.selectedFormData.form_type &&
      this.props.selectedFormData.form_type.startsWith("consent") &&
      !this.props.selectedFormData.doctor_signature_path &&
      this.props.role === "doctor"
    ) {
      this.addDoctorSignature();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      Object.keys(prevProps.signatureObject).length < 1 &&
      Object.keys(this.props.signatureObject).length > 0
    ) {
      this.removeFieldError("signature_data_url");
    }
  };

  // For multiple form editing, resets form data
  moveToNextForm = async (patientId) => {
    await this.props.clearSelectedForm();
    if (patientId) {
      await this.props.handleNewPatientCreated(patientId);
    }
    return new Promise((resolve, reject) => {
      this.props.handleNewFormCreated();
      if (this.state.mode === "create" && this.props.formType !== null) {
        this.props.updateSelectedForm("form_type", this.props.formType);
        this.props.updateSelectedForm(
          "form_version",
          this.getNewFormVersion(this.props.formType)
        );
      }
      // If patient data has been added, apply it to state
      if (patientId) {
        this.setState(
          {
            patientData: this.props.patientData,
          },
          () => {
            resolve();
          }
        );
      } else {
        resolve();
      }
    });
  };

  // Loads the values of patient data for the fields provided into the patientRecordFields state object
  // If loadIntoForm in field object is true, also loads them into selectedFormData
  populatePatientData = async (fields = []) => {
    const newPatientFields = {};
    if (this.state.patientData) {
      fields.forEach((field) => {
        if (this.state.patientData[field.fieldName]) {
          newPatientFields[field.fieldName] = this.state.patientData[
            field.fieldName
          ];
          if (field.loadIntoForm) {
            this.props.updateSelectedForm(
              field.fieldName,
              this.state.patientData[field.fieldName]
            );
          }
        }
      });
      this.setState({
        patientRecordFields: newPatientFields,
      });
    }
  };

  removeFieldError = (field) => {
    const newErrors = this.state.validationErrors.filter((error) => {
      return error.field !== field;
    });
    this.setState({
      validationErrors: newErrors,
    });
  };

  getNewFormVersion = (formType) => {
    let value = "";
    let ft = null;
    for (ft in enums.formType) {
      if (
        enums.formType[ft]["type"] === formType ||
        enums.formType[ft]["link"] === formType
      ) {
        value = enums.formType[ft]["currentVersion"];
      }
    }
    return value;
  };

  handleYesNoChange = async (event, data) => {
    if (data.clearChild && data.childrenFields) {
      data.childrenFields.forEach((childField) => {
        this.props.deleteFromSelectedForm(childField);
        this.removeFieldError(childField);
      });
    }
    this.props.updateSelectedForm(data.name, data.value);
    this.removeFieldError(data.name);
  };

  handleDelete = async () => {
    this.props.deleteForm(this.props.selectedFormData.id, this.props.headers);
  };

  handleSubmit = async () => {
    this.setState({
      loading: true,
      redirecting: true,
    });
    if (this.state.mode === "create") {
      // Remove any blank fields from the form data before validation and submission
      const submitData = Object.assign({}, this.props.selectedFormData);
      Object.keys(submitData).forEach((field) => {
        if (submitData[field] === "" || submitData[field] === null) {
          delete submitData[field];
        }
        // For all fields updating an existing string entry e.g. medical conditions, append the field value to the database value
        if (
          Object.keys(this.state.patientRecordFields).includes(field) &&
          submitData[field] &&
          typeof submitData[field] === "string"
        ) {
          submitData[
            field
          ] = `${this.state.patientRecordFields[field]}\n${submitData[field]}`;
        }
      });
      // attach contact fields if patient data provided
      if (this.state.patientData) {
        submitData[
          "contact_name"
        ] = `${this.state.patientData.first_name} ${this.state.patientData.surname}`;
        submitData["email_address"] = this.state.patientData.email_address;
        submitData["phone_number"] = this.state.patientData.phone_number;
      }
      //For the forms with an annotation image, include the image edit status in the data
      this.state.annotationStatus.forEach(
        (item) => (submitData[item.name] = item.status)
      );
      let patientId;
      if (
        this.props.formType === enums.formType.NEW_PATIENT_REGISTRATION.type ||
        this.props.formType === enums.formType.NEW_PATIENT_REGISTRATION.link
      ) {
        patientId = await this.props.createNewPatientForm(
          submitData,
          this.props.headers,
          this.props.history
        );
      } else {
        await this.props.createForm(
          submitData,
          this.props.patientId ? this.props.patientId : this.state.patientId, // preferentially use patient ID if given as a prop (controlled), otherwise use state determined ID
          this.props.headers,
          this.props.history
        );
      }
      if (this.props.response && this.props.signatureObject) {
        await this.handleSignatureSubmission(
          this.props.signatureObject,
          this.props.response.content
        );
      }
      if (this.props.response && this.props.annotationsObject) {
        await this.handleAnnotationSubmission(
          this.props.annotationsObject,
          this.props.response.content
        );
      }
      if (!this.props.multipleFormEditMode) {
        if (!patientId) {
          this.props.history.push(`/patients/view/${this.state.patientId}`);
        } else {
          this.props.history.push(`/patients/view/${patientId}`);
        }
      } else {
        await this.moveToNextForm(patientId);
      }
    }
    this.setState({
      loading: false,
      redirecting: false,
    });
  };

  handleSignatureSubmission = async (signatureObject, formId) => {
    let imageName = "patient-signature-" + formId + ".jpeg";
    signatureObject["imagePath"] = `signatures/forms/${formId}/${imageName}`;
    return this.props.uploadImage(signatureObject, this.props.headers);
  };

  handleAnnotationWasModified = async (annotationName, modificationType) => {
    let annotationStatus = this.state.annotationStatus.filter(
      (item) => item.name !== annotationName
    );
    annotationStatus.push({
      name: annotationName,
      status: modificationType,
    });
    this.setState({ annotationStatus: annotationStatus });
  };

  handleAnnotationSubmission = async (annotationsObject, formId) => {
    for (let name in annotationsObject) {
      let imageName = "face-annotation-" + name + "-" + formId + ".jpeg";
      let currentAnnotationObject = Object.assign({}, annotationsObject[name]);
      currentAnnotationObject[
        "imagePath"
      ] = `forms/${formId}/${name}/${imageName}`;
      await this.props.uploadImage(
        currentAnnotationObject,
        this.props.headers
      );
    }
  };

  handleCancel = () => {
    this.setState(
      {
        redirecting: true,
        validationErrors: [],
      },
      () => {
        if (this.props.multipleFormEditMode) {
          this.props.openPreConsultationComplete();
        } else {
          this.props.history.push("/patients/");
        }
      }
    );
  };

  handleChange = async (event, data) => {
    this.props.updateSelectedForm(data.name, data.value);
    this.removeFieldError(data.name);
  };

  // Adds the value into the form data AND inserts it into a given group as the "content" property
  handleNestedChange = async (event, data) => {
    // Only handle the change if the parent group entry is correctly set
    if (this.props.selectedFormData[data.group].hasOwnProperty(data.child)) {
      let currentGroup = this.props.selectedFormData[data.group];
      // Set the checkbox name in the object to the new checked state
      currentGroup[data.child].content = data.value;
      this.props.updateSelectedForm(data.group, currentGroup);
      this.props.updateSelectedForm(data.name, data.value);
      this.removeFieldError(data.name);
    }
  };

  handleCheckboxGroupChange = (event, value) => {
    // If array exists, take a copy, otherwise start a blank array
    let currentGroup = this.props.selectedFormData.hasOwnProperty(value.group)
      ? this.props.selectedFormData[value.group]
      : {};
    // Set the checkbox name in the object to the new checked state
    currentGroup[value.name] = {
      checked: value.checked,
      label: value.label,
    };
    this.props.updateSelectedForm(value.group, currentGroup);
    this.removeFieldError(value.group);
  };

  handleCheckboxChange = (event, value) => {
    this.props.updateSelectedForm(value.name, value.checked);
    this.removeFieldError(value.name);
  };

  handleSliderChange = (data) => {
    this.props.updateSelectedForm(data.name, data.value);
    this.removeFieldError(data.name);
  };

  handleDateKeyDown = (event) => {
    // Filter the input to the date box - restrict it to numbers, the dash separator,
    // the backspace key (keyCode 8), and the delete key (keyCode 46)
    if (
      isNaN(event.key) &&
      event.key !== "-" &&
      event.keyCode !== 8 &&
      event.keyCode !== 46
    ) {
      event.preventDefault();
    }
  };

  handleDateChange = async (event, data) => {
    let dataToSubmit = "";
    let currentValue = this.props.selectedFormData[data.name];
    // Only look to add dashes if there is something to format, and if the user
    // is typing rather than deleting
    if (
      currentValue &&
      data.value.length > 0 &&
      data.value.length > currentValue.length
    ) {
      dataToSubmit = formatDateInput(data.value);
    } else {
      dataToSubmit = data.value;
    }
    await this.props.updateSelectedForm(data.name, dataToSubmit);
    this.removeFieldError(data.name);
  };

  handleModeSwitch = () => {
    if (this.state.mode === "edit") {
      this.setState({ mode: "view" });
    } else if (this.state.mode === "view") {
      this.setState({ mode: "edit" });
    }
  };

  handleModalConfirm = (modalClassName) => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "confirmDeleteModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  addDoctorSignature = async () => {
    await this.props.updateSelectedForm(
      "doctor_signature_name",
      `${this.props.givenName} ${this.props.familyName}`
    );
    await this.props.updateSelectedForm(
      "doctor_signature_path",
      this.props.signaturePathOfLoggedInUser
    );
    const submitData = Object.assign({}, this.props.selectedFormData);
    await this.props.applyDoctorSignature(
      this.props.selectedFormData.form_details,
      submitData,
      this.props.headers
    );
    await this.props.getFormById(
      this.state.formId,
      this.state.patientId,
      this.props.headers
    );
  };

  downloadFormPdf = async () => {
    // Set state (passed to FormEdit to set a loader on download button)
    this.setState({ generatingPdf: true });
    // Download a Pdf of the div with id 'formDiv' and filename formType
    await downloadPdf(document, this.props.formType);
    this.setState({ generatingPdf: false });
  };

  toggleConfirmDeleteModalVisibility = () => {
    this.setState({
      confirmDeleteModalOpen: !this.state.confirmDeleteModalOpen,
    });
  };

  toggleConfirmSaveModalVisibility = async () => {
    const submitData = Object.assign({}, this.props.selectedFormData);
    Object.keys(submitData).forEach((field) => {
      if (submitData[field] === "") {
        delete submitData[field];
      }
    });
    submitData["signature_data_url"] = this.props.signatureObject;
    // Validate the data before showing the modal
    const validationResult = await validateFormInput(
      submitData,
      this.props.formType
    );
    if (validationResult.passedValidation === true) {
      this.setState({
        confirmSaveModalOpen: !this.state.confirmSaveModalOpen,
      });
    } else {
      // If failed validation, don't show modal, add errors to state and scroll to top
      window.scrollTo(0, 0);
      this.setState({
        validationErrors: validationResult.errors.slice(),
      });
    }
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen,
    });
  };

  render = () => {
    let formProperties = {
      mode: this.state.mode,
      validationErrors: this.state.validationErrors,
      patientId: this.state.patientId,
      //pageTitle: {strings.header.editNewPatientForm},
      selectedFormData: this.props.selectedFormData,
      toggleConfirmDeleteModalVisibility: this
        .toggleConfirmDeleteModalVisibility,
      toggleConfirmSaveModalVisibility: this.toggleConfirmSaveModalVisibility,
      toggleCancelChangesModalVisibility: this
        .toggleCancelChangesModalVisibility,
      history: this.props.history,
      handleChange: this.handleChange,
      handleNestedChange: this.handleNestedChange,
      handleModeSwitch: this.handleModeSwitch,
      handleYesNoChange: this.handleYesNoChange,
      handleDateChange: this.handleDateChange,
      handleCheckboxChange: this.handleCheckboxChange,
      handleCheckboxGroupChange: this.handleCheckboxGroupChange,
      handleSliderChange: this.handleSliderChange,
      handleDropdownChange: this.handleDropdownChange,
      handleDateKeyDown: this.handleDateKeyDown,
      handleModalConfirm: this.handleModalConfirm,
      handleClick: this.handleClick,
      headers: this.props.headers,
      generatingPdf: this.state.generatingPdf,
      downloadFormPdf: this.downloadFormPdf,
      addDoctorSignature: this.addDoctorSignature,
      confirmDeleteModalOpen: this.state.confirmDeleteModalOpen,
      confirmSaveModalOpen: this.state.confirmSaveModalOpen,
      cancelChangesModalOpen: this.state.cancelChangesModalOpen,
      formId: this.state.formId,
      multipleFormEditMode: this.props.multipleFormEditMode,
      patientData: this.state.patientData,
      populatePatientData: this.populatePatientData,
      patientRecordFields: this.state.patientRecordFields,
      updateSelectedForm: this.props.updateSelectedForm,
      cancelMessage: this.props.multipleFormEditMode
        ? strings.modal.cancelPreconsultationMessage
        : strings.modal.cancelChangesMessage,
      dateDoctorSigned:
        this.props.selectedFormData.doctor_signature_applied_date || null,
      doctorName: this.props.selectedFormData.doctor_signature_name || null,
      handleAnnotationWasModified: this.handleAnnotationWasModified,
    };

    const allowNavigation =
      this.state.redirecting ||
      (this.state.mode !== "edit" && this.state.mode !== "create");

    return (
      <React.Fragment>
        <Prompt
          when={!allowNavigation}
          message={strings.form.warning.dataLossNavigation}
        />
        {!this.props.formType || this.state.loading ? (
          <Loader active>{strings.header.loading}</Loader>
        ) : (
          <FormEdit {...formProperties} formType={this.props.formType} />
        )}
      </React.Fragment>
    );
  };
}

FormEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  formType: PropTypes.string.isRequired,
  selectedFormData: PropTypes.object.isRequired,
  updateSelectedForm: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  getFormById: PropTypes.func.isRequired,
  generatePatientID: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { forms, images, auth } = state;
  const { loadingPage, selectedFormData, response } = forms;
  const { signatureObject, annotationsObject } = images;
  const { familyName, givenName, signaturePathOfLoggedInUser } = auth;
  return {
    loadingPage,
    selectedFormData,
    response,
    signatureObject,
    annotationsObject,
    familyName,
    givenName,
    signaturePathOfLoggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormById: (id, patient_id, headers) => {
      return dispatch(formActions.getFormById(id, patient_id, headers));
    },
    updateForm: (id, data, headers, history) => {
      dispatch(formActions.updateForm(id, data, headers)).then(() =>
        history.push(`/patients/view/${this.state.patientId}`)
      );
    },
    createNewPatientForm: async (data, headers, history) => {
      //Note - the createPatient here is only temporary - when dev is complete, the creation of a new patient form in the back end will trigger the creation of a new patient via event sourcing
      const patientId = await dispatch(
        patientActions.createPatient(data, headers)
      );
      await dispatch(formActions.createForm(data, patientId, headers));
      return patientId;
    },
    createForm: async (data, patientId, headers, history) => {
      //Note - this can be used for creating all forms except new patient forms (where we already know the patient id)
      //await dispatch(patientActions.updatePatient(patientId, data, headers));
      return dispatch(formActions.createForm(data, patientId, headers));
    },
    updateSelectedForm: (key, value) => {
      dispatch(formActions.updateSelectedForm(key, value));
    },
    deleteFromSelectedForm: (key) => {
      dispatch(formActions.deleteFromSelectedForm(key));
    },
    clearSelectedForm: () => {
      return dispatch(formActions.clearSelectedForm());
    },
    validateForm: (details) => {
      return dispatch(formActions.validateForm(details));
    },
    generatePatientID: () => {
      dispatch(formActions.generatePatientID());
    },
    uploadImage: async (upload, headers) => {
      return await dispatch(imageActions.uploadImage(upload, headers));
    },
    applyDoctorSignature: (id, details, headers) => {
      return dispatch(formActions.applyDoctorSignature(id, details, headers));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormEditContainer)
);
