import React from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Header, Form } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { strings } from "../resources";
import { BasicModal, YesNoInput } from "./ui";

class CreatePatient extends React.Component {
  render = () => {
    return (
      <div className="createPatientContainer">
        <div className="createPatientHeader">{this.props.pageTitle}</div>
        <Form
          className="details"
          onSubmit={this.props.toggleConfirmSaveModalVisibility}
        >
          <div className="patientSectionHeader">
            {strings.form.header.personalInformation}
          </div>
          <div className="personalInformation">
            <Form.Group>
              <Form.Input
                label={strings.form.label.firstName}
                name="first_name"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.firstName}
                required
                value={this.props.selectedPatientData.first_name || ""}
                width={8}
              />
              <Form.Input
                className="noLabelInput"
                name="surname"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.surname}
                required
                value={this.props.selectedPatientData.surname || ""}
                width={8}
              />
            </Form.Group>
            <div className="dateOfBirthPicker">
              <Header as="h5">
                {strings.form.label.dateOfBirth}
                <span className="requiredAsterisk"> *</span>
              </Header>
              <DateInput
                className="dateOfBirth"
                iconPosition="left"
                name="date_of_birth"
                onChange={this.props.handleDateChange}
                onKeyDown={e => this.props.handleDateKeyDown(e)}
                placeholder={strings.form.placeholder.date}
                required
                value={this.props.selectedPatientData.date_of_birth || ""}
                clearIcon
                width={8}
              />
            </div>
            <Form.Group>
              <Form.Input
                label={strings.form.label.emailAddress}
                name="email_address"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.emailAddress}
                //required
                value={this.props.selectedPatientData.email_address || ""}
                width={8}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label={strings.form.label.address}
                name="postal_address"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.address}
                //required
                value={this.props.selectedPatientData.postal_address || ""}
                width={14}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label={strings.form.label.townOrCity}
                name="town_or_city"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.townOrCity}
                //required
                value={this.props.selectedPatientData.town_or_city || ""}
                width={8}
              />
              <Form.Input
                label={strings.form.label.postcode}
                name="postcode"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.postcode}
                //required
                value={this.props.selectedPatientData.postcode || ""}
                width={6}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label={strings.form.label.mainPhoneNumber}
                name="main_phone_number"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.phoneNumber}
                //required
                value={this.props.selectedPatientData.main_phone_number || ""}
                width={8}
              />
              <Form.Input
                label={strings.form.label.homePhoneNumber}
                name="home_phone_number"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.phoneNumber}
                value={this.props.selectedPatientData.home_phone_number || ""}
                width={8}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label={strings.form.label.mobilePhoneNumber}
                name="mobile_phone_number"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.phoneNumber}
                value={this.props.selectedPatientData.mobile_phone_number || ""}
                width={8}
              />
              <Form.Input
                label={strings.form.label.workPhoneNumber}
                name="work_phone_number"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.phoneNumber}
                value={this.props.selectedPatientData.work_phone_number || ""}
                width={8}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label={strings.form.label.occupation}
                name="occupation"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.occupation}
                value={this.props.selectedPatientData.occupation || ""}
                width={8}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label={strings.form.label.gpName}
                name="gp_name"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.gpName}
                value={this.props.selectedPatientData.gp_name || ""}
                width={8}
              />
              <Form.Input
                label={strings.form.label.gpAddress}
                name="gp_address"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.gpAddress}
                value={this.props.selectedPatientData.gp_address || ""}
                width={8}
              />
            </Form.Group>
            <YesNoInput
              label={strings.form.label.gpContactApproval}
              name="gp_contact_approval"
              value={this.props.selectedPatientData.gp_contact_approval || ""}
              changeHandler={this.props.handleChange}
            />
            <Form.Group>
              <Form.Input
                label={strings.form.label.emergencyContactName}
                name="emergency_contact_name"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.emergencyContactName}
                value={
                  this.props.selectedPatientData.emergency_contact_name || ""
                }
                width={8}
              />
              <Form.Input
                label={strings.form.label.emergencyContactPhoneNumber}
                name="emergency_contact_phone_number"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.phoneNumber}
                value={
                  this.props.selectedPatientData
                    .emergency_contact_phone_number || ""
                }
                width={8}
              />
            </Form.Group>
          </div>
          <div className="medicalHistoryHeader patientSectionHeader">
            {strings.form.header.medicalHistory}
          </div>
          <div className="medicalHistory">
            <Form.Group>
              <Form.TextArea
                className="textArea"
                label={strings.form.label.medicalConditions}
                name="medical_conditions"
                onChange={this.props.handleChange}
                rows={2}
                value={this.props.selectedPatientData.medical_conditions || ""}
                width={16}
              />
            </Form.Group>
            <Form.Group>
              <Form.TextArea
                className="textArea"
                label={strings.form.label.dermatologicalDiseases}
                name="dermatological_diseases"
                onChange={this.props.handleChange}
                rows={2}
                value={
                  this.props.selectedPatientData.dermatological_diseases || ""
                }
                width={16}
              />
            </Form.Group>
            <Form.Group>
              <Form.TextArea
                className="textArea"
                label={strings.form.label.neurologicalConditions}
                name="neurological_conditions"
                onChange={this.props.handleChange}
                rows={2}
                value={
                  this.props.selectedPatientData.neurological_conditions || ""
                }
                width={16}
              />
            </Form.Group>
            <Form.Group>
              <Form.TextArea
                className="textArea"
                label={strings.form.label.regularMedications}
                name="regular_medications"
                onChange={this.props.handleChange}
                rows={2}
                value={this.props.selectedPatientData.regular_medications || ""}
                width={16}
              />
            </Form.Group>
            <Form.Group>
              <Form.TextArea
                className="textArea"
                label={strings.form.label.supplements}
                name="supplements"
                onChange={this.props.handleChange}
                rows={2}
                value={this.props.selectedPatientData.supplements || ""}
                width={16}
              />
            </Form.Group>
            <Form.Group>
              <Form.TextArea
                className="textArea"
                label={strings.form.label.drugAllergies}
                name="drug_allergies"
                onChange={this.props.handleChange}
                rows={2}
                value={this.props.selectedPatientData.drug_allergies || ""}
                width={16}
              />
            </Form.Group>
            <Form.Group>
              <Form.TextArea
                className="textArea"
                label={strings.form.label.generalAllergies}
                name="general_allergies"
                onChange={this.props.handleChange}
                rows={2}
                value={this.props.selectedPatientData.general_allergies || ""}
                width={16}
              />
            </Form.Group>
          </div>
          <div className="mentalHealthHistoryHeader patientSectionHeader">
            {strings.form.header.mentalHealthHistory}
          </div>
          <div className="mentalHealthHistory">
            <Form.Group>
              <Form.Input
                label={strings.form.label.mentalHealthDisorders}
                name="mental_health_disorders"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.mentalHealthDisorders}
                value={
                  this.props.selectedPatientData.mental_health_disorders || ""
                }
                width={16}
              />
            </Form.Group>
            <YesNoInput
              label={strings.form.label.seenPsychiatrist}
              name="seen_psychiatrist"
              value={this.props.selectedPatientData.seen_psychiatrist || ""}
              changeHandler={this.props.handleChange}
            />
            <Form.Group>
              <Form.Input
                label={strings.form.label.psychiatricMedications}
                name="psychiatric_medications"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.psychiatricMedications}
                value={
                  this.props.selectedPatientData.psychiatric_medications || ""
                }
                width={16}
              />
            </Form.Group>
          </div>
          <div className="lifestyleHistoryHeader patientSectionHeader">
            {strings.form.header.lifestyleHistory}
          </div>
          <div className="lifestyleHistory">
            <YesNoInput
              label={strings.form.label.smokesCurrently}
              name="smokes_currently"
              value={this.props.selectedPatientData.smokes_currently || ""}
              changeHandler={this.props.handleChange}
            />
            <YesNoInput
              label={strings.form.label.smokedPreviously}
              name="smoked_previously"
              value={this.props.selectedPatientData.smoked_previously || ""}
              changeHandler={this.props.handleChange}
            />
            <YesNoInput
              label={strings.form.label.getsSuntans}
              name="gets_suntans"
              value={this.props.selectedPatientData.gets_suntans || ""}
              changeHandler={this.props.handleChange}
            />
            <YesNoInput
              label={strings.form.label.usesSunbeds}
              name="uses_sunbeds"
              value={this.props.selectedPatientData.uses_sunbeds || ""}
              changeHandler={this.props.handleChange}
            />
          </div>
          <div className="patientSectionHeader">
            {strings.form.header.aestheticHistory}
          </div>
          <div className="aestheticHistory">
            <YesNoInput
              label={strings.form.label.botoxPreviousUse}
              name="botox_previous_use"
              value={this.props.selectedPatientData.botox_previous_use || ""}
              changeHandler={this.props.handleChange}
            />
            <Form.Input
              label={strings.form.label.botoxNumberOfPastTreatments}
              name="botox_number_of_past_treatments"
              onChange={this.props.handleChange}
              placeholder={strings.form.placeholder.botoxNumberOfPastTreatments}
              value={
                this.props.selectedPatientData
                  .botox_number_of_past_treatments || ""
              }
              width={8}
            />
            <DateInput
              label={strings.form.label.botoxWhenLastTreated}
              iconPosition="left"
              name="botox_when_last_treated"
              onChange={this.props.handleChange}
              onKeyDown={e => this.props.handleDateKeyDown(e)}
              placeholder={strings.form.placeholder.date}
              value={
                this.props.selectedPatientData.botox_when_last_treated || ""
              }
              clearIcon
              width={8}
            />
            <YesNoInput
              label={strings.form.label.botoxComplications}
              name="botox_complications"
              value={this.props.selectedPatientData.botox_complications || ""}
              changeHandler={this.props.handleChange}
            />
            <YesNoInput
              label={strings.form.label.dermalFillerPreviousUse}
              name="dermal_filler_previous_use"
              value={
                this.props.selectedPatientData.dermal_filler_previous_use || ""
              }
              changeHandler={this.props.handleChange}
            />
            <Form.Input
              label={strings.form.label.dermalFillerNumberOfPastTreatments}
              name="dermal_filler_number_of_past_treatments"
              onChange={this.props.handleChange}
              placeholder={
                strings.form.placeholder.dermalFillerNumberOfPastTreatments
              }
              value={
                this.props.selectedPatientData
                  .dermal_filler_number_of_past_treatments || ""
              }
              width={8}
            />
            <DateInput
              label={strings.form.label.dermalFillerWhenLastTreated}
              iconPosition="left"
              name="dermal_filler_when_last_treated"
              onChange={this.props.handleChange}
              onKeyDown={e => this.props.handleDateKeyDown(e)}
              placeholder={strings.form.placeholder.date}
              value={
                this.props.selectedPatientData
                  .dermal_filler_when_last_treated || ""
              }
              clearIcon
              width={8}
            />
            <YesNoInput
              label={strings.form.label.dermalFillerComplications}
              name="dermal_filler_complications"
              value={
                this.props.selectedPatientData.dermal_filler_complications || ""
              }
              changeHandler={this.props.handleChange}
            />
            <Form.Group inline>
              <label>{strings.form.label.dermalFillerTypesQuestion}</label>
              <Checkbox
                name={"used_filler_juvederm"}
                checked={
                  this.props.selectedPatientData.used_filler_juvederm || false
                }
                label={strings.form.label.dermalFillerTypeJuvederm}
                onChange={this.props.handleCheckboxChange}
                onClick={this.props.handleCheckboxChange}
                style={{ paddingRight: 20 }}
              />
              <Checkbox
                name={"used_filler_emervel"}
                checked={
                  this.props.selectedPatientData.used_filler_emervel || false
                }
                label={strings.form.label.dermalFillerTypeEmervel}
                onChange={this.props.handleCheckboxChange}
                onClick={this.props.handleCheckboxChange}
                style={{ paddingRight: 20 }}
              />
              <Checkbox
                name={"used_filler_restylane"}
                checked={
                  this.props.selectedPatientData.used_filler_restylane || false
                }
                label={strings.form.label.dermalFillerTypeRestylane}
                onChange={this.props.handleCheckboxChange}
                onClick={this.props.handleCheckboxChange}
                style={{ paddingRight: 20 }}
              />
              <Checkbox
                name={"used_filler_aquamid"}
                checked={
                  this.props.selectedPatientData.used_filler_aquamid || false
                }
                label={strings.form.label.dermalFillerTypeAquamid}
                onChange={this.props.handleCheckboxChange}
                onClick={this.props.handleCheckboxChange}
                style={{ paddingRight: 20 }}
              />
              <Checkbox
                name={"used_filler_belotero"}
                checked={
                  this.props.selectedPatientData.used_filler_belotero || false
                }
                label={strings.form.label.dermalFillerTypeBelotero}
                onChange={this.props.handleCheckboxChange}
                onClick={this.props.handleCheckboxChange}
                style={{ paddingRight: 20 }}
              />
              <Checkbox
                name={"used_filler_bioalcamid"}
                checked={
                  this.props.selectedPatientData.used_filler_bioalcamid || false
                }
                label={strings.form.label.dermalFillerTypeBioalcamid}
                onChange={this.props.handleCheckboxChange}
                onClick={this.props.handleCheckboxChange}
                style={{ paddingRight: 20 }}
              />
              <Checkbox
                name={"used_filler_radiesse"}
                checked={
                  this.props.selectedPatientData.used_filler_radiesse || false
                }
                label={strings.form.label.dermalFillerTypeRadiesse}
                onChange={this.props.handleCheckboxChange}
                onClick={this.props.handleCheckboxChange}
                style={{ paddingRight: 20 }}
              />
              <Checkbox
                name={"used_filler_sculptra"}
                checked={
                  this.props.selectedPatientData.used_filler_sculptra || false
                }
                label={strings.form.label.dermalFillerTypeSculptra}
                onChange={this.props.handleCheckboxChange}
                onClick={this.props.handleCheckboxChange}
                style={{ paddingRight: 20 }}
              />
              <Checkbox
                name={"used_filler_teosyal"}
                checked={
                  this.props.selectedPatientData.used_filler_teosyal || false
                }
                label={strings.form.label.dermalFillerTypeTeosyal}
                onChange={this.props.handleCheckboxChange}
                onClick={this.props.handleCheckboxChange}
                style={{ paddingRight: 20 }}
              />
              <Checkbox
                name={"used_filler_other"}
                checked={
                  this.props.selectedPatientData.used_filler_other || false
                }
                label={strings.form.label.dermalFillerTypeOther}
                onChange={this.props.handleCheckboxChange}
                onClick={this.props.handleCheckboxChange}
                style={{ paddingRight: 20 }}
              />
            </Form.Group>
          </div>
          <div className="pregnancyHistoryHeader patientSectionHeader">
            {strings.form.header.pregnancyHistory}
          </div>
          <div className="pregnancyHistory">
            <Form.Group inline>
              <YesNoInput
                label={strings.form.label.pregnacy}
                name="pregnant"
                value={this.props.selectedPatientData.pregnant || ""}
                changeHandler={this.props.handleChange}
              />
              <YesNoInput
                label={strings.form.label.breastfeeding}
                name="breastfeeding"
                value={this.props.selectedPatientData.breastfeeding || ""}
                changeHandler={this.props.handleChange}
              />
            </Form.Group>
          </div>
          <div className="privacyHeader patientSectionHeader">
            {strings.form.header.privacy}
          </div>
          <div className="privacy">
            <Form.Group>
              <label>{strings.form.label.consentPhotographicInformation}</label>
            </Form.Group>
            <Form.Group>
              <Checkbox
                name={"consent_share_images"}
                checked={false}
                label={strings.form.label.consentShareImages}
                onChange={this.props.handleChange}
                onClick={this.props.handleChange}
                style={{ paddingRight: 20 }}
              />
            </Form.Group>
            <Form.Group>
              <Checkbox
                name={"consent_show_images_to_other_patients"}
                checked={false}
                label={strings.form.label.consentShowImagesToOtherPatients}
                onChange={this.props.handleChange}
                onClick={this.props.handleChange}
                style={{ paddingRight: 20 }}
              />
            </Form.Group>
            <Form.Group>
              <Checkbox
                name={"consent_show_images_on_website"}
                checked={false}
                label={strings.form.label.consentShowImagesOnWebsite}
                onChange={this.props.handleChange}
                onClick={this.props.handleChange}
                style={{ paddingRight: 20 }}
              />
            </Form.Group>
            <Form.Group>
              <Checkbox
                name={"consent_show_images_on_social_media"}
                checked={false}
                label={strings.form.label.consentShowImagesOnSocialMedia}
                onChange={this.props.handleChange}
                onClick={this.props.handleChange}
                style={{ paddingRight: 20 }}
              />
            </Form.Group>
            <Header as="h5">
              {strings.form.label.gdprConsentDate}
              <span className="requiredAsterisk"> *</span>
            </Header>
            <DateInput
              className="dateInput"
              iconPosition="left"
              name="gdpr_consent_date"
              onChange={this.props.handleChange}
              onKeyDown={e => this.props.handleDateKeyDown(e)}
              placeholder={strings.form.placeholder.date}
              //required
              value={this.props.selectedPatientData.gdpr_consent_date || ""}
              width={3}
            />
            <div className="gdprRadioButtons">
              <Form.Group inline>
                <label>
                  {strings.form.label.gdprMethodOfConsent}
                  <span className="requiredAsterisk"> *</span>
                </label>
                <Form.Radio
                  checked={
                    this.props.selectedPatientData.gdpr_consent_method ===
                    "email"
                  }
                  label={strings.form.label.gdprConsentMethodOptionEmail}
                  name="gdpr_consent_method"
                  onChange={this.props.handleCheckboxOrDateChange}
                  value="email"
                />
                <Form.Radio
                  checked={
                    this.props.selectedPatientData.gdpr_consent_method ===
                    "phone"
                  }
                  label={strings.form.label.gdprConsentMethodOptionPhone}
                  name="gdpr_consent_method"
                  onChange={this.props.handleCheckboxOrDateChange}
                  value="phone"
                />
                <Form.Radio
                  checked={
                    this.props.selectedPatientData.gdpr_consent_method ===
                    "person"
                  }
                  label={strings.form.label.gdprConsentMethodOptionPerson}
                  name="GDPR_consent_method"
                  onChange={this.props.handleCheckboxOrDateChange}
                  value="person"
                />
              </Form.Group>
            </div>
            <Form.Group>
              <label>{strings.form.label.consentDeclaration}</label>
            </Form.Group>
            <div className="cancelSaveButtons">
              <Button.Group>
                <Button className="saveButton">
                  {strings.button.saveLabel}
                </Button>
                <BasicModal
                  confirmMessage={strings.modal.saveChangesMessage}
                  handleModalConfirm={this.props.handleModalConfirm}
                  modalClassName="confirmSaveModal"
                  modalOpen={this.props.confirmSaveModalOpen}
                  showNoButton={true}
                  showOkButton={false}
                  showYesButton={true}
                  toggleModal={this.props.toggleConfirmSaveModalVisibility}
                />
                <Button.Or />
                <Button
                  className="cancelButton"
                  onClick={this.props.toggleCancelChangesModalVisibility}
                  type="button"
                >
                  {strings.button.cancelLabel}
                </Button>
                <BasicModal
                  confirmMessage={strings.modal.cancelChangesMessage}
                  handleModalConfirm={this.props.handleModalConfirm}
                  modalClassName="cancelChangesModal"
                  modalOpen={this.props.cancelChangesModalOpen}
                  showNoButton={true}
                  showOkButton={false}
                  showYesButton={true}
                  toggleModal={this.props.toggleCancelChangesModalVisibility}
                />
              </Button.Group>
            </div>
          </div>
        </Form>
      </div>
    );
  };
}

CreatePatient.propTypes = {
  //pageTitle: PropTypes.string.isRequired,
  //selectedPatientData: PropTypes.object.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  // handleDateOfDeathChange: PropTypes.func.isRequired,
  // handleDropdownChange: PropTypes.func.isRequired,
  // handleDateKeyDown: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired
  // hasDateOfDeath: PropTypes.func.isRequired
};

export default CreatePatient;
