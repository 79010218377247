import React from "react";
import { Grid, Header } from "semantic-ui-react";
import "./css/FormTableFunctions.css";
import PropTypes from "prop-types";

class FormTemplateTableFunctions extends React.Component {
  render = () => {
    return (
      <Grid columns={3} className="TableFunctions">
        <Grid.Column>
          <Header as="h1">{this.props.pageTitle}</Header>
        </Grid.Column>
        <Grid.Column />
        <Grid.Column />
      </Grid>
    );
  };
}

FormTemplateTableFunctions.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

export default FormTemplateTableFunctions;
