import React from "react";
import ModifyUser from "../../components/ModifyUser";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { userActions } from "../../store/actions/user.actions";
import PropTypes from "prop-types";

class UserEditContainer extends React.Component {
  componentDidMount = async () => {
    await this.props.getUserById(
      this.props.match.params.id,
      this.props.headers
    );
  };

  handleSubmit = () => {
    this.props.updateUser(
      this.props.match.params.id,
      this.props.selectedUserData,
      this.props.headers
    );
    this.props.history.push("/users");
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    this.props.updateSelectedUser(data.name, data.value);
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.props.setConfirmSaveModalVisibility(!this.props.confirmSaveModalOpen);
  };

  toggleCancelChangesModalVisibility = () => {
    this.props.setCancelChangesModalVisibility(
      !this.props.cancelChangesModalOpen
    );
  };

  render = () => {
    return (
      <div>
        <div style={{ display: this.props.loadingPage ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: this.props.loadingPage ? "none" : "" }}>
          <ModifyUser
            pageHeader={strings.header.editUser}
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            selectedUserData={this.props.selectedUserData}
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.props.cancelChangesModalOpen}
            confirmSaveModalOpen={this.props.confirmSaveModalOpen}
          />
        </div>
      </div>
    );
  };
}

UserEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  selectedUserData: PropTypes.object.isRequired,
  setCancelChangesModalVisibility: PropTypes.func.isRequired,
  setConfirmSaveModalVisibility: PropTypes.func.isRequired,
  updateSelectedUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { users } = state;
  const {
    loadingPage,
    selectedUserData,
    confirmSaveModalOpen,
    cancelChangesModalOpen
  } = users;
  return {
    loadingPage,
    selectedUserData,
    confirmSaveModalOpen,
    cancelChangesModalOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (id, data, headers) => {
      dispatch(userActions.updateUser(id, data, headers));
    },
    getUserById: (id, headers) => {
      dispatch(userActions.getUserById(id, headers));
    },
    updateSelectedUser: (key, value) => {
      dispatch(userActions.updateSelectedUser(key, value));
    },
    setConfirmSaveModalVisibility: open => {
      dispatch(userActions.setConfirmSaveModalVisibility(open));
    },
    setCancelChangesModalVisibility: open => {
      dispatch(userActions.setCancelChangesModalVisibility(open));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEditContainer);
