import { strings } from "./Strings";

export const reasonsForVisitOptions = [
  {
    key: "tired",
    label: strings.form.label.reasonTired
  },
  {
    key: "volume",
    label: strings.form.label.reasonVolume
  },
  {
    key: "eyes",
    label: strings.form.label.reasonEyes
  },
  {
    key: "sun",
    label: strings.form.label.reasonSunSkin
  },
  {
    key: "acne",
    label: strings.form.label.reasonSunAcne
  },
  {
    key: "lips",
    label: strings.form.label.reasonLips
  },
  {
    key: "nose",
    label: strings.form.label.reasonNose
  },
  {
    key: "chin",
    label: strings.form.label.reasonChin
  },
  {
    key: "other",
    label: strings.form.label.reasonOther
  }
];

export const treatmentsConsideredOptions = [
  {
    key: "facial",
    label: strings.form.label.treatmentsFacial
  },
  {
    key: "wrinkle",
    label: strings.form.label.treatmentsWrinkle
  },
  {
    key: "laser",
    label: strings.form.label.treatmentsLaser
  },
  {
    key: "skin",
    label: strings.form.label.treatmentsSkin
  },
  {
    key: "pigmentation",
    label: strings.form.label.treatmentsPigmentation
  },
  {
    key: "thread",
    label: strings.form.label.treatmentsThread
  },
  {
    key: "facelift",
    label: strings.form.label.treatmentsFacelift
  },
  {
    key: "eye",
    label: strings.form.label.treatmentsEye
  }
];

export const previousTreatmentComplicationOptions = [
  {
    key: "droop_eyelid",
    label: strings.form.label.complicationsDroopEyelid
  },
  {
    key: "droop_eyebrow",
    label: strings.form.label.complicationsDroopyEyebrow
  },
  {
    key: "reaction",
    label: strings.form.label.complicationsProductReaction
  },
  {
    key: "infection",
    label: strings.form.label.complicationsInfection
  },
  {
    key: "other",
    label: strings.form.label.complicationsOther
  }
];

export const medicalConditionsOptions = [
  {
    key: "blood_pressure",
    label: strings.form.label.medicalConditionBloodPressure
  },
  {
    key: "chest_pains",
    label: strings.form.label.medicalConditionChestPains
  },
  {
    key: "heart_attack",
    label: strings.form.label.medicalConditionHeartAttack
  },
  {
    key: "asthma",
    label: strings.form.label.medicalConditionAsthma
  },
  {
    key: "copd",
    label: strings.form.label.medicalConditionCOPD
  },
  {
    key: "osteoarthritis",
    label: strings.form.label.medicalConditionOsteoarthritis
  },
  {
    key: "rheumatoid",
    label: strings.form.label.medicalConditionRheumatoid
  },
  {
    key: "breast_cancer",
    label: strings.form.label.medicalConditionBreastCancer
  },
  {
    key: "colon_cancer",
    label: strings.form.label.medicalConditionColonCancer
  },
  {
    key: "lung_cancer",
    label: strings.form.label.medicalConditionLungCancer
  },
  {
    key: "ulcerative_colitis",
    label: strings.form.label.medicalConditionUlcerativeColitis
  },
  {
    key: "crohns",
    label: strings.form.label.medicalConditionCrohns
  },
  {
    key: "diabetes_i",
    label: strings.form.label.medicalConditionDiabetesI
  },
  {
    key: "diabetes_ii",
    label: strings.form.label.medicalConditionDiabetesII
  },
  {
    key: "other",
    label: strings.form.label.medicalConditionOther
  }
];

export const dermatologicalDiseaseOptions = [
  {
    key: "eczema",
    label: strings.form.label.dermatologicalDiseasesEczema
  },
  {
    key: "psoriasis",
    label: strings.form.label.dermatologicalDiseasesPsoriasis
  },
  {
    key: "urticaria",
    label: strings.form.label.dermatologicalDiseasesUrticaria
  },
  {
    key: "vitiligo",
    label: strings.form.label.dermatologicalDiseasesVitiligo
  },
  {
    key: "other",
    label: strings.form.label.dermatologicalDiseasesOther
  }
];

export const neurologicalConditionOptions = [
  {
    key: "myasthenia",
    label: strings.form.label.neurologicalConditionsMyasthenia
  },
  {
    key: "multiple_sclerosis",
    label: strings.form.label.neurologicalConditionsMultipleSclerosis
  },
  {
    key: "mnd",
    label: strings.form.label.neurologicalConditionsMND
  },
  {
    key: "bells_palsy",
    label: strings.form.label.neurologicalConditionsBellsPalsy
  },
  {
    key: "other",
    label: strings.form.label.neurologicalConditionsOther
  }
];

export const dermalFillerTypeOptions = [
  {
    key: "juvederm",
    label: strings.form.label.dermalFillerTypeJuvederm
  },
  {
    key: "emervel",
    label: strings.form.label.dermalFillerTypeEmervel
  },
  {
    key: "restylane",
    label: strings.form.label.dermalFillerTypeRestylane
  },
  {
    key: "aquamid",
    label: strings.form.label.dermalFillerTypeAquamid
  },
  {
    key: "belotero",
    label: strings.form.label.dermalFillerTypeBelotero
  },
  {
    key: "bioalcamid",
    label: strings.form.label.dermalFillerTypeBioalcamid
  },
  {
    key: "radiesse",
    label: strings.form.label.dermalFillerTypeRadiesse
  },
  {
    key: "sculptra",
    label: strings.form.label.dermalFillerTypeSculptra
  },
  {
    key: "teosyal",
    label: strings.form.label.dermalFillerTypeTeosyal
  },
  {
    key: "other",
    label: strings.form.label.dermalFillerTypeOther
  }
];

export const previousProcedureOptions = [
  {
    key: "laser",
    label: strings.form.label.previousProceduresLaser
  },
  {
    key: "facelift",
    label: strings.form.label.previousProceduresFacelift
  },
  {
    key: "eyelid",
    label: strings.form.label.previousProceduresEyelid
  },
  {
    key: "breast",
    label: strings.form.label.previousProceduresBreast
  },
  {
    key: "liposuction",
    label: strings.form.label.previousProceduresLiposuction
  },
  {
    key: "tummy",
    label: strings.form.label.previousProceduresTummy
  }
];

export const photographicConsentOptions = [
  {
    key: "consent_share_images",
    label: strings.form.label.consentShareImages
  },
  {
    key: "consent_show_images_to_other_patients",
    label: strings.form.label.consentShowImagesToOtherPatients
  },
  {
    key: "consent_show_images_on_website",
    label: strings.form.label.consentShowImagesOnWebsite
  },
  {
    key: "consent_show_images_on_social_media",
    label: strings.form.label.consentShowImagesOnSocialMedia
  }
];
