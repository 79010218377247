import React from "react";
import {
  Button,
  List,
  Icon,
  TextArea,
  Label,
  Header,
  Modal,
  Image,
  Loader,
  Dimmer
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { connect } from "react-redux";
import { strings } from "../resources";
import { BasicModal, EnterPhotoNameModal } from ".";
import Photograph from "./Photograph/Photograph";
import PropTypes from "prop-types";
import { imageActions } from "../store/actions/image.actions";
import "./css/ImageComponents.css";

class ImageManager extends React.Component {
  handleGetSingleImage = async name => {
    if (this.props.fullsizedImageData.length > 0) {
      if (this.props.fullsizedImageData[0].imageName !== name) {
        this.props.setLoadingFullsizedImage(true);
        await this.props.getSingleImage(
          `${this.props.imagePath}/fullsized`,
          name,
          this.props.headers
        );
        this.props.setLoadingFullsizedImage(false);
      }
    } else {
      this.props.setLoadingFullsizedImage(true);
      await this.props.getSingleImage(
        `${this.props.imagePath}/fullsized`,
        name,
        this.props.headers
      );
      this.props.setLoadingFullsizedImage(false);
    }
  };

  render = () => {
    return (
      <div>
        <List className="imageList">
          {this.props.localData.map((item, key) => (
            <List.Item key={"item" + key} className="listItem">
              <List.Content>
                <Modal
                  closeIcon
                  //dimmer="inverted"
                  onOpen={() => {
                    this.handleGetSingleImage(item.imageName);
                  }}
                  trigger={
                    <div>
                      <Photograph
                        image={item.url}
                        imageName={item.imageName}
                        caption={item.caption}
                        takenOnDate={item.takenOnDate}
                        uploadedOnDate={item.uploadedOnDate}
                      />
                      <div className="photographTags">
                        <div className="imageName">{item.imageName}</div>
                        <div>
                          {strings.photograph.label.uploadedOn}{" "}
                          {item.uploadedOnDate}
                        </div>
                      </div>
                    </div>
                  }
                >
                  {!this.props.loadingFullsizedImage && (
                    <Modal.Header>{item.imageName}</Modal.Header>
                  )}
                  {this.props.loadingFullsizedImage ? (
                    <Modal.Content className="loaderContent">
                      <Dimmer active inverted>
                        <Loader active className="fullsizedImageLoader">
                          {strings.header.loadingImage}
                        </Loader>
                      </Dimmer>
                    </Modal.Content>
                  ) : (
                    <Modal.Content>
                      <Image
                        inline
                        wrapped
                        src={
                          this.props.fullsizedImageData.length > 0
                            ? this.props.fullsizedImageData[0].url
                            : ""
                        }
                        className="imageModalImage"
                      />
                      {item.caption && (
                        <Modal.Description className="imageModalDescription">
                          {item.caption}
                        </Modal.Description>
                      )}
                      <Modal.Description className="imageModalUploadedOnDate">
                        {strings.label.uploadedOn + ": " + item.uploadedOnDate}
                      </Modal.Description>
                      {item.takenOnDate && (
                        <Modal.Description className="imageModalTakenOnDate">
                          {strings.label.takenOnDate + ": " + item.takenOnDate}
                        </Modal.Description>
                      )}
                    </Modal.Content>
                  )}
                </Modal>
                <div className="imagePropertiesInputs">
                  <div>
                    <Header as="h5">{strings.label.caption}</Header>
                    <TextArea
                      className="imageCaptionInput"
                      disabled={this.props.getViewModeByImage(item) === "view"}
                      name="caption"
                      onChange={(event, data) =>
                        this.props.handleChange(event, data, key)
                      }
                      placeholder={strings.placeholder.caption}
                      value={item.caption || ""}
                    />
                  </div>
                  <div>
                    <Header as="h5">{strings.label.takenOnDate}</Header>
                    <DateInput
                      className="imageTakenOnDateInput"
                      disabled={this.props.getViewModeByImage(item) === "view"}
                      iconPosition="left"
                      name="takenOnDate"
                      onChange={(event, data) =>
                        this.props.handleChange(event, data, key)
                      }
                      onKeyDown={e => this.props.handleDateKeyDown(e)}
                      placeholder={strings.placeholder.takenOnDate}
                      value={item.takenOnDate || ""}
                    />
                  </div>
                </div>
                {this.props.getViewModeByImage(item) === "view" && (
                  <div className="editDeleteImageButtons">
                    <Button.Group>
                      <Button
                        className="editButton"
                        onClick={() => this.props.handleModeSwitch(item)}
                      >
                        {strings.button.editLabel}
                      </Button>
                      <Button.Or />
                      <Button
                        className="cancelButton"
                        onClick={() => this.props.prepareForDelete(item)}
                        type="button"
                      >
                        {strings.button.deleteLabel}
                      </Button>
                    </Button.Group>
                  </div>
                )}
                {this.props.getViewModeByImage(item) === "edit" && (
                  <div className="cancelSaveImageButtons">
                    <Button.Group>
                      <Button
                        className="saveButton"
                        onClick={() => this.props.prepareForSave(item)}
                        type="button"
                      >
                        {strings.button.saveLabel}
                      </Button>
                      <Button.Or />
                      <Button
                        className="cancelButton"
                        onClick={() => this.props.prepareForCancel(item)}
                        type="button"
                      >
                        {strings.button.cancelLabel}
                      </Button>
                      <BasicModal
                        confirmMessage={strings.modal.saveChangesMessage}
                        handleModalConfirm={this.props.handleModalConfirm}
                        modalClassName="confirmSaveModal"
                        modalOpen={this.props.confirmSaveModalOpen}
                        showOkButton={false}
                        showYesButton={true}
                        showNoButton={true}
                        toggleModal={
                          this.props.toggleConfirmSaveModalVisibility
                        }
                      />
                      <BasicModal
                        confirmMessage={strings.modal.cancelChangesMessage}
                        handleModalConfirm={this.props.handleModalConfirm}
                        modalClassName="cancelChangesModal"
                        modalOpen={this.props.cancelChangesModalOpen}
                        showOkButton={false}
                        showYesButton={true}
                        showNoButton={true}
                        toggleModal={
                          this.props.toggleCancelChangesModalVisibility
                        }
                      />
                    </Button.Group>
                  </div>
                )}
              </List.Content>
            </List.Item>
          ))}
          {
            // ghost elements allow Flexbox to fill out columns when the number of
            // items does not divide equally into the number of columns
          }
          <List.Item className="listItem" />
          <List.Item className="listItem" />
          <List.Item
            style={{ display: this.props.localData.length === 0 ? "" : "none" }}
          >
            No Images Found
          </List.Item>
          <BasicModal
            confirmMessage={strings.modal.deleteImageMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmDeleteModal"
            modalOpen={this.props.confirmDeleteModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleConfirmDeleteModalVisibility}
          />
        </List>
        <div className="imageOperations">
          <div className="uploadButton">
            <Button
              as="label"
              className="uploadImageButton"
              htmlFor="fileUploadButton"
            >
              <Icon name="cloud upload" />
              {strings.button.uploadImageLabel}
            </Button>
            <input
              id="fileUploadButton"
              hidden
              accept="image/*"
              type="file"
              onChange={event => {
                this.props.handleUpload(event.target.files[0], false);
              }}
            />
            {this.props.fileTypeWarningVisible && (
              <Label color="red" pointing="left">
                {strings.warning.fileTypeWarning}
              </Label>
            )}
          </div>
          <div className="takePhotoButtonDiv">
            <Button
              as="label"
              className="takePhotoButton"
              htmlFor="takePhotoButton"
              disabled={this.props.isMobile() ? false : true}
            >
              <Icon name="camera" />
              {strings.button.addPhotoLabel}
            </Button>
            <input
              id="takePhotoButton"
              hidden
              accept="image/*"
              type="file"
              capture="camera"
              onChange={this.props.handleTakePhoto}
              // allows the same file to be uploaded twice in a row
              onClick={event => {
                event.target.value = null;
              }}
            />
          </div>
        </div>
        <EnterPhotoNameModal
          handleModalConfirm={this.props.handleModalConfirm}
          modalClassName="enterPhotoNameModal"
          modalOpen={this.props.enterPhotoNameModalOpen}
          toggleModal={this.props.toggleEnterPhotoNameModalVisibility}
        />
      </div>
    );
  };
}

ImageManager.propTypes = {
  // headers: PropTypes.object.isRequired,
  // imagePath: PropTypes.string.isRequired,
  // allowEdit: PropTypes.bool.isRequired,
  localData: PropTypes.array.isRequired
  // remoteData: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  const { images } = state;
  const { fullsizedImageData, loadingFullsizedImage } = images;
  return {
    fullsizedImageData,
    loadingFullsizedImage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSingleImage: (path, name, headers) => {
      return dispatch(imageActions.getSingleImage(path, name, headers));
    },
    setLoadingFullsizedImage: loading => {
      return dispatch(imageActions.setLoadingFullsizedImage(loading));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageManager);
