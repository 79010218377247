import React from "react";
import PropTypes from "prop-types";
import { Divider, Form } from "semantic-ui-react";
import { strings } from "../../resources";
import {
  previousTreatmentComplicationOptions,
  medicalConditionsOptions,
  dermatologicalDiseaseOptions,
  neurologicalConditionOptions,
  reasonsForVisitOptions,
} from "../../resources/CheckboxOptions";
import {
  YesNoInput,
  CheckboxGroup,
  RangeSlider,
  PatientDataField,
} from "../ui";
import { ImageAnnotatorContainer } from "../../../Images/containers";
import face from "../../../Images/assets/images/face.png";
import { SignatureFieldContainer } from "../../../Images/containers";
import { DatePicker } from "../ui";
import { dateEpochToDateTimeString } from "../../../App/helpers/DateTimeFormatConversion";

class FormReturningPatient extends React.Component {
  componentWillMount = () => {
    if (this.props.mode === "create" || this.props.mode === "edit") {
      let todaysDateStr = dateEpochToDateTimeString(new Date(), "-");
      this.props.updateSelectedForm("date_patient_signed", todaysDateStr);
    }
  };

  componentDidMount = () => {
    this.props.populatePatientData([
      { fieldName: "medical_conditions", loadIntoForm: true },
      { fieldName: "dermatological_diseases", loadIntoForm: true },
      { fieldName: "neurological_conditions", loadIntoForm: true },
      { fieldName: "supplements", loadIntoForm: false },
      { fieldName: "regular_medications", loadIntoForm: false },
      { fieldName: "drug_allergies", loadIntoForm: false },
      { fieldName: "general_allergies", loadIntoForm: false },
    ]);
  };

  render = () => {
    const errorFields = this.props.validationErrors.map((error) => {
      return error.field;
    });
    return (
      <div className="createFormContainer">
        <div className="patientFormSectionHeader">
          {strings.form.header.previousTreatment}
        </div>
        <Form.Field
          error={errorFields.includes("previous_treatment_satisfaction")}
          className="textField"
          content={
            <label>
              <span>{strings.form.label.satisfiedWithTreatment}</span>
              <span className="requiredAsterisk"> *</span>
            </label>
          }
        />
        <Form.Group inline>
          <Form.Radio
            error={errorFields.includes("previous_treatment_satisfaction")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.satisfied}
            name="previous_treatment_satisfaction"
            value={strings.form.databaseValues.satisfied}
            checked={
              this.props.selectedFormData.previous_treatment_satisfaction ===
                strings.form.databaseValues.satisfied || false
            }
            onChange={this.props.handleChange}
          />
          <Form.Radio
            error={errorFields.includes("previous_treatment_satisfaction")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.dissatisfied}
            name="previous_treatment_satisfaction"
            value={strings.form.databaseValues.dissatisfied}
            checked={
              this.props.selectedFormData.previous_treatment_satisfaction ===
                strings.form.databaseValues.dissatisfied || false
            }
            onChange={this.props.handleChange}
          />
        </Form.Group>

        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes(
            "previous_treatment_complications_yes_no"
          )}
          name="previous_treatment_complications_yes_no"
          label={strings.form.label.complicationsFollowingTreatmentYesNo}
          value={
            this.props.selectedFormData.previous_treatment_complications_yes_no
          }
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childElement={
            <React.Fragment>
              <CheckboxGroup
                mode={this.props.mode}
                error={errorFields.includes("previous_treatment_complications")}
                label={strings.form.label.complicationsFollowingTreatment}
                group="previous_treatment_complications"
                data={this.props.selectedFormData}
                required
                handleCheckboxChange={this.props.handleCheckboxGroupChange}
                options={previousTreatmentComplicationOptions}
              />
            </React.Fragment>
          }
        />
        <Form.Group>
          {this.props.selectedFormData.previous_treatment_complications &&
            this.props.selectedFormData.previous_treatment_complications
              .other &&
            this.props.selectedFormData.previous_treatment_complications.other
              .checked === true && (
              <Form.TextArea
                error={errorFields.includes(
                  "previous_treatment_complications_other"
                )}
                className="groupChild"
                label={strings.form.label.complicationsOtherDetails}
                name="previous_treatment_complications_other"
                group="previous_treatment_complications"
                child="other"
                onChange={this.props.handleNestedChange}
                placeholder={strings.form.placeholder.details}
                required
                value={
                  this.props.selectedFormData
                    .previous_treatment_complications_other || ""
                }
                rows={2}
                width={16}
              />
            )}
        </Form.Group>

        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("previous_treatment_confidence")}
          name="previous_treatment_confidence"
          label={strings.form.label.confidenceImprovedByTreatment}
          value={this.props.selectedFormData.previous_treatment_confidence}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <div className="patientFormSectionHeader">
          {strings.form.header.medicalHistory}
        </div>
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("medical_conditions_changes_toggle")}
          name="medical_conditions_changes_toggle"
          label={strings.form.label.changesToMedicalHistory}
          value={this.props.selectedFormData.medical_conditions_changes_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["medical_conditions"]}
          childElement={
            <React.Fragment>
              <CheckboxGroup
                mode={this.props.mode}
                error={errorFields.includes("medical_conditions")}
                required
                label={strings.form.label.changesToMedicalHistoryDetails}
                group="medical_conditions"
                data={this.props.selectedFormData}
                handleCheckboxChange={this.props.handleCheckboxGroupChange}
                options={medicalConditionsOptions}
              />
              <Form.Group>
                {this.props.selectedFormData.medical_conditions &&
                  this.props.selectedFormData.medical_conditions.other &&
                  this.props.selectedFormData.medical_conditions.other
                    .checked === true && (
                    <Form.TextArea
                      error={errorFields.includes("medical_conditions_other")}
                      className="groupChild"
                      label={strings.form.label.complicationsOtherDetails}
                      name="medical_conditions_other"
                      group="medical_conditions"
                      child="other"
                      onChange={this.props.handleNestedChange}
                      placeholder={strings.form.placeholder.details}
                      required
                      value={
                        this.props.selectedFormData.medical_conditions_other ||
                        ""
                      }
                      rows={2}
                      width={16}
                    />
                  )}
              </Form.Group>
            </React.Fragment>
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("dermatological_diseases_changes_toggle")}
          name="dermatological_diseases_changes_toggle"
          label={strings.form.label.newDermatologicalDiseasesToggle}
          value={
            this.props.selectedFormData.dermatological_diseases_changes_toggle
          }
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["dermatological_diseases"]}
          childElement={
            <React.Fragment>
              <CheckboxGroup
                mode={this.props.mode}
                error={errorFields.includes("dermatological_diseases")}
                required
                label={strings.form.label.dermatologicalDiseases}
                group="dermatological_diseases"
                data={this.props.selectedFormData}
                handleCheckboxChange={this.props.handleCheckboxGroupChange}
                options={dermatologicalDiseaseOptions}
              />
              <Form.Group>
                {this.props.selectedFormData.dermatological_diseases &&
                  this.props.selectedFormData.dermatological_diseases.other &&
                  this.props.selectedFormData.dermatological_diseases.other
                    .checked === true && (
                    <Form.TextArea
                      error={errorFields.includes(
                        "dermatological_diseases_other"
                      )}
                      className="groupChild"
                      label={strings.form.label.complicationsOtherDetails}
                      name="dermatological_diseases_other"
                      group="dermatological_diseases"
                      child="other"
                      onChange={this.props.handleNestedChange}
                      placeholder={strings.form.placeholder.details}
                      required
                      value={
                        this.props.selectedFormData
                          .dermatological_diseases_other || ""
                      }
                      rows={2}
                      width={16}
                    />
                  )}
              </Form.Group>
            </React.Fragment>
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("neurological_conditions_changes_toggle")}
          name="neurological_conditions_changes_toggle"
          label={strings.form.label.neurologicalConditionsToggle}
          value={
            this.props.selectedFormData.neurological_conditions_changes_toggle
          }
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["neurological_conditions"]}
          childElement={
            <React.Fragment>
              <CheckboxGroup
                mode={this.props.mode}
                error={errorFields.includes("neurological_conditions")}
                required
                label={strings.form.label.neurologicalConditions}
                group="neurological_conditions"
                data={this.props.selectedFormData}
                handleCheckboxChange={this.props.handleCheckboxGroupChange}
                options={neurologicalConditionOptions}
              />
              <Form.Group>
                {this.props.selectedFormData.neurological_conditions &&
                  this.props.selectedFormData.neurological_conditions.other &&
                  this.props.selectedFormData.neurological_conditions.other
                    .checked === true && (
                    <Form.TextArea
                      error={errorFields.includes(
                        "neurological_conditions_other"
                      )}
                      className="groupChild"
                      label={strings.form.label.complicationsOtherDetails}
                      name="neurological_conditions_other"
                      group="neurological_conditions"
                      child="other"
                      onChange={this.props.handleNestedChange}
                      placeholder={strings.form.placeholder.details}
                      required
                      value={
                        this.props.selectedFormData
                          .neurological_conditions_other || ""
                      }
                      rows={2}
                      width={16}
                    />
                  )}
              </Form.Group>
            </React.Fragment>
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("supplements_changes_toggle")}
          name="supplements_changes_toggle"
          label={strings.form.label.changesToSupplementsToggle}
          value={this.props.selectedFormData.supplements_changes_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["supplements"]}
          childElement={
            <React.Fragment>
              <PatientDataField
                label={strings.form.label.patientRecordSupplements}
                value={this.props.patientRecordFields.supplements || null}
              />
              <Form.TextArea
                error={errorFields.includes("supplements")}
                label={strings.form.label.changesToSupplementsDetails}
                name="supplements"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.details}
                required
                value={this.props.selectedFormData.supplements || ""}
                rows={2}
                width={16}
              />
            </React.Fragment>
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("regular_medications_changes_toggle")}
          name="regular_medications_changes_toggle"
          label={strings.form.label.newMedications}
          value={this.props.selectedFormData.regular_medications_changes_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["regular_medications"]}
          childElement={
            <React.Fragment>
              <PatientDataField
                label={strings.form.label.patientRecordRegularMedications}
                value={
                  this.props.patientRecordFields.regular_medications || null
                }
              />
              <Form.TextArea
                error={errorFields.includes("regular_medications")}
                label={strings.form.label.newMedicationsDetails}
                name="regular_medications"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.details}
                required
                value={this.props.selectedFormData.regular_medications || ""}
                rows={2}
                width={16}
              />
            </React.Fragment>
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("drug_allergies_changes_toggle")}
          name="drug_allergies_changes_toggle"
          label={strings.form.label.newDrugAllergies}
          value={this.props.selectedFormData.drug_allergies_changes_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["drug_allergies"]}
          childElement={
            <React.Fragment>
              <PatientDataField
                label={strings.form.label.patientRecordDrugAllergies}
                value={this.props.patientRecordFields.drug_allergies || null}
              />
              <Form.TextArea
                error={errorFields.includes("drug_allergies")}
                label={strings.form.label.drugAllergies}
                name="drug_allergies"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.details}
                required
                value={this.props.selectedFormData.drug_allergies || ""}
                rows={2}
                width={16}
              />
            </React.Fragment>
          }
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("general_allergies_changes_toggle")}
          name="general_allergies_changes_toggle"
          label={strings.form.label.newGeneralAllergies}
          value={this.props.selectedFormData.general_allergies_changes_toggle}
          changehandler={this.props.handleYesNoChange}
          required
          showChildWhenValue="Yes"
          childrenFields={["general_allergies"]}
          childElement={
            <React.Fragment>
              <PatientDataField
                label={strings.form.label.patientRecordGeneralAllergies}
                value={this.props.patientRecordFields.general_allergies || null}
              />
              <Form.TextArea
                label={strings.form.label.generalAllergies}
                error={errorFields.includes("general_allergies")}
                name="general_allergies"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.details}
                required
                value={this.props.selectedFormData.general_allergies || ""}
                rows={2}
                width={16}
              />
            </React.Fragment>
          }
        />
        <div className="patientFormSectionHeader">
          {strings.form.header.aestheticInterest}
        </div>
        <ImageAnnotatorContainer
          annotationImagePath={
            this.props.formId ? `forms/${this.props.formId}/returningPatientAreasOfConcern` : ""
          }
          backgroundImage={face}
          blank={this.props.mode === "template"}
          header={
            this.props.mode === "create"
              ? strings.header.circleAreasOfConcern
              : strings.header.areasOfConcern
          }
          headers={this.props.headers}
          readOnly={this.props.mode === "create" ? false : true}
          annotationName={"returningPatientAreasOfConcern"}
          handleAnnotationWasModified={this.props.handleAnnotationWasModified}
        />
        <CheckboxGroup
          mode={this.props.mode}
          error={errorFields.includes("reasons")}
          label={strings.form.label.reasonForVisit}
          required
          group="reasons"
          data={this.props.selectedFormData}
          handleCheckboxChange={this.props.handleCheckboxGroupChange}
          options={reasonsForVisitOptions}
        />
        <Form.Group>
          {this.props.selectedFormData.reasons &&
            this.props.selectedFormData.reasons.other &&
            this.props.selectedFormData.reasons.other.checked === true && (
              <Form.TextArea
                error={errorFields.includes("reasons_other")}
                label={strings.form.label.reasonOtherDetails}
                name="reasons_other"
                group="reasons"
                child="other"
                onChange={this.props.handleNestedChange}
                placeholder={strings.form.placeholder.details}
                required
                value={this.props.selectedFormData.reasons_other || ""}
                rows={2}
                width={16}
              />
            )}
        </Form.Group>
        <RangeSlider
          error={errorFields.includes("bothered_by_appearance")}
          disabled={
            this.props.mode === "view" || this.props.mode === "template"
          }
          onChange={this.props.handleSliderChange}
          name="bothered_by_appearance"
          label={strings.form.label.botheredByAppearance}
          value={this.props.selectedFormData.bothered_by_appearance || 0}
          required
        />
        <Form.Field
          error={errorFields.includes("how_often_appearance")}
          className="textField"
          content={
            <label>
              <span>{strings.form.label.howOftenAppearanceQuestion}</span>
              <span className="requiredAsterisk"> *</span>
            </label>
          }
        />
        <Form.Group inline>
          <Form.Radio
            error={errorFields.includes("how_often_appearance")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.howOftenAlways}
            name="how_often_appearance"
            value={strings.form.databaseValues.howOftenAlways}
            checked={
              this.props.selectedFormData.how_often_appearance ===
                strings.form.databaseValues.howOftenAlways || false
            }
            onChange={this.props.handleChange}
          />
          <Form.Radio
            error={errorFields.includes("how_often_appearance")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.howOftenDaily}
            name="how_often_appearance"
            value={strings.form.databaseValues.howOftenDaily}
            checked={
              this.props.selectedFormData.how_often_appearance ===
                strings.form.databaseValues.howOftenDaily || false
            }
            onChange={this.props.handleChange}
          />
          <Form.Radio
            error={errorFields.includes("how_often_appearance")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.howOftenMostDays}
            name="how_often_appearance"
            value={strings.form.databaseValues.howOftenMostDays}
            checked={
              this.props.selectedFormData.how_often_appearance ===
                strings.form.databaseValues.howOftenMostDays || false
            }
            onChange={this.props.handleChange}
          />
          <Form.Radio
            error={errorFields.includes("how_often_appearance")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.howOftenWeekly}
            name="how_often_appearance"
            value={strings.form.databaseValues.howOftenWeekly}
            checked={
              this.props.selectedFormData.how_often_appearance ===
                strings.form.databaseValues.howOftenWeekly || false
            }
            onChange={this.props.handleChange}
          />
          <Form.Radio
            error={errorFields.includes("how_often_appearance")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.howOftenMonthly}
            name="how_often_appearance"
            value={strings.form.databaseValues.howOftenMonthly}
            checked={
              this.props.selectedFormData.how_often_appearance ===
                strings.form.databaseValues.howOftenMonthly || false
            }
            onChange={this.props.handleChange}
          />
          <Form.Radio
            error={errorFields.includes("how_often_appearance")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.howOftenNotVery}
            name="how_often_appearance"
            value={strings.form.databaseValues.howOftenNotVery}
            checked={
              this.props.selectedFormData.how_often_appearance ===
                strings.form.databaseValues.howOftenNotVery || false
            }
            onChange={this.props.handleChange}
          />
        </Form.Group>
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("leave_house_toggle")}
          name="leave_house_toggle"
          label={strings.form.label.leaveHouseToggle}
          value={this.props.selectedFormData.leave_house_toggle}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("avoids_social_situations_toggle")}
          name="avoids_social_situations_toggle"
          label={strings.form.label.avoidsSocialSituationsToggle}
          value={this.props.selectedFormData.avoids_social_situations_toggle}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("avoids_photographs_toggle")}
          name="avoids_photographs_toggle"
          label={strings.form.label.avoidsPhotographsToggle}
          value={this.props.selectedFormData.avoids_photographs_toggle}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("avoids_certain_angles_toggle")}
          name="avoids_certain_angles_toggle"
          label={strings.form.label.avoidsCertainAnglesToggle}
          value={this.props.selectedFormData.avoids_certain_angles_toggle}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("wears_makeup_toggle")}
          name="wears_makeup_toggle"
          label={strings.form.label.wearsMakeupToggle}
          value={this.props.selectedFormData.wears_makeup_toggle}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <Divider hidden />
        <Form.Group>
          <Form.Input
            label={strings.form.label.patientPrintName}
            error={errorFields.includes("signed_by_patient")}
            name="signed_by_patient"
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.patientPrintName}
            required
            value={this.props.selectedFormData.signed_by_patient || ""}
            width={8}
          />
        </Form.Group>
        <SignatureFieldContainer
          mode={this.props.mode}
          error={errorFields.includes("signature_data_url")}
          headers={this.props.headers}
          readOnly={this.props.mode === "create" ? false : true}
          signatureImagePath={
            this.props.formId ? `signatures/forms/${this.props.formId}` : ""
          }
        />
        <Form.Group>
          <DatePicker
            error={errorFields.includes("date_patient_signed")}
            className="datePatientSigned"
            name="date_patient_signed"
            onChange={this.props.handleDateChange}
            onKeyDown={(e) => this.props.handleDateKeyDown(e)}
            required
            label={strings.form.label.datePatientSigned}
            value={this.props.selectedFormData.date_patient_signed || ""}
          />
        </Form.Group>
      </div>
    );
  };
}

FormReturningPatient.propTypes = {
  mode: PropTypes.string.isRequired, //create, view or edit
  selectedFormData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  handleAnnotationWasModified: PropTypes.func.isRequired,
};

export default FormReturningPatient;
