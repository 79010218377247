import newPatientRegistration from "./newPatientRegistrationSchema";
import aestheticInterest from "./aestheticInterestSchema";
import followUp from "./followUpSchema";
import returningPatient from "./returningPatientSchema";
import consentCovid from "./consentCovidSchema";
import consentBotox from "./consentBotoxSchema";
import consentUpperBlepharoplasty from "./consentUpperBlepharoplastySchema";
import consentUpperLowerBlepharoplasty from "./consentUpperLowerBlepharoplastySchema";
import consentCO2RE from "./consentCO2RESchema";
import consentProfound from "./consentProfoundSchema";
import consentFaceAndNeck from "./consentFaceAndNeckSchema";
import consentDermalFillers from "./consentDermalFillersSchema";

export const formSchemas = {
  newPatientRegistration,
  aestheticInterest,
  followUp,
  returningPatient,
  consentCovid,
  consentBotox,
  consentUpperBlepharoplasty,
  consentUpperLowerBlepharoplasty,
  consentCO2RE,
  consentProfound,
  consentFaceAndNeck,
  consentDermalFillers,
};
