import { API } from "../../Products/services";

const productApi = new API();

export async function getProductsFilteredByUsedInTreatment(props) {
  const filteredList = props.productListData.filter(product => {
    return props.selectedTreatmentData.selected_products.hasOwnProperty(
      product.id
    );
  });
  return filteredList
};

export async function addInactiveAndExpiredUsedProducts(selectedProductsListData, props) {
  for (let product in props.selectedTreatmentData.selected_products) {
    let productIsInList = false;
    for (let i = 0; i < selectedProductsListData.length; i++) {
      if (selectedProductsListData[i].id === product) {
        productIsInList = true;
      }
    }
    if (!productIsInList) {
      // The treatment data only stores the product's id and name, so we need
      // to get the other information for the product
      let productData = await productApi.getProductById(product)
      selectedProductsListData.push(productData.data);
    }
  }
  return selectedProductsListData;
};