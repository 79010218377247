import moment from "moment";
moment.suppressDeprecationWarnings = true;

const comparePatientNameAndDob = (
  enteredFirstName,
  enteredSurname,
  enteredDob,
  storedFirstName,
  storedSurname,
  storedDob
) => {
  const patientFirstName = enteredFirstName.replace(/\W/g, "").toLowerCase();
  const verifyFirstName = storedFirstName.replace(/\W/g, "").toLowerCase();
  const patientSurname = enteredSurname.replace(/\W/g, "").toLowerCase();
  const verifySurname = storedSurname.replace(/\W/g, "").toLowerCase();
  if (
    verifyFirstName !== patientFirstName ||
    verifySurname !== patientSurname
  ) {
    return false;
  }

  // wrap in a try in case date creation fails
  try {
    let patientDOB = moment(enteredDob, "DD-MM-YYYY", true);
    if (!patientDOB.isValid()) {
      //Month may be in a different format (e.g. May instead of 05)
      patientDOB = moment(enteredDob, "DD-MMM-YYYY");
    }
    let verifyDOB = moment(storedDob, "DD-MM-YYYY", true);
    if (!verifyDOB.isValid()) {
      //Month may be in a different format (e.g. May instead of 05)
      verifyDOB = moment(storedDob, "DD-MMM-YYYY");
    }

    if (!patientDOB.isValid() || !verifyDOB.isValid()) {
      console.log("Invalid date - cannot convert and compare");
      return false;
    } else if (patientDOB.isSame(verifyDOB)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("DOB conversion error");
    return false;
  }
};

export default comparePatientNameAndDob;
