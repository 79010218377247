import { strings } from "../";

const newTreatmentTypeSchema = {
  treatment_type: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.treatmentType
  },
  treatment_category: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.treatmentCategory
  },
  period_until_next_treatment: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.periodUntilNextTreatment
  },
  text_notification_wording: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.textNotificationWording
  },
  text_notification_timeframe: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.textNotificationTimeFrame
  },
  doctors_and_prices: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.doctorsAndPrices,
    validate: (field, data) => {
      if (data[field] === undefined || data[field].length === 0) {
        return strings.form.validation.errorMessageReason.isRequired;
      } else {
        return true;
      }
    }
  },
  aftercare_instructions: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.aftercareInstructions
  }
};

export default newTreatmentTypeSchema;
