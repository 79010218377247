import React from "react";
import PropTypes from "prop-types";
import TableComponent from "../../components/TreatmentTypeList";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { treatmentTypeActions } from "../../store/actions/treatmentType.actions";
import { strings } from "../../resources";
import { dateFormatter } from "../../helpers";
import "./css/TreatmentList.css";

class TreatmentTypeListContainer extends React.Component {
  state = {
    deleteModalOpen: false
  };

  componentDidMount = async () => {
    //this.props.hideAllTableModals();
    if (this.props.treatmentTypeListData[0]) {
      //if (Object.keys(this.props.treatmentTypeListData[0]).length === 0) {
      await this.props.getTreatmentTypes(this.props.headers);
      //}
    }
  };

  handleDelete = () => {
    this.props.deleteTreatment(
      this.props.treatmentToDelete,
      this.props.headers
    );
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "deleteTreatmentModal":
        this.handleDelete();
        break;

      default:
        break;
    }
  };

  toggleConfirmDeleteModalVisibility = () => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen
    });
  };

  render = () => {
    let data = this.props.treatmentTypeListData;
    data.forEach(function(treatment) {
      if (treatment["created_date"]) {
        treatment[
          "created_date_formatted"
        ] = dateFormatter.isoToFormattedDateTime(treatment["created_date"]);
      }
      if (
        treatment["period_until_next_treatment"] &&
        treatment["period_until_next_treatment_units"]
      ) {
        treatment["combined_time_until_next_treatment"] =
          treatment["period_until_next_treatment"] +
          " " +
          treatment["period_until_next_treatment_units"];
      }
    });
    return (
      <div>
        <div style={{ display: this.props.loadingPage ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          className="clientList"
          style={{ display: this.props.loadingPage ? "none" : "" }}
        >
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            treatmentTypeListData={data}
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            deleteModalOpen={this.state.deleteModalOpen}
            handleModalConfirm={this.handleModalConfirm}
          />
        </div>
      </div>
    );
  };
}

TreatmentTypeListContainer.propTypes = {
  treatmentTypeListData: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getTreatmentTypes: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { treatments } = state;
  const { treatmentTypeListData, loadingPage } = treatments.treatmentTypes;
  return {
    treatmentTypeListData,
    loadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTreatmentTypes: headers => {
      dispatch(treatmentTypeActions.getTreatmentTypes(headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreatmentTypeListContainer);
