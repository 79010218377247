import React from "react";
import { Input } from "semantic-ui-react";
import { strings } from "../../resources";
import PropTypes from "prop-types";

class SearchBar extends React.Component {
  state = {
    searchCriteria: ""
  };

  handleSearchCriteriaChange = async (event, key) => {
    let newData = Object.assign({}, this.state.searchCriteria);
    newData = key.value;
    if (newData.trim().length === 0) {
      if (this.state.searchCriteria.length !== newData.trim().length) {
        this.props.fetchPatientData();
      }
      newData = newData.trim();
    }
    this.setState({ searchCriteria: newData });
  };

  render = () => {
    return (
      <Input
        action={{
          className: "searchButton",
          loading: this.props.loadingPatients,
          primary: true,
          icon: "search",
          content: "Search",
          disabled: this.state.searchCriteria === "",
          onClick: () => {
            if (this.state.searchCriteria.length > 0) {
              this.props.fetchPatientData(this.state.searchCriteria);
            }
          }
        }}
        onKeyPress={(e, data) => {
          if (e.key === "Enter") {
            if (this.state.searchCriteria.length > 0) {
              this.props.fetchPatientData(this.state.searchCriteria);
            }
          }
        }}
        fluid
        className={this.props.className}
        name="search_box"
        onChange={this.handleSearchCriteriaChange}
        placeholder={strings.table.placeholder.searchbar}
        value={this.state.searchCriteria}
      />
    );
  };
}

SearchBar.propTypes = {
  className: PropTypes.string.isRequired
};

export default SearchBar;
