import React from "react";
import PropTypes from "prop-types";
import { Form, Dropdown } from "semantic-ui-react";
import { countryOptions } from "../../resources";

const CountryInput = props => {
  return (
    <React.Fragment>
      <Form.Field width={props.width}>
        <label>
          <span className={props.error ? "errorLabel" : null}>
            {props.label}
          </span>
          {props.required && <span className="requiredAsterisk"> *</span>}
        </label>
        <Dropdown fluid search selection options={countryOptions} {...props} />
      </Form.Field>
    </React.Fragment>
  );
};

CountryInput.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CountryInput;
