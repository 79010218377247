import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      productList: "Products",
      createProduct: "Create Product",
      editProduct: "Edit Product",
      viewProduct: "Product Details",
      loading: "Loading Information",
      active: "Active",
      activeFilter: "Only show Active",
      inactiveFilter: "Only show Inactive"
    },
    form: {
      header: {
        productInfo: "Product Information",
        contactInfo: "Contact Information",
        usersLink: "Users",
        clientsLink: "Clients",
        supportLink: "Support",
        settingsLink: "Settings"
      },
      label: {
        expiryDate: "Batch Expiry Date",
        errorHeader: "There were some errors with your submission, please fix",
        productName: "Product Name",
        batchName: "Batch Name",
        productType: "Product Type",
        productNumber: "Product Number",
        contactName: "Contact Name",
        contactEmailAddress: "Contact Email Address",
        contactNumber: "Contact Phone Number"
      },
      placeholder: {
        date: "DD-MM-YYYY",
        productName: "Enter Product Name",
        batchName: "Enter Batch Name",
        productType: "Enter Product Type",
        productNumber: "Enter Phone Number (Main Switchboard)",
        contactName: "Enter Contact Name",
        contactEmailAddress: "Enter Contact Email Address",
        contactNumber: "Enter Contact Phone Number",
        searchBar: "Search"
      },
      validation: {
        errorMessagePrefix: {
          productName: "Product Name",
          productType: "Product Type",
          expiryDate: "Expiry Date"
        },
        errorMessageReason: {
          wrongDateFormat: "must be a valid date in the format DD-MM-YYYY"
        }
      }
    },
    modal: {
      createProductMessage: "Save product?",
      modifyProductMessage: "Are you sure you want to save these changes?",
      deactivateProductMessage:
        "Are you sure you want to deactivate this product?",
      activateProductMessage:
        "Are you sure you want to activate this product?",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this product's status to active?",
      setInactiveMessage: "Set this product's status to inactive?"
    },
    button: {
      editLabel: "Edit",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      cancelLabel: "Cancel",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addProductLabel: "Add Product"
    },
    tooltip: {
      editProduct: "Edit Product",
      deleteProduct: "Delete Product",
      toggleStatus: "Click to change status",
      productTypeWarning: "Product Type cannot be changed after creation."
    },
    table: {
      header: {
        productName: "Product Name",
        productType: "Product Type",
        batchName: "Batch Name",
        batchExpiryDate: "Batch Expiry Date",
        createdDate: "Added",
        status: "Status",
        actions: "Actions",
        ViewIcon: "View Details"
      }
    }
  }
});
