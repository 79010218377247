import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { strings } from "../../resources";
import BasicModal from "../SemanticModal";

const EditDeleteButtons = props => {
    return (
        <div className="editDeleteButtons" data-html2canvas-ignore="true">
          <Button.Group>
            <Button className="editButton" onClick={props.handleModeSwitch}>
              {strings.button.editLabel}
            </Button>
            <Button.Or />
            <Button
              className="deleteButton"
              onClick={props.toggleConfirmDeleteModalVisibility}
              type="button"
            >
              {strings.button.deleteLabel}
            </Button>
          </Button.Group>
          <BasicModal
            confirmMessage={strings.modal.deleteMessage}
            handleModalConfirm={props.handleModalConfirm}
            modalClassName="confirmDeleteModal"
            modalOpen={props.confirmDeleteModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={props.toggleConfirmDeleteModalVisibility}
          />
        </div>
    )
};

EditDeleteButtons.propTypes = {
    handleModalConfirm: PropTypes.func.isRequired,
    handleModeSwitch: PropTypes.func.isRequired,
    confirmDeleteModalOpen: PropTypes.bool.isRequired,
    toggleConfirmDeleteModalVisibility: PropTypes.func.isRequired
};

export default EditDeleteButtons;
