import React from "react";
import PropTypes from "prop-types";
import { Icon, Button, Grid, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "../css/TreatmentComponents.css";
import { strings } from "../../resources";

class TableFunctions extends React.Component {
  render = () => {
    const handleMenuSelect = path => {
      this.props.history.push({
        pathname: path,
        state: {
          mode: "create"
        }
      });
    };

    return (
      <Grid columns={3} className="TableFunctions">
        <Grid.Column>
          <Header as="h1">{strings.header.treatmentTypeList}</Header>
        </Grid.Column>
        <Grid.Column />
        <Grid.Column>
          <Link to={`/treatmentTypes/create`}>
            <Button
              primary
              className="addTreatmentButton"
              onClick={() => handleMenuSelect(`/treatmentTypes/create`)}
            >
              <Icon name="building" />
              {strings.button.addTreatmentTypeLabel}
            </Button>
          </Link>
        </Grid.Column>
      </Grid>
    );
  };
}

TableFunctions.propTypes = {
  history: PropTypes.object.isRequired
};

export default TableFunctions;
