import { combineReducers } from "redux";
import userReducer from "../Users/store/reducers/user.reducer";
import patientReducer from "../Patients/store/reducers/patient.reducer";
import formReducer from "../Forms/store/reducers/form.reducer";
import productReducer from "../Products/store/reducers/product.reducer";
import treatmentsReducer from "../Treatments/store/reducers/";
import imageReducer from "../Images/store/reducers/image.reducer";
import tableReducer from "../Forms/store/reducers/table.reducer";
import auth from "../App/store/reducers/auth.reducer";
import app from "../App/store/reducers/app.reducer";

const appReducer = combineReducers({
  patients: patientReducer,
  forms: formReducer,
  products: productReducer,
  treatments: treatmentsReducer,
  table: tableReducer,
  users: userReducer,
  images: imageReducer,
  auth,
  app
});

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_SUCCESS") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
