import React from "react";
import PropTypes from "prop-types"
import { Form } from "semantic-ui-react";

const CheckboxGroup = props => {
  if (props.horizontal) {
    return (
      <div className={props.groupClassName}>
        <Form.Field>
          <label>
            <span>
              <span className={props.error ? "errorLabel" : null}>
                {props.label}
              </span>
              {props.required && (<span className="requiredAsterisk"> *</span>)}
            </span>
          </label>
        </Form.Field>
        <Form.Group inline>
          <CheckboxGroupField {...props} />
        </Form.Group>
      </div>
    )
  } else {
    return (
      <div className={props.groupClassName}>
        <Form.Field>
          <label>
            <span>
              <span className={props.error ? "errorLabel" : null}>
                {props.label}
              </span>
              {props.required && (<span className="requiredAsterisk"> *</span>)}
            </span>
          </label>
        </Form.Field>
        <CheckboxGroupField {...props} />
      </div>
    )
  }
};

const CheckboxGroupField = props => {
  return (
    <React.Fragment>
      {props.options.map((option, optionIndex) => {
        let optionChecked = false
        if (props.data.hasOwnProperty(props.group)) {
          if (props.data[props.group].hasOwnProperty(option.key)) {
            optionChecked = props.data[props.group][option.key].checked
          }
        }
        return (
          <Form.Checkbox
            className={props.checkboxClassName}
            disabled={props.mode === "view" || props.mode === "template"}
            error={props.error}
            key={`${props.group}-${optionIndex}`}
            name={option.key}
            group={props.group}
            checked={optionChecked}
            label={option.label}
            onChange={props.handleCheckboxChange}
          />
        )}
      )}
    </React.Fragment>
  )
}

CheckboxGroup.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  horizontal: PropTypes.bool,
  error: PropTypes.bool,
  group: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired, // needed for checked status
  options: PropTypes.array.isRequired,
  mode: PropTypes.string // to check for disabled state
}

export default CheckboxGroup;
