import { strings } from "../resources";

// Return a comma separated string of the ticked checkboxes labels if the group exists in the patient record
// List will be prefixed by itemsPresentPrefix string if it has any items, otherwise it will return noItemsText, items will be separated by itemSeparator
// If mapping is passed as true, field labels will be replaced by the entry sharing the field name in strings.
const checkboxGroupList = (
  fieldName,
  value,
  noItemsText,
  mapping = false
) => {
  const groupObject = Object.assign({}, value)
  // Get an array of the labels for the ticked checkboxes in the group
  let list = [];
  Object.keys(groupObject).forEach((groupEntry, index) => {
    // If checkbox and ticked, add label, content (for 'other' fields) or mapped label (if mapping = true)
    if (groupObject[groupEntry].checked === true) {
      let newListItem = '';
      if (groupObject[groupEntry].content) {
        newListItem = groupObject[groupEntry].content
      } else if (mapping && strings.form.mapping.hasOwnProperty(fieldName) && strings.form.mapping[fieldName].hasOwnProperty(groupEntry)) {
        newListItem = strings.form.mapping[fieldName][groupEntry]
      } else {
        newListItem = groupObject[groupEntry].label
      }
      list.push(newListItem);
    }

    // If input field, add content
    if (groupObject[groupEntry].value) {
      if (groupObject[groupEntry].value.length > 0) {
        list.push(groupObject[groupEntry].value);
      }
    }
  });
  if (list.length > 0) {
    return list
  } else {
    return [noItemsText];
  }
};

export default checkboxGroupList;
