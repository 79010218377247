import { strings } from "../../Patients/resources/Strings";

//Convert the options list of complications into a comma separated string for displaying
const parseComplicationsDetails = complications => {
  if (
    complications.previous_treatment_complications == null ||
    !typeof complications.previous_treatment_complications === "object"
  ) {
    return "";
  }
  const keys = Object.keys(complications.previous_treatment_complications);
  const conditions = keys
    .filter(
      item => complications.previous_treatment_complications[item].checked
    )
    .map(item =>
      complications.previous_treatment_complications[item].label !== "Other"
        ? complications.previous_treatment_complications[item].label
        : complications.previous_treatment_complications[item].content
    );
  return conditions.join(", ");
};

export const formatPreviousTreatmentOutcome = data => {
  const feedback = [];
  if (data.previous_treatment_satisfaction === "Satisfied") {
    feedback.push(strings.patientRecord.mappings.satisfiedWithLastTreatment);
  } else if (data.previous_treatment_satisfaction === "Dissatisfied") {
    feedback.push(strings.patientRecord.mappings.notSatisfiedWithLastTreatment);
  } else if (data.previous_treatment_satisfaction === "Highly Satisfied") {
    feedback.push(strings.patientRecord.mappings.highlySatisfiedWithLastTreatment);
  } else if (data.previous_treatment_satisfaction === "Highly Dissatisfied") {
    feedback.push(strings.patientRecord.mappings.highlyDissatisfiedWithLastTreatment);
  }

  if (data.previous_treatment_complications_yes_no === "Yes") {
    feedback.push(
      strings.patientRecord.mappings.experiencedComplications +
        parseComplicationsDetails(data)
    );
  } else if (data.previous_treatment_complications_yes_no === "No") {
    feedback.push(strings.patientRecord.mappings.didNotExperienceComplications);
  }

  if (data.previous_treatment_confidence === "Yes") {
    feedback.push(strings.patientRecord.mappings.improvedSelfConfidence);
  } else if (data.previous_treatment_confidence === "No") {
    feedback.push(strings.patientRecord.mappings.didNotImproveSelfConfidence);
  }

  return feedback;
};
