import axios from "./axios-images";

export class API {
  constructor() {
    this.headers = {};
    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  getRequest = async (path, headers) => {
    this.setHeaders({ ...headers, systemHeader_subsysref: "getRequest" });
    var config = {
      headers: this.headers
    };
    return axios
      .get(`/${path}/images?imagePath=${path}`, config)
      .catch(error => console.log("Error getting images: ", error.message));
  };

  getSingleImageRequest = async (path, name, headers) => {
    this.setHeaders({
      ...headers,
      systemHeader_subsysref: "getSingleImageRequest"
    });
    var config = {
      headers: this.headers
    };
    return axios
      .get(`/${path}/images?imagePath=${path}/${name}`, config)
      .catch(error => console.log("Error getting images: ", error.message));
  };

  postRequest = async (body, headers) => {
    this.setHeaders({ ...headers, systemHeader_subsysref: "postRequest" });
    var config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      const urlRequest = {
        imagePath: body.imagePath,
        metadata: {
          caption: body.metadata.caption,
          takenOnDate: body.metadata.takenOnDate,
          uploadedOnDate: body.metadata.uploadedOnDate
        }
      };
      axios
        .post("images", JSON.stringify(urlRequest), config)
        .then(response => {
          const signedPUTHeaders = {
			"x-amz-tagging": `caption=${body.metadata.caption}&takenOnDate=${
              body.metadata.takenOnDate
            }&uploadedOnDate=${body.metadata.uploadedOnDate}`,  
            "content-dispostion": "attachment",
            "content-encoding": "base64"
          };
          let config = {
            headers: signedPUTHeaders,
            noAuthHeader: true
          };
          axios
            .put(response.data.content, body.imageContent, config)
            .then(result => {
              resolve(result);
            });
        })
        .catch(error => {
          console.log("Error creating image: ", error.message);
          reject(error.message);
        });
    });
  };

  putRequest = async (body, headers) => {
    this.setHeaders({ ...headers, systemHeader_subsysref: "putRequest" });
    var config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put("images", JSON.stringify(body), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error updating image: ", error.message);
          reject(error.message);
        });
    });
  };

  deleteRequest = async (imagePath, headers) => {
    this.setHeaders({ ...headers, systemHeader_subsysref: "deleteRequest" });
    let body = { imagePath: imagePath };
    var config = {
      headers: this.headers,
      data: body
    };
    return new Promise((resolve, reject) => {
      axios
        .delete("images", config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting image: ", error.message);
          reject(error.message);
        });
    });
  };
}
