import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      treatmentList: "Treatments",
      treatmentTypeList: "Treatment Types",
      createTreatment: "Create Treatment",
      editTreatment: "Treatment Record",
      viewTreatment: "Treatment Record",
      loading: "Loading Information",
      createFollowUpNote: "Create Follow Up Note",
      editFollowUpNote: "Follow Up Note",
    },
    form: {
      header: {
        treatmentInfo: "Treatment Information",
        previousTreatmentOutcome: "Previous Treatment Outcome",
        medicalHistoryUpdate:
          "Medical History Update",
        patientExamination: "Examination",
        treatmentDetails: "Treatment",
        postTreatment: "Post-Treatment",
        periodUntilNextTreatment: "Minimum period until next treatment",
        aftercare: "Aftercare",
        nextTreatmentDetails: "Next Treatment Details",
        examinationAnnotation: "Examination",
        treatmentAnnotation: "Treatment",
        followUpNote: "Follow Up Note",
        followUpNoteReport: "Follow Up Record",
      },
      patientMappings: {
        leaveHouse:
          "The patient sometimes avoids leaving the house because of their appearance",
        avoidsSocialSituations:
          "The patient will sometimes avoid social situations due to their appearance",
        avoidsPhotographs:
          "The patient will avoid having photographs taken because of concerns relating to their appearance",
        avoidsCertainAngles:
          "The patient will try to avoid being seen from certain angles to avoid people seeing their features",
        wearsMakeup:
          "The patient will use make up to camouflage the features of concern",
        fitzpatrickSkinTypeI: "The patient has Fitzpatrick skin type I",
        fitzpatrickSkinTypeII: "The patient has Fitzpatrick skin type II",
        fitzpatrickSkinTypeIII: "The patient has Fitzpatrick skin type III",
        fitzpatrickSkinTypeIV: "The patient has Fitzpatrick skin type IV",
        fitzpatrickSkinTypeV: "The patient has Fitzpatrick skin type V",
        fitzpatrickSkinTypeVI: "The patient has Fitzpatrick skin type VI",
        glogauSkinGroup1: "The Glogau skin group is 1",
        glogauSkinGroup2: "The Glogau skin group is 2",
        glogauSkinGroup3: "The Glogau skin group is 3",
        glogauSkinGroup4: "The Glogau skin group is 4",
        goodSkinCondition: "The overall skin condition is good",
        mildSkinCondition:
          "The overall skin condition demonstrates signs of mild of sun damage",
        moderateSkinCondition:
          "The overall skin condition demonstrates signs of moderate sun damage",
        severeSkinCondition:
          "The overall skin condition demonstrates signs of severe sun damage",
        signsOfSmoking: "The patient shows signs of smoking",
        abnormalFeatures: "The patient shows abnormal features",
        volumeLossAreas: "Areas affected by volume loss include the",
        expectedOutcome: "The outcome of treatment is as expected",
        notExpectedOutcome: "The outcome of treatment is not as expected",
        experiencedComplications: "The patient has experienced complications following treatment",
        experiencedNoComplications: "The patient has experienced no complications following treatment",
      },
      label: {
        firstName: "Name",
        dateOfBirth: "Date of Birth",
        treatmentType: "Treatment Type(s)",
        treatmentCategory: "Treatment Category",
        treatmentDescription: "Treatment Description",
        treatmentPrice: "Treatment Price",
        undefinedTreatmentPrice: "{select treatment type and doctor}",
        unitsUntilNextTreatment: "Units",
        treatmentFrequency: "Frequency",
        doctorsAndPrices: "Doctors and Prices",
        patientConcerns: "Patient Concerns",
        examinationViewModeHeader: "Examination Notes",
        additionalDetailsHeader: "Additional Details",
        additionalInformationHeader: "Additional Information",
        aftercareInstructions: "Aftercare Instructions",
        skinPrep: "Skin Preparation",
        localAnaesthetics: "Local Anaesthetics",
        productHeader: "Products Used",
        productName: "Product Name",
        productType: "Product Type",
        productBatch: "Batch",
        productExpiryDate: "Expiry Date",
        productUsed: "Used?",
        productConcentration: "Product Concentration (U/ml)",
        productVolume: "Total Volume",
        productInjectionDevice: "Injection Device",
        injectionCannula: "Cannula",
        injectionNeedle: "Needle",
        notes: "Notes",
        textNotificationWording: "Text Notification Wording",
        textNotificationTimeframe: "Text Notification Timeframe",
        aftercareInstructionsSent:
          "Patient was sent aftercare instructions by email?",
        sendAftercareInstructions:
          "Send patient aftercare instructions by email?",
        errorHeader: "There were some errors with your submission, please fix",
        diagnosisHeader: "Diagnosis",
        diagnosisPsychological: "Psychological concerns relation to appearance",
        diagnosisPhotoDamage:
          "Photo damage secondary to chronic ultra violet light exposure",
        diagnosisSkinCancer:
          "At risk of skin cancer because of lifestyle factors",
        diagnosisBodyDysmorphic: "Body dysmorphic disorder",
        diagnosisFacialLipoatrophy: "Facial lipoatrophy",
        diagnosisDyschromia: "Dyschromia",
        diagnosisPigmentation: "Hyper pigmentation",
        diagnosisTelangiectasia: "Telangiectasia",
        diagnosisNicotinic: "Nicotinic skin changes from cigarette smoking",
        diagnosisUpperDermatochalasis:
          "Upper eyelid dermatochalasis bilaterally",
        diagnosisLowerDermatochalasis:
          "Lower eyelid dermatochalasis bilaterally",
        diagnosisBrow: "Brow ptosis",
        diagnosisNasal: "Nasal deformity",
        diagnosisMicrogenia: "Microgenia",
        diagnosisDepression: "Clinical depression",
        diagnosisOther: "Other",
        treatmentOptionsHeader: "Treatment Options",
        treatmentOptionsEmotional: "Emotional concern/support",
        treatmentOptionsChemodenervation:
          "Chemodenervation with botulinum toxin",
        treatmentOptionsHyalurinic:
          "Targeted fat compartment augmentation with injectable hyaluronic acid",
        treatmentOptionsLight: "Intense pulsed light",
        treatmentOptionsPrescription: "Prescription skincare",
        treatmentOptionsUpperBlepharoplasty: "Upper blepharoplasty",
        treatmentOptionsLowerBlepharoplasty: "Lower blepharoplasty",
        treatmentOptionsRhytidectomy: "Rhytidectomy",
        treatmentOptionsSkinTightening: "Energy based skin tightening",
        treatmentOptionsCO2: "CO2 laser skin resurfacing",
        treatmentOptionsFacial: "Facial contouring",
        treatmentOptionsOther: "Other",
        treatmentPlanHeader: "Treatment Plan",
        treatmentPlanEmotional: "Emotional concern/support",
        treatmentPlanChemodenervation: "Chemodenervation with botulinum toxin",
        treatmentPlanHyalurinic:
          "Targeted fat compartment augmentation with injectable hyaluronic acid",
        treatmentPlanLight: "Intense pulsed light",
        treatmentPlanPrescription: "Prescription skincare",
        treatmentPlanUpperBlepharoplasty: "Upper blepharoplasty",
        treatmentPlanLowerBlepharoplasty: "Lower blepharoplasty",
        treatmentPlanRhytidectomy: "Rhytidectomy",
        treatmentPlanSkinTightening: "Energy based skin tightening",
        treatmentPlanCO2: "CO2 laser skin resurfacing",
        treatmentPlanFacial: "Facial contouring",
        treatmentPlanOther: "Other",
        treatmentDetails: "Treatment Details",
        fitzpatrickSkinTypeHeader: "Fitzpatrick Skin Type",
        glogauSkinGroupHeader: "Glogau Skin Group",
        skinConditionHeader: "Overall condition of the skin",
        skinConditionPigmentation: "Hyper Pigmentation",
        skinConditionTelangiectasia: "Telangiectasia",
        skinConditionElastosis: "Elastosis",
        skinConditionDynamicRhytids: "Dynamic Rhytids",
        skinConditionStaticRhytids: "Static Rhytids",
        smokingHeader: "Signs of smoking",
        smokingConditionSkinTone: "Poor Skin Tone",
        smokingConditionElasticRecoil: "Loss of Elastic Recoil",
        smokingConditionPerioralRhytids: "Perioral Rhytids",
        facialVolumeHeader: "Facial volume",
        facialVolumeForehead: "Forehead",
        facialVolumeTemples: "Temples",
        facialVolumeBrow: "Brow",
        facialVolumeInfraorbitalRegion: "Infraorbital Region",
        facialVolumeMalarEminence: "Malar Eminence",
        facialVolumeMidFace: "Mid-Face",
        facialVolumeLateralCheek: "Lateral Cheek Fat",
        facialVolumeSubmalar: "Submalar",
        facialVolumeNasogenian: "Nasogenian",
        facialVolumePerioral: "Perioral",
        facialVolumeMandible: "Mandible",
        abnormalHeader: "Abnormal features",
        abnormalConditionsHeader: "Abnormal features",
        abnormalNasalHump: "There is a strong nasal hump",
        abnormalPseudohump:
          "There is evidence of a pseudohump with lack of projection at the radix",
        abnormalNasalTip: "There is downturning of the nasal tip",
        abnormalThinLips:
          "The lips are thin and appear under developed in relation to the other facial features",
        abnormalChin: "The chin is underdeveloped",
        radioYes: "Yes",
        radioNo: "No",
        otherDetails: "Please Specify",
        treatmentPerformedToday: "Was treatment performed today?",
        consentIssuesWereDiscussed:
          "Consent Issues were discussed with the patient?",
        discussedConsentIssuesWithPatient:
          "I have discussed the consent issues for todays treatment with the patient.",
        wasExpectedOutcome: "Is this outcome as expected for this stage in the process?",
        wereThereComplications: "Has the Patient experienced complications following treatment?",
        additionalOutcomeDetails: "Additional Outcome Details",
      },
      databaseValues: {
        radioYes: "Yes",
        radioNo: "No",
        fitzpatrickSkinTypeI: "I",
        fitzpatrickSkinTypeII: "II",
        fitzpatrickSkinTypeIII: "III",
        fitzpatrickSkinTypeIV: "IV",
        fitzpatrickSkinTypeV: "V",
        fitzpatrickSkinTypeVI: "VI",
        glogauSkinGroup1: "1",
        glogauSkinGroup2: "2",
        glogauSkinGroup3: "3",
        glogauSkinGroup4: "4",
        skinConditionGood: "Good",
        skinConditionMildDamage: "Mild Damage",
        skinConditionModerateDamage: "Moderate Damage",
        skinConditionSevereDamage: "Severe Damage",
        facialVolumeGood:
          "The overall facial volume is good, with minimal to no signs of lipoatrophy or bony retrusion",
        facialVolumeGoodMinimal:
          "The overall facial volume is good, with minimal signs of lipoatrophy",
        facialVolumeStartedDeteriorating:
          "The overall facial volume has started to deteriorate, with moderate signs of lipoatrophy and early bony retrusion",
        facialVolumeDeteriorated:
          "The facial volume has deteriorated, with severe signs of lipoatrophy and bony retrusion",
      },
      placeholder: {
        treatmentName: "Enter Treatment Name",
        treatmentCategory: "Enter Treatment Category",
        skinPrep: "Select skin prep method",
        localAnaesthetics: "Select Local Anaesthetic",
        productConcentration: "Concentration",
        productVolume: "Volume",
        injectionDevice: "Select injection device",
        selectFrequency: "Select Frequency",
        none: "None",
        searchBar: "Search",
      },
      validation: {
        errorMessagePrefix: {
          treatmentType: "Treatment Type(s)",
          treatmentCategory: "Treatment Category",
          periodUntilNextTreatment: "Period Until Next Treatment",
          textNotificationTimeFrame: "Text Notification Timeframe",
          doctorsAndPrices: "At least one Doctors and Prices entry",
          doctor: "Doctor",
          skinPrep: "Skin Preparation",
          localAnaesthetics: "Local Anaesthetics",
          productBatch: "Product Batch",
          productConcentration: "Product Concentration",
          productExpiryDate: "Product Expiry Date",
          productType: "Product Type",
          productVolume: "Product Volume",
          injectionDevice: "Injection Device",
          textNotificationWording: "Text Notification Wording",
          aftercareInstructions: "Aftercare Instructions",
          diagnosis: "Diagnosis",
          diagnosisOther: "Other Diagnosis",
          treatmentOptions: "Treatment Options",
          treatmentOptionsOther: "Other Treatment Option",
          treatmentPlan: "Treatment Plans",
          treatmentPlanOther: "Other Treatment Plan",
          examination_fitzpatrick_skin_type: "Fitzpatrick Skin Type",
          examination_glogau_skin_group: "Glogau Ski Group",
          examination_skin_condition: "Skin Condition",
          examination_skin_conditions: "Skin Conditions",
          examination_smoking: "Signs of Smoking",
          examination_smoking_conditions: "Signs of Smoking",
          examination_facial_volume: "Facial Volume",
          examination_facial_volume_details: "Facial Volume Details",
          examination_abnormal: "Abnormal Features",
          examination_abnormal_conditions: "Abnormal Features Details",
          selected_products: "Products Used",
          treatment_performed_today: "Was Treatment Performed Today",
          wasExpectedOutcome: "Expected Outcome",
          wereThereComplications: "Were there Complications",
        },
        errorMessageReason: {
          requireAtLeastOneOption: " requires at least one entry",
          requireAtLeastOneProduct: " requires at least one product",
          requireVolume: " entries require volume",
          requireDevice: " entries require at least one injection device",
          isRequired: " is required",
        },
      },
      mapping: {
        // Used for field verbose statement mappings
        examination_facial_volume_details: {
          forehead: "forehead",
          temples: "temples",
          brow: "brow",
          infraorbital_region: "infraorbital region",
          malar_eminence: "malar eminence",
          mid_face: "mid-face",
          lateral_cheek: "lateral cheek fat",
          submalar: "submalar",
          nasogenian: "nasogenian",
          perioral: "perioral",
          mandible: "mandible",
        },
        examination_smoking_conditions: {
          skin_tone: "poor skin tone",
          elastic_recoil: "loss of elastic recoil",
          perioral_rhytids: "Perioral Rhytids",
        },
      },
    },
    modal: {
      createTreatmentMessage: "Save treatment?",
      modifyTreatmentMessage: "Are you sure you want to save these changes?",
      deleteTreatmentMessage:
        "Are you sure you want to delete this treatment? (This action cannot be undone)",
      deactivateTreatmentTypeMessage:
        "Are you sure you want to deactivate this treatment type?",
      activateTreatmentTypeMessage:
        "Are you sure you want to activate this treatment type?",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this treatment's status to active?",
      setInactiveMessage: "Set this treatment's status to inactive?",
      addDoctorAndPriceMessage: "Select a doctor and price to add.",
    },
    button: {
      downloadFormLabel: "Download Form",
      editLabel: "Edit",
      deleteLabel: "Delete",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      cancelLabel: "Cancel",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addTreatmentLabel: "Add Treatment",
      addTreatmentTypeLabel: "Add Treatment Type",
      addFollowUpNoteLabel: "Add Follow Up Note",
    },
    tooltip: {
      editTreatment: "Edit Treatment",
      deleteTreatment: "Delete Treatment",
      toggleStatus: "Click to change status",
      treatmentTypeWarning:
        "Treatment Category cannot be changed after creation.",
    },
    table: {
      header: {
        treatmentType: "Treatment Type(s)",
        treatmentCategory: "Treatment Category",
        periodUntilNextTreatment: "Minimum period until next treatment",
        selectedProducts: "Products",
        doctor: "Doctor",
        createdDate: "Added",
        status: "Active",
        actions: "Actions",
        ViewIcon: "View Details",
      },
    },
    types: {
      followUpNote: "Follow Up Note",
      // Set in the back end
      followUpNoteId: "3d32302a-327f-4ded-8d6d-9fa77fb6e00d",
    }
  },
});
