import React from "react";
import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import { imageActions } from "../store/actions/image.actions";
import PropTypes from "prop-types";
import SignatureField from "../components/SignatureField";
import { strings } from "../resources";
import { dateHelper } from "../helpers";
import axios from "axios";

/**
 *  SignatureFieldContainer
 *
 * Used to show signatures, or allow a signature to be written and uploaded to S3
 */
class SignatureFieldContainer extends React.Component {
  componentDidMount = async () => {
    await this.props.updateSignatureObject({});
    // If the form is not a template, get the patient's signature if it's in read only mode
    if (
      this.props.readOnly &&
      this.props.signatureImagePath &&
      this.props.mode !== "template"
    ) {
      await this.props.getPatientSignature(
        this.props.signatureImagePath,
        this.props.headers
      );
    }
  };

  // returns the drawing as an array of point groups
  getSignatureData = () => {
    if (this.canvasRef) {
      return this.canvasRef.toData();
    }
  };

  // returns the drawing as a data URL
  getSignatureImage = () => {
    return this.canvasRef.toDataURL("image/png");
  };

  handleClear = () => {
    this.canvasRef.clear();
    this.handleUpdate();
  };

  handleUndo = () => {
    let data = this.canvasRef.toData();

    if (data) {
      data.pop();
      this.canvasRef.fromData(data);
      this.handleUpdate();
    }
  };

  handleUpdate = () => {
    if (this.canvasRef) {
      if (this.getSignatureData().length === 0) {
        this.props.updateSignatureObject({});
      } else {
        axios
          .get(this.getSignatureImage(), { responseType: "blob" })
          .then(async (response) => {
            if (response.data) {
              let currentDate = dateHelper.getTodaysDateFormatted();
              const upload = {
                imageContent: response.data,
                metadata: {
                  caption: "",
                  takenOnDate: currentDate,
                  uploadedOnDate: currentDate,
                },
              };
              await this.props.updateSignatureObject(
                upload,
                this.props.headers
              );
            } else {
              console.log("image is empty");
            }
          });
      }
    }
  };

  setRef = (ref) => {
    this.canvasRef = ref;
  };

  writeSignatureFromBase64String = (base64String) => {
    this.canvasRef.fromDataURL(base64String);
  };

  writeSignatureFromData = (pointGroupArray) => {
    if (this.canvasRef && pointGroupArray) {
      this.canvasRef.fromData(pointGroupArray);
    }
  };

  render = () => {
    return (
      <div>
        {this.props.loadingImageData && (
          <Loader active>{strings.header.loading}</Loader>
        )}
        {!this.props.loadingImageData && (
          <SignatureField
            mode={this.props.mode}
            error={this.props.error}
            getSignatureData={this.getSignatureData}
            getSignatureImage={this.getSignatureImage}
            handleClear={this.handleClear}
            handleUpdate={this.handleUpdate}
            handleUndo={this.handleUndo}
            readOnly={this.props.readOnly}
            setRef={this.setRef}
            signatureURL={this.props.patientSignature}
            writeSignatureFromData={this.writeSignatureFromData}
          />
        )}
      </div>
    );
  };
}

SignatureFieldContainer.propTypes = {
  readOnly: PropTypes.bool.isRequired, // whether the signature canvas can be drawn on required
  signatureImagePath: PropTypes.string.isRequired, // path on S3 of where either to store or retrieve the signature image required
};

const mapStateToProps = (state) => {
  const { images, auth } = state;
  const {
    localData,
    remoteData,
    loadingImageData,
    patientSignature,
    fileTypeWarningVisible,
  } = images;
  const { signaturePath } = auth;
  return {
    localData,
    remoteData,
    loadingImageData,
    fileTypeWarningVisible,
    patientSignature,
    signaturePath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getImages: (path, headers) => {
      return dispatch(imageActions.getImages(path, headers));
    },
    updateLocalImages: (index, newVal) => {
      return dispatch(imageActions.updateLocalImages(index, newVal));
    },
    setLoadingImages: (status) => {
      return dispatch(imageActions.setLoadingImages(status));
    },
    uploadImage: (upload, headers) => {
      return dispatch(imageActions.uploadImage(upload, headers));
    },
    setFileTypeWarningVisible: (visible) => {
      return dispatch(imageActions.setFileTypeWarningVisible(visible));
    },
    deleteImage: (imageName, headers) => {
      return dispatch(imageActions.deleteImage(imageName, headers));
    },
    updateImage: (update, headers) => {
      return dispatch(imageActions.updateImage(update, headers));
    },
    getPatientSignature: (path, headers) => {
      return dispatch(imageActions.getPatientSignature(path, headers));
    },
    updateSignatureObject: (upload, headers) => {
      return dispatch(imageActions.updateSignatureObject(upload, headers));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignatureFieldContainer);
