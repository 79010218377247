import { strings } from "../resources";
import { regexes } from "../resources/Regexes";

const dateRegex = new RegExp(regexes.dateRegex);

// If an entry is made in the schema with key = field name, the valid function will be run
// It should return true if the field is valid and an error message string if not
const patientSchema = {
  gp_name: {
    tab: strings.patientRecord.validation.errorTab.medical,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.gp_name,
  },
  gp_address: {
    tab: strings.patientRecord.validation.errorTab.medical,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.gp_address,
  },
  gp_contact_approval: {
    required: true,
    tab: strings.patientRecord.validation.errorTab.medical,
  },
  medical_conditions_toggle: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .medical_conditions_toggle,
    tab: strings.patientRecord.validation.errorTab.medical,
    required: true,
  },
  medical_conditions: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.medical_conditions,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return getRequiredDependent("medical_conditions_toggle", "Yes", data);
    },
    validate: (field, data) => {
      const atLeastOneOptionSelected = data[field]
        ? Object.keys(data[field]).some((item) => {
            return data[field][item].checked;
          })
        : false;
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.patientRecord.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },
  medical_conditions_other: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .medical_conditions_other,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return (
        data.hasOwnProperty("medical_conditions") &&
        data.medical_conditions &&
        data.medical_conditions.other &&
        data.medical_conditions.other.checked === true
      );
    },
  },
  dermatological_diseases_toggle: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .dermatological_diseases_toggle,
    tab: strings.patientRecord.validation.errorTab.medical,
    required: true,
  },
  dermatological_diseases: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .dermatological_diseases,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return getRequiredDependent(
        "dermatological_diseases_toggle",
        "Yes",
        data
      );
    },
    validate: (field, data) => {
      const atLeastOneOptionSelected = data[field]
        ? Object.keys(data[field]).some((item) => {
            return data[field][item].checked;
          })
        : false;
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.patientRecord.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },
  dermatological_diseases_other: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .dermatological_diseases_other,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return (
        data.hasOwnProperty("dermatological_diseases") &&
        data.dermatological_diseases &&
        data.dermatological_diseases.other &&
        data.dermatological_diseases.other.checked === true
      );
    },
  },
  neurological_conditions_toggle: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .neurological_conditions_toggle,
    tab: strings.patientRecord.validation.errorTab.medical,
    required: true,
  },
  neurological_conditions: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .neurological_conditions,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return getRequiredDependent(
        "neurological_conditions_toggle",
        "Yes",
        data
      );
    },
    validate: (field, data) => {
      const atLeastOneOptionSelected = data[field]
        ? Object.keys(data[field]).some((item) => {
            return data[field][item].checked;
          })
        : false;
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.patientRecord.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },
  neurological_conditions_other: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .neurological_conditions_other,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return (
        data.hasOwnProperty("neurological_conditions") &&
        data.neurological_conditions &&
        data.neurological_conditions.other &&
        data.neurological_conditions.other.checked === true
      );
    },
  },
  regular_medications_toggle: {
    tab: strings.patientRecord.validation.errorTab.medical,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.regular_medications,
  },
  regular_medications: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .regular_medications_details,
    requireIf: (data) => {
      return getRequiredDependent("regular_medications_toggle", "Yes", data);
    },
  },
  supplements_toggle: {
    tab: strings.patientRecord.validation.errorTab.medical,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.supplements,
  },
  supplements: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.supplements_details,
    requireIf: (data) => {
      return getRequiredDependent("supplements_toggle", "Yes", data);
    },
  },
  drug_allergies_toggle: {
    tab: strings.patientRecord.validation.errorTab.medical,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.drug_allergies,
  },
  drug_allergies: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .drug_allergies_details,
    requireIf: (data) => {
      return getRequiredDependent("drug_allergies_toggle", "Yes", data);
    },
  },
  general_allergies_toggle: {
    tab: strings.patientRecord.validation.errorTab.medical,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.general_allergies,
  },
  general_allergies: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .general_allergies_details,
    requireIf: (data) => {
      return getRequiredDependent("general_allergies_toggle", "Yes", data);
    },
  },
  mental_health_disorders_toggle: {
    tab: strings.patientRecord.validation.errorTab.medical,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .mental_health_disorders,
  },
  mental_health_disorders: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .mental_health_disorder_details,
    requireIf: (data) => {
      return getRequiredDependent(
        "mental_health_disorders_toggle",
        "Yes",
        data
      );
    },
  },
  seen_psychiatrist: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.psychiatrist_seen,
    required: true,
  },
  psychiatric_medications_toggle: {
    tab: strings.patientRecord.validation.errorTab.medical,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .psychiatric_medications,
  },
  psychiatric_medications: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .psychiatric_medications_details,
    requireIf: (data) => {
      return getRequiredDependent(
        "psychiatric_medications_toggle",
        "Yes",
        data
      );
    },
  },
  smoked_previously: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.previously_smoked,
    required: true,
  },
  smokes_currently: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.smokes_currently,
    required: true,
  },
  uses_sunbeds: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.used_sunbeds,
    required: true,
  },
  gets_suntans: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.tans_face,
    required: true,
  },
  botox_previous_use: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .previous_botox_treatment,
    required: true,
  },
  botox_when_last_treated: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.last_botox_treatment,
    requireIf: (data) => {
      return getRequiredDependent("botox_previous_use", "Yes", data);
    },
    validate: (field, data) => {
      if (dateRegex.test(data[field])) {
        return true;
      } else {
        return strings.patientRecord.validation.errorMessageReason
          .wrongDateFormat;
      }
    },
  },
  botox_number_of_past_treatments: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.botox_treatment_count,
    requireIf: (data) => {
      return getRequiredDependent("botox_previous_use", "Yes", data);
    },
  },
  botox_complications: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.botox_complications,
    requireIf: (data) => {
      return getRequiredDependent("botox_previous_use", "Yes", data);
    },
  },
  dermal_fillers_previous_use: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .previous_dermal_filler_treatment,
    required: true,
  },
  dermal_fillers_when_last_treated: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .last_dermal_filler_treatment,
    requireIf: (data) => {
      return getRequiredDependent("dermal_fillers_previous_use", "Yes", data);
    },
    validate: (field, data) => {
      if (dateRegex.test(data[field])) {
        return true;
      } else {
        return strings.patientRecord.validation.errorMessageReason
          .wrongDateFormat;
      }
    },
  },
  dermal_filler_types: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .dermal_filler_treatments,
    requireIf: (data) => {
      return getRequiredDependent("dermal_fillers_previous_use", "Yes", data);
    },
    validate: (field, data) => {
      const atLeastOneOptionSelected = Object.keys(data[field]).some((item) => {
        return data[field][item].checked;
      });
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.patientRecord.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },
  dermal_fillers_number_of_past_treatments: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .dermal_filler_treatment_count,
    requireIf: (data) => {
      return getRequiredDependent("dermal_fillers_previous_use", "Yes", data);
    },
  },
  dermal_fillers_complications: {
    tab: strings.patientRecord.validation.errorTab.medical,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .dermal_filler_complications,
    requireIf: (data) => {
      return getRequiredDependent("dermal_fillers_previous_use", "Yes", data);
    },
  },
  first_name: {
    tab: strings.patientRecord.validation.errorTab.personal,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.first_name,
  },
  communication_consent: {
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.communication_consent,
  },
  surname: {
    tab: strings.patientRecord.validation.errorTab.personal,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.surname,
  },
  date_of_birth: {
    tab: strings.patientRecord.validation.errorTab.personal,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.date_of_birth,
    validate: (field, data) => {
      if (dateRegex.test(data[field])) {
        return true;
      } else {
        return strings.patientRecord.validation.errorMessageReason
          .wrongDateFormat;
      }
    },
  },
  email_address: {
    tab: strings.patientRecord.validation.errorTab.personal,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.email_address,
  },
  postal_address: {
    tab: strings.patientRecord.validation.errorTab.personal,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.postal_address,
  },
  country: {
    tab: strings.patientRecord.validation.errorTab.personal,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.country,
  },
  town_or_city: {
    tab: strings.patientRecord.validation.errorTab.personal,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.townOrCity,
  },
  phone_number: {
    tab: strings.patientRecord.validation.errorTab.personal,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.phone_number,
  },
  mobile_phone_number: {
    tab: strings.patientRecord.validation.errorTab.personal,
    required: true,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.mobile_phone_number,
  },
  image_consent: {
    tab: strings.patientRecord.validation.errorTab.personal,
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.image_consent,
    required: true,
    validate: (field, data) => {
      // Check that at least one consent option is selected and if it is checked to revoke
      // consent, don't allow other positive consent options to be checked
      const atLeastOneOptionSelected = Object.keys(data[field]).some((item) => {
        return data[field][item].checked;
      });
      if (atLeastOneOptionSelected === true) {
        if (data[field].hasOwnProperty("consent_share_images")) {
          if (data[field].consent_share_images.checked === true) {
            const numberSelectedOptions = Object.keys(data[field]).filter(
              (item) => {
                return data[field][item].checked;
              }
            ).length;
            if (numberSelectedOptions > 1) {
              return strings.patientRecord.validation.errorMessageReason
                .incompatibleImageConsent;
            }
          }
        }
        return true;
      } else {
        return strings.patientRecord.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },

  examination_fitzpatrick_skin_type: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .examination_fitzpatrick_skin_type,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return getRequiredDependent("has_had_examination", true, data);
    },
  },

  examination_glogau_skin_group: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .examination_glogau_skin_group,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return getRequiredDependent("has_had_examination", true, data);
    },
  },

  examination_skin_condition: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .examination_skin_condition,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return getRequiredDependent("has_had_examination", true, data);
    },
  },
  examination_smoking: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.examination_smoking,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return getRequiredDependent("has_had_examination", true, data);
    },
  },
  examination_facial_volume: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .examination_facial_volume,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return getRequiredDependent("has_had_examination", true, data);
    },
  },
  examination_abnormal: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix.examination_abnormal,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return getRequiredDependent("has_had_examination", true, data);
    },
  },
  examination_skin_conditions: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .examination_skin_conditions,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return (
        getRequiredDependent("has_had_examination", true, data) &&
        (getRequiredDependent(
          "examination_skin_condition",
          strings.patientRecord.databaseValues.skinConditionModerateDamage,
          data
        ) ||
          getRequiredDependent(
            "examination_skin_condition",
            strings.patientRecord.databaseValues.skinConditionSevereDamage,
            data
          ))
      );
    },
    validate: (field, data) => {
      if (
        getRequiredDependent(
          "examination_skin_condition",
          strings.patientRecord.databaseValues.skinConditionModerateDamage,
          data
        ) ||
        getRequiredDependent(
          "examination_skin_condition",
          strings.patientRecord.databaseValues.skinConditionSevereDamage,
          data
        )
      ) {
        const atLeastOneOptionSelected = Object.keys(data[field]).some(
          (item) => {
            return data[field][item].checked;
          }
        );
        if (atLeastOneOptionSelected === true) {
          return true;
        } else {
          return strings.patientRecord.validation.errorMessageReason
            .requireAtLeastOneOption;
        }
      }
      return true;
    },
  },
  examination_smoking_conditions: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .examination_smoking_conditions,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return (
        getRequiredDependent("has_had_examination", true, data) &&
        getRequiredDependent("examination_smoking", "Yes", data)
      );
    },
    validate: (field, data) => {
      if (getRequiredDependent("examination_smoking", "Yes", data)) {
        const atLeastOneOptionSelected = Object.keys(data[field]).some(
          (item) => {
            return data[field][item].checked;
          }
        );
        if (atLeastOneOptionSelected === true) {
          return true;
        } else {
          return strings.patientRecord.validation.errorMessageReason
            .requireAtLeastOneOption;
        }
      }
      return true;
    },
  },
  examination_facial_volume_details: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .examination_facial_volume_details,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      const isRequired =
        getRequiredDependent("has_had_examination", true, data) &&
        (getRequiredDependent(
          "examination_facial_volume",
          strings.patientRecord.databaseValues.facialVolumeGoodMinimal,
          data
        ) ||
          getRequiredDependent(
            "examination_facial_volume",
            strings.patientRecord.databaseValues
              .facialVolumeStartedDeteriorating,
            data
          ) ||
          getRequiredDependent(
            "examination_facial_volume",
            strings.patientRecord.databaseValues.facialVolumeDeteriorated,
            data
          ));
      return isRequired;
    },
    validate: (field, data) => {
      const isRequired =
        getRequiredDependent("has_had_examination", true, data) &&
        (getRequiredDependent(
          "examination_facial_volume",
          strings.patientRecord.databaseValues.facialVolumeGoodMinimal,
          data
        ) ||
          getRequiredDependent(
            "examination_facial_volume",
            strings.patientRecord.databaseValues
              .facialVolumeStartedDeteriorating,
            data
          ) ||
          getRequiredDependent(
            "examination_facial_volume",
            strings.patientRecord.databaseValues.facialVolumeDeteriorated,
            data
          ));
      if (isRequired) {
        const atLeastOneOptionSelected = Object.keys(data[field]).some(
          (item) => {
            return data[field][item].checked;
          }
        );
        if (atLeastOneOptionSelected === true) {
          return true;
        } else {
          return strings.patientRecord.validation.errorMessageReason
            .requireAtLeastOneOption;
        }
      }
      return true;
    },
  },
  examination_abnormal_conditions: {
    errorMessagePrefix:
      strings.patientRecord.validation.errorMessagePrefix
        .examination_abnormal_conditions,
    tab: strings.patientRecord.validation.errorTab.medical,
    requireIf: (data) => {
      return (
        getRequiredDependent("has_had_examination", true, data) &&
        getRequiredDependent("examination_abnormal", "Yes", data)
      );
    },
    validate: (field, data) => {
      if (
        getRequiredDependent("has_had_examination", true, data) &&
        getRequiredDependent("examination_abnormal", "Yes", data)
      ) {
        const atLeastOneOptionSelected = Object.keys(data[field]).some(
          (item) => {
            return data[field][item].checked;
          }
        );
        if (atLeastOneOptionSelected === true) {
          return true;
        } else {
          return strings.patientRecord.validation.errorMessageReason
            .requireAtLeastOneOption;
        }
      }
      return true;
    },
  },
};

const getRequiredDependent = (requiredIfField, requiredIfValue, data) => {
  // a field is required if it's dependent field has a value and that value is not equal to requiredIfValue
  if (data.hasOwnProperty(requiredIfField)) {
    if (data[requiredIfField] === requiredIfValue) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export default (patientData) => {
  return new Promise((resolve, reject) => {
    const errors = [];
    // First, validate all the fields present in the patient data (if field should be validated))
    Object.keys(patientData).forEach((field) => {
      if (patientSchema.hasOwnProperty(field)) {
        // If validation function provided, use it, otherwise field is ignored
        if (patientSchema[field].hasOwnProperty("validate") === true) {
          const validationResult = patientSchema[field].validate(
            field,
            patientData
          );
          if (validationResult !== true) {
            errors.push({
              field: field,
              tab: patientSchema[field].tab,
              error: `${patientSchema[field].errorMessagePrefix} ${validationResult}`,
            });
          }
        }
      }
    });

    // Next, ensure all required fields in the schema are present in the data
    Object.keys(patientSchema).forEach((schemaEntry) => {
      if (patientSchema[schemaEntry].required === true) {
        if (
          patientData.hasOwnProperty(schemaEntry) !== true ||
          patientData[schemaEntry] === null
        ) {
          errors.push({
            field: schemaEntry,
            tab: patientSchema[schemaEntry].tab,
            error: `${patientSchema[schemaEntry].errorMessagePrefix} is required`,
          });
        }
      } else if (patientSchema[schemaEntry].hasOwnProperty("requireIf")) {
        if (patientSchema[schemaEntry].requireIf(patientData)) {
          if (
            patientData.hasOwnProperty(schemaEntry) !== true ||
            patientData[schemaEntry] === null
          ) {
            errors.push({
              field: schemaEntry,
              tab: patientSchema[schemaEntry].tab,
              error: `${patientSchema[schemaEntry].errorMessagePrefix} is required`,
            });
          }
        }
      }
    });

    const results = {
      passedValidation: errors.length === 0,
      errors,
    };
    resolve(results);
  });
};
