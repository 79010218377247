import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources/Strings";
import { CheckboxGroup, YesNoInput } from ".";
import {
  skinConditionOptions,
  smokingConditionOptions,
  facialVolumeDetailOptions,
  abnormalConditionOptions,
} from "../../resources/CheckboxOptions";

const ExaminationNotesEdit = (props) => {
  const data = props.selectedPatientData;

  return (
    <>
      {renderFitzpatrickSkinTypeQuestion()}
      {renderGlogauSkinGroupQuestion()}
      {renderSkinConditionQuestion()}
      {renderSignsOfSmokingQuestion()}
      {renderFacialVolumeQuestion()}
      {renderAbnormalFeaturesQuestion()}
    </>
  );

  function renderFitzpatrickSkinTypeQuestion() {
    const radioButtonLabels = [
      strings.patientRecord.databaseValues.fitzpatrickSkinTypeI,
      strings.patientRecord.databaseValues.fitzpatrickSkinTypeII,
      strings.patientRecord.databaseValues.fitzpatrickSkinTypeIII,
      strings.patientRecord.databaseValues.fitzpatrickSkinTypeIV,
      strings.patientRecord.databaseValues.fitzpatrickSkinTypeV,
      strings.patientRecord.databaseValues.fitzpatrickSkinTypeVI,
    ];

    return (
      <>
        <Form.Field
          className="textField"
          error={props.errorFields.includes(
            "examination_fitzpatrick_skin_type"
          )}
          content={
            <label>
              <span>
                {strings.patientRecord.edit.label.fitzpatrickSkinTypeHeader}
              </span>
              <span className="requiredAsterisk"> *</span>
            </label>
          }
        />
        <Form.Group inline>
          {radioButtonLabels.map((radioButtonLabel) => {
            return (
              <Form.Radio
                error={props.errorFields.includes(
                  "examination_fitzpatrick_skin_type"
                )}
                disabled={props.mode === "view" || props.mode === "template"}
                label={radioButtonLabel}
                name="examination_fitzpatrick_skin_type"
                value={radioButtonLabel}
                checked={
                  data.examination_fitzpatrick_skin_type === radioButtonLabel ||
                  false
                }
                onChange={props.handleChange}
              />
            );
          })}
        </Form.Group>
      </>
    );
  }

  function renderGlogauSkinGroupQuestion() {
    const radioButtonLabels = [
      strings.patientRecord.databaseValues.glogauSkinGroup1,
      strings.patientRecord.databaseValues.glogauSkinGroup2,
      strings.patientRecord.databaseValues.glogauSkinGroup3,
      strings.patientRecord.databaseValues.glogauSkinGroup4,
    ];
    return (
      <>
        <Form.Field
          className="textField"
          error={props.errorFields.includes("examination_glogau_skin_group")}
          content={
            <label>
              <span>
                {strings.patientRecord.edit.label.glogauSkinGroupHeader}
              </span>
              <span className="requiredAsterisk"> *</span>
            </label>
          }
        />
        <Form.Group inline>
          {radioButtonLabels.map((radioButtonLabel) => {
            return (
              <Form.Radio
                error={props.errorFields.includes(
                  "examination_glogau_skin_group"
                )}
                disabled={props.mode === "view" || props.mode === "template"}
                label={radioButtonLabel}
                name="examination_glogau_skin_group"
                value={radioButtonLabel}
                checked={
                  data.examination_glogau_skin_group === radioButtonLabel ||
                  false
                }
                onChange={props.handleChange}
              />
            );
          })}
        </Form.Group>
      </>
    );
  }

  function renderSkinConditionQuestion() {
    const radioButtonLabels = [
      strings.patientRecord.databaseValues.skinConditionGood,
      strings.patientRecord.databaseValues.skinConditionMildDamage,
      strings.patientRecord.databaseValues.skinConditionModerateDamage,
      strings.patientRecord.databaseValues.skinConditionSevereDamage,
    ];
    return (
      <>
        <Form.Field
          className="textField"
          error={props.errorFields.includes("examination_skin_condition")}
          content={
            <label>
              <span>
                {strings.patientRecord.edit.label.skinConditionHeader}
              </span>
              <span className="requiredAsterisk"> *</span>
            </label>
          }
        />
        <Form.Group inline>
          {radioButtonLabels.map((radioButtonLabel) => {
            return (
              <Form.Radio
                error={props.errorFields.includes("examination_skin_condition")}
                disabled={props.mode === "view" || props.mode === "template"}
                label={radioButtonLabel}
                name="examination_skin_condition"
                value={radioButtonLabel}
                checked={
                  data.examination_skin_condition === radioButtonLabel || false
                }
                onChange={props.handleChange}
              />
            );
          })}
        </Form.Group>
        {(data.examination_skin_condition ===
          strings.patientRecord.databaseValues.skinConditionModerateDamage ||
          data.examination_skin_condition ===
            strings.patientRecord.databaseValues.skinConditionSevereDamage) && (
          <div className="yesNoChild">
            <CheckboxGroup
              label=""
              horizontal
              error={props.errorFields.includes("examination_skin_conditions")}
              group="examination_skin_conditions"
              data={data}
              handleCheckboxChange={props.handleCheckboxGroupChange}
              options={skinConditionOptions}
            />
          </div>
        )}
      </>
    );
  }

  function renderSignsOfSmokingQuestion() {
    return (
      <YesNoInput
        mode={props.mode}
        error={props.errorFields.includes("examination_smoking")}
        name="examination_smoking"
        label={strings.patientRecord.edit.label.smokingHeader}
        value={data.examination_smoking}
        changehandler={props.handleYesNoChange}
        required
        showChildWhenValue="Yes"
        childrenFields={["examination_smoking_conditions"]}
        childElement={
          <CheckboxGroup
            label=""
            mode={props.mode}
            horizontal
            error={props.errorFields.includes("examination_smoking_conditions")}
            group="examination_smoking_conditions"
            data={data}
            handleCheckboxChange={props.handleCheckboxGroupChange}
            options={smokingConditionOptions}
          />
        }
      />
    );
  }

  function renderFacialVolumeQuestion() {
    const radioButtonLabels = [
      strings.patientRecord.databaseValues.facialVolumeGood,
      strings.patientRecord.databaseValues.facialVolumeGoodMinimal,
      strings.patientRecord.databaseValues.facialVolumeStartedDeteriorating,
      strings.patientRecord.databaseValues.facialVolumeDeteriorated,
    ];
    return (
      <>
        <Form.Field
          error={props.errorFields.includes("examination_skin_condition")}
          className="textField"
          content={
            <label>
              <span>{strings.patientRecord.edit.label.facialVolumeHeader}</span>
              <span className="requiredAsterisk"> *</span>
            </label>
          }
        />
        {radioButtonLabels.map((radioButtonLabel) => {
          return (
            <Form.Group>
              <Form.Radio
                error={props.errorFields.includes("examination_facial_volume")}
                disabled={props.mode === "view" || props.mode === "template"}
                label={radioButtonLabel}
                name="examination_facial_volume"
                value={radioButtonLabel}
                checked={
                  data.examination_facial_volume === radioButtonLabel || false
                }
                onChange={props.handleChange}
              />
            </Form.Group>
          );
        })}
        {(data.examination_facial_volume ===
          strings.patientRecord.databaseValues.facialVolumeGoodMinimal ||
          data.examination_facial_volume ===
            strings.patientRecord.databaseValues
              .facialVolumeStartedDeteriorating ||
          data.examination_facial_volume ===
            strings.patientRecord.databaseValues.facialVolumeDeteriorated) && (
          <div className="yesNoChild">
            <CheckboxGroup
              label=""
              horizontal
              error={props.errorFields.includes(
                "examination_facial_volume_details"
              )}
              group="examination_facial_volume_details"
              data={data}
              handleCheckboxChange={props.handleCheckboxGroupChange}
              options={facialVolumeDetailOptions}
            />
          </div>
        )}
      </>
    );
  }

  function renderAbnormalFeaturesQuestion() {
    return (
      <YesNoInput
        mode={props.mode}
        error={props.errorFields.includes("examination_abnormal")}
        name="examination_abnormal"
        label={strings.patientRecord.edit.label.abnormalHeader}
        value={data.examination_abnormal}
        changehandler={props.handleYesNoChange}
        required
        showChildWhenValue="Yes"
        childrenFields={["examination_abnormal_conditions"]}
        childElement={
          <CheckboxGroup
            label=""
            mode={props.mode}
            error={props.errorFields.includes(
              "examination_abnormal_conditions"
            )}
            group="examination_abnormal_conditions"
            data={data}
            handleCheckboxChange={props.handleCheckboxGroupChange}
            options={abnormalConditionOptions}
          />
        }
      />
    );
  }
};

ExaminationNotesEdit.protoTypes = {
  mode: PropTypes.string.isRequired,
  selectedPatientData: PropTypes.object.isRequired,
  errorFields: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheckboxGroupChange: PropTypes.func.isRequired,
  handleYesNoChange: PropTypes.func.isRequired,
};

export default ExaminationNotesEdit;
