import { treatmentSchemas } from "../resources/treatmentSchemas";

export default (treatmentData, formType) => {
  return new Promise((resolve, reject) => {
    const errors = [];
    const schema = treatmentSchemas[formType];
    // First, validate all the fields present in the treatment data (if field should be validated))
    Object.keys(treatmentData).forEach(field => {
      if (schema.hasOwnProperty(field)) {
        // If validation function provided, use it, otherwise field is ignored
        if (schema[field].hasOwnProperty("validate") === true) {
          const validationResult = schema[field].validate(field, treatmentData);
          if (validationResult !== true) {
            errors.push({
              field: field,
              error: `${
                schema[field].errorMessagePrefix
              } ${validationResult}`
            });
          }
        }
      }
    });

    // Next, ensure all required fields in the schema are present in the data
    Object.keys(schema).forEach(schemaEntry => {
      if (schema[schemaEntry].required === true) {
        if (
          treatmentData.hasOwnProperty(schemaEntry) !== true ||
          treatmentData[schemaEntry] === null
        ) {
          errors.push({
            field: schemaEntry,
            error: `${schema[schemaEntry].errorMessagePrefix} is required`
          });
        }
      } else if (schema[schemaEntry].hasOwnProperty("requireIf")) {
        if (schema[schemaEntry].requireIf(treatmentData)) {
          if (
            treatmentData.hasOwnProperty(schemaEntry) !== true ||
            treatmentData[schemaEntry] === null
          ) {
            errors.push({
              field: schemaEntry,
              error: `${schema[schemaEntry].errorMessagePrefix} is required`
            });
          }
        }
      }
    });

    const results = {
      passedValidation: errors.length === 0,
      errors
    };
    resolve(results);
  });
};
