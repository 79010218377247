const isoToFormattedDateTime = isoDate => {
  let dateObject = new Date(isoDate);
  let minutes = dateObject.getMinutes();
  let hour = dateObject.getHours();
  let day = dateObject.getDate();
  let month = dateObject.getMonth() + 1;
  let year = dateObject.getFullYear();
  if (minutes < 10) {
    minutes = appendZero(minutes);
  }
  if (hour < 10) {
    hour = appendZero(hour);
  }
  if (day < 10) {
    day = appendZero(day);
  }
  if (month < 10) {
    month = appendZero(month);
  }
  return `${day}-${month}-${year}, ${hour}:${minutes}` 
};

const appendZero = numberString => {
  return '0' + numberString;
};

export const dateFormatter = {
  isoToFormattedDateTime
};