import React from "react";
import { Button, Header, Image } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../resources";
import PropTypes from "prop-types";
import SignatureCanvas from "react-signature-canvas";
import "./css/SignatureField.css";

class SignatureField extends React.Component {
  // Used to prevent memory leaks but running this.setState on unmounted components
  _isMounted = false;

  state = {
    canvasWidth: 480,
    canvasHeight: 300,
  };

  componentDidMount = async () => {
    this._isMounted = true;
    window.addEventListener('orientationchange', this.resizeCanvas);
    if (document.getElementById("signatureCanvas")) {
      if (this._isMounted) {
        this.setState({
          canvasWidth: document.getElementById("signatureCanvas").offsetWidth,
          canvasHeight: document.getElementById("signatureCanvas").offsetHeight,
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  };

  resizeCanvas = async () => {
    let currentData = this.props.getSignatureData();
    // Using an additional listener for resize makes this wait until the device
    // rotation is complete, and all elements have their new sizes
    let afterOrientationChange = async () => {
      if (this._isMounted && document.getElementById("signatureCanvas")) {
        // Calculate the new position of the current canvas contents by multiplying
        // the current co-ordinates by the new size ratios
        let newHeight = document.getElementById("signatureCanvas").offsetHeight;
        let newWidth = document.getElementById("signatureCanvas").offsetWidth;
        let heightRatio = newHeight / this.state.canvasHeight;
        let widthRatio = newWidth / this.state.canvasWidth;
        // Only update and calculate if one of the dimensions have changed
        if (
          document.getElementById("signatureCanvas") &&
          (this.state.canvasHeight !==
            document.getElementById("signatureCanvas").offsetHeight ||
            this.state.canvasWidth !==
              document.getElementById("signatureCanvas").offsetWidth)
        ) {
          this.setState({
            canvasWidth: document.getElementById("signatureCanvas").offsetWidth,
            canvasHeight: document.getElementById("signatureCanvas").offsetHeight,
          });
        }
        if (currentData) {
          let newData = currentData;
          // The canvas data is an array of line arrays - each line array contains
          // a list of data points that include x, y, and time of creation
          newData.forEach((item, dataIndex) => {
            let newItem = item;
            newItem.forEach((dataPoint, pointIndex) => {
              let newX = dataPoint.x * widthRatio;
              let newY = dataPoint.y * heightRatio;
              newItem[pointIndex].x = newX;
              newItem[pointIndex].y = newY;
            })
            newData[dataIndex] = newItem;
          })

          this.props.writeSignatureFromData(newData);
        }
        window.removeEventListener('resize', afterOrientationChange);
      };
    };
    window.addEventListener('resize', afterOrientationChange);
  };

  render = () => {
    const canvasBackgroundColor = "rgb(255, 255, 255)";
    return (
      <div>
        {this.props.readOnly && (
          <div>
            <Header as="h5" className="signatureHeader">
              {strings.header.patientSignature}
            </Header>
            {this.props.mode !== "template" ? (
              <Image
                className="signatureCanvas signatureCanvasReadOnly"
                crossOrigin="anonymous"
                src={this.props.signatureURL ? this.props.signatureURL.url : ""}
                width={this.state.canvasWidth}
                height={this.state.canvasHeight}
              />
            ) : (
              <div
                className="blankSignature"
                style={{
                  width: this.state.canvasWidth,
                  height: this.state.canvasHeight
                }}
              />
            )}
          </div>
        )}
        {!this.props.readOnly && (
          <div>
            <Header as="h5" className={this.props.error ? "errorLabel signatureHeader" : "signatureHeader"}>
              {strings.header.signaturePleaseSign}
            </Header>
              <SignatureCanvas
                backgroundColor={canvasBackgroundColor}
                canvasProps={{
                  width: this.state.canvasWidth,
                  height: this.state.canvasHeight,
                  className: "signatureCanvas signatureCanvasEditable",
                  id: "signatureCanvas"
                }}
                onEnd={this.props.handleUpdate}
                ref={this.props.setRef}
              />
            <div className="signatureButtons">
              <Button
                className="undoButton"
                onClick={() => this.props.handleUndo()}
                type="button"
              >
                {strings.button.undoLabel}
              </Button>
              <Button
                className="clearButton"
                onClick={() => this.props.handleClear()}
                type="button"
              >
                {strings.button.clearLabel}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };
}

SignatureField.propTypes = {
  canvasHeight: PropTypes.number,
  canvasWidth: PropTypes.number,
  readOnly: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(SignatureField);
