import { productActionTypes } from "../actions/product.actionTypes";

const initialState = {
  loadingPage: false,
  selectedProductData: {},
  productListData: [{}],
  productToDelete: "",
  result: [],
  error: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case productActionTypes.CREATE_PRODUCT_REQUEST:
      return { ...state, loadingPage: true };
    case productActionTypes.CREATE_PRODUCT_SUCCESS:
      return { ...state, loadingPage: false, result: action.data };
    case productActionTypes.CREATE_PRODUCT_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case productActionTypes.UPDATE_PRODUCT_REQUEST:
      return { ...state, loadingPage: true };
    case productActionTypes.UPDATE_PRODUCT_SUCCESS:
      return { ...state, loadingPage: false, result: action.data };
    case productActionTypes.UPDATE_PRODUCT_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case productActionTypes.DEACTIVATE_PRODUCT_REQUEST:
      return { ...state, loadingPage: true };
    case productActionTypes.DEACTIVATE_PRODUCT_SUCCESS:
      return { ...state, loadingPage: false, result: action.data };
    case productActionTypes.DEACTIVATE_PRODUCT_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case productActionTypes.ACTIVATE_PRODUCT_REQUEST:
      return { ...state, loadingPage: true };
    case productActionTypes.ACTIVATE_PRODUCT_SUCCESS:
      return { ...state, loadingPage: false, result: action.data };
    case productActionTypes.ACTIVATE_PRODUCT_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case productActionTypes.GET_PRODUCTS_REQUEST:
      return { ...state, loadingPage: true };
    case productActionTypes.GET_PRODUCTS_SUCCESS:
      return { ...state, loadingPage: false, productListData: action.data };
    case productActionTypes.GET_PRODUCTS_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case productActionTypes.GET_PRODUCT_BY_ID_REQUEST:
      return { ...state, loadingPage: true };
    case productActionTypes.GET_PRODUCT_BY_ID_SUCCESS:
      return { ...state, loadingPage: false, selectedProductData: action.data };
    case productActionTypes.GET_PRODUCT_BY_ID_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case productActionTypes.UPDATE_SELECTED_PRODUCT_REQUEST:
      return { ...state };
    case productActionTypes.UPDATE_SELECTED_PRODUCT_SUCCESS:
      return {
        ...state,
        selectedProductData: {
          ...state.selectedProductData,
          [action.key]: action.value
        }
      };
    case productActionTypes.UPDATE_SELECTED_PRODUCT_FAILURE:
      return { ...state };

    case productActionTypes.CLEAR_SELECTED_PRODUCT_REQUEST:
      return { ...state, loadingPage: true };
    case productActionTypes.CLEAR_SELECTED_PRODUCT_SUCCESS:
      return { ...state, loadingPage: false, selectedProductData: {} };
    case productActionTypes.CLEAR_SELECTED_PRODUCT_FAILURE:
      return { ...state, loadingPage: false };

    default:
      return state;
  }
};
