/*global awsEnvUsersApiEndpoint, awsEnvPatientsApiEndpoint, awsEnvFormsApiEndpoint, awsEnvProductsApiEndpoint, awsEnvTreatmentsApiEndpoint, awsEnvImagesApiEndpoint */

const development = {
  usersEndpoint: `${awsEnvUsersApiEndpoint}/users`,
  patientsEndpoint: `${awsEnvPatientsApiEndpoint}/patients`,
  formsEndpoint: `${awsEnvFormsApiEndpoint}/forms`,
  imagesEndpoint: `${awsEnvImagesApiEndpoint}/`,
  productsEndpoint: `${awsEnvProductsApiEndpoint}/products`,
  treatmentsEndpoint: `${awsEnvTreatmentsApiEndpoint}/treatments`
};

const uat = {
  usersEndpoint: `${awsEnvUsersApiEndpoint}/users`,
  patientsEndpoint: `${awsEnvPatientsApiEndpoint}/patients`,
  formsEndpoint: `${awsEnvFormsApiEndpoint}/forms`,
  imagesEndpoint: `${awsEnvImagesApiEndpoint}/`,
  productsEndpoint: `${awsEnvProductsApiEndpoint}/products`,
  treatmentsEndpoint: `${awsEnvTreatmentsApiEndpoint}/treatments`
};

const pp = {
  usersEndpoint: `${awsEnvUsersApiEndpoint}/users`,
  patientsEndpoint: `${awsEnvPatientsApiEndpoint}/patients`,
  formsEndpoint: `${awsEnvFormsApiEndpoint}/forms`,
  imagesEndpoint: `${awsEnvImagesApiEndpoint}/`,
  productsEndpoint: `${awsEnvProductsApiEndpoint}/products`,
  treatmentsEndpoint: `${awsEnvTreatmentsApiEndpoint}/treatments`
};

const production = {
  usersEndpoint: `${awsEnvUsersApiEndpoint}/users`,
  patientsEndpoint: `${awsEnvPatientsApiEndpoint}/patients`,
  formsEndpoint: `${awsEnvFormsApiEndpoint}/forms`,
  imagesEndpoint: `${awsEnvImagesApiEndpoint}/`,
  productsEndpoint: `${awsEnvProductsApiEndpoint}/products`,
  treatmentsEndpoint: `${awsEnvTreatmentsApiEndpoint}/treatments`
};

let environmentConfig = {};

switch (process.env.REACT_APP_STAGE) {
  case "development":
  default:
    environmentConfig = development;
    break;
  case "uat":
    environmentConfig = uat;
    break;
  case "pp":
    environmentConfig = pp;
    break;
  case "production":
    environmentConfig = production;
    break;
}

export const config = {
  ...environmentConfig
};
