import { API } from "../../services";
import { formActionTypes } from "./form.actionTypes";
import formValidator from "../../helpers/formValidator";

const api = new API();

const createForm = (details, patientId, headers) => {
  const request = () => {
    return { type: formActionTypes.CREATE_FORM_REQUEST };
  };
  const success = data => {
    return { type: formActionTypes.CREATE_FORM_SUCCESS, payload: data };
  };
  const failure = error => {
    return { type: formActionTypes.CREATE_FORM_FAILURE, payload: error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createForm" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.createForm(details, patientId).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const validateForm = details => {
  const request = () => {
    return { type: formActionTypes.VALIDATE_FORM_REQUEST };
  };
  const success = result => {
    return { type: formActionTypes.VALIDATE_FORM_SUCCESS, payload: result };
  };
  const failure = result => {
    return { type: formActionTypes.VALIDATE_FORM_FAILURE, payload: result };
  };

  return dispatch => {
    dispatch(request());
    // Attempting Form Validation
    return new Promise((resolve, reject) => {
      try {
        formValidator(details)
          .then(valid => {
            dispatch(success(true));
            resolve(valid);
          })
          .catch(error => {
            dispatch(failure(false));
            reject(error);
          });
      } catch (error) {
        dispatch(failure(false));
        reject(error);
      }
    });
  };
};

const deleteForm = (patientId, formId, headers) => {
  const request = () => {
    return { type: formActionTypes.DELETE_FORM_REQUEST };
  };
  const success = response => {
    return { type: formActionTypes.DELETE_FORM_SUCCESS, payload: response };
  };
  const failure = error => {
    return { type: formActionTypes.DELETE_FORM_FAILURE, payload: error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteForm" });

  return dispatch => {
    dispatch(request());
    api.deleteForm(patientId, formId).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error))
    );
  };
};

const getForms = (
  headers,
  patientId,
  formType = null,
  pageSize = 10,
  lastKey = null
) => {
  const request = () => {
    return { type: formActionTypes.GET_FORMS_REQUEST };
  };
  const success = response => {
    return { type: formActionTypes.GET_FORMS_SUCCESS, payload: response };
  };
  const failure = error => {
    return { type: formActionTypes.GET_FORMS_FAILURE, payload: error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getForms" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .getForms(patientId, formType, pageSize, lastKey)
        .then(response => {
          dispatch(success(response.data));
          resolve(response.data);
        })
        .catch(error => {
          dispatch(failure(error));
          reject(error);
        });
    });
  };
};

const getFormById = (id, patient_id, headers) => {
  const request = () => {
    return { type: formActionTypes.GET_FORM_BY_ID_REQUEST };
  };
  const success = payload => {
    return { type: formActionTypes.GET_FORM_BY_ID_SUCCESS, payload };
  };
  const failure = error => {
    return { type: formActionTypes.GET_FORM_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getFormById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getFormById(id, patient_id).then(
        response => {
          dispatch(success(response.data.content));
          resolve(response.data.content);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedForm = (key, value) => {
  const request = (key, value) => {
    return { type: formActionTypes.UPDATE_SELECTED_FORM_REQUEST, key, value };
  };
  const success = (key, value) => {
    return { type: formActionTypes.UPDATE_SELECTED_FORM_SUCCESS, key, value };
  };
  const failure = () => {
    return { type: formActionTypes.UPDATE_SELECTED_FORM_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const deleteFromSelectedForm = key => {
  const request = key => {
    return { type: formActionTypes.DELETE_FROM_SELECTED_FORM_REQUEST, key };
  };
  const success = key => {
    return { type: formActionTypes.DELETE_FROM_SELECTED_FORM_SUCCESS, key };
  };
  const failure = () => {
    return { type: formActionTypes.DELETE_FROM_SELECTED_FORM_FAILURE };
  };

  return dispatch => {
    dispatch(request(key));
    if (key) {
      dispatch(success(key));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedForm = () => {
  const request = () => {
    return { type: formActionTypes.CLEAR_SELECTED_FORM_REQUEST };
  };
  const success = () => {
    return { type: formActionTypes.CLEAR_SELECTED_FORM_SUCCESS };
  };

  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      dispatch(success());
      resolve();
    });
  };
};

const applyDoctorSignature = (id, details, headers) => dispatch => {
  return new Promise(function(resolve, reject) {
    const request = details => {
      return { type: formActionTypes.APPLY_DOCTOR_SIGNATURE_REQUEST, details };
    };
    const success = data => {
      return { type: formActionTypes.APPLY_DOCTOR_SIGNATURE_SUCCESS, data };
    };
    const failure = error => {
      return { type: formActionTypes.APPLY_DOCTOR_SIGNATURE_FAILURE, error };
    };

    api.setHeaders({
      ...headers,
      systemHeader_subsysref: "applyDoctorSignature"
    });

    dispatch(request(details));

    api.applyDoctorSignature(id, details).then(
      response => {
        dispatch(success(response));
        resolve();
      },
      error => {
        dispatch(failure(error));
        reject(error);
      }
    );
  });
};

export const formActions = {
  createForm,
  deleteForm,
  validateForm,
  getFormById,
  getForms,
  updateSelectedForm,
  deleteFromSelectedForm,
  clearSelectedForm,
  applyDoctorSignature
};
