import React from "react";
import { Route, Redirect } from "react-router-dom";

/**
 * PrivateRoute component.
 *
 * Route standard props need to be merged with the passed-down
 * child component props, as they aren't included in the render props.
 */

export const PrivateRoute = ({
  component: Component,
  roles,
  formType,
  ...injectedProps
}) => (
  <>
    {Component !== undefined && Component !== null && Component !== "" && (
      <Route
        {...injectedProps}
        render={(props) => {
          // check if user is logged in
          if (!injectedProps.isLoggedIn || !injectedProps.role) {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            );
          }

          // check if route is restricted by role
          if (roles && roles.indexOf(injectedProps.role) === -1) {
            // role not authorised so redirect to home page (unless the user is the "patient" user or the "remote-patient" user)
            if (
              injectedProps.role !== "patient" &&
              injectedProps.role !== "remote-patient"
            ) {
              return <Redirect to={{ pathname: "/" }} />;
            } else {
              //log out the "patient" or "remote-patient" user, because they won't have access to the homepage anyway (only the blank forms)
              injectedProps.signOut();
            }
          }

          // check if the formType needs added to the props for the route
          if (formType !== undefined) {
            const newInjectedProps = { ...injectedProps, formType: formType };
            return (
              <Component {...Object.assign({}, props, newInjectedProps)} />
            );
          }

          return <Component {...Object.assign({}, props, injectedProps)} />;
        }}
      />
    )}
  </>
);
