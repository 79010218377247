import React from "react";
import classes from "./css/Layout.css";
import ResponsiveContainer from "../../components/Layout/ResponsiveContainer";

const HomepageLayout = props => (
  <ResponsiveContainer>
    <main className={classes.Content}>{props.children}</main>
  </ResponsiveContainer>
);
export default HomepageLayout;
