import React from "react";
import { List } from "semantic-ui-react";
import checkboxGroupList from "../../helpers/checkboxGroupList";

const CheckboxGroupViewList = props => {
  const list = checkboxGroupList(
    props.name,
    props.value,
    props.noItemText,
    props.mapping
  );
  return (
    <div className={props.className}>
      <List bulleted>
        {list.map((listItem, index) => {
          return <List.Item key={index}>{listItem}</List.Item>;
        })}
      </List>
    </div>
  );
};

export default CheckboxGroupViewList;
