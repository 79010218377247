import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { enums } from "../../../resources/Enums";
import ShowIfAuthorised from "../../ShowIfAuthorised";
import { Divider, Image, Menu, Sidebar } from "semantic-ui-react";
import drdmLogo from "../../../assets/images/logo.png";
import "./Sidebar.css";

const DesktopSidebar = props => (
  <Sidebar
    as={Menu}
    animation="overlay"
    className="desktopSidebar"
    inverted
    vertical
    visible
  >
    <Image src={drdmLogo} size="small" />
    <Divider hidden />

    <ShowIfAuthorised allowedRoles={[enums.role.PULSION]}>
      <Menu.Item name="users" active={props.activeItem === "users"}>
        <Link to="/users" className="item">
          Users
        </Link>
      </Menu.Item>
    </ShowIfAuthorised>

    <ShowIfAuthorised
      allowedRoles={[
        enums.role.PULSION,
        enums.role.ADMIN,
        enums.role.DOCTOR,
        enums.role.RECEPTIONIST
      ]}
    >
      <Menu.Item name="patients" active={props.activeItem === "patients"}>
        <Link to="/patients" className="item">
          Patients
        </Link>
      </Menu.Item>

      <Menu.Item name="forms" active={props.activeItem === "forms"}>
        <Link to="/forms" className="item">
          Form Templates
        </Link>
      </Menu.Item>
    </ShowIfAuthorised>

    <ShowIfAuthorised allowedRoles={[enums.role.PULSION, enums.role.ADMIN]}>
      <Menu.Item name="treatments" active={props.activeItem === "treatments"}>
        <Link to="/treatmentTypes" className="item">
          Treatment Types
        </Link>
      </Menu.Item>
    </ShowIfAuthorised>

    <ShowIfAuthorised
      allowedRoles={[enums.role.PULSION, enums.role.ADMIN, enums.role.DOCTOR]}
    >
      <Menu.Item name="products" active={props.activeItem === "products"}>
        <Link to="/products" className="item">
          Products
        </Link>
      </Menu.Item>
    </ShowIfAuthorised>
  </Sidebar>
);

DesktopSidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired
};

export default DesktopSidebar;
