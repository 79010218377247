import { strings } from "../";

const newTreatmentSchema = {
  treatment_type: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.treatmentType,
  },
  doctor: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.doctor,
  },
  skin_prep: {
    requireIf: (data) => {
      return getRequiredDependent("treatment_performed_today", "Yes", data);
    },
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.skinPrep,
  },
  local_anaesthetics: {
    requireIf: (data) => {
      return getRequiredDependent("treatment_performed_today", "Yes", data);
    },
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.localAnaesthetics,
  },
  diagnosis: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.diagnosis,
    required: true,
    validate: (field, data) => {
      const atLeastOneOptionSelected = Object.keys(data[field]).some((item) => {
        return data[field][item].checked;
      });
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },
  diagnosis_other: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.diagnosisOther,
    requireIf: (data) => {
      return (
        data.hasOwnProperty("diagnosis") &&
        data.diagnosis.other &&
        data.diagnosis.other.checked === true
      );
    },
  },
  treatment_options: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.treatmentOptions,
    required: true,
    validate: (field, data) => {
      const atLeastOneOptionSelected = Object.keys(data[field]).some((item) => {
        return data[field][item].checked;
      });
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },
  treatment_options_other: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.treatmentOptionsOther,
    requireIf: (data) => {
      return (
        data.hasOwnProperty("treatment_options") &&
        data.treatment_options.other &&
        data.treatment_options.other.checked === true
      );
    },
  },
  treatment_plan: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.treatmentPlan,
    required: true,
    validate: (field, data) => {
      const atLeastOneOptionSelected = Object.keys(data[field]).some((item) => {
        return data[field][item].checked;
      });
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },
  treatment_plan_other: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.treatmentPlanOther,
    requireIf: (data) => {
      return (
        data.hasOwnProperty("treatment_plan") &&
        data.treatment_plan.other &&
        data.treatment_plan.other.checked === true
      );
    },
  },
  treatment_performed_today: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.treatment_performed_today,
    required: true,
  },
  examination_fitzpatrick_skin_type: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .examination_fitzpatrick_skin_type,
    required: true,
  },
  examination_glogau_skin_group: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.examination_glogau_skin_group,
    required: true,
  },
  examination_skin_condition: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.examination_skin_condition,
    required: true,
  },
  examination_smoking: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.examination_smoking,
    required: true,
  },
  examination_facial_volume: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.examination_facial_volume,
    required: true,
  },
  examination_abnormal: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.examination_abnormal,
    required: true,
  },
  examination_skin_conditions: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.examination_skin_conditions,
    requireIf: (data) => {
      return (
        getRequiredDependent(
          "examination_skin_condition",
          strings.form.databaseValues.skinConditionModerateDamage,
          data
        ) ||
        getRequiredDependent(
          "examination_skin_condition",
          strings.form.databaseValues.skinConditionSevereDamage,
          data
        )
      );
    },
    validate: (field, data) => {
      if (
        getRequiredDependent(
          "examination_skin_condition",
          strings.form.databaseValues.skinConditionModerateDamage,
          data
        ) ||
        getRequiredDependent(
          "examination_skin_condition",
          strings.form.databaseValues.skinConditionSevereDamage,
          data
        )
      ) {
        const atLeastOneOptionSelected = Object.keys(data[field]).some(
          (item) => {
            return data[field][item].checked;
          }
        );
        if (atLeastOneOptionSelected === true) {
          return true;
        } else {
          return strings.form.validation.errorMessageReason
            .requireAtLeastOneOption;
        }
      }
      return true;
    },
  },
  examination_smoking_conditions: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.examination_smoking_conditions,
    requireIf: (data) => {
      return getRequiredDependent("examination_smoking", "Yes", data);
    },
    validate: (field, data) => {
      if (getRequiredDependent("examination_smoking", "Yes", data)) {
        const atLeastOneOptionSelected = Object.keys(data[field]).some(
          (item) => {
            return data[field][item].checked;
          }
        );
        if (atLeastOneOptionSelected === true) {
          return true;
        } else {
          return strings.form.validation.errorMessageReason
            .requireAtLeastOneOption;
        }
      }
      return true;
    },
  },
  examination_facial_volume_details: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .examination_facial_volume_details,
    requireIf: (data) => {
      const isRequired =
        getRequiredDependent(
          "examination_facial_volume",
          strings.form.databaseValues.facialVolumeGoodMinimal,
          data
        ) ||
        getRequiredDependent(
          "examination_facial_volume",
          strings.form.databaseValues.facialVolumeStartedDeteriorating,
          data
        ) ||
        getRequiredDependent(
          "examination_facial_volume",
          strings.form.databaseValues.facialVolumeDeteriorated,
          data
        );
      return isRequired;
    },
    validate: (field, data) => {
      const isRequired =
        getRequiredDependent(
          "examination_facial_volume",
          strings.form.databaseValues.facialVolumeGoodMinimal,
          data
        ) ||
        getRequiredDependent(
          "examination_facial_volume",
          strings.form.databaseValues.facialVolumeStartedDeteriorating,
          data
        ) ||
        getRequiredDependent(
          "examination_facial_volume",
          strings.form.databaseValues.facialVolumeDeteriorated,
          data
        );
      if (isRequired) {
        const atLeastOneOptionSelected = Object.keys(data[field]).some(
          (item) => {
            return data[field][item].checked;
          }
        );
        if (atLeastOneOptionSelected === true) {
          return true;
        } else {
          return strings.form.validation.errorMessageReason
            .requireAtLeastOneOption;
        }
      }
      return true;
    },
  },
  examination_abnormal_conditions: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .examination_abnormal_conditions,
    requireIf: (data) => {
      return getRequiredDependent("examination_abnormal", "Yes", data);
    },
    validate: (field, data) => {
      if (getRequiredDependent("examination_abnormal", "Yes", data)) {
        const atLeastOneOptionSelected = Object.keys(data[field]).some(
          (item) => {
            return data[field][item].checked;
          }
        );
        if (atLeastOneOptionSelected === true) {
          return true;
        } else {
          return strings.form.validation.errorMessageReason
            .requireAtLeastOneOption;
        }
      }
      return true;
    },
  },
  selected_products: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.selected_products,
    requireIf: (data) => {
      return getRequiredDependent("treatment_performed_today", "Yes", data);
    },
    validate: (field, data) => {
      if (data["treatment_performed_today"] === "Yes") {
        const productKeys = Object.keys(data[field]);
        // Check at least one product is selected
        if (productKeys.length <= 0) {
          return strings.form.validation.errorMessageReason
            .requireAtLeastOneProduct;
        }

        // Check all keys in products for volume and at least one injection device
        let invalid = null;
        productKeys.forEach((key) => {
          const productData = data[field][key];
          // Ensure all entries have a volume
          if (!productData.volume || productData.volume === "") {
            invalid = strings.form.validation.errorMessageReason.requireVolume;
          }

          let atLeastOneDeviceSelected = false;
          atLeastOneDeviceSelected = Object.keys(
            productData.injection_devices
          ).some((item) => {
            return productData.injection_devices[item];
          });
          if (!atLeastOneDeviceSelected) {
            invalid = strings.form.validation.errorMessageReason.requireDevice;
          }
        });
        if (invalid) {
          return invalid;
        }
      }
      return true;
    },
  },
};

const getRequiredDependent = (requiredIfField, requiredIfValue, data) => {
  // a field is required if it's dependent field has a value and that value is not equal to requiredIfValue
  if (data.hasOwnProperty(requiredIfField)) {
    if (data[requiredIfField] === requiredIfValue) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export default newTreatmentSchema;
