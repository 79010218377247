import React from "react";
import { DatePicker, BasicModal } from "./ui";
import { Form, Message, Button } from "semantic-ui-react";
import { strings } from "../resources";
import PropTypes from "prop-types";

class PreConsultationPatientLogin extends React.Component {
  renderNewPatientInput() {
    return (
      <React.Fragment>
        <Form.Group>
          <Form.Input
            name="email_address"
            onChange={this.props.handleChange}
            required
            label={strings.form.label.emailAddress}
            placeholder={strings.form.placeholder.emailAddress}
            value={this.props.data.email_address || ""}
            width={8}
          />
        </Form.Group>
      </React.Fragment>
    );
  }

  renderExistingPatientInput() {
    return (
      <React.Fragment>
        <Form.Group>
          <Form.Input
            name="first_name"
            onChange={this.props.handleChange}
            required
            label={strings.form.label.firstName}
            placeholder={strings.form.placeholder.firstName}
            value={this.props.data.first_name || ""}
            width={8}
          />
          <Form.Input
            className="noLabelInput"
            name="surname"
            onChange={this.props.handleChange}
            required
            placeholder={strings.form.placeholder.surname}
            value={this.props.data.surname || ""}
            width={8}
          />
        </Form.Group>
        <DatePicker
          className="verifyDateOfBirth"
          name="date_of_birth"
          onChange={this.props.handleDateChange}
          onKeyDown={(e) => this.props.handleDateKeyDown(e)}
          required
          label={strings.form.label.dateOfBirth}
          value={this.props.data.date_of_birth || ""}
          noFutureDates
          width={5}
        />
      </React.Fragment>
    );
  }

  renderInvalidLink() {
    return (
      <React.Fragment>
        <Form.Group>
          <Form.Field>
            <label>Please contact us if you require a new login link.</label>
          </Form.Field>
        </Form.Group>
      </React.Fragment>
    );
  }

  render = () => {
    return (
      <React.Fragment>
        {
          //Invalid or expired link - Show not valid header
          !this.props.isValidLink && (
            <div className="preconsultationHeader">
              {strings.header.preConsultationLinkNotValid}
            </div>
          )
        }
        {
          //Valid link, patient to confirm details - show confirm details header
          this.props.isValidLink && !this.props.showFailureMessage && (
            <div className="preconsultationHeader">
              {strings.header.verifyPreconsultation}
            </div>
          )
        }
        {
          //Valid link, patient entered incorrect details - show incorrect details header
          this.props.isValidLink && this.props.showFailureMessage && (
            <div className="preconsultationHeader">
              {strings.header.preConsultationLinkNoMatch}
            </div>
          )
        }

        <Form onSubmit={this.props.handleSubmit}>
          {
            //Invalid or expired link or patient entered incorrect details - show failure message
            (!this.props.isValidLink || this.props.showFailureMessage) &&
              this.renderInvalidLink()
          }

          {
            //Valid link, patient to confirm details, patient is existing  patient - show existing patient verification form
            this.props.isValidLink &&
              !this.props.showFailureMessage &&
              this.props.isExistingPatient &&
              this.renderExistingPatientInput()
          }

          {
            //Valid link, patient to confirm details, patient is new patient - show new patient verification form
            this.props.isValidLink &&
              !this.props.showFailureMessage &&
              !this.props.isExistingPatient &&
              this.renderNewPatientInput()
          }

          {
            //Valid link, patient to confirm details - show submit button
            this.props.isValidLink && !this.props.showFailureMessage && (
              <div className="preconsultationVerifyButton">
                <Button.Group>
                  <Button className="saveButton">
                    {strings.button.continue}
                  </Button>
                  <Button.Or />
                  <Button
                    className="cancelButton"
                    type="button"
                    onClick={this.props.toggleCancelModalVisibility}
                  >
                    {strings.button.cancelLabel}
                  </Button>
                </Button.Group>
              </div>
            )
          }
        </Form>
        {this.props.error && (
          <Message negative>
            <span>{strings.form.label.preConsultationVerifyError}</span>
          </Message>
        )}
        <BasicModal
          confirmMessage={strings.modal.cancelPreconsultationMessage}
          handleModalConfirm={this.props.handleModalConfirm}
          modalClassName="cancelModal"
          modalOpen={this.props.cancelModalOpen}
          showNoButton={true}
          showOkButton={false}
          showYesButton={true}
          toggleModal={this.props.toggleCancelModalVisibility}
        />
      </React.Fragment>
    );
  };
}

PreConsultationPatientLogin.propTypes = {
  error: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDateKeyDown: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggleCancelModalVisibility: PropTypes.func.isRequired,
  cancelModalOpen: PropTypes.bool.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
};

export default PreConsultationPatientLogin;
