export const patientActionTypes = {
  CREATE_PATIENT_REQUEST: "CREATE_PATIENT_REQUEST",
  CREATE_PATIENT_SUCCESS: "CREATE_PATIENT_SUCCESS",
  CREATE_PATIENT_FAILURE: "CREATE_PATIENT_FAILURE",

  DELETE_PATIENT_REQUEST: "DELETE_PATIENT_REQUEST",
  DELETE_PATIENT_SUCCESS: "DELETE_PATIENT_SUCCESS",
  DELETE_PATIENT_FAILURE: "DELETE_PATIENT_FAILURE",

  UPDATE_SELECTED_PATIENT_REQUEST: "UPDATE_SELECTED_PATIENT_REQUEST",
  UPDATE_SELECTED_PATIENT_SUCCESS: "UPDATE_SELECTED_PATIENT_SUCCESS",
  UPDATE_SELECTED_PATIENT_FAILURE: "UPDATE_SELECTED_PATIENT_FAILURE",

  DELETE_FROM_SELECTED_PATIENT_REQUEST: "DELETE_FROM_SELECTED_PATIENT_REQUEST",
  DELETE_FROM_SELECTED_PATIENT_SUCCESS: "DELETE_FROM_SELECTED_PATIENT_SUCCESS",
  DELETE_FROM_SELECTED_PATIENT_FAILURE: "DELETE_FROM_SELECTED_PATIENT_FAILURE",

  UPDATE_SELECTED_PATIENT_TAB_REQUEST: "UPDATE_SELECTED_PATIENT_TAB_REQUEST",
  UPDATE_SELECTED_PATIENT_TAB_SUCCESS: "UPDATE_SELECTED_PATIENT_TAB_SUCCESS",

  CLEAR_SELECTED_PATIENT_REQUEST: "CLEAR_SELECTED_PATIENT_REQUEST",
  CLEAR_SELECTED_PATIENT_SUCCESS: "CLEAR_SELECTED_PATIENT_SUCCESS",
  CLEAR_SELECTED_PATIENT_FAILURE: "CLEAR_SELECTED_PATIENT_FAILURE",

  GET_PATIENTS_REQUEST: "GET_PATIENTS_REQUEST",
  GET_PATIENTS_SUCCESS: "GET_PATIENTS_SUCCESS",
  GET_PATIENTS_FAILURE: "GET_PATIENTS_FAILURE",

  UPDATE_PATIENT_REQUEST: "UPDATE_PATIENT_REQUEST",
  UPDATE_PATIENT_SUCCESS: "UPDATE_PATIENT_SUCCESS",
  UPDATE_PATIENT_FAILURE: "UPDATE_PATIENT_FAILURE",

  ACTIVATE_PATIENT_REQUEST: "ACTIVATE_PATIENT_REQUEST",
  ACTIVATE_PATIENT_SUCCESS: "ACTIVATE_PATIENT_SUCCESS",
  ACTIVATE_PATIENT_FAILURE: "ACTIVATE_PATIENT_FAILURE",

  DEACTIVATE_PATIENT_REQUEST: "DEACTIVATE_PATIENT_REQUEST",
  DEACTIVATE_PATIENT_SUCCESS: "DEACTIVATE_PATIENT_SUCCESS",
  DEACTIVATE_PATIENT_FAILURE: "DEACTIVATE_PATIENT_FAILURE",

  GET_PATIENT_BY_ID_REQUEST: "GET_PATIENT_BY_ID_REQUEST",
  GET_PATIENT_BY_ID_SUCCESS: "GET_PATIENT_BY_ID_SUCCESS",
  GET_PATIENT_BY_ID_FAILURE: "GET_PATIENT_BY_ID_FAILURE",

  GET_PATIENT_HISTORY_BY_ID_REQUEST: "GET_PATIENT_HISTORY_BY_ID_REQUEST",
  GET_PATIENT_HISTORY_BY_ID_SUCCESS: "GET_PATIENT_HISTORY_BY_ID_SUCCESS",
  GET_PATIENT_HISTORY_BY_ID_FAILURE: "GET_PATIENT_HISTORY_BY_ID_FAILURE",

  GENERATE_PATIENT_ID_REQUEST: "GENERATE_PATIENT_ID_REQUEST",
  GENERATE_PATIENT_ID_SUCCESS: "GENERATE_PATIENT_ID_SUCCESS",

  APPLY_DOCTOR_SIGNATURE_REQUEST: "APPLY_DOCTOR_SIGNATURE_REQUEST",
  APPLY_DOCTOR_SIGNATURE_SUCCESS: "APPLY_DOCTOR_SIGNATURE_SUCCESS",
  APPLY_DOCTOR_SIGNATURE_FAILURE: "APPLY_DOCTOR_SIGNATURE_FAILURE"
};
