import React from "react";
import PropTypes from "prop-types";
import { Form, Container, Button, Icon } from "semantic-ui-react";
import { enums, strings, patientCategories } from "../resources";
import { connect } from "react-redux";
import { authActions } from "../../App/store/actions/auth.actions";
import { userActions } from "../../Users/store/actions/user.actions";
import { withRouter } from "react-router-dom";
import "./css/PreConsultation.css";

class PreConsultationSelectContainer extends React.Component {
  state = {
    patientId: this.props.location.state
      ? this.props.location.state.patientId
      : null,
    patientData: this.props.location.state
      ? this.props.location.state.patientData
      : null,
    formTypesAvailable: {},
  };

  componentWillMount = () => {
    let patientCategory =
      this.state.patientId === null
        ? patientCategories.new
        : patientCategories.returnAndFollowUp;
    const formTypesAvailable = {};
    patientCategory.eligibleForms.forEach((eligibleForm) => {
      const formEnum = enums.formType[eligibleForm.formType];
      formTypesAvailable[formEnum.link] = {
        name: formEnum.name,
        selected: eligibleForm.compulsory,
        compulsory: eligibleForm.compulsory,
      };
    });
    this.setState({ formTypesAvailable });
  };

  handleChange = (e, data) => {
    const newData = Object.assign({}, this.state.formTypesAvailable);
    newData[data.value].selected = !newData[data.value].selected;
    this.setState({
      formTypesAvailable: newData,
    });
  };

  openEmailLinkConfirm = async () => {
    const selectedFormTypes = [];
    const selectedFormNames = [];
    Object.keys(this.state.formTypesAvailable).forEach((formType) => {
      if (this.state.formTypesAvailable[formType].selected) {
        selectedFormTypes.push(formType);
        selectedFormNames.push(this.state.formTypesAvailable[formType].name);
      }
    });
    const indexOfNewPatientRegistrationForm = selectedFormTypes.indexOf(
      enums.formType.NEW_PATIENT_REGISTRATION.link
    );

    // if new patient registration form isn't first in the list if present, move it there
    if (indexOfNewPatientRegistrationForm > 0) {
      selectedFormTypes.splice(indexOfNewPatientRegistrationForm, 1);
      selectedFormTypes.unshift(enums.formType.NEW_PATIENT_REGISTRATION.link);
      selectedFormNames.splice(indexOfNewPatientRegistrationForm, 1);
      selectedFormNames.unshift(enums.formType.NEW_PATIENT_REGISTRATION.name);
    }

    // open the email link page, so the receptionist can enter a new user's email address or confirm email to be sent
    this.props.history.push({
      pathname: "/forms/preconsultation/emailLink/",
      state: {
        patientId: this.state.patientId,
        patientData: this.state.patientData,
        selectedFormTypes,
        selectedFormNames,
        mode: "create",
      },
    });
  };

  openPreConsultationVerify = async () => {
    const selectedFormTypes = [];
    const selectedFormNames = [];
    Object.keys(this.state.formTypesAvailable).forEach((formType) => {
      if (this.state.formTypesAvailable[formType].selected) {
        selectedFormTypes.push(formType);
        selectedFormNames.push(this.state.formTypesAvailable[formType].name);
      }
    });
    const indexOfNewPatientRegistrationForm = selectedFormTypes.indexOf(
      enums.formType.NEW_PATIENT_REGISTRATION.link
    );

    // if new patient registration form isn't first in the list if present, move it there
    if (indexOfNewPatientRegistrationForm > 0) {
      selectedFormTypes.splice(indexOfNewPatientRegistrationForm, 1);
      selectedFormTypes.unshift(enums.formType.NEW_PATIENT_REGISTRATION.link);
      selectedFormNames.splice(indexOfNewPatientRegistrationForm, 1);
      selectedFormNames.unshift(enums.formType.NEW_PATIENT_REGISTRATION.name);
    }

    //Get the patient login credentials from the Users service
    let patientPassword = await this.props.getPatientCredentials(
      this.props.headers
    );

    //Log in the generic patient user (this will automatically log out the receptionist or admin user with no redirect)
    await this.props.signIn("patient", patientPassword);

    // send user to verify details if existing client, otherwise send directly to progress
    if (this.state.patientId) {
      this.props.history.push({
        pathname: "/forms/preconsultation/verify/",
        state: {
          patientId: this.state.patientId,
          patientData: this.state.patientData,
          selectedFormTypes,
          selectedFormNames,
          mode: "create",
        },
      });
    } else {
      this.props.history.push({
        pathname: "/forms/preconsultation/progress/",
        state: {
          patientId: this.state.patientId,
          patientData: this.state.patientData,
          selectedFormTypes,
          selectedFormNames,
          mode: "create",
        },
      });
    }
  };

  getAtLeastOneFormSelected = () => {
    return Object.keys(this.state.formTypesAvailable).some(
      (availableFormType) => {
        return (
          this.state.formTypesAvailable[availableFormType].selected === true
        );
      }
    );
  };

  render = () => {
    const selectPreconsultationFormsHeader =
      this.state.patientId === null
        ? strings.header.selectPreconsultationFormsNew
        : strings.header.selectPreconsultationFormsReturning;
    return (
      <React.Fragment>
        <Container className="preConsultationSelectContainer">
          <Form className="preConsultationSelectForm">
            <div className="preconsultationHeader">
              {selectPreconsultationFormsHeader}
            </div>
            {Object.keys(this.state.formTypesAvailable).map((formType) => (
              <Form.Checkbox
                key={`formTypeCheckbox-${formType}`}
                label={this.state.formTypesAvailable[formType].name}
                value={formType}
                disabled={this.state.formTypesAvailable[formType].compulsory}
                checked={this.state.formTypesAvailable[formType].selected}
                onChange={this.handleChange}
              />
            ))}
          </Form>
          <div className="preconsultationSendEmailButton">
            <Button
              icon
              labelPosition="left"
              className="primary icon preConsultationButton"
              onClick={() => this.openEmailLinkConfirm()}
              disabled={!this.getAtLeastOneFormSelected()}
            >
              <Icon name="envelope" />
              {strings.button.emailLinkToPatient}
            </Button>
          </div>
          <div className="preconsultationContinueButton">
            <Button
              icon
              labelPosition="left"
              className="primary icon preConsultationButton"
              onClick={() => this.openPreConsultationVerify()}
              disabled={!this.getAtLeastOneFormSelected()}
            >
              <Icon name="arrow right" />
              {strings.button.continue}
            </Button>
          </div>
        </Container>
      </React.Fragment>
    );
  };
}

PreConsultationSelectContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
  getPatientCredentials: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (username, password) => {
      dispatch(authActions.signIn(username, password));
    },
    getPatientCredentials: (headers) => {
      return dispatch(userActions.getPatientCredentials(headers));
    },
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(PreConsultationSelectContainer)
);
