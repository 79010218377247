import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Icon, Message } from "semantic-ui-react";
import { strings, timeUntilNextTreatmentOptions } from "../resources";
import { BasicModal } from "./ui";
import DoctorsAndPricesTable from "./ui/DoctorsAndPricesTable";
import {
  doctorOptions,
  treatmentTypeCategoryOptions
} from "../resources/DropdownOptions";
import "./css/TreatmentComponents.css";

class TreatmentType extends React.Component {
  render = () => {
    const errorFields = this.props.validationErrors.map(error => {
      return error.field;
    });
    const removeExistingSelections = options => {
      return options.filter(item => {
        for (let i in this.props.selectedTreatmentTypeData.doctors_and_prices) {
          if (
            this.props.selectedTreatmentTypeData.doctors_and_prices[i]
              .doctor === item.text
          )
            return false;
        }
        return true;
      });
    };

    const editActivationButtons = (
      <div className="centerContent">
        <Button.Group>
          <Button
            className="editButton"
            onClick={() => {
              this.props.handleModeSwitch("edit");
            }}
            disabled={!this.props.selectedTreatmentTypeData.active}
          >
            {strings.button.editLabel}
          </Button>
          <Button.Or />
          <Button
            className="activationButton"
            onClick={this.props.toggleActivationModalVisibility}
            type="button"
          >
            {this.props.selectedTreatmentTypeData.active &&
              strings.button.deactivateLabel}
            {!this.props.selectedTreatmentTypeData.active &&
              strings.button.activateLabel}
          </Button>
        </Button.Group>
        <BasicModal
          confirmMessage={
            this.props.selectedTreatmentTypeData.active
              ? strings.modal.deactivateTreatmentTypeMessage
              : strings.modal.activateTreatmentTypeMessage
          }
          handleModalConfirm={this.props.handleModalConfirm}
          modalClassName="activationModal"
          modalOpen={this.props.activationModalOpen}
          showOkButton={false}
          showYesButton={true}
          showNoButton={true}
          toggleModal={this.props.toggleActivationModalVisibility}
        />
      </div>
    );

    const cancelSaveButtons = (
      <div className="cancelSaveButtons">
        <Button.Group>
          <Button
            className="saveButton"
            onClick={this.props.toggleConfirmSaveModalVisibility}
          >
            {strings.button.saveLabel}
          </Button>
          <Button.Or />
          <Button
            className="cancelButton"
            onClick={this.props.toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={this.props.confirmSaveModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleConfirmSaveModalVisibility}
          />
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              this.props.mode === "create"
                ? this.props.handleModalConfirm
                : this.props.handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={this.props.cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleCancelChangesModalVisibility}
          />
        </Button.Group>
      </div>
    );

    const treatmentForm = (
      <>
        <div className="treatmentInfoHeader">
          {strings.form.header.treatmentInfo}
        </div>
        <div className="treatmentInfo">
          <Form.Group>
            <Form.Input
              error={errorFields.includes("treatment_type")}
              label={strings.form.label.treatmentType}
              name="treatment_type"
              onChange={this.props.handleChange}
              placeholder={strings.form.placeholder.treatmentType}
              required
              value={this.props.selectedTreatmentTypeData.treatment_type || ""}
              width={16}
            />
            {this.props.mode === "create" || this.props.mode === "edit" ? (
              <Form.Dropdown
                error={errorFields.includes("treatment_category")}
                label={strings.form.label.treatmentCategory}
                name="treatment_category"
                onChange={this.props.handleChange}
                placeholder="Select Treatment Category"
                selection
                options={treatmentTypeCategoryOptions}
                value={
                  this.props.selectedTreatmentTypeData.treatment_category || ""
                }
                required
                selectOnBlur={false}
                width={16}
              />
            ) : (
              <Form.Input
                label={strings.form.label.treatmentCategory}
                name="treatment_category"
                placeholder="Select Treatment Category"
                required
                value={
                  this.props.selectedTreatmentTypeData.treatment_category || ""
                }
                width={16}
              />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Field width={16}>
              <DoctorsAndPricesTable
                mode={this.props.mode}
                handleChange={this.props.handleChange}
                selectedTreatmentTypeData={this.props.selectedTreatmentTypeData}
                doctorsAndPrices={this.props.doctorsAndPrices || []}
                removeDoctor={this.props.removeDoctor}
                error={errorFields.includes("doctors_and_prices")}
              />
            </Form.Field>
          </Form.Group>

          {(this.props.mode === "create" || this.props.mode === "edit") &&
            removeExistingSelections(doctorOptions).length > 0 && (
              <Form.Group>
                <Form.Dropdown
                  placeholder="Select Doctor to add..."
                  selection
                  options={removeExistingSelections(doctorOptions)}
                  name="doctor"
                  value={this.props.selectedTreatmentTypeData.doctor || ""}
                  onChange={this.props.handleChange}
                  selectOnBlur={false}
                  width={8}
                />
                <Form.Input
                  name="price"
                  onChange={this.props.handleChange}
                  placeholder="Price for selected doctor"
                  value={this.props.selectedTreatmentTypeData.price || ""}
                  width={7}
                />
                <Form.Field width={1}>
                  <Icon
                    name="plus circle"
                    size="large"
                    onClick={this.props.addDoctor}
                  />
                </Form.Field>
              </Form.Group>
            )}
          <Form.Group>
            <Form.TextArea
              className="textAreaDouble"
              label={strings.form.label.treatmentDescription}
              name="treatment_description"
              onChange={this.props.handleChange}
              rows={3}
              value={
                this.props.selectedTreatmentTypeData.treatment_description || ""
              }
              width={16}
            />
          </Form.Group>
          <Form.Group className="minimumPeriodHeader">
            <Form.Field>
              <label>{strings.form.header.periodUntilNextTreatment}</label>
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Input
              error={errorFields.includes("period_until_next_treatment")}
              label={strings.form.label.unitsUntilNextTreatment}
              min="0"
              name="period_until_next_treatment"
              onChange={this.props.handleChange}
              onKeyDown={e => this.props.handleNumberOnlyInputKeyDown(e)} // TODO: key down event for preventing decimal
              required
              step="1"
              type="number"
              value={
                this.props.selectedTreatmentTypeData
                  .period_until_next_treatment || ""
              }
              width={6}
            />
            {(this.props.mode === "edit" || this.props.mode === "create") && (
              <Form.Dropdown
                label={strings.form.label.treatmentFrequency}
                name="period_until_next_treatment_units"
                onChange={this.props.handleChange}
                options={timeUntilNextTreatmentOptions}
                placeholder={strings.form.placeholder.selectFrequency}
                selection
                width={4}
                value={
                  this.props.selectedTreatmentTypeData
                    .period_until_next_treatment_units || "Days"
                }
              />
            )}
            {this.props.mode === "view" && (
              <Form.Input
                label={strings.form.label.treatmentFrequency}
                name="period_until_next_treatment_units"
                placeholder={strings.form.placeholder.selectFrequency}
                width={4}
                value={
                  this.props.selectedTreatmentTypeData
                    .period_until_next_treatment_units || "Days"
                }
              />
            )}
          </Form.Group>
          <div className="sectionHeader">{strings.form.header.aftercare}</div>
          <div className="aftercare">
            <Form.Group>
              <Form.TextArea
                error={errorFields.includes("aftercare_instructions")}
                className="textArea"
                label={strings.form.label.aftercareInstructions}
                name="aftercare_instructions"
                onChange={this.props.handleChange}
                required
                rows={2}
                value={
                  this.props.selectedTreatmentTypeData.aftercare_instructions ||
                  ""
                }
              />
            </Form.Group>
          </div>
          <div className="sectionHeader">
            {strings.form.header.nextTreatmentDetails}
          </div>
          <div className="nextTreatmentDetails">
            <Form.Group>
              <Form.TextArea
                error={errorFields.includes("text_notification_wording")}
                className="textAreaDouble"
                label={strings.form.label.textNotificationWording}
                name="text_notification_wording"
                onChange={this.props.handleChange}
                required
                rows={2}
                value={
                  this.props.selectedTreatmentTypeData
                    .text_notification_wording || ""
                }
                width={16}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                error={errorFields.includes("text_notification_timeframe")}
                label={strings.form.label.textNotificationTimeframe}
                min="0"
                name="text_notification_timeframe"
                onChange={this.props.handleChange}
                onKeyDown={e => this.props.handleNumberOnlyInputKeyDown(e)} // TODO: key down event for preventing decimal
                required
                step="1"
                type="number"
                value={
                  this.props.selectedTreatmentTypeData
                    .text_notification_timeframe || ""
                }
                width={6}
              />

              {(this.props.mode === "edit" || this.props.mode === "create") && (
                <Form.Dropdown
                  label={strings.form.label.treatmentFrequency}
                  name="text_notification_timeframe_units"
                  onChange={this.props.handleChange}
                  options={timeUntilNextTreatmentOptions}
                  placeholder={strings.form.placeholder.selectFrequency}
                  selection
                  width={4}
                  value={
                    this.props.selectedTreatmentTypeData
                      .text_notification_timeframe_units || "Days"
                  }
                />
              )}
              {this.props.mode === "view" && (
                <Form.Input
                  label={strings.form.label.treatmentFrequency}
                  name="text_notification_timeframe_units"
                  placeholder={strings.form.placeholder.selectFrequency}
                  width={4}
                  value={
                    this.props.selectedTreatmentTypeData
                      .text_notification_timeframe_units || "Days"
                  }
                />
              )}
            </Form.Group>
          </div>
        </div>
      </>
    );

    return (
      <div className="treatmentContainer">
        {this.props.validationErrors.length > 0 && (
          <div className="errorMessageContainer">
            <Message
              compact
              error
              header={strings.form.label.errorHeader}
              list={this.props.validationErrors.map(error => {
                return error.error;
              })}
            />
          </div>
        )}
        <div className="pageHeader">{this.props.pageTitle}</div>
        <Form
          className="details"
          onSubmit={this.props.toggleConfirmSaveModalVisibility}
        >
          {this.props.mode === "view" && (
            <fieldset disabled>{treatmentForm}</fieldset>
          )}
          {this.props.mode !== "view" && <fieldset>{treatmentForm}</fieldset>}
        </Form>
        {this.props.mode === "view" && editActivationButtons}
        {this.props.mode === "edit" && cancelSaveButtons}
        {this.props.mode === "create" && cancelSaveButtons}
      </div>
    );
  };
}

TreatmentType.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedTreatmentTypeData: PropTypes.object.isRequired,
  //pageTitle: PropTypes.string.isRequired,
  //confirmDeleteModalOpen: PropTypes.bool.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  //toggleConfirmDeleteModalVisibility: PropTypes.func.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  //handleModeSwitch: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  handleNumberOnlyInputKeyDown: PropTypes.func.isRequired,
  addDoctor: PropTypes.func.isRequired,
  removeDoctor: PropTypes.func.isRequired
};

export default TreatmentType;
