import { strings } from "../";
import { regexes } from "../Regexes";

const dateRegex = new RegExp(regexes.dateRegex);

const newPatientRegistrationSchema = {
  first_name: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.first_name,
  },
  surname: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.surname,
  },
  date_of_birth: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.date_of_birth,
    validate: (field, data) => {
      if (dateRegex.test(data[field])) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason.wrongDateFormat;
      }
    },
  },
  email_address: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.email_address,
  },
  postal_address: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.postal_address,
  },
  postcode: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.postcode,
  },
  country: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.country,
  },
  town_or_city: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.townOrCity,
  },
  phone_number: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.phone_number,
  },
  mobile_phone_number: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.mobile_phone_number,
  },
  gp_name: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.gp_name,
  },
  gp_address: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.gp_address,
  },
  gp_contact_approval: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.gp_contact_consent,
  },
  communication_consent: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.communication_consent,
  },
  emergency_contact_name: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.emergency_contact_name,
  },
  emergency_contact_phone_number: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.emergency_contact_phone_number,
  },
  medical_conditions_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.medical_conditions_toggle,
    required: true,
  },
  medical_conditions: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.medical_conditions,
    requireIf: (data) => {
      return getRequiredDependent("medical_conditions_toggle", "Yes", data);
    },
    validate: (field, data) => {
      const atLeastOneOptionSelected = data[field]
        ? Object.keys(data[field]).some((item) => {
            return data[field][item].checked;
          })
        : false;
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },
  medical_conditions_other: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.medical_conditions_other,
    requireIf: (data) => {
      return (
        data.hasOwnProperty("medical_conditions") &&
        data.medical_conditions &&
        data.medical_conditions.other &&
        data.medical_conditions.other.checked === true
      );
    },
  },
  dermatological_diseases_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.dermatological_diseases_toggle,
    required: true,
  },
  dermatological_diseases: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.dermatological_diseases,
    requireIf: (data) => {
      return getRequiredDependent(
        "dermatological_diseases_toggle",
        "Yes",
        data
      );
    },
    validate: (field, data) => {
      const atLeastOneOptionSelected = data[field]
        ? Object.keys(data[field]).some((item) => {
            return data[field][item].checked;
          })
        : false;
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },
  dermatological_diseases_other: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.dermatological_diseases_other,
    requireIf: (data) => {
      return (
        data.hasOwnProperty("dermatological_diseases") &&
        data.dermatological_diseases &&
        data.dermatological_diseases.other &&
        data.dermatological_diseases.other.checked === true
      );
    },
  },
  neurological_conditions_toggle: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.neurological_conditions_toggle,
    required: true,
  },
  neurological_conditions: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.neurological_conditions,
    requireIf: (data) => {
      return getRequiredDependent(
        "neurological_conditions_toggle",
        "Yes",
        data
      );
    },
    validate: (field, data) => {
      const atLeastOneOptionSelected = data[field]
        ? Object.keys(data[field]).some((item) => {
            return data[field][item].checked;
          })
        : false;
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },
  neurological_conditions_other: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.neurological_conditions_other,
    requireIf: (data) => {
      return (
        data.hasOwnProperty("neurological_conditions") &&
        data.neurological_conditions &&
        data.neurological_conditions.other &&
        data.neurological_conditions.other.checked === true
      );
    },
  },
  regular_medications_toggle: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.regular_medications,
  },
  regular_medications: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.regular_medications_details,
    requireIf: (data) => {
      return getRequiredDependent("regular_medications_toggle", "Yes", data);
    },
  },
  supplements_toggle: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.supplements,
  },
  supplements: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.supplements_details,
    requireIf: (data) => {
      return getRequiredDependent("supplements_toggle", "Yes", data);
    },
  },
  drug_allergies_toggle: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.drug_allergies,
  },
  drug_allergies: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.drug_allergies_details,
    requireIf: (data) => {
      return getRequiredDependent("drug_allergies_toggle", "Yes", data);
    },
  },
  general_allergies_toggle: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.general_allergies,
  },
  general_allergies: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.general_allergies_details,
    requireIf: (data) => {
      return getRequiredDependent("general_allergies_toggle", "Yes", data);
    },
  },
  mental_health_disorders_toggle: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.mental_health_disorders,
  },
  mental_health_disorders: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.mental_health_disorder_details,
    requireIf: (data) => {
      return getRequiredDependent(
        "mental_health_disorders_toggle",
        "Yes",
        data
      );
    },
  },
  seen_psychiatrist: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.psychiatrist_seen,
    required: true,
  },
  psychiatric_medications_toggle: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.psychiatric_medications,
  },
  psychiatric_medications: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .psychiatric_medications_details,
    requireIf: (data) => {
      return getRequiredDependent(
        "psychiatric_medications_toggle",
        "Yes",
        data
      );
    },
  },
  smoked_previously: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.previously_smoked,
    required: true,
  },
  smokes_currently: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.smokes_currently,
    required: true,
  },
  uses_sunbeds: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.used_sunbeds,
    required: true,
  },
  gets_suntans: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.tans_face,
    required: true,
  },
  botox_previous_use: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.previous_botox_treatment,
    required: true,
  },
  botox_when_last_treated: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.last_botox_treatment,
    requireIf: (data) => {
      return getRequiredDependent("botox_previous_use", "Yes", data);
    },
    validate: (field, data) => {
      if (dateRegex.test(data[field])) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason.wrongDateFormat;
      }
    },
  },
  botox_number_of_past_treatments: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.botox_treatment_count,
    requireIf: (data) => {
      return getRequiredDependent("botox_previous_use", "Yes", data);
    },
  },
  botox_complications: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.botox_complications,
    requireIf: (data) => {
      return getRequiredDependent("botox_previous_use", "Yes", data);
    },
  },
  dermal_fillers_previous_use: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix
        .previous_dermal_filler_treatment,
    required: true,
  },
  dermal_fillers_when_last_treated: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.last_dermal_filler_treatment,
    requireIf: (data) => {
      return getRequiredDependent("dermal_fillers_previous_use", "Yes", data);
    },
    validate: (field, data) => {
      if (dateRegex.test(data[field])) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason.wrongDateFormat;
      }
    },
  },
  dermal_filler_types: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.dermal_filler_treatments,
    requireIf: (data) => {
      return getRequiredDependent("dermal_fillers_previous_use", "Yes", data);
    },
    validate: (field, data) => {
      const atLeastOneOptionSelected = Object.keys(data[field]).some((item) => {
        return data[field][item].checked;
      });
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },
  dermal_fillers_number_of_past_treatments: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.dermal_filler_treatment_count,
    requireIf: (data) => {
      return getRequiredDependent("dermal_fillers_previous_use", "Yes", data);
    },
  },
  dermal_fillers_complications: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.dermal_filler_complications,
    requireIf: (data) => {
      return getRequiredDependent("dermal_fillers_previous_use", "Yes", data);
    },
  },
  pregnancy: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.pregnancy,
    required: true,
  },
  breastfeeding: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.breastfeeding,
    required: true,
  },
  image_consent: {
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.image_consent,
    required: true,
    validate: (field, data) => {
      // Check that at least one consent option is selected and if it is checked to revoke
      // consent, don't allow other positive consent options to be checked
      const atLeastOneOptionSelected = Object.keys(data[field]).some((item) => {
        return data[field][item].checked;
      });
      if (atLeastOneOptionSelected === true) {
        if (data[field].hasOwnProperty("consent_share_images")) {
          if (data[field].consent_share_images.checked === true) {
            const numberSelectedOptions = Object.keys(data[field]).filter(
              (item) => {
                return data[field][item].checked;
              }
            ).length;
            if (numberSelectedOptions > 1) {
              return strings.form.validation.errorMessageReason
                .incompatibleImageConsent;
            }
          }
        }
        return true;
      } else {
        return strings.form.validation.errorMessageReason
          .requireAtLeastOneOption;
      }
    },
  },
  signature_data_url: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.signature,
    required: true,
    validate: (field, data) => {
      if (Object.keys(data[field]).length === 0) {
        return strings.form.validation.errorMessageReason.missingSignature;
      } else {
        return true;
      }
    },
  },
  date_patient_signed: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.date_patient_signed,
    validate: (field, data) => {
      if (dateRegex.test(data[field])) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason.wrongDateFormat;
      }
    },
  },
};

const getRequiredDependent = (requiredIfField, requiredIfValue, data) => {
  // a field is required if it's dependent field has a value and that value is not equal to requiredIfValue
  if (data.hasOwnProperty(requiredIfField)) {
    if (data[requiredIfField] === requiredIfValue) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export default newPatientRegistrationSchema;
