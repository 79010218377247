import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Icon, Button, Header, Grid } from "semantic-ui-react";
import "../css/TreatmentComponents.css";
import { strings } from "../../resources";
import { enums as roles } from "../../../App/resources/Enums";
import ShowIfAuthorised from "../../../App/components/ShowIfAuthorised";

class TableFunctions extends React.Component {
  render = () => {
    const handleMenuSelect = (path) => {
      this.props.history.push({
        pathname: path,
        state: {
          patientId: this.props.patientId,
          selectedPatientData: this.props.selectedPatientData,
          tab: "treatments",
          mode: "create",
        },
      });
    };

    return (
      <Grid columns={2} className="TableFunctions">
        <Grid.Column width={7} floated="left">
          <Header as="h1">
            {strings.header.treatmentList}
          </Header>
          <Dropdown
            className="allTreatmentsDropdown"
            placeholder="All Treatments"
            selection
            options={this.props.getAllTreatmentOptions()}
            onChange={(e, data) => {
              this.props.setTreatmentType(data.value);
            }}
          />
        </Grid.Column>
        <Grid.Column width={7} floated="right">
          <ShowIfAuthorised
            allowedRoles={[
              roles.role.PULSION,
              roles.role.ADMIN,
              roles.role.DOCTOR,
            ]}
          >
            <Button
              primary
              className="addTreatmentButton"
              onClick={() => handleMenuSelect(`/treatments/create`)}
            >
              <Icon name="building" />
              {strings.button.addTreatmentLabel}
            </Button>
            <div style={{ clear: "both" }} />
            <Button
              primary
              className="addFollowUpNoteButton"
              onClick={() =>
                handleMenuSelect(`/treatments/followUpNote/create`)
              }
            >
              <Icon name="building" />
              {strings.button.addFollowUpNoteLabel}
            </Button>
          </ShowIfAuthorised>
        </Grid.Column>
      </Grid>
    );
  };
}

TableFunctions.propTypes = {
  history: PropTypes.object.isRequired,
  setTreatmentType: PropTypes.func.isRequired,
  getAllTreatmentOptions: PropTypes.func.isRequired,
  selectedPatientData: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
};

export default TableFunctions;
