import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Divider, Icon, Image, Menu, Sidebar } from "semantic-ui-react";
import { enums } from "../../../resources/Enums";
import ShowIfAuthorised from "../../ShowIfAuthorised";
import drdmLogo from "../../../assets/images/logo.png";
import "./Sidebar.css";

const MobileSidebar = props => (
  <Sidebar
    as={Menu}
    animation="overlay"
    className="mobileSidebar"
    inverted
    //onHide={props.toggleSidebar}
    vertical
    visible={props.sidebarOpened}
  >
    <Icon
      name="sidebar"
      size="large"
      className="hamburger"
      onClick={props.toggleSidebar}
    />
    <Image src={drdmLogo} size="tiny" />
    <Divider hidden />

    <ShowIfAuthorised allowedRoles={[enums.role.PULSION]}>
      <Menu.Item>
        <Link to="/users" className="item">
          Users
        </Link>
      </Menu.Item>
    </ShowIfAuthorised>

    <ShowIfAuthorised
      allowedRoles={[
        enums.role.PULSION,
        enums.role.ADMIN,
        enums.role.DOCTOR,
        enums.role.RECEPTIONIST
      ]}
    >
      <Menu.Item>
        <Link to="/patients" className="item">
          Patients
        </Link>
      </Menu.Item>

      <Menu.Item>
        <Link to="/forms" className="item">
          Form Templates
        </Link>
      </Menu.Item>
    </ShowIfAuthorised>

    <ShowIfAuthorised allowedRoles={[enums.role.PULSION, enums.role.ADMIN]}>
      <Menu.Item>
        <Link to="/treatmentTypes" className="item">
          Treatment Types
        </Link>
      </Menu.Item>
    </ShowIfAuthorised>

    <ShowIfAuthorised
      allowedRoles={[enums.role.PULSION, enums.role.ADMIN, enums.role.DOCTOR]}
    >
      <Menu.Item>
        <Link to="/products" className="item">
          Products
        </Link>
      </Menu.Item>
    </ShowIfAuthorised>
  </Sidebar>
);

MobileSidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired,
  role: PropTypes.string
};

export default MobileSidebar;
