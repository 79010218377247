import { API } from "../../services";
import { productActionTypes } from "./product.actionTypes";
import { addLowercaseDetails } from "../../helpers";

const api = new API();

const createProduct = (details, headers) => dispatch =>
  new Promise(function(resolve, reject) {
    const request = details => {
      return { type: productActionTypes.CREATE_PRODUCT_REQUEST, details };
    };
    const success = data => {
      return { type: productActionTypes.CREATE_PRODUCT_SUCCESS, data };
    };
    const failure = error => {
      return { type: productActionTypes.CREATE_PRODUCT_FAILURE, error };
    };

    api.setHeaders({ ...headers, systemHeader_subsysref: "createProduct" });

    const lowercasedDetails = addLowercaseDetails(details);

    dispatch(request(lowercasedDetails));

    api.createProduct(lowercasedDetails).then(
      response => {
        dispatch(success(response));
        dispatch(getProducts(headers));
        resolve();
      },
      error => {
        dispatch(failure(error));
        reject(error);
      }
    );
  });

const updateProduct = (id, details, headers) => dispatch => {
  return new Promise(function(resolve, reject) {
    const request = details => {
      return { type: productActionTypes.UPDATE_PRODUCT_REQUEST, details };
    };
    const success = data => {
      return { type: productActionTypes.UPDATE_PRODUCT_SUCCESS, data };
    };
    const failure = error => {
      return { type: productActionTypes.UPDATE_PRODUCT_FAILURE, error };
    };

    api.setHeaders({ ...headers, systemHeader_subsysref: "updateProduct" });

    dispatch(request(details));

    api.updateProduct(id, details).then(
      response => {
        dispatch(success(response));
        resolve();
      },
      error => {
        dispatch(failure(error));
        reject(error);
      }
    );
  });
};

const deactivateProduct = (id, headers) => dispatch => {
  return new Promise(function(resolve, reject) {
    const request = () => {
      return { type: productActionTypes.DEACTIVATE_PRODUCT_REQUEST };
    };
    const success = data => {
      return { type: productActionTypes.DEACTIVATE_PRODUCT_SUCCESS, data };
    };
    const failure = error => {
      return { type: productActionTypes.DEACTIVATE_PRODUCT_FAILURE, error };
    };

    api.setHeaders({ ...headers, systemHeader_subsysref: "deactivateProduct" });

    dispatch(request());

    if (id !== "") {
      api.deactivateProduct(id).then(
        response => {
          dispatch(success(response));
          resolve();
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    }
  });
};

const activateProduct = (id, headers) => dispatch => {
  return new Promise(function(resolve, reject) {
    const request = () => {
      return { type: productActionTypes.ACTIVATE_PRODUCT_REQUEST };
    };
    const success = data => {
      return { type: productActionTypes.ACTIVATE_PRODUCT_SUCCESS, data };
    };
    const failure = error => {
      return { type: productActionTypes.ACTIVATE_PRODUCT_FAILURE, error };
    };

    api.setHeaders({ ...headers, systemHeader_subsysref: "activateProduct" });

    dispatch(request());

    if (id !== "") {
      api.activateProduct(id).then(
        response => {
          dispatch(success(response));
          resolve();
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    }
  });
};

const getProducts = (headers, active, expiresAfter) => {
  const request = () => {
    return { type: productActionTypes.GET_PRODUCTS_REQUEST };
  };
  const success = data => {
    return { type: productActionTypes.GET_PRODUCTS_SUCCESS, data };
  };
  const failure = error => {
    return { type: productActionTypes.GET_PRODUCTS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getProducts" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .getProducts(active, expiresAfter)
        .then(response => {
          dispatch(success(response.data));
          resolve(response.data);
        })
        .catch(error => {
          dispatch(failure(error));
          reject(error);
        });
    });
  };
};

const getProductById = (id, headers) => {
  const request = () => {
    return { type: productActionTypes.GET_PRODUCT_BY_ID_REQUEST };
  };
  const success = data => {
    return { type: productActionTypes.GET_PRODUCT_BY_ID_SUCCESS, data };
  };
  const failure = error => {
    return { type: productActionTypes.GET_PRODUCT_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getProductById" });

  return async dispatch => {
    dispatch(request());
 
    await api
      .getProductById(id)
      .then(response => {
        dispatch(success(response.data));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };
};

const updateSelectedProduct = (key, value) => {
  const request = (key, value) => {
    return {
      type: productActionTypes.UPDATE_SELECTED_PRODUCT_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: productActionTypes.UPDATE_SELECTED_PRODUCT_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: productActionTypes.UPDATE_SELECTED_PRODUCT_FAILURE };
  };
  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedProduct = () => {
  const request = () => {
    return { type: productActionTypes.CLEAR_SELECTED_PRODUCT_REQUEST };
  };
  const success = () => {
    return { type: productActionTypes.CLEAR_SELECTED_PRODUCT_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

export const productActions = {
  createProduct,
  updateProduct,
  deactivateProduct,
  activateProduct,
  getProductById,
  getProducts,
  updateSelectedProduct,
  clearSelectedProduct
};
