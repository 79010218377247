import React from "react";
import ReactTable from "react-table";
import { Icon, Popup } from "semantic-ui-react";
import { TableFunctions } from "./ui";
import { strings } from "../resources";
import PropTypes from "prop-types";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      if (column.Header !== "Actions") {
        return {
          onClick: () => {
            if (rowInfo) {
              this.props.history.push("/patients/view/" + rowInfo.original.id);
            }
          }
        };
      }
    }
    return {}; // getTdProps requires a return statement
  };

  render = () => {
    let data = this.props.patientListData;

    return (
      <div>
        <TableFunctions
          fetchPatientData={this.props.fetchPatientData}
          loadingPatients={this.props.loadingPatients}
          headers={this.props.headers}
          history={this.props.history}
          patientFillsInForm={this.props.patientFillsInForm}
          toggleFormFillerChange={this.props.toggleFormFillerChange}
        />
        <div>
          <ReactTable
            data={data}
            columns={[
              {
                Header: strings.table.header.firstName,
                accessor: "first_name",
                headerStyle: { textAlign: "left" }
              },
              {
                Header: strings.table.header.surname,
                accessor: "surname",
                headerStyle: { textAlign: "left" }
              },
              {
                Header: strings.table.header.dateOfBirth,
                accessor: "date_of_birth",
                headerStyle: { textAlign: "left" }
              },
              {
                Header: strings.table.header.emailAddress,
                accessor: "email_address",
                headerStyle: { textAlign: "left" }
              },
              {
                Header: strings.table.header.phoneNumber,
                accessor: "phone_number",
                headerStyle: { textAlign: "left" }
              },
              {
                Header: strings.table.header.address,
                accessor: "postal_address",
                headerStyle: { textAlign: "left" }
              },
              {
                Header: strings.table.header.postcode,
                accessor: "postcode",
                headerStyle: { textAlign: "left" }
              },
              {
                Header: strings.table.header.status,
                accessor: "status",
                width: 60,
                sortable: false,
                headerStyle: { textAlign: "center" },
                Cell: row => (
                  <div style={{ textAlign: "center" }}>
                    <Icon
                      name={row.original.active === true ? "check" : "close"}
                      size="big"
                    />
                  </div>
                )
              },
              {
                Header: strings.table.header.actions,
                width: 60,
                sortable: false,
                headerStyle: { textAlign: "center" },
                Cell: row => (
                  <div style={{ textAlign: "center" }}>
                    <Popup
                      trigger={
                        <Icon
                          name="edit outline"
                          size="big"
                          className="iconButton"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/patients/view/" + row.original.id,
                              state: {
                                mode: "edit"
                              }
                            });
                          }}
                        />
                      }
                      content={`Edit ${row.original.first_name} ${
                        row.original.surname
                      }`}
                    />
                  </div>
                )
              }
            ]}
            className="-striped -highlight"
            defaultPageSize={10}
            getTdProps={this.getTdProps}
            minRows="1"
            loading={this.props.loadingPatients}
            noDataText="No patients found."
            pageSizeOptions={[10, 20, 30]}
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  patientListData: PropTypes.array.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedFormId: PropTypes.string
};

export default TableComponent;
