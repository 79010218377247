import React from "react";
import PropTypes from "prop-types";
import { Icon, Table } from "semantic-ui-react";

const DoctorsAndPricesTable = props => (
  <Table celled columns={2}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell className={props.error ? "errorLabel doctorHeader" : "doctorHeader"}>Doctor</Table.HeaderCell>
        <Table.HeaderCell className={props.error ? "errorLabel priceHeader" : "priceHeader"}>Price (UK Pounds)</Table.HeaderCell>
        {(props.mode === "create" || props.mode === "edit") && (
          <Table.HeaderCell className="removeHeader">Remove</Table.HeaderCell>
        )}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.doctorsAndPrices.map(item => (
        <Table.Row key={item.doctor}>
          <Table.Cell className="doctorCell">{item.doctor}</Table.Cell>
          <Table.Cell className="priceCell">{item.price}</Table.Cell>

          {(props.mode === "create" || props.mode === "edit") && (
            <Table.Cell className="removeCell">
              <Icon
                name="minus circle"
                size="large"
                onClick={() => props.removeDoctor(item.doctor)}
              />
            </Table.Cell>
          )}
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

DoctorsAndPricesTable.protoTypes = {
  mode: PropTypes.string.isRequired,
  doctorsAndPrices: PropTypes.array.isRequired,
  removeDoctor: PropTypes.func.isRequired
};

export default DoctorsAndPricesTable;
