import React from "react";
import PropTypes from "prop-types";
import TreatmentList from "../../components/TreatmentList";
import { connect } from "react-redux";
import { treatmentActions } from "../../store/actions/treatment.actions";
import { dateFormatter } from "../../helpers";
import "./css/TreatmentList.css";

class TreatmentListContainer extends React.Component {
  state = {
    treatmentType: null
  };

  componentDidMount = async () => {
    this.setTreatmentType(this.state.treatmentType);
  };

  setTreatmentType = async treatmentType => {
    this.setState({
      treatmentType: treatmentType
    });
    this.props.getTreatments(
      this.props.patientId,
      this.props.headers,
      treatmentType
    );
  };

  render = () => {
    let data = this.props.treatmentListData.map(treatment => {
      if (treatment["created_date"]) {
        treatment[
          "created_date_formatted"
        ] = dateFormatter.isoToFormattedDateTime(treatment["created_date"]);
      }
      if (
        treatment["period_until_next_treatment"] &&
        treatment["period_until_next_treatment_units"]
      ) {
        treatment["combined_time_until_next_treatment"] =
          treatment["period_until_next_treatment"] +
          " " +
          treatment["period_until_next_treatment_units"];
      }
      return treatment;
    });

    return (
      <div className="clientList">
        <TreatmentList
          headers={this.props.headers}
          history={this.props.history}
          treatmentListData={data}
          setTreatmentType={this.setTreatmentType}
          selectedTreatmentType={this.state.treatmentType}
          patientId={this.props.patientId}
          selectedPatientData={this.props.selectedPatientData}
          loadingPage={this.props.loadingPage}
        />
      </div>
    );
  };
}

TreatmentListContainer.propTypes = {
  treatmentListData: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getTreatments: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  const { treatments } = state;
  const { treatmentListData, loadingPage } = treatments.treatments;
  return {
    treatmentListData,
    loadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTreatments: (patientId, headers) => {
      dispatch(treatmentActions.getTreatments(patientId, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreatmentListContainer);
