import { strings } from "../";
import { regexes } from "../Regexes";

const dateRegex = new RegExp(regexes.dateRegex);

const aestheticInterestSchema = {
  reasons: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.reasons,
    required: true,
    validate: (field, data) => {
      const atLeastOneOptionSelected = Object.keys(data[field]).some(item => {
        return data[field][item].checked;
      });
      if (atLeastOneOptionSelected === true) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason.requireAtLeastOneOption;
      }
    }
  },
  reasons_other: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.other_reason_details,
    requireIf: data => {
      return (data.hasOwnProperty("reasons") && data.reasons.other && data.reasons.other.checked === true);
    }
  },
  bothered_by_appearance: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.bothered_by_appearance,
    required: true
  },
  how_often_appearance: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.how_often_appearance,
    required: true
  },
  leave_house_toggle: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.leave_house_toggle,
    required: true
  },
  avoids_social_situations_toggle: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.avoids_social_situations_toggle,
    required: true
  },
  avoids_photographs_toggle: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.avoids_photographs_toggle,
    required: true
  },
  avoids_certain_angles_toggle: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.avoids_certain_angles_toggle,
    required: true
  },
  wears_makeup_toggle: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.wears_makeup_toggle,
    required: true
  },
  signed_by_patient: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.signed_by_patient
  },
  signature_data_url: {
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.signature,
    required: true,
    validate: (field, data) => {
      if (Object.keys(data[field]).length === 0) {
        return strings.form.validation.errorMessageReason.missingSignature;
      } else {
        return true;
      }
    }
  },
  date_patient_signed: {
    required: true,
    errorMessagePrefix:
      strings.form.validation.errorMessagePrefix.date_patient_signed,
    validate: (field, data) => {
      if (dateRegex.test(data[field])) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason.wrongDateFormat;
      }
    }
  }
};

export default aestheticInterestSchema;
