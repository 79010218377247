import { combineReducers } from "redux";
import treatmentReducer from "./treatment.reducer";
import treatmentTypeReducer from "./treatmentType.reducer";

const treatmentsReducer = combineReducers({
  treatments: treatmentReducer,
  treatmentTypes: treatmentTypeReducer
});

export default treatmentsReducer;
