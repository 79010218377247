export const treatmentTypeActionTypes = {
  CREATE_TREATMENT_TYPE_REQUEST: "CREATE_TREATMENT_TYPE_REQUEST",
  CREATE_TREATMENT_TYPE_SUCCESS: "CREATE_TREATMENT_TYPE_SUCCESS",
  CREATE_TREATMENT_TYPE_FAILURE: "CREATE_TREATMENT_TYPE_FAILURE",

  DEACTIVATE_TREATMENT_TYPE_REQUEST: "DEACTIVATE_TREATMENT_TYPE_REQUEST",
  DEACTIVATE_TREATMENT_TYPE_SUCCESS: "DEACTIVATE_TREATMENT_TYPE_SUCCESS",
  DEACTIVATE_TREATMENT_TYPE_FAILURE: "DEACTIVATE_TREATMENT_TYPE_FAILURE",

  ACTIVATE_TREATMENT_TYPE_REQUEST: "ACTIVATE_TREATMENT_TYPE_REQUEST",
  ACTIVATE_TREATMENT_TYPE_SUCCESS: "ACTIVATE_TREATMENT_TYPE_SUCCESS",
  ACTIVATE_TREATMENT_TYPE_FAILURE: "ACTIVATE_TREATMENT_TYPE_FAILURE",

  UPDATE_SELECTED_TREATMENT_TYPE_REQUEST:
    "UPDATE_SELECTED_TREATMENT_TYPE_REQUEST",
  UPDATE_SELECTED_TREATMENT_TYPE_SUCCESS:
    "UPDATE_SELECTED_TREATMENT_TYPE_SUCCESS",
  UPDATE_SELECTED_TREATMENT_TYPE_FAILURE:
    "UPDATE_SELECTED_TREATMENT_TYPE_FAILURE",

  CLEAR_SELECTED_TREATMENT_TYPE_REQUEST:
    "CLEAR_SELECTED_TREATMENT_TYPE_REQUEST",
  CLEAR_SELECTED_TREATMENT_TYPE_SUCCESS:
    "CLEAR_SELECTED_TREATMENT_TYPE_SUCCESS",
  CLEAR_SELECTED_TREATMENT_TYPE_FAILURE:
    "CLEAR_SELECTED_TREATMENT_TYPE_FAILURE",

  GET_TREATMENT_TYPES_REQUEST: "GET_TREATMENT_TYPES_REQUEST",
  GET_TREATMENT_TYPES_SUCCESS: "GET_TREATMENT_TYPES_SUCCESS",
  GET_TREATMENT_TYPES_FAILURE: "GET_TREATMENT_TYPES_FAILURE",

  UPDATE_TREATMENT_TYPE_REQUEST: "UPDATE_TREATMENT_TYPE_REQUEST",
  UPDATE_TREATMENT_TYPE_SUCCESS: "UPDATE_TREATMENT_TYPE_SUCCESS",
  UPDATE_TREATMENT_TYPE_FAILURE: "UPDATE_TREATMENT_TYPE_FAILURE",

  GET_TREATMENT_TYPE_BY_ID_REQUEST: "GET_TREATMENT_TYPE_BY_ID_REQUEST",
  GET_TREATMENT_TYPE_BY_ID_SUCCESS: "GET_TREATMENT_TYPE_BY_ID_SUCCESS",
  GET_TREATMENT_TYPE_BY_ID_FAILURE: "GET_TREATMENT_TYPE_BY_ID_FAILURE"
};
