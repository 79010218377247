import React from "react";
import TableComponent from "../../components/UserTable";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { userActions } from "../../store/actions/user.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import "./css/UsersTable.css";

class UserListContainer extends React.Component {
  state = {
    deleteModalOpen: false
  };

  componentDidMount = async () => {
    //this.props.hideAllTableModals();
    if (this.props.userListData[0]) {
      //if (Object.keys(this.props.userListData[0]).length === 0) {
      await this.props.getUsers(this.props.headers);
      //}
    }
  };

  componentDidUpdate = async () => {
    if (this.props.userListData[0]) {
      //if (Object.keys(this.props.userListData[0]).length === 0) {
      //await this.props.getUsers(this.props.headers);
      //}
    }
  };

  handleDelete = () => {
    this.props.deleteUser(this.props.userToDelete, this.props.headers);
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "deleteUserModal":
        this.handleDelete();
        break;

      default:
        break;
    }
  };

  toggleConfirmDeleteModalVisibility = () => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen
    });
  };

  render = () => {
    return (
      <div>
        <div style={{ display: this.props.loadingPage ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          className="clientList"
          style={{ display: this.props.loadingPage ? "none" : "" }}
        >
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            userListData={this.props.userListData}
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            deleteModalOpen={this.state.deleteModalOpen}
            handleModalConfirm={this.handleModalConfirm}
          />
        </div>
      </div>
    );
  };
}

UserListContainer.propTypes = {
  userListData: PropTypes.array.isRequired,
  userToDelete: PropTypes.string.isRequired,
  deleteUser: PropTypes.func.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getUsers: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { users } = state;
  const { userListData, loadingPage, userToDelete } = users;
  return {
    userListData,
    loadingPage,
    userToDelete
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUsers: headers => {
      dispatch(userActions.getUsers(headers));
    },
    deleteUser: (id, headers) => {
      dispatch(userActions.deleteUser(id, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListContainer);
