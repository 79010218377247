import React from "react";
import PropTypes from "prop-types";
import { List } from "semantic-ui-react";
import { strings } from "../../resources/Strings";
import {
  checkNumberResponse,
  checkDateResponse,
  checkGroupResponse,
  checkYesNoResponse
} from "../../helpers/checkUserResponseHelpers";

const TreatmentHistoryView = props => {
  const data = props.selectedPatientData;

  return (
    <div className="viewModeMedicalInfo">
      <List relaxed>
        <List.Item>
          <span className="infoLabel">Botox Treatment</span>
          {checkYesNoResponse(
            data,
            "botox_previous_use",
            `Patient has been treated ${checkNumberResponse(
              data,
              "botox_number_of_past_treatments"
            )} times in the past. The most recent occasion was ${checkDateResponse(
              data,
              "botox_when_last_treated"
            )}. ${checkYesNoResponse(
              data,
              "botox_complications",
              strings.patientRecord.mappings.botoxComplications,
              ""
            )}.`,
            strings.patientRecord.mappings.noBotoxHistory
          )}
        </List.Item>
        <List.Item>
          <span className="infoLabel">Dermal Filler History</span>
          {checkYesNoResponse(
            data,
            "dermal_fillers_previous_use",
            `Patient has been treated ${checkNumberResponse(
              data,
              "dermal_fillers_number_of_past_treatments"
            )} times in the past. The most recent occasion was ${checkDateResponse(
              data,
              "dermal_fillers_when_last_treated"
            )}. ${checkGroupResponse(
              data,
              "dermal_filler_types",
              strings.patientRecord.mappings.dermalFillersPrefix,
              strings.patientRecord.mappings.dermalFillersUnknown,
              "lower"
            )}. ${checkYesNoResponse(
              data,
              "dermal_fillers_complications",
              strings.patientRecord.mappings.dermalFillerComplications,
              ""
            )} `,
            strings.patientRecord.mappings.noDermalFillerHistory
          )}
        </List.Item>
        <List.Item>
          <span className="infoLabel">
            {strings.patientRecord.header.previousProcedures}
          </span>
          {checkGroupResponse(
            data,
            "previous_procedures",
            strings.patientRecord.mappings.previousProceduresPrefix,
            strings.patientRecord.mappings.noPreviousProcedures,
            "lower"
          )}
        </List.Item>
      </List>
    </div>
  );
};

TreatmentHistoryView.protoTypes = {
  selectedPatientData: PropTypes.object.isRequired
};

export default TreatmentHistoryView;
