import axios from "./axios-users";
import axiosPublic from "./axios-public-users";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = (headers) => {
    this.headers = headers;
  };

  createUser = (details) => {
    const config = {
      headers: this.headers,
    };
    return axios
      .post("", JSON.stringify(details), config)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log("Error creating user: ", error.message));
  };

  updateUser = (id, details) => {
    const config = {
      headers: this.headers,
    };
    return axios
      .put(`/${id}`, JSON.stringify(details), config)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log("Error updating user: ", error.message));
  };

  deleteUser = (id) => {
    const config = {
      headers: this.headers,
    };
    return axios
      .delete(`/${id}`, null, config)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log("Error deleting user: ", error.message));
  };

  getUsers = () => {
    const config = {
      headers: this.headers,
    };
    return axios
      .get("", config)
      .catch((error) => console.log("Error getting users: ", error.message));
  };

  getUserById = (id) => {
    const config = {
      headers: this.headers,
    };
    return axios
      .get(`/${id}`, config)
      .catch((error) => console.log("Error getting user: ", error.message));
  };

  getPatientCredentials = () => {
    const config = {
      headers: this.headers,
    };
    return axios
      .get(`/patientCredentials`, config)
      .catch((error) =>
        console.log("Error getting patient credentials: ", error.message)
      );
  };

  createPatientLoginLink = (details) => {
    const config = {
      headers: this.headers,
    };
    console.log("DETAILS:", details);
    return axios
      .post(`/patientLoginLinks`, JSON.stringify(details), config)
      .catch((error) =>
        console.log("Error creating patient login link: ", error.message)
      );
  };

  checkPatientLoginLink = (details) => {
    const config = {
      headers: this.headers,
    };

    let linkId = details.link_id;
    details.link_id = undefined;

    return axiosPublic.post(
      `/patientLoginLinks/${linkId}`,
      JSON.stringify(details),
      config
    );
  };

  checkLoginLinkForExistingPatient = (id) => {
    const config = {
      headers: this.headers,
    };
    return axiosPublic
      .get(`/patientLoginLinks/${id}`, config)
      .catch((error) =>
        console.log(
          "Error checking if patient login link is for an existing patient: ",
          error.message
        )
      );
  };
}
