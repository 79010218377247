export const productActionTypes = {
  CREATE_PRODUCT_REQUEST: "CREATE_PRODUCT_REQUEST",
  CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_FAILURE: "CREATE_PRODUCT_FAILURE",

  UPDATE_PRODUCT_REQUEST: "UPDATE_PRODUCT_REQUEST",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAILURE: "UPDATE_PRODUCT_FAILURE",

  DEACTIVATE_PRODUCT_REQUEST: "DEACTIVATE_PRODUCT_REQUEST",
  DEACTIVATE_PRODUCT_SUCCESS: "DEACTIVATE_PRODUCT_SUCCESS",
  DEACTIVATE_PRODUCT_FAILURE: "DEACTIVATE_PRODUCT_FAILURE",

  ACTIVATE_PRODUCT_REQUEST: "ACTIVATE_PRODUCT_REQUEST",
  ACTIVATE_PRODUCT_SUCCESS: "ACTIVATE_PRODUCT_SUCCESS",
  ACTIVATE_PRODUCT_FAILURE: "ACTIVATE_PRODUCT_FAILURE",

  GET_PRODUCTS_REQUEST: "GET_PRODUCTS_REQUEST",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",

  GET_PRODUCT_BY_ID_REQUEST: "GET_PRODUCT_BY_ID_REQUEST",
  GET_PRODUCT_BY_ID_SUCCESS: "GET_PRODUCT_BY_ID_SUCCESS",
  GET_PRODUCT_BY_ID_FAILURE: "GET_PRODUCT_BY_ID_FAILURE",

  UPDATE_SELECTED_PRODUCT_REQUEST: "UPDATE_SELECTED_PRODUCT_REQUEST",
  UPDATE_SELECTED_PRODUCT_SUCCESS: "UPDATE_SELECTED_PRODUCT_SUCCESS",
  UPDATE_SELECTED_PRODUCT_FAILURE: "UPDATE_SELECTED_PRODUCT_FAILURE",

  CLEAR_SELECTED_PRODUCT_REQUEST: "CLEAR_SELECTED_PRODUCT_REQUEST",
  CLEAR_SELECTED_PRODUCT_SUCCESS: "CLEAR_SELECTED_PRODUCT_SUCCESS",
  CLEAR_SELECTED_PRODUCT_FAILURE: "CLEAR_SELECTED_PRODUCT_FAILURE"
};
