import React from 'react'
import { Form, Message } from 'semantic-ui-react'

const PatientDataField = (props) => {
  if (props.value) {
    return (
      <Form.Group>
        <Message
          className="patientDataField"
          info
          header={props.label}
          content={props.value}
        />
      </Form.Group>
    )
  } else {
    return null
  }
}

export default PatientDataField