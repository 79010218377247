import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import "./css/SemanticModal.css";
import { strings } from "../../resources";
import PropTypes from "prop-types";

class BasicModal extends React.Component {
  handleClose = () => {
    this.props.toggleModal();
    if (this.props.handleModalCancel) {
      this.props.handleModalCancel(this.props.modalClassName);
    }
  };

  handleSubmit = () => {
    this.props.toggleModal();
    this.props.handleModalConfirm(this.props.modalClassName);
  };

  render = () => {
    return (
      <Modal
        className={this.props.modalClassName}
        open={this.props.modalOpen}
        onClose={this.handleClose}
      >
        <Modal.Content className="modalContent">
          <h3>{this.props.confirmMessage}</h3>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            onClick={this.handleClose}
            style={{ display: this.props.showNoButton ? "" : "none" }}
          >
            <Icon name="remove" /> {strings.button.noLabel}
          </Button>
          <Button
            basic
            color="green"
            onClick={this.handleSubmit}
            style={{ display: this.props.showYesButton ? "" : "none" }}
          >
            <Icon name="checkmark" /> {strings.button.yesLabel}
          </Button>
          <Button
            basic
            color="green"
            onClick={this.handleClose}
            style={{ display: this.props.showOkButton ? "" : "none" }}
          >
            <Icon name="checkmark" /> {strings.button.okLabel}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

BasicModal.propTypes = {
  confirmMessage: PropTypes.string.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  handleModalCancel: PropTypes.func,
  modalClassName: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  showNoButton: PropTypes.bool.isRequired,
  showOkButton: PropTypes.bool.isRequired,
  showYesButton: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default BasicModal;
