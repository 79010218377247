import { patientActionTypes } from "../actions/patient.actionTypes";

const initialState = {
  patientListData: [{}],
  selectedPatientData: {},
  selectedPatientHistoryData: {},
  pendingPatientUpdate: {},
  selectedTab: {},
  searchCriteria: "",
  loadingPage: false,
  isUploading: false,
  uploadMessage: {},
  response: {},
  patientId: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case patientActionTypes.GET_PATIENT_BY_ID_REQUEST:
      return { ...state, loadingPage: true, response: null };
    case patientActionTypes.GET_PATIENT_BY_ID_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        selectedPatientData: action.payload
      };
    case patientActionTypes.GET_PATIENT_BY_ID_FAILURE:
      return {
        ...state,
        loadingPage: false,
        response: action.payload
      };

    case patientActionTypes.GET_PATIENT_HISTORY_BY_ID_REQUEST:
      return { ...state, loadingPage: true, response: null };
    case patientActionTypes.GET_PATIENT_HISTORY_BY_ID_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        selectedPatientHistoryData: action.payload
      };
    case patientActionTypes.GET_PATIENT_HISTORY_BY_ID_FAILURE:
      return {
        ...state,
        loadingPage: false,
        response: action.payload
      };

    case patientActionTypes.GET_PATIENTS_REQUEST:
      return { ...state, loadingPage: true, response: null };
    case patientActionTypes.GET_PATIENTS_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        patientListData: action.payload
      };
    case patientActionTypes.GET_PATIENTS_FAILURE:
      return { ...state, loadingPage: false, response: action.type };

    case patientActionTypes.CREATE_PATIENT_REQUEST:
      return { ...state, loadingPage: true, response: null };
    case patientActionTypes.CREATE_PATIENT_SUCCESS:
      return { ...state, loadingPage: false, response: action.payload };
    case patientActionTypes.CREATE_PATIENT_FAILURE:
      return { ...state, loadingPage: false, response: action.payload };

    case patientActionTypes.UPDATE_PATIENT_REQUEST:
      return { ...state, loadingPage: true, response: null };
    case patientActionTypes.UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        response: action.payload,
        pendingPatientUpdate: {}
      };
    case patientActionTypes.UPDATE_PATIENT_FAILURE:
      return { ...state, loadingPage: false, response: action.payload };

    case patientActionTypes.ACTIVATE_PATIENT_REQUEST:
      return { ...state, response: null };
    case patientActionTypes.ACTIVATE_PATIENT_SUCCESS:
      return { ...state, response: action.payload };
    case patientActionTypes.ACTIVATE_PATIENT_FAILURE:
      return { ...state, response: action.payload };

    case patientActionTypes.DEACTIVATE_PATIENT_REQUEST:
      return { ...state, response: null };
    case patientActionTypes.DEACTIVATE_PATIENT_SUCCESS:
      return { ...state, response: action.payload };
    case patientActionTypes.DEACTIVATE_PATIENT_FAILURE:
      return { ...state, response: action.payload };

    case patientActionTypes.APPLY_DOCTOR_SIGNATURE_REQUEST:
      return { ...state, response: null };
    case patientActionTypes.APPLY_DOCTOR_SIGNATURE_SUCCESS:
      return {
        ...state,
        response: action.payload,
        selectedPatientData: {
          ...state.selectedPatientData,
          doctor_signature_to_be_updated: false
        }
      };
    case patientActionTypes.APPLY_DOCTOR_SIGNATURE_FAILURE:
      return { ...state, response: action.payload };

    case patientActionTypes.DELETE_PATIENT_REQUEST:
      return { ...state, loadingPage: true, response: null };
    case patientActionTypes.DELETE_PATIENT_SUCCESS:
      return { ...state, loadingPage: false, response: action.payload };
    case patientActionTypes.DELETE_PATIENT_FAILURE:
      return { ...state, loadingPage: false, response: action.payload };

    case patientActionTypes.UPDATE_PATIENT_LOCAL_DATA_REQUEST:
      return { ...state, response: null };
    case patientActionTypes.UPDATE_PATIENT_LOCAL_DATA_SUCCESS:
      return {
        ...state,
        selectedPatientData: action.payload,
        response: action.payload
      };
    case patientActionTypes.UPDATE_PATIENT_LOCAL_DATA_FAILURE:
      return { ...state, response: action.payload };

    case patientActionTypes.UPDATE_SELECTED_PATIENT_REQUEST:
      return { ...state, loadingPage: true };
    case patientActionTypes.UPDATE_SELECTED_PATIENT_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        selectedPatientData: {
          ...state.selectedPatientData,
          [action.key]: action.value
        },
        pendingPatientUpdate: {
          ...state.pendingPatientUpdate,
          [action.key]: action.value
        }
      };
    case patientActionTypes.UPDATE_SELECTED_PATIENT_FAILURE:
      return { ...state, loadingPage: false };

    case patientActionTypes.DELETE_FROM_SELECTED_PATIENT_REQUEST:
      return { ...state };
    case patientActionTypes.DELETE_FROM_SELECTED_PATIENT_SUCCESS:
      const newPatientData = Object.assign({}, state.selectedPatientData);
      delete newPatientData[action.key];
      return {
        ...state,
        selectedPatientData: newPatientData
      };
    case patientActionTypes.DELETE_FROM_SELECTED_PATIENT_FAILURE:
      return { ...state };

    case patientActionTypes.UPDATE_SELECTED_PATIENT_TAB_REQUEST:
      return { ...state };
    case patientActionTypes.UPDATE_SELECTED_PATIENT_TAB_SUCCESS:
      return {
        ...state,
        selectedTab: {
          patientId: action.patientId,
          tabNumber: action.tabNumber
        }
      };

    case patientActionTypes.CLEAR_SELECTED_PATIENT_REQUEST:
      return { ...state, loadingPage: true };
    case patientActionTypes.CLEAR_SELECTED_PATIENT_SUCCESS:
      return { ...state, loadingPage: false, selectedPatientData: {} };
    case patientActionTypes.CLEAR_SELECTED_PATIENT_FAILURE:
      return { ...state, loadingPage: false };

    default:
      return state;
  }
}
