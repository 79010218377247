import { strings } from "../resources/Strings";
import { checkGroupResponse } from "./checkUserResponseHelpers";

//Returns an array of strings corresponding to the patient's aesthetic concerns
export const formatExaminationNotesView = (data) => {
  let output = [];

  if (data.hasOwnProperty("examination_fitzpatrick_skin_type")) {
    let option = "";
    switch (data.examination_fitzpatrick_skin_type) {
      case strings.patientRecord.databaseValues.fitzpatrickSkinTypeI:
        option = strings.patientRecord.mappings.fitzpatrickSkinTypeI;
        break;

      case strings.patientRecord.databaseValues.fitzpatrickSkinTypeII:
        option = strings.patientRecord.mappings.fitzpatrickSkinTypeII;
        break;

      case strings.patientRecord.databaseValues.fitzpatrickSkinTypeIII:
        option = strings.patientRecord.mappings.fitzpatrickSkinTypeIII;
        break;

      case strings.patientRecord.databaseValues.fitzpatrickSkinTypeIV:
        option = strings.patientRecord.mappings.fitzpatrickSkinTypeIV;
        break;

      case strings.patientRecord.databaseValues.fitzpatrickSkinTypeV:
        option = strings.patientRecord.mappings.fitzpatrickSkinTypeV;
        break;

      case strings.patientRecord.databaseValues.fitzpatrickSkinTypeVI:
        option = strings.patientRecord.mappings.fitzpatrickSkinTypeVI;
        break;

      default:
        break;
    }
    if (option !== "") {
      output.push(option + ".");
    }
  }

  if (data.hasOwnProperty("examination_glogau_skin_group")) {
    let option = "";
    switch (data.examination_glogau_skin_group) {
      case strings.patientRecord.databaseValues.glogauSkinGroup1:
        option = strings.patientRecord.mappings.glogauSkinGroup1;
        break;

      case strings.patientRecord.databaseValues.glogauSkinGroup2:
        option = strings.patientRecord.mappings.glogauSkinGroup2;
        break;

      case strings.patientRecord.databaseValues.glogauSkinGroup3:
        option = strings.patientRecord.mappings.glogauSkinGroup3;
        break;

      case strings.patientRecord.databaseValues.glogauSkinGroup4:
        option = strings.patientRecord.mappings.glogauSkinGroup4;
        break;

      default:
        break;
    }
    if (option !== "") {
      output.push(option + ".");
    }
  }

  if (data.hasOwnProperty("examination_skin_condition")) {
    let option = "";
    switch (data.examination_skin_condition) {
      case strings.patientRecord.databaseValues.skinConditionGood:
        option = strings.patientRecord.mappings.goodSkinCondition;
        break;

      case strings.patientRecord.databaseValues.skinConditionMildDamage:
        option = strings.patientRecord.mappings.mildSkinCondition;
        break;

      case strings.patientRecord.databaseValues.skinConditionModerateDamage:
        option =
          strings.patientRecord.mappings.moderateSkinCondition +
          checkGroupResponse(
            data,
            "examination_skin_conditions",
            " with ",
            "",
            "lower"
          );
        break;

      case strings.patientRecord.databaseValues.skinConditionSevereDamage:
        option =
          strings.patientRecord.mappings.severeSkinCondition +
          checkGroupResponse(
            data,
            "examination_skin_conditions",
            " with ",
            "",
            "lower"
          );
        break;

      default:
        break;
    }
    if (option !== "") {
      output.push(option + ".");
    }
  }

  if (data.examination_smoking === "Yes") {
    output.push(
      checkGroupResponse(
        data,
        "examination_smoking_conditions",
        `${strings.patientRecord.mappings.signsOfSmoking}, including `,
        "",
        "lower"
      ) + "."
    );
  }

  if (data.examination_smoking === "No") {
    output.push(`${strings.patientRecord.mappings.noSignsOfSmoking}.`);
  }

  if (data.hasOwnProperty("examination_facial_volume")) {
    let option = "";
    switch (data.examination_facial_volume) {
      case strings.patientRecord.databaseValues.facialVolumeGood:
        option = strings.patientRecord.databaseValues.facialVolumeGood;
        break;

      case strings.patientRecord.databaseValues.facialVolumeGoodMinimal:
        option = strings.patientRecord.databaseValues.facialVolumeGoodMinimal;
        break;

      case strings.patientRecord.databaseValues
        .facialVolumeStartedDeteriorating:
        option =
          strings.patientRecord.databaseValues.facialVolumeStartedDeteriorating;
        break;

      case strings.patientRecord.databaseValues.facialVolumeDeteriorated:
        option = strings.patientRecord.databaseValues.facialVolumeDeteriorated;
        break;

      default:
        break;
    }
    if (option !== "") {
      output.push(option + ".");
    }

    if (
      data.examination_facial_volume !==
      strings.patientRecord.databaseValues.facialVolumeGood
    ) {
      let facialVolumeDetails = checkGroupResponse(
        data,
        "examination_facial_volume_details",
        `${strings.patientRecord.mappings.volumeLossAreas} `,
        "",
        "lower"
      );
      output.push(facialVolumeDetails + ".");
    }
  }

  if (data.examination_abnormal === "Yes") {
    output.push(
      checkGroupResponse(
        data,
        "examination_abnormal_conditions",
        "",
        "",
        "none",
        false,
        ". "
      ) + "."
    );
  }

  return output;
};
