export const patientCategories = {
  new: {
    eligibleForms: [
      {
        formType: "NEW_PATIENT_REGISTRATION",
        compulsory: true,
      },
      {
        formType: "AESTHETIC_INTEREST",
        compulsory: true,
      },
      {
        formType: "CONSENT_COVID",
        compulsory: false,
      },
      {
        formType: "CONSENT_BOTOX",
        compulsory: false,
      },
      {
        formType: "CONSENT_DERMAL_FILLERS",
        compulsory: false,
      },
      {
        formType: "CONSENT_UPPER_BLEPHAROPLASTY",
        compulsory: false,
      },
      {
        formType: "CONSENT_UPPER_LOWER_BLEPHAROPLASTY",
        compulsory: false,
      },
      {
        formType: "CONSENT_CO2RE",
        compulsory: false,
      },
      {
        formType: "CONSENT_PROFOUND",
        compulsory: false,
      },
      {
        formType: "CONSENT_FACE_AND_NECK",
        compulsory: false,
      },
    ],
  },
  returnAndFollowUp: {
    eligibleForms: [
      {
        formType: "FOLLOW_UP",
        compulsory: false,
      },
      {
        formType: "RETURNING_PATIENT",
        compulsory: false,
      },
      {
        formType: "AESTHETIC_INTEREST",
        compulsory: false,
      },
      {
        formType: "CONSENT_COVID",
        compulsory: false,
      },
      {
        formType: "CONSENT_BOTOX",
        compulsory: false,
      },
      {
        formType: "CONSENT_DERMAL_FILLERS",
        compulsory: false,
      },
      {
        formType: "CONSENT_UPPER_BLEPHAROPLASTY",
        compulsory: false,
      },
      {
        formType: "CONSENT_UPPER_LOWER_BLEPHAROPLASTY",
        compulsory: false,
      },
      {
        formType: "CONSENT_CO2RE",
        compulsory: false,
      },
      {
        formType: "CONSENT_PROFOUND",
        compulsory: false,
      },
      {
        formType: "CONSENT_FACE_AND_NECK",
        compulsory: false,
      },
    ],
  },
};
