import axios from "./axios-forms";

export class API {
  constructor() {
    this.headers = {};
    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createForm = (details, patientId) => {
    const config = {
      headers: this.headers
    };
    const requestBody = {
      patient_id: patientId,
      ...details
    };
    return axios.post("", JSON.stringify(requestBody), config);
  };

  deleteForm = (patientId, formId) => {
    const config = {
      headers: this.headers
    };
    const requestBody = {
      patient_id: patientId,
      form_id: formId
    };
    return axios
      .delete(`/${formId}`, JSON.stringify(requestBody), config)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log("Error deleting form: ", error.message));
  };

  getFormById = (formId, patientId) => {
    const config = {
      headers: this.headers
    };

    let queryString = "";
    if (patientId !== undefined && patientId !== null && patientId !== "") {
      queryString = `?patient_id=${patientId}`;
    }

    return axios.get(`/${formId}${queryString}`, config);
  };

  getForms = (patientId, formType, pageSize, lastKey = null) => {
    const config = {
      headers: this.headers
    };

    let queryStringComponents = [];
    if (patientId !== undefined && patientId !== null && patientId !== "") {
      queryStringComponents.push(`patient_id=${patientId}`);
    }
    if (formType !== undefined && formType !== null && formType !== "") {
      queryStringComponents.push(`form_type=${formType}`);
    }
    if (pageSize !== undefined && pageSize !== null && pageSize !== "") {
      queryStringComponents.push(`page=${pageSize}`);
    }
    if (lastKey !== undefined && lastKey !== null && lastKey !== "") {
      queryStringComponents.push(
        `last_key=${encodeURIComponent(JSON.stringify(lastKey))}`
      );
    }
    let queryString =
      queryStringComponents.length > 0
        ? "?" + queryStringComponents.join("&")
        : "";

    return axios.get(queryString, config);
  };

  applyDoctorSignature = (id, details) => {
    const config = {
      headers: this.headers
    };
    return axios
      .put(`/${id}`, JSON.stringify(details), config)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log("Error applying doctor signature: ", error.message));
  };
}
