import React, { Component } from "react";
import PropTypes from "prop-types";
import CreatePatient from "../components/CreatePatient";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../resources";
import { patientActions } from "../store/actions/patient.actions";
import formatDateInput from "../../Forms/helpers/FormatDateInput";
import "./css/PatientCreate.css";

class PatientCreateContainer extends Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false
  };

  componentDidMount = async () => {
    this.props.clearSelectedPatient();
    await this.props.generatePatientID();
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  handleSubmit = () => {
    this.props.createPatient(
      this.props.selectedPatientData,
      this.props.headers
    );
    this.props.history.push("/patients");
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    this.props.updateSelectedPatient(data.name, data.value);
  };

  handleCheckboxChange = (event, data) => {
    this.props.updateSelectedPatient(data.name, data.checked);
  };

  handleDateChange = async (event, data) => {
    let dataToSubmit = "";
    let currentValue = this.props.selectedPatientData[data.name];
    // Only look to add dashes if there is something to format, and if the user
    // is typing rather than deleting
    if (currentValue && data.value.length > 0 && data.value.length > currentValue.length) {
      dataToSubmit = formatDateInput(data.value);
    } else {
      dataToSubmit = data.value;
    }
    await this.props.updateSelectedPatient(data.name, dataToSubmit);
    this.removeFieldError(data.name);
  };

  handleDateKeyDown = event => {
    // Filter the input to the date box - restrict it to numbers, the dash separator,
    // the backspace key (keyCode 8), and the delete key (keyCode 46)
    if (isNaN(event.key) && event.key !== "-" && event.keyCode !== 8 && event.keyCode !== 46) {
      event.preventDefault();
    }
  };

  handleDropdownChange = (event, key) => {
    let newData = Object.assign({}, this.props.selectedPatientData);
    newData[key.name] = key.value;
    if (
      newData.hasOwnProperty("date_of_death") &&
      newData["date_of_death"] !== "" &&
      key.value === "PWMND"
    ) {
      newData["status"] = "Inactive";
    }
    this.props.updatePatientLocalData(newData);
  };

  handlePostcodeChange = async postcode => {
    await this.props.getPostcodeInformation(postcode);
    if (
      this.props.postcodeData !== undefined &&
      this.props.postcodeData[0] !== undefined
    ) {
      let newData = Object.assign({}, this.props.selectedPatientData);
      newData["local_authority"] = this.props.postcodeData[0].local_authority;
      newData["health_board"] = this.props.postcodeData[0].health_board;
      this.props.updatePatientLocalData(newData);
    } else {
      let newData = Object.assign({}, this.props.selectedPatientData);
      newData["local_authority"] = "";
      newData["health_board"] = "";
      this.props.updatePatientLocalData(newData);
    }
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  hasDateOfDeath = () => {
    return (
      this.props.selectedPatientData.hasOwnProperty("date_of_death") &&
      this.props.selectedPatientData.date_of_death !== ""
    );
  };

  render = () => {
    return (
      <React.Fragment>
        <div style={{ display: this.props.loadingPage ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: this.props.loadingPage ? "none" : "" }}>
          <CreatePatient
            mode="create"
            pageTitle={strings.header.createPatient}
            selectedPatientData={this.props.selectedPatientData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            handleChange={this.handleChange}
            handleCheckboxChange={this.handleCheckboxChange}
            handleDateOfDeathChange={this.handleDateOfDeathChange}
            handleDropdownChange={this.handleDropdownChange}
            handleDateChange={this.handleDateChange}
            handleDateKeyDown={this.handleDateKeyDown}
            handleModalConfirm={this.handleModalConfirm}
            hasDateOfDeath={this.hasDateOfDeath}
          />
        </div>
      </React.Fragment>
    );
  };
}

PatientCreateContainer.propTypes = {
  //headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  // loadingPage: PropTypes.bool.isRequired,
  // selectedPatientData: PropTypes.object.isRequired,
  generatePatientID: PropTypes.func.isRequired,
  createPatient: PropTypes.func.isRequired,
  updateSelectedPatient: PropTypes.func.isRequired
  //clearSelectedPatient: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { patients } = state;
  const { loadingPage, selectedPatientData } = patients;
  return {
    loadingPage,
    selectedPatientData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createPatient: (data, headers) => {
      dispatch(patientActions.createPatient(data, headers));
    },
    updateSelectedPatient: (key, value) => {
      dispatch(patientActions.updateSelectedPatient(key, value));
    },
    clearSelectedPatient: () => {
      dispatch(patientActions.clearSelectedPatient());
    },
    generatePatientID: () => {
      dispatch(patientActions.generatePatientID());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientCreateContainer);
