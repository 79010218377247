import React from "react";
import { Segment, Dimmer, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { authActions } from "../../store/actions/auth.actions";
import "./css/login.css";
import logo from "../../assets/images/logo.png";

class PasswordResetContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      email: "",
      password: "",
      codeSent: false,
      confirmed: false,
      confirmPassword: "",
      isConfirming: false,
      isSendingCode: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoggedIn) {
      this.props.history.push("/patients");
    }
  }

  validateCodeForm() {
    return this.state.email.length > 0;
  }

  validateResetForm() {
    return (
      this.state.code.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSendCodeClick = async event => {
    event.preventDefault();

    this.setState({ isSendingCode: true });

    // try {
    //   await Auth.forgotPassword(this.state.email);
    //   this.setState({ codeSent: true });
    // } catch (e) {
    //   alert(e.message);
    //   this.setState({ isSendingCode: false });
    // }
  };

  handleConfirmClick = async event => {
    event.preventDefault();

    this.setState({ isConfirming: true });

    // try {
    //   await Auth.forgotPasswordSubmit(
    //     this.state.email,
    //     this.state.code,
    //     this.state.password
    //   );
    //   this.setState({ confirmed: true });
    // } catch (e) {
    //   alert(e.message);
    //   this.setState({ isConfirming: false });
    // }

    // this.props.signIn(this.state.email, this.state.password);
    // this.props.history.push("/patients");
  };

  renderRequestCodeForm() {
    return (
      <Dimmer.Dimmable as={Segment} blurring dimmed={this.props.loggingIn}>
        <Dimmer inverted active={this.props.loggingIn}>
          <Loader size="large">Signing in..</Loader>
        </Dimmer>
        <div>
          <form className="ui large form" method="post">
            <div className="ui stacked segment">
              <div className="field">
                <div className="ui left icon input">
                  <i className="user icon" />
                  <input
                    type="text"
                    name="email"
                    placeholder="Email address"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <button
                className="ui fluid large blue submit button"
                id="LoginButton"
                onClick={this.handleSubmit}
              >
                Submit
              </button>
            </div>
            {this.props.error && (
              <div className="ui negative message">{this.props.error}</div>
            )}
          </form>
        </div>
      </Dimmer.Dimmable>
    );
  }

  renderConfirmationForm() {
    return (
      <Dimmer.Dimmable as={Segment} blurring dimmed={this.props.loggingIn}>
        <Dimmer inverted active={this.props.loggingIn}>
          <Loader size="large">Signing in..</Loader>
        </Dimmer>
        <div>
          <form className="ui large form" method="post">
            <div className="ui stacked segment">
              <p>
                Please check your email ({this.state.email}) for the
                confirmation code.
              </p>
              <div className="field">
                <div className="ui left icon input">
                  <i className="user icon" />
                  <input
                    type="text"
                    name="code"
                    placeholder="Confirmation Code"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <div className="ui left icon input">
                  <i className="lock icon" />
                  <input
                    type="password"
                    name="password"
                    placeholder="New Password"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <div className="ui left icon input">
                  <i className="lock icon" />
                  <input
                    type="confirmPassword"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <button
                className="ui fluid large blue submit button"
                id="LoginButton"
                onClick={this.handleSubmit}
              >
                Login
              </button>
            </div>
            {this.props.error && (
              <div className="ui negative message">{this.props.error}</div>
            )}
          </form>
          <div className="ui message">
            <Link to="/reset-password">Password Reset</Link>
          </div>
        </div>
      </Dimmer.Dimmable>
    );
  }

  renderSuccessMessage() {
    return (
      <div className="success">
        <p>Your password has been reset.</p>
        <p>
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="Login">
        <div className="ui middle aligned center aligned grid">
          <div id="LoginColumn" className="column">
            <br />
            <h2 className="ui image header">
              <center>
                <div className="field">
                  <img
                    className="image"
                    src={logo}
                    alt="Dr Darren McKeown"
                    width="80%"
                    height="80%"
                  />
                </div>
              </center>
              <br />
              <div className="content">Password reset</div>
            </h2>
            {!this.state.codeSent
              ? this.renderRequestCodeForm()
              : !this.state.confirmed
              ? this.renderConfirmationForm()
              : this.renderSuccessMessage()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  const { loggingIn, isLoggedIn, headers, error } = auth;

  return {
    loggingIn,
    isLoggedIn,
    headers,
    error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: (email, password) => {
      dispatch(authActions.signIn(email, password));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PasswordResetContainer)
);
