//This is a version that doesn't use the axios interceptors to add the auth header,
// for use with endpoints that don't require an auth token, e.g. for remote patient login process

import axios from "axios";
import { config } from "../../_config";

const instance = axios.create({
  baseURL: config.usersEndpoint,
});

export default instance;
