import React from "react";
import PropTypes from "prop-types";
import { Button, Form } from "semantic-ui-react";
import { strings, userRoleOptions } from "../resources";
import { BasicModal } from ".";
import "./css/UserComponents.css";

class ModifyUser extends React.Component {
  render = () => {
    return (
      <div className="modifyUserContainer">
        <div className="pageHeader">{this.props.pageTitle}</div>
        <Form
          className="details"
          onSubmit={this.props.toggleConfirmSaveModalVisibility}
        >
          <div className="userInfoHeader">{strings.form.header.userInfo}</div>
          <div className="userInfo">
            <Form.Group>
              <Form.Dropdown
                label={strings.form.label.role}
                name="role"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.role}
                selection
                options={userRoleOptions}
                value={this.props.selectedUserData.role || ""}
                width={8}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label={strings.form.label.userName}
                name="username"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.userName}
                required
                value={this.props.selectedUserData.username || ""}
                width={8}
              />
              <Form.Input
                label={strings.form.label.password}
                name="password"
                type="password"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.password}
                required
                value={this.props.selectedUserData.password || ""}
                width={8}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label={strings.form.label.firstName}
                name="given_name"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.firstName}
                required
                value={this.props.selectedUserData.given_name || ""}
                width={8}
              />
              <Form.Input
                label={strings.form.label.surname}
                name="family_name"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.surname}
                required
                value={this.props.selectedUserData.family_name || ""}
                width={8}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label={strings.form.label.email}
                name="email"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.email}
                required
                value={this.props.selectedUserData.email || ""}
                width={8}
              />
              <Form.Input
                readOnly
                label={strings.form.label.signaturePath}
                name="signature_path"
                onChange={this.props.handleChange}
                placeholder={strings.form.placeholder.signaturePath}
                value={this.props.selectedUserData.signature_path || ""}
                width={8}
              />
            </Form.Group>
          </div>
          <div className="cancelSaveButtons">
            <Button.Group>
              <Button className="saveButton">{strings.button.saveLabel}</Button>
              <BasicModal
                confirmMessage={strings.modal.saveChangesMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="confirmSaveModal"
                modalOpen={this.props.confirmSaveModalOpen}
                showNoButton={true}
                showOkButton={false}
                showYesButton={true}
                toggleModal={this.props.toggleConfirmSaveModalVisibility}
              />
              <Button.Or />
              <Button
                className="cancelButton"
                onClick={this.props.toggleCancelChangesModalVisibility}
                type="button"
              >
                {strings.button.cancelLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.cancelChangesMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="cancelChangesModal"
                modalOpen={this.props.cancelChangesModalOpen}
                showNoButton={true}
                showOkButton={false}
                showYesButton={true}
                toggleModal={this.props.toggleCancelChangesModalVisibility}
              />
            </Button.Group>
          </div>
        </Form>
      </div>
    );
  };
}

ModifyUser.propTypes = {
  selectedUserData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired
};

export default ModifyUser;
