import React from "react";
import { Breadcrumb } from "semantic-ui-react";
import { connect } from "react-redux";
import { patientActions } from "../../Patients/store/actions/patient.actions";
import { FormEditContainer } from ".";

class PreConsultationProgressContainer extends React.Component {
  constructor(props) {
    super(props);

    let locationSelectedFormTypes = this.props.location.state
      ? this.props.location.state.selectedFormTypes
      : null;
    let locationSelectedFormNames = this.props.location.state
      ? this.props.location.state.selectedFormNames
      : null;

    let locationPatientId = this.props.location.state
      ? this.props.location.state.patientId
      : null;

    let reduxSelectedFormTypes = this.props.result
      ? this.props.result.selectedFormTypes
      : [];
    let reduxSelectedFormNames = this.props.result
      ? this.props.result.selectedFormNames
      : [];

    let reduxPatientId = this.props.result ? this.props.result.patientId : null;

    this.state = {
      patientId: locationPatientId ? locationPatientId : reduxPatientId,
      patientData: this.props.location.state
        ? this.props.location.state.patientData
        : null,
      selectedFormTypes: locationSelectedFormTypes
        ? locationSelectedFormTypes
        : reduxSelectedFormTypes,
      breadcrumbHistory: locationSelectedFormNames
        ? locationSelectedFormNames
        : reduxSelectedFormNames,
      breadcrumbActiveIndex: 0,
      currentFormType: locationSelectedFormTypes
        ? locationSelectedFormTypes[0]
        : reduxSelectedFormTypes
        ? reduxSelectedFormTypes[0]
        : null,
    };
  }

  componentDidMount = () => {
    console.log("state", this.state);
    //if page has loaded without form types, send user back to patients list
    if (!this.state.selectedFormTypes || !this.state.currentFormType) {
      this.props.history.push({
        pathname: "/patients/",
      });
    }

    //Retrieve the patient details if the user is an existing patient (the API should block the request if the patient name and DOB are wrong)
    if (this.props.isExistingPatient) {
      //We need a new API call here to only get the details if the name and DOB provided are a match
    }
  };

  handleNewPatientCreated = async (patientId) => {
    await this.props.getPatientById(patientId, this.props.headers);
    return new Promise((resolve, reject) => {
      this.setState(
        {
          patientId: patientId,
          patientData: this.props.selectedPatientData,
        },
        () => {
          resolve();
        }
      );
    });
  };

  handleNewFormCreated = () => {
    window.scroll(0, 0);
    const newOrderUncompletedFormTypes = this.state.selectedFormTypes.slice();
    newOrderUncompletedFormTypes.splice(0, 1);
    this.setState({
      selectedFormTypes: newOrderUncompletedFormTypes,
      currentFormType: newOrderUncompletedFormTypes[0],
      breadcrumbActiveIndex: this.state.breadcrumbActiveIndex + 1,
    });
    if (this.state.selectedFormTypes.length === 0) {
      this.openPreConsultationComplete();
    }
  };

  openPreConsultationComplete = () => {
    this.props.history.push({
      pathname: "/forms/preconsultation/complete/",
      state: {
        patientId: this.state.patientId,
      },
    });
  };

  mapFormTypesToBreadcrumb = () => {
    const breadcrumbs = [];
    this.state.breadcrumbHistory.forEach((breadcrumb) => {
      breadcrumbs.push({
        key: breadcrumb,
        content: breadcrumb,
        active:
          this.state.breadcrumbHistory[this.state.breadcrumbActiveIndex] ===
          breadcrumb,
      });
    });
    return breadcrumbs;
  };

  render = () => {
    const breadcrumbs = this.mapFormTypesToBreadcrumb();
    return (
      <React.Fragment>
        <div className="centerContent">
          <Breadcrumb icon="right angle" sections={breadcrumbs} size="large" />
        </div>
        <FormEditContainer
          formType={this.state.currentFormType}
          mode="create"
          multipleFormEditMode={true}
          handleNewPatientCreated={this.handleNewPatientCreated}
          handleNewFormCreated={this.handleNewFormCreated}
          patientData={this.state.patientData}
          patientId={this.state.patientId}
          openPreConsultationComplete={this.openPreConsultationComplete}
          headers={this.props.headers}
        />
      </React.Fragment>
    );
  };
}

PreConsultationProgressContainer.propTypes = {};

const mapStateToProps = (state) => {
  const { selectedPatientData } = state.patients;
  const { isExistingPatient, result } = state.users;
  return {
    selectedPatientData,
    isExistingPatient,
    result,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPatientById: (id) => {
      return dispatch(patientActions.getPatientById(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreConsultationProgressContainer);
