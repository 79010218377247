import { treatmentTypeActionTypes } from "../actions/treatmentType.actionTypes";

const initialState = {
  loadingPage: false,
  selectedTreatmentTypeData: {},
  treatmentTypeListData: [{}],
  treatmentTypeToDelete: "",
  result: [],
  error: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case treatmentTypeActionTypes.CREATE_TREATMENT_TYPE_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentTypeActionTypes.CREATE_TREATMENT_TYPE_SUCCESS:
      return { ...state, loadingPage: false, result: action.data };
    case treatmentTypeActionTypes.CREATE_TREATMENT_TYPE_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case treatmentTypeActionTypes.UPDATE_TREATMENT_TYPE_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentTypeActionTypes.UPDATE_TREATMENT_TYPE_SUCCESS:
      return { ...state, loadingPage: false, result: action.data };
    case treatmentTypeActionTypes.UPDATE_TREATMENT_TYPE_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case treatmentTypeActionTypes.DEACTIVATE_TREATMENT_TYPE_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentTypeActionTypes.DEACTIVATE_TREATMENT_TYPE_SUCCESS:
      return { ...state, loadingPage: false, result: action.data };
    case treatmentTypeActionTypes.DEACTIVATE_TREATMENT_TYPE_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case treatmentTypeActionTypes.ACTIVATE_TREATMENT_TYPE_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentTypeActionTypes.ACTIVATE_TREATMENT_TYPE_SUCCESS:
      return { ...state, loadingPage: false, result: action.data };
    case treatmentTypeActionTypes.ACTIVATE_TREATMENT_TYPE_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case treatmentTypeActionTypes.GET_TREATMENT_TYPES_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentTypeActionTypes.GET_TREATMENT_TYPES_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        treatmentTypeListData: action.data
      };
    case treatmentTypeActionTypes.GET_TREATMENT_TYPES_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case treatmentTypeActionTypes.GET_TREATMENT_TYPE_BY_ID_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentTypeActionTypes.GET_TREATMENT_TYPE_BY_ID_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        selectedTreatmentTypeData: action.data
      };
    case treatmentTypeActionTypes.GET_TREATMENT_TYPE_BY_ID_FAILURE:
      return { ...state, loadingPage: false, error: action.error };

    case treatmentTypeActionTypes.SET_TREATMENT_TYPE_TO_DELETE_REQUEST:
      return { ...state };
    case treatmentTypeActionTypes.SET_TREATMENT_TYPE_TO_DELETE_SUCCESS:
      return { ...state, treatmentTypeToDelete: action.id };

    case treatmentTypeActionTypes.UPDATE_SELECTED_TREATMENT_TYPE_REQUEST:
      return { ...state };
    case treatmentTypeActionTypes.UPDATE_SELECTED_TREATMENT_TYPE_SUCCESS:
      return {
        ...state,
        selectedTreatmentTypeData: {
          ...state.selectedTreatmentTypeData,
          [action.key]: action.value
        }
      };
    case treatmentTypeActionTypes.UPDATE_SELECTED_TREATMENT_TYPE_FAILURE:
      return { ...state };

    case treatmentTypeActionTypes.CLEAR_SELECTED_TREATMENT_TYPE_REQUEST:
      return { ...state, loadingPage: true };
    case treatmentTypeActionTypes.CLEAR_SELECTED_TREATMENT_TYPE_SUCCESS:
      return { ...state, loadingPage: false, selectedTreatmentTypeData: {} };
    case treatmentTypeActionTypes.CLEAR_SELECTED_TREATMENT_TYPE_FAILURE:
      return { ...state, loadingPage: false };

    default:
      return state;
  }
};
