const addLowercaseDetails = details => {
  for (let key in details) {
    if (!key.includes("lowercase_") && !key.includes("user_id")) {
      let lowerCaseKey = "lowercase_" + key;
      // check for array
      if (Array.isArray(details[key])) {
        details[lowerCaseKey] = details[key]
          .join("|")
          .toLowerCase()
          .split("|");
      } else {
        details[lowerCaseKey] = details[key].toLowerCase();
      }
    }
  }

  // Remove any lowercase keys without corresponding regular entries
  for (let key in details) {
    if (key.includes("lowercase_")) {
      let regularKey = key.slice(10);
      if (!details.hasOwnProperty(regularKey)) {
        let lowerCaseKey = "lowercase_" + regularKey;
        delete details[lowerCaseKey];
      }
    }
  }
  return details;
};

export default addLowercaseDetails;
