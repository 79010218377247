import { authActionTypes } from "../actions/auth.actionTypes";

const initialState = {
  headers: {},
  loading: false,
  error: "",
  passwordChanged: null,
  loggingIn: false,
  isLoggedIn: false,
  role: null,
  signaturePathOfLoggedInUser: null,
  givenName: null,
  familyName: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.GENERATE_SYSTEM_HEADERS_SUCCESS:
      return { ...state, headers: action.headers };
    case authActionTypes.GENERATE_SYSTEM_HEADERS_FAILURE:
      return { ...state, error: action.error };

    case authActionTypes.SET_SYSTEM_HEADER_REQUEST:
      return { ...state };
    case authActionTypes.SET_SYSTEM_HEADER_SUCCESS:
      return { ...state, headers: action.headers };
    case authActionTypes.SET_SYSTEM_HEADER_FAILURE:
      return { ...state, error: action.error };

    case authActionTypes.REFRESH_TOKENS_REQUEST:
      return { ...state, loading: true };
    case authActionTypes.REFRESH_TOKENS_SUCCESS:
      return { ...state, loading: false };
    case authActionTypes.REFRESH_TOKENS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case authActionTypes.SIGNUP_REQUEST:
      return { ...state, loggingIn: true };
    case authActionTypes.SIGNUP_SUCCESS:
      return { ...state, loggingIn: false, isLoggedIn: false, error: "" };
    case authActionTypes.SIGNUP_FAILURE:
      return { ...state, loggingIn: false, error: action.error };

    case authActionTypes.SIGNIN_REQUEST:
      return { ...state, loggingIn: true };
    case authActionTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isLoggedIn: true,
        role: action.signInInfo.group,
        signaturePathOfLoggedInUser: action.signInInfo.signaturePath,
        givenName: action.signInInfo.givenName,
        familyName: action.signInInfo.familyName,
        error: ""
      };
    case authActionTypes.SIGNIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        isLoggedIn: false,
        role: null,
        error: action.error
      };

    case authActionTypes.CHECK_AUTHENTICATION_STATUS_REQUEST:
      return { ...state };
    case authActionTypes.CHECK_AUTHENTICATION_STATUS_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isLoggedIn: true,
        role: action.user.attributes["custom:role"],
        signaturePathOfLoggedInUser:
          action.user.attributes["custom:signature_path"],
        givenName: action.user.attributes["given_name"],
        familyName: action.user.attributes["family_name"],
        error: ""
      };

    case authActionTypes.SIGNOUT_REQUEST:
      return { ...state };
    case authActionTypes.SIGNOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        role: null,
        error: initialState.error
      };

    case authActionTypes.CHECK_SIGNIN_STATUS:
      return { ...state };
    case authActionTypes.REFRESH_SIGNIN_STATUS:
      return { ...state, isLoggedIn: true, role: action.role };

    case authActionTypes.CHANGE_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case authActionTypes.CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false, passwordChanged: true };
    case authActionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        passwordChanged: false,
        error: action.error
      };

    case authActionTypes.PURGE_SYSTEM_HEADERS_REQUEST:
      return { ...state };
    case authActionTypes.PURGE_SYSTEM_HEADERS_SUCCESS:
      return { ...state, headers: {} };

    case authActionTypes.SET_SESSION_EXPIRED_REQUEST:
      return { ...state };
    case authActionTypes.SET_SESSION_EXPIRED_SUCCESS:
      return { ...state, error: action.message };

    default:
      return state;
  }
};
