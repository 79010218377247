import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

const YesNoInput = props => {
  const handleChange = (event, data) => {
    // Add the child field and whether or not to clear it
    // i.e. when a child is removed, clear the value in redux of it's field
    props.changehandler(event, {
      ...data,
      childrenFields: props.childrenFields,
      clearChild: data.value !== props.showChildWhenValue
    });
  };
  return (
    <React.Fragment>
      <Form.Group inline className="yesNoInput">
        <label>
          <span className={props.error ? "errorLabel" : null}>
            {props.label}
          </span>
          {props.required && <span className="requiredAsterisk"> *</span>}
        </label>
        <Form.Radio
          disabled={props.mode === "view" || props.mode === "template"}
          error={props.error}
          checked={props.value === "Yes"}
          label={"Yes"}
          name={props.name}
          onChange={handleChange}
          value="Yes"
        />
        <Form.Radio
          disabled={props.mode === "view" || props.mode === "template"}
          error={props.error}
          checked={props.value === "No"}
          label={"No"}
          name={props.name}
          onChange={handleChange}
          value="No"
        />
      </Form.Group>
      {props.childElement && props.value === props.showChildWhenValue && (
        <div className="yesNoChild">{props.childElement}</div>
      )}
    </React.Fragment>
  );
};

YesNoInput.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  changehandler: PropTypes.func.isRequired,
  showChildWhenValue: PropTypes.string, //If present, this sets the Yes/No option that displays the child component (e.g. "Yes"). Some Yes/No controls have no child component.
  childElement: PropTypes.node
};

export default YesNoInput;
