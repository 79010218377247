import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      login: "Login"
    },
    form: {
      placeholder: {
        username: "Username",
        password: "Password",
        email: "Email Address"
      }
    },
    button: {
      cancelLabel: "Cancel",
      deleteLabel: "Delete",
      editLabel: "Edit",
      loginLabel: "Login",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
    },
    modal: {
      cancelChangesMessage: "Cancel changes?",
      deleteMessage: "Are you sure you want to delete? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
    }
  }
});
