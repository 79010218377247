import axios from "./axios-treatments";

export class API {
  constructor() {
    this.headers = {};
    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createTreatment = (details, patientId) => {
    const config = {
      headers: this.headers
    };
    let queryString = "";
    if (patientId !== undefined && patientId !== null && patientId !== "") {
      queryString = `?patient_id=${patientId}`;
    }

    return axios
      .post(`${queryString}`, JSON.stringify(details), config)
  };

  updateTreatment = (id, patientId, details) => {
    const config = {
      headers: this.headers
    };
    return axios
      .put(`/${id}`, JSON.stringify(details), config)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log("Error updating treatment: ", error.message));
  };

  deleteTreatment = (id, patientId) => {
    const config = {
      headers: this.headers
    };
    let queryString = "";
    if (patientId !== undefined && patientId !== null && patientId !== "") {
      queryString = `?patient_id=${patientId}`;
    }

    return axios
      .delete(`/${id}${queryString}`, config)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log("Error deleting treatment: ", error.message));
  };

  getTreatments = (patientId, excludeFollowUpNotes)=> {
    const config = {
      headers: this.headers
    };

    let queryString = "";
    if (patientId !== undefined && patientId !== null && patientId !== "") {
      queryString = `?patient_id=${patientId}`;
    }

    if (excludeFollowUpNotes) {
      queryString = `${queryString}&exclude_follow_ups=true`
    }
    
    return axios
      .get(`${queryString}`, config)
      .catch(error => console.log("Error getting treatments: ", error.message));
  };

  getTreatmentById = (id, patientId) => {
    const config = {
      headers: this.headers
    };
    let queryString = "";
    if (patientId !== undefined && patientId !== null && patientId !== "") {
      queryString = `?patient_id=${patientId}`;
    }

    return axios.get(`/${id}${queryString}`, config);
  };

  createTreatmentType = details => {
    const config = {
      headers: this.headers
    };
    return axios
      .post("/type", JSON.stringify(details), config)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log("Error creating treatment: ", error.message));
  };

  updateTreatmentType = (id, details) => {
    const config = {
      headers: this.headers
    };
    return axios
      .put(`/type/${id}`, JSON.stringify(details), config)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log("Error updating treatment: ", error.message));
  };

  activateTreatmentType = id => {
    const config = {
      headers: this.headers
    };
    return axios
      .put(`/type/status/${id}`, JSON.stringify({ active: true }), config)
      .then(response => {
        console.log(response);
      })
      .catch(error =>
        console.log("Error activating treatment: ", error.message)
      );
  };

  deactivateTreatmentType = id => {
    const config = {
      headers: this.headers
    };
    return axios
      .put(`/type/status/${id}`, JSON.stringify({ active: false }), config)
      .then(response => {
        console.log(response);
      })
      .catch(error =>
        console.log("Error deactivating treatment: ", error.message)
      );
  };

  getTreatmentTypes = () => {
    const config = {
      headers: this.headers
    };
    return axios
      .get("/types", config)
      .catch(error => console.log("Error getting treatments: ", error.message));
  };

  getTreatmentTypeById = id => {
    const config = {
      headers: this.headers
    };
    return axios.get(`/type/${id}`, config);
  };
}
