import React from "react";
import { DatePicker, BasicModal } from "./ui";
import { Form, Message, Button } from "semantic-ui-react";
import { strings } from "../resources";
import PropTypes from "prop-types";

class PreConsultationVerify extends React.Component {

  render = () => {
    return (
      <React.Fragment>
        <Form
          onSubmit={this.props.handleSubmit}
          
        >
          <Form.Group>
            <Form.Input
              name="first_name"
              onChange={this.props.handleChange}
              required
              label={strings.form.label.firstName}
              placeholder={strings.form.placeholder.firstName}
              value={this.props.data.first_name || ""}
              width={8}
            />
            <Form.Input
              className="noLabelInput"
              name="surname"
              onChange={this.props.handleChange}
              required
              placeholder={strings.form.placeholder.surname}
              value={this.props.data.surname || ""}
              width={8}
            />
          </Form.Group>
          <DatePicker
            className="verifyDateOfBirth"
            name="date_of_birth"
            onChange={this.props.handleDateChange}
            onKeyDown={e => this.props.handleDateKeyDown(e)}
            required
            label={strings.form.label.dateOfBirth}
            value={this.props.data.date_of_birth || ""}
            noFutureDates
            width={5}
          />
          <div className="preconsultationVerifyButton">
            <Button.Group>
              <Button className="saveButton">{strings.button.continue}</Button>
              <Button.Or />
              <Button
                className="cancelButton"
                type="button"
                onClick={this.props.toggleCancelModalVisibility}
              >
                {strings.button.cancelLabel}
              </Button>
            </Button.Group>
          </div>
        </Form>
        {this.props.error && (
          <Message
            negative
          >
            <span>{strings.form.label.preConsultationVerifyError}</span>
          </Message>
        )}
        <BasicModal
          confirmMessage={strings.modal.cancelPreconsultationMessage}
          handleModalConfirm={this.props.handleModalConfirm}
          modalClassName="cancelModal"
          modalOpen={this.props.cancelModalOpen}
          showNoButton={true}
          showOkButton={false}
          showYesButton={true}
          toggleModal={this.props.toggleCancelModalVisibility}
        />
      </React.Fragment>
    );
  };
}

PreConsultationVerify.propTypes = {
  error: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDateKeyDown: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggleCancelModalVisibility: PropTypes.func.isRequired,
  cancelModalOpen: PropTypes.bool.isRequired,
  handleModalConfirm: PropTypes.func.isRequired
};

export default PreConsultationVerify;
