import React from "react";
import { Icon, Button, Grid, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./css/UserComponents.css";
import { strings } from "../resources";

class TableFunctions extends React.Component {
  render = () => {
    return (
      <Grid columns={3} className="TableFunctions">
        <Grid.Column>
          <Header as="h1">{strings.header.userList}</Header>
        </Grid.Column>
        <Grid.Column />
        <Grid.Column>
          <Link to={`/users/create`}>
            <Button primary className="addUserButton">
              <Icon name="building" />
              {strings.button.addUserLabel}
            </Button>
          </Link>
        </Grid.Column>
      </Grid>
    );
  };
}

TableFunctions.propTypes = {};

export default TableFunctions;
