import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import {
  reasonsForVisitOptions,
  treatmentsConsideredOptions,
} from "../../resources/CheckboxOptions";
import { YesNoInput, CheckboxGroup, RangeSlider } from "../ui";
import {
  SignatureFieldContainer,
  ImageAnnotatorContainer,
} from "../../../Images/containers";
import face from "../../../Images/assets/images/face.png";
import { DatePicker } from "../ui";
import { dateEpochToDateTimeString } from "../../../App/helpers/DateTimeFormatConversion";

class FormAestheticInterestQuestionnaire extends React.Component {
  componentWillMount = () => {
    if (this.props.mode === "create" || this.props.mode === "edit") {
      let todaysDateStr = dateEpochToDateTimeString(new Date(), "-");
      this.props.updateSelectedForm("date_patient_signed", todaysDateStr);
    }
  };

  render = () => {
    const errorFields = this.props.validationErrors.map((error) => {
      return error.field;
    });
    let showEmailForConsideredTreatmentsCheckbox = false;
    if (this.props.selectedFormData.treatments_considered) {
      showEmailForConsideredTreatmentsCheckbox = Object.keys(
        this.props.selectedFormData.treatments_considered
      ).some((treatment) => {
        return (
          this.props.selectedFormData.treatments_considered[treatment]
            .checked === true
        );
      });
    }
    return (
      <div className="createFormContainer">
        <div className="patientFormSectionHeader">
          {strings.form.header.aestheticInterest}
        </div>
        <CheckboxGroup
          mode={this.props.mode}
          error={errorFields.includes("reasons")}
          label={strings.form.label.reasonForVisit}
          required
          group="reasons"
          data={this.props.selectedFormData}
          handleCheckboxChange={this.props.handleCheckboxGroupChange}
          options={reasonsForVisitOptions}
        />
        <Form.Group>
          {this.props.selectedFormData.reasons &&
            this.props.selectedFormData.reasons.other &&
            this.props.selectedFormData.reasons.other.checked === true && (
              <Form.TextArea
                error={errorFields.includes("reasons_other")}
                label={strings.form.label.reasonOtherDetails}
                name="reasons_other"
                group="reasons"
                child="other"
                onChange={this.props.handleNestedChange}
                placeholder={strings.form.placeholder.details}
                required
                value={this.props.selectedFormData.reasons_other || ""}
                rows={2}
                width={16}
              />
            )}
        </Form.Group>
        <div className="patientFormSectionHeader">
          {strings.form.header.appearance}
        </div>
        <Form.Field
          className="textField"
          content={strings.form.label.appearanceInformation}
        />
        <RangeSlider
          error={errorFields.includes("bothered_by_appearance")}
          disabled={
            this.props.mode === "view" || this.props.mode === "template"
          }
          onChange={this.props.handleSliderChange}
          name="bothered_by_appearance"
          label={strings.form.label.botheredByAppearance}
          value={this.props.selectedFormData.bothered_by_appearance || 0}
          required
        />
        <Form.Field
          className="textField"
          content={strings.form.label.botheredByAppearanceText}
        />
        <Form.Field
          error={errorFields.includes("how_often_appearance")}
          className="textField"
          content={
            <label>
              <span>{strings.form.label.howOftenAppearanceQuestion}</span>
              <span className="requiredAsterisk"> *</span>
            </label>
          }
        />
        <Form.Group inline>
          <Form.Radio
            error={errorFields.includes("how_often_appearance")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.howOftenAlways}
            name="how_often_appearance"
            value={strings.form.databaseValues.howOftenAlways}
            checked={
              this.props.selectedFormData.how_often_appearance ===
                strings.form.databaseValues.howOftenAlways || false
            }
            onChange={this.props.handleChange}
          />
          <Form.Radio
            error={errorFields.includes("how_often_appearance")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.howOftenDaily}
            name="how_often_appearance"
            value={strings.form.databaseValues.howOftenDaily}
            checked={
              this.props.selectedFormData.how_often_appearance ===
                strings.form.databaseValues.howOftenDaily || false
            }
            onChange={this.props.handleChange}
          />
          <Form.Radio
            error={errorFields.includes("how_often_appearance")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.howOftenMostDays}
            name="how_often_appearance"
            value={strings.form.databaseValues.howOftenMostDays}
            checked={
              this.props.selectedFormData.how_often_appearance ===
                strings.form.databaseValues.howOftenMostDays || false
            }
            onChange={this.props.handleChange}
          />
          <Form.Radio
            error={errorFields.includes("how_often_appearance")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.howOftenWeekly}
            name="how_often_appearance"
            value={strings.form.databaseValues.howOftenWeekly}
            checked={
              this.props.selectedFormData.how_often_appearance ===
                strings.form.databaseValues.howOftenWeekly || false
            }
            onChange={this.props.handleChange}
          />
          <Form.Radio
            error={errorFields.includes("how_often_appearance")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.howOftenMonthly}
            name="how_often_appearance"
            value={strings.form.databaseValues.howOftenMonthly}
            checked={
              this.props.selectedFormData.how_often_appearance ===
                strings.form.databaseValues.howOftenMonthly || false
            }
            onChange={this.props.handleChange}
          />
          <Form.Radio
            error={errorFields.includes("how_often_appearance")}
            disabled={
              this.props.mode === "view" || this.props.mode === "template"
            }
            label={strings.form.label.howOftenNotVery}
            name="how_often_appearance"
            value={strings.form.databaseValues.howOftenNotVery}
            checked={
              this.props.selectedFormData.how_often_appearance ===
                strings.form.databaseValues.howOftenNotVery || false
            }
            onChange={this.props.handleChange}
          />
        </Form.Group>
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("leave_house_toggle")}
          name="leave_house_toggle"
          label={strings.form.label.leaveHouseToggle}
          value={this.props.selectedFormData.leave_house_toggle}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("avoids_social_situations_toggle")}
          name="avoids_social_situations_toggle"
          label={strings.form.label.avoidsSocialSituationsToggle}
          value={this.props.selectedFormData.avoids_social_situations_toggle}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("avoids_photographs_toggle")}
          name="avoids_photographs_toggle"
          label={strings.form.label.avoidsPhotographsToggle}
          value={this.props.selectedFormData.avoids_photographs_toggle}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("avoids_certain_angles_toggle")}
          name="avoids_certain_angles_toggle"
          label={strings.form.label.avoidsCertainAnglesToggle}
          value={this.props.selectedFormData.avoids_certain_angles_toggle}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <YesNoInput
          mode={this.props.mode}
          error={errorFields.includes("wears_makeup_toggle")}
          name="wears_makeup_toggle"
          label={strings.form.label.wearsMakeupToggle}
          value={this.props.selectedFormData.wears_makeup_toggle}
          changehandler={this.props.handleYesNoChange}
          required
        />
        <CheckboxGroup
          mode={this.props.mode}
          error={errorFields.includes("treatments_considered")}
          label={strings.form.label.treatmentsConsidered}
          group="treatments_considered"
          data={this.props.selectedFormData}
          handleCheckboxChange={this.props.handleCheckboxGroupChange}
          options={treatmentsConsideredOptions}
        />
        {
          //Temporarily disabled because emails are not yet set up
          false && showEmailForConsideredTreatmentsCheckbox && (
            <Form.Checkbox
              className="yesNoChild"
              error={errorFields.includes("treatments_email")}
              label={strings.form.label.treatmentsEmailQuestion}
              name="treatments_email"
              disabled={
                this.props.mode === "view" || this.props.mode === "template"
              }
              onChange={this.props.handleCheckboxChange}
              checked={this.props.selectedFormData.treatments_email || false}
            />
          )
        }
        <ImageAnnotatorContainer
          annotationImagePath={
            this.props.formId ? `forms/${this.props.formId}/areasOfConcern` : ""
          }
          backgroundImage={face}
          blank={this.props.mode === "template"}
          header={
            this.props.mode === "create"
              ? strings.header.circleAreasOfConcern
              : strings.header.areasOfConcern
          }
          headers={this.props.headers}
          readOnly={this.props.mode === "create" ? false : true}
          annotationName={"areasOfConcern"}
          handleAnnotationWasModified={this.props.handleAnnotationWasModified}
        />

        <Form.Group>
          <Form.Input
            label={strings.form.label.patientPrintName}
            error={errorFields.includes("signed_by_patient")}
            name="signed_by_patient"
            onChange={this.props.handleChange}
            placeholder={strings.form.placeholder.patientPrintName}
            required
            value={this.props.selectedFormData.signed_by_patient || ""}
            width={8}
          />
        </Form.Group>
        <SignatureFieldContainer
          error={errorFields.includes("signature_data_url")}
          headers={this.props.headers}
          mode={this.props.mode}
          readOnly={this.props.mode === "create" ? false : true}
          signatureImagePath={
            this.props.formId ? `signatures/forms/${this.props.formId}` : ""
          }
        />
        <Form.Group>
          <DatePicker
            error={errorFields.includes("date_patient_signed")}
            className="datePatientSigned"
            name="date_patient_signed"
            onChange={this.props.handleDateChange}
            onKeyDown={(e) => this.props.handleDateKeyDown(e)}
            required
            label={strings.form.label.datePatientSigned}
            value={this.props.selectedFormData.date_patient_signed || ""}
          />
        </Form.Group>
      </div>
    );
  };
}

FormAestheticInterestQuestionnaire.propTypes = {
  mode: PropTypes.string.isRequired, //create, view or edit
  selectedFormData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  handleAnnotationWasModified: PropTypes.func.isRequired,
};

export default FormAestheticInterestQuestionnaire;
