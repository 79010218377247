import { API } from "../../services";
import { userActionTypes } from "./user.actionTypes";
import addLowercaseDetails from "../../helpers/lowercaser";

const api = new API();

const createUser = (details, headers) => {
  const request = (details) => {
    return { type: userActionTypes.CREATE_USER_REQUEST, details };
  };
  const success = (data) => {
    return { type: userActionTypes.CREATE_USER_SUCCESS, data };
  };
  const failure = (error) => {
    return { type: userActionTypes.CREATE_USER_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createUser" });

  const lowercasedDetails = addLowercaseDetails(details);

  return (dispatch) => {
    dispatch(request(lowercasedDetails));

    api.createUser(lowercasedDetails).then(
      (response) => {
        dispatch(success(response.data));
        //dispatch(getUsers(headers));
      },
      (error) => dispatch(failure(error))
    );
  };
};

const updateUser = (id, details, headers) => {
  const request = (details) => {
    return { type: userActionTypes.UPDATE_USER_REQUEST, details };
  };
  const success = (data) => {
    return { type: userActionTypes.UPDATE_USER_SUCCESS, data };
  };
  const failure = (error) => {
    return { type: userActionTypes.UPDATE_USER_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "updateUser" });

  const lowercasedDetails = addLowercaseDetails(details);

  return (dispatch) => {
    dispatch(request(lowercasedDetails));

    api.updateUser(id, lowercasedDetails).then(
      (data) => {
        dispatch(success(data));
        dispatch(getUsers(headers));
      },
      (error) => dispatch(failure(error))
    );
  };
};

const deleteUser = (id, headers) => {
  const request = () => {
    return { type: userActionTypes.DELETE_USER_REQUEST };
  };
  const success = (data) => {
    return { type: userActionTypes.DELETE_USER_SUCCESS, data };
  };
  const failure = (error) => {
    return { type: userActionTypes.DELETE_USER_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteUser" });

  return (dispatch) => {
    dispatch(request());
    if (id !== "") {
      api.deleteUser(id).then(
        (data) => {
          dispatch(success(data));
          dispatch(getUsers(headers));
        },
        (error) => dispatch(failure(error))
      );
    }
  };
};

const getUsers = (headers) => (dispatch) =>
  new Promise(function (resolve, reject) {
    const request = () => {
      return { type: userActionTypes.GET_USERS_REQUEST };
    };
    const success = (data) => {
      return { type: userActionTypes.GET_USERS_SUCCESS, data };
    };
    const failure = (error) => {
      return { type: userActionTypes.GET_USERS_FAILURE, error };
    };

    api.setHeaders({ ...headers, systemHeader_subsysref: "getUsers" });

    dispatch(request());

    api.getUsers().then(
      (response) => {
        if (response === undefined || response === null) {
          dispatch(failure());
          throw new Error("No response available for getUsers");
        }
        if (!Array.isArray(response.data.Users)) {
          dispatch(success([])); //Show a blank list if no user data was provided
          resolve();
        } else {
          dispatch(success(response.data));
          resolve();
        }
      },
      (error) => {
        dispatch(failure(error));
        reject(error);
      }
    );
  });

const getUserById = (id, headers) => {
  const request = () => {
    return { type: userActionTypes.GET_USER_BY_ID_REQUEST };
  };
  const success = (data) => {
    return { type: userActionTypes.GET_USER_BY_ID_SUCCESS, data };
  };
  const failure = (error) => {
    return { type: userActionTypes.GET_USER_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getUserById" });

  return (dispatch) => {
    dispatch(request());
    api.getUserById(id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => dispatch(failure(error))
    );
  };
};

const updateSelectedUser = (key, value) => {
  const request = (key, value) => {
    return {
      type: userActionTypes.UPDATE_SELECTED_USER_REQUEST,
      key,
      value,
    };
  };
  const success = (key, value) => {
    return {
      type: userActionTypes.UPDATE_SELECTED_USER_SUCCESS,
      key,
      value,
    };
  };
  const failure = () => {
    return { type: userActionTypes.UPDATE_SELECTED_USER_FAILURE };
  };

  return (dispatch) => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedUser = () => {
  const request = () => {
    return { type: userActionTypes.CLEAR_SELECTED_USER_REQUEST };
  };
  const success = () => {
    return { type: userActionTypes.CLEAR_SELECTED_USER_SUCCESS };
  };

  return (dispatch) => {
    dispatch(request());
    dispatch(success());
  };
};

const getPatientCredentials = (headers) => {
  const request = () => {
    return { type: userActionTypes.GET_PATIENT_CREDENTIALS_REQUEST };
  };
  const success = (data) => {
    return {
      type: userActionTypes.GET_PATIENT_CREDENTIALS_SUCCESS,
      data,
    };
  };
  const failure = (error) => {
    return {
      type: userActionTypes.GET_PATIENT_CREDENTIALS_FAILURE,
      error,
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getPatientCredentials",
  });

  return (dispatch) => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .getPatientCredentials()
        .then((response) => {
          dispatch(success(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(failure(error));
          resolve(error);
        });
    });
  };
};

const createPatientLoginLink = (details, headers) => {
  const request = (details) => {
    return {
      type: userActionTypes.CREATE_PATIENT_LOGIN_LINK_REQUEST,
      details,
    };
  };
  const success = (data) => {
    return { type: userActionTypes.CREATE_PATIENT_LOGIN_LINK_SUCCESS, data };
  };
  const failure = (error) => {
    return { type: userActionTypes.CREATE_PATIENT_LOGIN_LINK_FAILURE, error };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "createPatientLoginLink",
  });

  return (dispatch) => {
    dispatch(request(details));

    api.createPatientLoginLink(details).then(
      (response) => {
        console.log("RESPONSE", response);
        dispatch(success(response));
      },
      (error) => dispatch(failure(error))
    );
  };
};

const checkPatientLoginLink = (details, headers) => {
  const request = (details) => {
    return {
      type: userActionTypes.CHECK_PATIENT_LOGIN_LINK_REQUEST,
      details,
    };
  };
  const success = (data) => {
    return { type: userActionTypes.CHECK_PATIENT_LOGIN_LINK_SUCCESS, data };
  };
  const failure = (error) => {
    return { type: userActionTypes.CHECK_PATIENT_LOGIN_LINK_FAILURE, error };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "checkPatientLoginLink",
  });

  return (dispatch) => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api
        .checkPatientLoginLink(details)
        .then((response) => {
          if (response) {
            dispatch(success(response.data));
            resolve(response.data);
          }
        })
        .catch((error) => {
          console.log("Error checking patient login link: ", error.message);
          dispatch(failure(error));
          resolve();
        });
    });
  };
};

const checkLoginLinkForExistingPatient = (id, headers) => {
  const request = (id) => {
    return {
      type: userActionTypes.CHECK_LOGIN_LINK_FOR_EXISTING_PATIENT_REQUEST,
      id,
    };
  };
  const success = (data) => {
    return {
      type: userActionTypes.CHECK_LOGIN_LINK_FOR_EXISTING_PATIENT_SUCCESS,
      data,
    };
  };
  const failure = (error) => {
    return {
      type: userActionTypes.CHECK_LOGIN_LINK_FOR_EXISTING_PATIENT_FAILURE,
      error,
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "checkLoginLinkForExistingPatient",
  });

  return (dispatch) => {
    dispatch(request(id));

    api.checkLoginLinkForExistingPatient(id).then(
      (response) => {
        if (response) {
          if (response.data.status === false) {
            response.data.valid = false;
          }
          dispatch(success(response.data));
        }
      },
      (error) => dispatch(failure(error))
    );
  };
};

export const userActions = {
  createUser,
  updateUser,
  deleteUser,
  getUserById,
  getUsers,
  updateSelectedUser,
  clearSelectedUser,
  getPatientCredentials,
  createPatientLoginLink,
  checkPatientLoginLink,
  checkLoginLinkForExistingPatient,
};
