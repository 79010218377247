import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "./components/Navigation/PrivateRoute";
import { Login } from "./containers/Login";
import NotFoundPage from "./components/Navigation/NotFoundPage/NotFoundPage";
import { enums } from "../Forms/resources";

import {
  UserListContainer,
  UserEditContainer,
  UserCreateContainer,
} from "../Users/containers";
import {
  PatientListContainer,
  PatientViewContainer,
  PatientCreateContainer,
} from "../Patients/containers";
import {
  FormEditContainer,
  FormTemplateListContainer,
  PreConsultationSelectContainer,
  PreConsultationEmailLinkContainer,
  PreConsultationVerifyContainer,
  PreConsultationPatientLoginContainer,
  PreConsultationProgressContainer,
  PreConsultationCompleteContainer,
} from "../Forms/containers";
import {
  ProductListContainer,
  ProductEditContainer,
  ProductCreateContainer,
} from "../Products/containers";
import {
  TreatmentListContainer,
  TreatmentContainer,
  TreatmentTypeListContainer,
  TreatmentTypeContainer,
  FollowUpNoteContainer,
} from "../Treatments/containers";

const Routes = (props) => {
  return (
    <Switch>
      <Route exact path="/login" render={(props) => <Login {...props} />} />
      <PrivateRoute
        exact
        path="/users"
        roles={["pulsion"]}
        component={UserListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/users/create"
        roles={["pulsion"]}
        component={UserCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/users/edit/:id"
        roles={["pulsion"]}
        component={UserEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/users/view/:id"
        roles={["pulsion"]}
        component={UserEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/forms/"
        roles={["pulsion", "admin", "doctor", "receptionist"]}
        component={FormTemplateListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path={`/forms/${enums.formType.NEW_PATIENT_REGISTRATION.link}`}
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={FormEditContainer}
        {...props.injectedProps}
        formType={enums.formType.NEW_PATIENT_REGISTRATION.link}
      />
      <PrivateRoute
        exact
        path={`/forms/${enums.formType.AESTHETIC_INTEREST.link}`}
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={FormEditContainer}
        {...props.injectedProps}
        formType={enums.formType.AESTHETIC_INTEREST.link}
      />
      <PrivateRoute
        exact
        path={`/forms/${enums.formType.FOLLOW_UP.link}`}
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={FormEditContainer}
        {...props.injectedProps}
        formType={enums.formType.FOLLOW_UP.link}
      />
      <PrivateRoute
        exact
        path={`/forms/${enums.formType.RETURNING_PATIENT.link}`}
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={FormEditContainer}
        {...props.injectedProps}
        formType={enums.formType.RETURNING_PATIENT.link}
      />
      <PrivateRoute
        exact
        path={`/forms/${enums.formType.CONSENT_BOTOX.link}`}
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={FormEditContainer}
        {...props.injectedProps}
        formType={enums.formType.CONSENT_BOTOX.link}
      />
      <PrivateRoute
        exact
        path={`/forms/${enums.formType.CONSENT_DERMAL_FILLERS.link}`}
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={FormEditContainer}
        {...props.injectedProps}
        formType={enums.formType.CONSENT_DERMAL_FILLERS.link}
      />
      <PrivateRoute
        exact
        path={`/forms/${enums.formType.CONSENT_COVID.link}`}
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={FormEditContainer}
        {...props.injectedProps}
        formType={enums.formType.CONSENT_COVID.link}
      />
      <PrivateRoute
        exact
        path={`/forms/${enums.formType.CONSENT_UPPER_BLEPHAROPLASTY.link}`}
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={FormEditContainer}
        {...props.injectedProps}
        formType={enums.formType.CONSENT_UPPER_BLEPHAROPLASTY.link}
      />
      <PrivateRoute
        exact
        path={`/forms/${enums.formType.CONSENT_UPPER_LOWER_BLEPHAROPLASTY.link}`}
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={FormEditContainer}
        {...props.injectedProps}
        formType={enums.formType.CONSENT_UPPER_LOWER_BLEPHAROPLASTY.link}
      />
      <PrivateRoute
        exact
        path={`/forms/${enums.formType.CONSENT_CO2RE.link}`}
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={FormEditContainer}
        {...props.injectedProps}
        formType={enums.formType.CONSENT_CO2RE.link}
      />
      <PrivateRoute
        exact
        path={`/forms/${enums.formType.CONSENT_PROFOUND.link}`}
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={FormEditContainer}
        {...props.injectedProps}
        formType={enums.formType.CONSENT_PROFOUND.link}
      />
      <PrivateRoute
        exact
        path={`/forms/${enums.formType.CONSENT_FACE_AND_NECK.link}`}
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={FormEditContainer}
        {...props.injectedProps}
        formType={enums.formType.CONSENT_FACE_AND_NECK.link}
      />
      <PrivateRoute
        exact
        path="/forms/preconsultation/select/"
        roles={["pulsion", "admin", "doctor", "receptionist"]}
        component={PreConsultationSelectContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/forms/preconsultation/emailLink/"
        roles={["pulsion", "admin", "doctor", "receptionist"]}
        component={PreConsultationEmailLinkContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/forms/preconsultation/verify/"
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={PreConsultationVerifyContainer}
        {...props.injectedProps}
      />
      <Route
        exact
        path="/forms/preconsultation/patientLogin/:id"
        component={PreConsultationPatientLoginContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/forms/preconsultation/progress/"
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={PreConsultationProgressContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/forms/preconsultation/complete/"
        roles={["pulsion", "admin", "doctor", "receptionist", "patient"]}
        component={PreConsultationCompleteContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/patients/"
        roles={["pulsion", "admin", "doctor", "receptionist"]}
        component={PatientListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/patients/create"
        roles={["pulsion", "admin", "doctor", "receptionist"]}
        component={PatientCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/patients/edit/:id"
        roles={["pulsion", "admin", "doctor", "receptionist"]}
        component={PatientViewContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/patients/view/:id"
        roles={["pulsion", "admin", "doctor", "receptionist"]}
        component={PatientViewContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/products/"
        roles={["pulsion", "admin", "doctor"]}
        component={ProductListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/products/create"
        roles={["pulsion", "admin", "doctor"]}
        component={ProductCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/products/:id"
        roles={["pulsion", "admin", "doctor"]}
        component={ProductEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/treatments"
        roles={["pulsion", "admin", "doctor", "receptionist"]}
        component={TreatmentListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/treatments/create"
        roles={["pulsion", "admin", "doctor"]}
        component={TreatmentContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/treatments/followUpNote/create"
        roles={["pulsion", "admin", "doctor"]}
        component={FollowUpNoteContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/treatments/:id"
        roles={["pulsion", "admin", "doctor", "receptionist"]}
        component={TreatmentContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/treatments/followUpNote/:id"
        roles={["pulsion", "admin", "doctor", "receptionist"]}
        component={FollowUpNoteContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/treatmentTypes"
        roles={["pulsion", "admin"]}
        component={TreatmentTypeListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/treatmentTypes/create"
        roles={["pulsion", "admin"]}
        component={TreatmentTypeContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/treatmentTypes/:id"
        roles={["pulsion", "admin"]}
        component={TreatmentTypeContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/"
        roles={["pulsion", "admin", "doctor", "receptionist"]}
        component={PatientListContainer}
        {...props.injectedProps}
      />
      <Route
        exact
        path="/notFound"
        component={NotFoundPage}
        {...props.injectedProps}
      />
      <Redirect to="/notFound" />
    </Switch>
  );
};

Routes.propTypes = {
  injectedProps: PropTypes.object.isRequired,
};

export default Routes;
