import React from "react";
import PropTypes from "prop-types";
import { Table, Form } from "semantic-ui-react";
import { strings } from "../../resources";

const ProductsTable = (props) => {
  return (
    <Table celled className="productTable">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={8}>
            {strings.form.label.productHeader}
            {props.selectedTreatmentData.treatment_performed_today ===
              strings.form.label.radioYes && (
              <span className="requiredAsterisk"> *</span>
            )}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>{strings.form.label.productName}</Table.HeaderCell>
          <Table.HeaderCell>{strings.form.label.productType}</Table.HeaderCell>
          <Table.HeaderCell>{strings.form.label.productBatch}</Table.HeaderCell>
          <Table.HeaderCell>
            {strings.form.label.productExpiryDate}
          </Table.HeaderCell>
          <Table.HeaderCell>{strings.form.label.productUsed}</Table.HeaderCell>
          <Table.HeaderCell>
            {strings.form.label.productConcentration}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {strings.form.label.productVolume}
            <span className="requiredAsterisk"> *</span>
          </Table.HeaderCell>
          <Table.HeaderCell>
            {strings.form.label.productInjectionDevice}
            <span className="requiredAsterisk"> *</span>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.productListData.map((product) => {
          const productSelected = props.selectedTreatmentData[
            "selected_products"
          ]
            ? props.selectedTreatmentData[
                "selected_products"
              ].hasOwnProperty(product.id)
            : false;
          const productData = productSelected
            ? props.selectedTreatmentData["selected_products"][product.id]
            : false;
          const productInjectionDevices = productData
            ? productData["injection_devices"] || {}
            : {};
          return (
            <Table.Row error={props.errorFields.includes("selected_products")}>
              <Table.Cell>{product.product_name}</Table.Cell>
              <Table.Cell>{product.product_type}</Table.Cell>
              <Table.Cell>{product.batch_name}</Table.Cell>
              <Table.Cell>{product.expiry_date}</Table.Cell>
              <Table.Cell textAlign="center">
                <Form.Checkbox
                  onChange={props.handleProductSelectChange}
                  product_id={product.id}
                  checked={productSelected}
                  disabled={props.mode === "view"}
                />
              </Table.Cell>
              <Table.Cell
                disabled={!productSelected || props.mode === "view"}
                active={!productSelected}
              >
                <Form.Input
                  name="concentration"
                  transparent
                  onChange={props.handleProductDataChange}
                  product_id={product.id}
                  value={
                    productData !== false
                      ? productData["concentration"] || ""
                      : ""
                  }
                />
              </Table.Cell>
              <Table.Cell
                disabled={!productSelected || props.mode === "view"}
                active={!productSelected}
              >
                <Form.Input
                  name="volume"
                  placeholder={strings.form.placeholder.productVolume}
                  transparent
                  onChange={props.handleProductDataChange}
                  product_id={product.id}
                  value={
                    productData !== false ? productData["volume"] || "" : ""
                  }
                />
              </Table.Cell>
              <Table.Cell
                disabled={!productSelected || props.mode === "view"}
                active={!productSelected}
              >
                <Form.Checkbox
                  name="needle"
                  group="injection_devices"
                  disabled={!productSelected || props.mode === "view"}
                  label={strings.form.label.injectionNeedle}
                  onChange={props.handleProductCheckboxChange}
                  product_id={product.id}
                  checked={productInjectionDevices["needle"] || false}
                />
                <Form.Checkbox
                  name="cannula"
                  group="injection_devices"
                  disabled={!productSelected || props.mode === "view"}
                  label={strings.form.label.injectionCannula}
                  onChange={props.handleProductCheckboxChange}
                  product_id={product.id}
                  checked={productInjectionDevices["cannula"] || false}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

ProductsTable.propTypes = {
  errorFields: PropTypes.array,
  handleProductCheckboxChange: PropTypes.func,
  handleProductDataChange: PropTypes.func,
  handleProductSelectChange: PropTypes.func,
  mode: PropTypes.string,
  productListData: PropTypes.array,
  selectedTreatmentData: PropTypes.object,
};

export default ProductsTable;
